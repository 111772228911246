import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
  background: #f4f4f4;
  gap: 24px;
  height: 100vw;
`;

const HeaderBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BreadCrumbsBox = styled.div`
  display: flex;
  gap: 16px;
  text-align: center;
  font-family: 'IBM Plex Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  p {
    text-decoration: none;
    color: var(--primary-400, #0f62fe);
    a {
      text-decoration: none;
      color: #5f5f5f;
    }
  }
  span {
    cursor: pointer;
    color: #5f5f5f;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
  }
`;

const BoxContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 24px;
`;

const SwitcherBox = styled.div<{ activeSection?: string }>`
  display: flex;
  width: 100%;
  div {
    padding: 14px;
    cursor: pointer;
    color: #686868;
    border-bottom: none;
    font-size: 20px;
    font-weight: 500;
    line-height: 18px; /* 90% */
    letter-spacing: 0.16px;
    display: flex;
    &.active {
      color: var(--White, #5784f7);
      border-bottom: 2px solid #5784f7;
    }
  }
`;

const Box = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`;

const TitleButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export { Container, HeaderBox, TitleBox, BoxContent, SwitcherBox, Box, TitleButtons };
