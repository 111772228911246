import colors from 'global_styling/colors';
import styled from 'styled-components';
import { ReactComponent as LogoNotifications } from 'assets/img/LogoNotifications.svg';
import { NavLink } from 'react-router-dom';

const HeaderMenu = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 16px 24px 16px 0px;
  background: #fff;
  justify-content: flex-end;
  border-bottom: 1px solid #e4e4e4;
  max-height: 70px;
`;

const HeaderNav = styled.div<{ isSpecialUser: boolean }>`
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
`;

const IconNotifications = styled(LogoNotifications)`
  width: 50px;
  height: 50px;
  cursor: pointer;

  &:hover {
    border: 1px solid #8757f7;
    border-radius: 50%;
  }
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
  .active {
    color: #5784f7;
    path {
      stroke: #5784f7;
    }
  }
  :hover {
    color: #5784f7;
    path {
      stroke: #5784f7;
    }
  }
`;

const UserInitialsCircle = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #faebec;
  color: #a62b35;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.75px;
`;

const NotificationBadge = styled.span`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #f8b801;
  color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
`;

const IconWithBadge = styled.div`
  position: relative;
`;

export const BalanceBlock = styled.div`
  display: flex;
  padding: 8px 12px;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  max-height: 40px;
  border-radius: 24px;
  background: var(--neutral-100, #f4f4f4);
  cursor: pointer;
  border: 1px solid #f4f4f4;
  margin-left: auto; /* Альтернативный вариант */

  &:hover {
    border: 1px solid #8757f7;
  }
`;

export const BalanceText = styled.p`
  color: var(--neutral-400, #a0a0a0);
  font-size: 12px;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
`;

export const BalanceAmount = styled.p`
  color: var(--neutral-800, #303030);
  font-size: 16px;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
`;

export const BalanceImage = styled.img`
  width: 16px;
  height: 16px;
`;

export const KYCFailBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fbefc5;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
`;

export const KYCFailContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  div {
    display: flex;
    align-items: center;
    gap: 10px;
    p {
      color: #f1c21b;

      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
`;

export {
  HeaderMenu,
  HeaderNav,
  IconNotifications,
  UserInitialsCircle,
  StyledNavLink,
  NotificationBadge,
  IconWithBadge,
};
