import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  Title,
  Table,
  TableHeader,
  TableRowHeader,
  TableRow,
  TableCell,
} from '../ApplicationBusinessList/ApplicationBusinessList.style';
import { useAppContext } from 'contexts/AppContext';
import {
  CircleAvatar,
  getRandomGradient,
  OwnerText,
  StatusCircle,
  StatusText,
} from './Groups.style';
import { GroupData } from './Groups.interface';
import { default as GroupIconEdit } from 'assets/img/GroupIconEdit.svg';
import { default as GroupIconExit } from 'assets/img/GroupIconExit.svg';
import { default as GroupIconAccept } from 'assets/img/GroupIconAccept.svg';
import { default as GroupIconDecline } from 'assets/img/GroupIconDecline.svg';
import SuccessModal from 'components/common/SuccessModal/SuccessModal';
import { ErrorModal } from 'components/common/ErrorModal';
import Hint from 'components/common/Hint/Hint';
import { StyledTooltip } from 'index.style';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

export const Groups = () => {
  const navigate = useNavigate();
  const { userDataLight } = useAppContext();

  const [groupData, setGroupData] = useState<GroupData[]>([]);
  const [successMessage, setSuccessMessage] = useState<string | null>(null); // Message for modal
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false); // State for showing modal
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false); // State for showing error modal
  const [errorHeader, setErrorHeader] = useState<string | null>(null); // Error modal header
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // Error modal message

  const fetchData = () => {
    axios
      .get(`${BASE_URL}/api/v1/ui/group/list`, {
        withCredentials: true,
      })
      .then((response) => {
        setGroupData(response.data);
      })
      .catch((error) => {
        console.error('Ошибка загрузки данных:', error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleGroupClick = (groupId: string) => {
    navigate(`/group/${groupId}`);
  };

  const handleGroupAction = (groupId: string, accept: boolean) => {
    const actionText = accept ? 'приняли' : 'покинули';
    const successMessage = `Вы успешно ${actionText} группу`;

    axios
      .post(`${BASE_URL}/api/v1/ui/group/${groupId}/accept?accept=${accept}`, null, {
        withCredentials: true,
      })
      .then(() => {
        setSuccessMessage(successMessage);
        setIsSuccessModalVisible(true);

        setTimeout(() => {
          window.location.href = '/groups';
        }, 2500);
      })
      .catch(() => {
        const errorMessage = accept
          ? 'Не удалось принять приглашение'
          : 'Не удалось покинуть группу';
        handleError('Ошибка', errorMessage);
      });
  };

  const handleError = (header: string, message: string) => {
    setErrorHeader(header);
    setErrorMessage(message);
    setIsErrorModalVisible(true);
  };

  const handleCloseErrorModal = () => {
    setIsErrorModalVisible(false);
  };

  const handleCloseModal = () => {
    setIsSuccessModalVisible(false); // Hide the modal
  };

  return (
    <Container>
      {isSuccessModalVisible && (
        <SuccessModal
          onClose={handleCloseModal}
          title='Успех!'
          message={successMessage}
          buttonText='ОК'
        />
      )}

      {isErrorModalVisible && (
        <ErrorModal
          headerError={errorHeader}
          textError={errorMessage}
          buttonClose='Закрыть'
          onClick={handleCloseErrorModal}
        />
      )}
      <Title>Мои группы</Title>
      <Table>
        <thead>
          <TableRowHeader>
            <TableHeader>Название группы</TableHeader>
            <TableHeader>Статус в группе</TableHeader>
            <TableHeader>Владелец группы</TableHeader>
            <TableHeader width='100px'>Действие</TableHeader>
          </TableRowHeader>
        </thead>
        <tbody>
          {groupData.map((group) => (
            <TableRow
              key={group.group}
              onClick={() => handleGroupClick(group.group)}
              style={{ cursor: 'pointer' }}>
              <TableCell>
                <div>
                  {group.logo ? (
                    <img src={group.logo} alt={group.name} style={{ marginRight: '8px' }} />
                  ) : (
                    <CircleAvatar gradient={getRandomGradient()} />
                  )}
                  {group.name}
                </div>
              </TableCell>
              <TableCell>
                {group.status.code === 'INVITED' || group.status.code === 'ON_MODERATE' ? (
                  <StatusText color='blue'>{group.status.text_ru}</StatusText>
                ) : (
                  <>
                    <StatusCircle color={group.owner ? '#29E584' : '#D8D8D8'} />
                    {group.owner ? 'Владелец' : 'Участник'}
                  </>
                )}
              </TableCell>
              <TableCell>
                <div>
                  <Hint text={group.ownerName} truncatedLength={34} />
                  {group.owner && <OwnerText>(Вы)</OwnerText>}
                </div>
              </TableCell>

              <TableCell>
                {group.status.code === 'INVITED' ? (
                  <>
                    <>
                      <img
                        src={GroupIconAccept}
                        alt='Accept Invitation'
                        style={{ cursor: 'pointer', marginRight: '8px' }}
                        data-tooltip-id='group-accept-tooltip'
                        onClick={(e) => {
                          e.stopPropagation();
                          handleGroupAction(group.group, true); // Accept group invitation
                        }}
                      />
                      <StyledTooltip id='group-accept-tooltip' place='bottom'>
                        Принять предложение
                      </StyledTooltip>
                    </>
                    <>
                      <img
                        src={GroupIconDecline}
                        alt='Decline Invitation'
                        style={{ cursor: 'pointer' }}
                        data-tooltip-id='group-decline-tooltip'
                        onClick={(e) => {
                          e.stopPropagation();
                          handleGroupAction(group.group, false); // Decline group invitation
                        }}
                      />
                      <StyledTooltip id='group-decline-tooltip' place='bottom'>
                        Отклонить предложение
                      </StyledTooltip>
                    </>
                  </>
                ) : group.status.code === 'ON_MODERATE' ? (
                  // No actions for ON_MODERATE status
                  <></>
                ) : group.owner ? (
                  <>
                    <img
                      src={GroupIconEdit}
                      alt='Edit Group'
                      style={{ cursor: 'pointer' }}
                      data-tooltip-id='group-edit-tooltip'
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/group/${group.group}`);
                      }}
                    />
                    <StyledTooltip id='group-edit-tooltip' place='bottom'>
                      Редактировать группу
                    </StyledTooltip>
                  </>
                ) : // Если пользователь не владелец и не в статусе INVITED/ON_MODERATE
                // Но при этом проверяем, является ли это группой ALL
                group.group === 'ALL' ? (
                  // Если группа ALL - вообще не показываем иконку «Покинуть»
                  <></>
                ) : (
                  <>
                    <img
                      src={GroupIconExit}
                      alt='Exit Group'
                      style={{ cursor: 'pointer' }}
                      data-tooltip-id='group-exit-tooltip'
                      onClick={(e) => {
                        e.stopPropagation();
                        handleGroupAction(group.group, false); // Decline group invitation
                      }}
                    />
                    <StyledTooltip id='group-exit-tooltip' place='bottom'>
                      Покинуть группу
                    </StyledTooltip>
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};
