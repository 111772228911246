import styled from 'styled-components';
import colors from 'global_styling/colors';

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ErrorDialog = styled.div`
  display: flex;
  align-items: center;
  width: 400px;
  padding: 32px;
  background-color: #fefefe;
  border: 2px solid var(--functional-negative, #ffc700);
  border-radius: 8px;
  flex-direction: column;
  text-align: center;
  gap: 10px;
  position: relative;

  img {
    width: 88px;
    height: 88px;
    margin-bottom: 16px;
  }

  h2 {
    color: #000;
    font-family: 'IBM Plex Sans';
    font-size: 28px;
    font-weight: 600;
    line-height: 40px;
  }

  p {
    color: var(--fordevs-gray-900, #242527);
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }

  button {
    margin-top: 16px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0px;
  right: 12px;
  background: none;
  border: none;
  font-size: 32px;
  font-weight: bold;
  cursor: pointer;
  color: #5784f7;
`;

export { ModalBackground, ErrorDialog, CloseButton };
