import React, { useState } from 'react';
import { Container } from './TwoStep.style';
import { Box, BoxContent, BoxButtons } from './FourStep.style';
import AproveGIF from 'assets/img/AproveGIF.gif';
import { NewButton } from 'components/common/Button';
import { FourStepProps } from './NewCreateApplication.interface';
import { ErrorModal } from 'components/common/ErrorModal';

const FourStep: React.FC<FourStepProps> = ({ idApplication }) => {
  const [error, setError] = useState<string | null>(null);

  const closeModal = () => {
    setError(null);
  };

  return (
    <Container>
      <Box>
        <img src={AproveGIF} alt='IconDone' />
        <BoxContent>
          <h3>Успех!</h3>
          <p>Заявка на финансирование создана</p>
          <BoxButtons>
            <NewButton
              text='Посмотреть черновик'
              className='white'
              onClick={() => {
                window.location.href = `/request/${idApplication}`;
              }}
            />
          </BoxButtons>
        </BoxContent>
      </Box>
      {error && (
        <ErrorModal
          headerError='Ошибка'
          textError={error}
          buttonClose='Закрыть'
          onClick={closeModal}
        />
      )}
    </Container>
  );
};

export default FourStep;
