import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
`;

export const BoxScale = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 80%;
`;

export const ScaleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  gap: 8px;
  span {
    color: var(--Text-text-primary, #161616);
    font-family: 'IBM Plex Sans';
    font-size: 14px;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.16px;
  }
`;

export const ScaleLine = styled.div`
  flex: 1;
  height: 2px;
  background: linear-gradient(to right, #30e681, #fef40f, #f33030);
  position: relative;
`;

interface IndicatorProps {
  percentage: number;
  color: string;
}

export const Indicator = styled.div<IndicatorProps>`
  position: absolute;
  top: -10px;
  left: ${(props) => props.percentage}%;
  width: 20px;
  height: 20px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
`;

interface ValueTextProps {
  percentage?: number;
  color: string;
}

export const ValueText = styled.div<ValueTextProps>`
  position: absolute;
  top: 10px;
  left: ${(props) => props.percentage}%;
  color: #303030;
  font-family: 'IBM Plex Sans';
  font-size: 14px;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.16px;
`;

export const Value = styled.p<ValueTextProps>`
  color: ${(props) => props.color};
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 39px; /* 162.5% */
  margin-left: 20px;
`;

export const Header = styled.p`
  color: var(--Text-text-secondary, #525252);
  font-family: 'IBM Plex Sans';
  font-size: 16px;
  font-weight: 500;
  line-height: 16px; /* 100% */
  letter-spacing: 0.32px;
`;

export const MainInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const BoxSimple = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  border: 1px solid var(--neutral-800, #303030);
  border-radius: 8px;
  color: var(--neutral-800, #303030);
  font-size: 18px;
  font-weight: 600;
  line-height: 125%;
  margin-top: 8px;
`;

interface BoxBoolProps {
  color: string;
}

export const BoxBool = styled.div<BoxBoolProps>`
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  border: 1px solid var(--neutral-800, #303030);
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  line-height: 125%;
  margin-top: 8px;
  color: ${(props) => props.color};
`;

export const BoxRecommended = styled.div`
  display: flex;
  justify-content: center;
  gap: 14px;
  padding: 8px 12px;
  border: 1px solid var(--neutral-800, #303030);
  border-radius: 8px;
  color: var(--neutral-800, #303030);
  font-size: 18px;
  font-weight: 600;
  line-height: 125%;
  margin-top: 20px;
`;

export const AIMainInfoTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  border-radius: 8px 8px 8px 8px;
  color: #8757f7;
  font-size: 18px;
  font-weight: 600;
  line-height: 125%; /* 23.193px */
  cursor: pointer;
  padding: 24px;
  width: 100%;
  background: linear-gradient(90deg, rgba(135, 87, 247, 0.05) 0%, rgba(135, 87, 247, 0) 100%), #fff;
  p {
    color: var(--neutral-400, #a0a0a0);
    font-size: 18px;
    font-weight: 600;
    line-height: 125%;
  }
`;

export const AIIsPro = styled.div`
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  background: #8757f7;

  color: var(--Text-text-inverse, #fff);
  font-feature-settings:
    'liga' off,
    'clig' off;
  font-size: 16px;
  font-weight: 600;
  line-height: 125%; /* 20px */
`;

export const AITableContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  padding: 0px 24px 24px 24px;
  background: linear-gradient(90deg, rgba(135, 87, 247, 0.05) 0%, rgba(135, 87, 247, 0) 100%), #fff;
`;

// Анимация для блесток
const sparkleAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

// Анимация переливания
const shimmerAnimation = keyframes`
  0% {
    opacity: 0.8;
    transform: translateX(-150%);
  }
  50% {
    opacity: 1;
    transform: translateX(150%);
  }
  100% {
    opacity: 0.8;
    transform: translateX(-150%);
  }
`;

export const AIMainInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #8757f7;
  background: linear-gradient(90deg, rgba(135, 87, 247, 0.05) 0%, rgba(135, 87, 247, 0) 100%), #fff;
  border-radius: 6px;
  position: relative;
  overflow: hidden;

  /* Анимация блесток */
  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 300%;
    height: 300%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0) 70%);
    background-size: 50px 50px;
    background-repeat: repeat;
    animation: ${sparkleAnimation} 4s infinite linear;
    pointer-events: none;
    z-index: 1;
  }

  /* Переливающаяся полоса */
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0.6) 50%,
      rgba(255, 255, 255, 0.3) 100%
    );
    animation: ${shimmerAnimation} 3s infinite linear;
    pointer-events: none;
    z-index: 1;
  }

  /* Легкий блеск вокруг */
  box-shadow:
    0 0 20px rgba(135, 87, 247, 0.5),
    0 0 40px rgba(135, 87, 247, 0.3);

  /* Содержимое всегда сверху */
  > * {
    position: relative;
    z-index: 2;
  }
`;

export const StyledSummary = styled.div`
  color: #00002f;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  h3 {
    font-weight: 700;
  }
`;
