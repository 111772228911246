import React, { useEffect, useState } from 'react';
import {
  Container,
  StyledTable,
  StyledTableCell,
  StyledTableHead,
  StyledTableRow,
  TableContainer,
  MainInfoBox,
  AllSum,
  ImgContainer,
} from 'components/BankStatement/BankStatement.style';
import axios from 'axios';
import IconArrow from 'assets/img/IconArrow.svg';
import AIMagicIcon from 'assets/img/AIMagicIcon.svg';
import BlurReport from 'assets/img/BlurReport.webp';
import AIBlurReport from 'assets/img/AIBlurReport.webp';
import IconArrowDown from 'assets/img/IconArrowDown.svg';
import {
  ArrowIcon,
  ExpandableSection,
  MainInfoTitle,
} from 'components/ApplicationInvestor/ApplicationInvestorNew.style';
import { GroupedStatuses, ScoringInfoProps, StatusReportItem } from './InfoEGZNew.interface';
import IconDocuments from 'assets/img/IconDocuments.svg';
import Hint from 'components/common/Hint/Hint';
import { LoadingGif } from 'components/common/LoadingGif';
import {
  AIIsPro,
  AIMainInfoBox,
  AIMainInfoTitle,
  AITableContainer,
  StyledSummary,
} from './InfoEGZNew.style';
import IconPro from 'assets/img/IconPro.svg';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;

const monthNames = [
  '',
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

const InfoEGZNew: React.FC<ScoringInfoProps> = ({ id }) => {
  const [reportData, setReportData] = useState<any>(null);
  const [expandedYears, setExpandedYears] = useState<{ [key: string]: boolean }>({});
  const [expandedSections, setExpandedSections] = useState<{ [key: string]: boolean }>({});
  const [proAccessed, setProAccessed] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchReportData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}cabinet/investor/request/creditor/report/egz/${id}`,
          {
            withCredentials: true,
          },
        );
        setReportData(response.data.reports);
        setProAccessed(response.data.proAccessed);
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setLoading(false);
      }
    };

    fetchReportData();
  }, [id]);

  const toggleYear = (year: string) => {
    setExpandedYears((prev) => ({ ...prev, [year]: !prev[year] }));
  };

  const toggleSection = (key: string) => {
    setExpandedSections((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const groupDataByYear = (data: any[]) => {
    return data.reduce((acc: any, item: any) => {
      if (!acc[item.year]) {
        acc[item.year] = { months: [], total: null };
      }
      if (item.month === null) {
        acc[item.year].total = item; // This is the yearly total
      } else {
        acc[item.year].months.push(item); // This is a monthly entry
      }
      return acc;
    }, {});
  };

  const renderDynamicsReport = () => {
    if (!reportData || !reportData.dynamics || reportData.dynamics.report.length === 0) {
      return null;
    }

    const groupedData = groupDataByYear(reportData.dynamics.report);

    return (
      <MainInfoBox>
        <MainInfoTitle onClick={() => toggleSection('dynamics')}>
          <img src={IconDocuments} alt='icon' />
          Динамика сделок ГЗ
          {reportData?.dynamics?.pro && <img src={IconPro} alt='iconPro' />}
          <ArrowIcon src={expandedSections['dynamics'] ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {expandedSections['dynamics'] && (
          <TableContainer>
            {!proAccessed && reportData.dynamics.pro ? (
              <ImgContainer>
                <img src={BlurReport} alt='Pro-only report' />
              </ImgContainer>
            ) : (
              <StyledTable>
                <StyledTableHead>
                  <StyledTableRow>
                    <StyledTableCell>Год, месяц</StyledTableCell>
                    <StyledTableCell>Количество</StyledTableCell>
                    <StyledTableCell className='right'>Сумма</StyledTableCell>
                    <StyledTableCell className='right'>Средний чек</StyledTableCell>
                  </StyledTableRow>
                </StyledTableHead>
                <tbody>
                  {Object.keys(groupedData).map((year, index) => (
                    <React.Fragment key={index}>
                      <StyledTableRow onClick={() => toggleYear(year)}>
                        <StyledTableCell colSpan={1}>
                          {year}
                          <ArrowIcon
                            src={expandedYears[year] ? IconArrowDown : IconArrow}
                            alt='arrow'
                            style={{ float: 'right' }}
                          />
                        </StyledTableCell>
                        <StyledTableCell>{groupedData[year].total?.count}</StyledTableCell>
                        <StyledTableCell className='right'>
                          {parseFloat(groupedData[year].total?.sum).toLocaleString('ru-RU')} KZT
                        </StyledTableCell>
                        <StyledTableCell className='right'>
                          {parseFloat(groupedData[year].total?.avg).toLocaleString('ru-RU')} KZT
                        </StyledTableCell>
                      </StyledTableRow>
                      {expandedYears[year] &&
                        groupedData[year].months.map((monthItem: any, monthIndex: number) => (
                          <StyledTableRow key={monthIndex}>
                            <StyledTableCell className='open' style={{ paddingLeft: '48px' }}>
                              {monthNames[monthItem.month]}
                            </StyledTableCell>
                            <StyledTableCell className='open'>{monthItem.count}</StyledTableCell>
                            <StyledTableCell className='open right'>
                              {parseFloat(monthItem.sum).toLocaleString('ru-RU')} KZT
                            </StyledTableCell>
                            <StyledTableCell className='open right'>
                              {parseFloat(monthItem.avg).toLocaleString('ru-RU')} KZT
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </StyledTable>
            )}
          </TableContainer>
        )}
      </MainInfoBox>
    );
  };

  const renderCustomerReport = () => {
    if (!reportData || !reportData.customers || reportData.customers.report.length === 0) {
      return null;
    }

    const totalSum = reportData.customers.report.reduce((acc: number, customer: any) => {
      return acc + parseFloat(customer.sum);
    }, 0);

    return (
      <MainInfoBox>
        <MainInfoTitle onClick={() => toggleSection('customers')}>
          <img src={IconDocuments} alt='icon' />
          Ключевые заказчики за последний год
          {reportData?.customers?.pro && <img src={IconPro} alt='iconPro' />}
          <ArrowIcon src={expandedSections['customers'] ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {expandedSections['customers'] && (
          <TableContainer>
            {!proAccessed && reportData.customers.pro ? (
              <ImgContainer>
                <img src={BlurReport} alt='Pro-only report' />
              </ImgContainer>
            ) : (
              <StyledTable>
                <StyledTableHead>
                  <StyledTableRow>
                    <StyledTableCell>Заказчик</StyledTableCell>
                    <StyledTableCell className='right'>Сумма</StyledTableCell>
                  </StyledTableRow>
                </StyledTableHead>
                <tbody>
                  {reportData.customers.report.map((customer: any, index: number) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{customer.name}</StyledTableCell>
                      <StyledTableCell className='right'>
                        {parseFloat(customer.sum).toLocaleString('ru-RU')} KZT
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                  {/* Total row */}
                  <StyledTableRow>
                    <StyledTableCell>Итого</StyledTableCell>
                    <StyledTableCell className='right'>
                      <AllSum>{totalSum.toLocaleString('ru-RU')} KZT</AllSum>
                    </StyledTableCell>
                  </StyledTableRow>
                </tbody>
              </StyledTable>
            )}
          </TableContainer>
        )}
      </MainInfoBox>
    );
  };

  const renderStatusesReport = () => {
    if (!reportData || !reportData.statuses || reportData.statuses.report.length === 0) {
      return null;
    }

    // Group statuses by year and month
    const groupedStatuses: GroupedStatuses = reportData.statuses.report.reduce(
      (acc: GroupedStatuses, item: StatusReportItem) => {
        const year = item.year.toString();
        const month = item.month;
        const status = item.name;

        if (!acc[year]) {
          acc[year] = { months: {}, total: {} };
        }

        if (month === null) {
          acc[year].total[status] = item.sum;
        } else {
          if (!acc[year].months[month]) {
            acc[year].months[month] = {};
          }
          acc[year].months[month][status] = item.sum;
        }

        return acc;
      },
      {} as GroupedStatuses,
    );

    return (
      <MainInfoBox>
        <MainInfoTitle onClick={() => toggleSection('statuses')}>
          <img src={IconDocuments} alt='icon' />
          Статусы договоров
          {reportData?.statuses?.pro && <img src={IconPro} alt='iconPro' />}
          <ArrowIcon src={expandedSections['statuses'] ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {expandedSections['statuses'] && (
          <TableContainer>
            {!proAccessed && reportData.statuses.pro ? (
              <ImgContainer>
                <img src={BlurReport} alt='Pro-only report' />
              </ImgContainer>
            ) : (
              <StyledTable>
                <StyledTableHead>
                  <StyledTableRow>
                    <StyledTableCell>Год, месяц </StyledTableCell>
                    <StyledTableCell className='right'>Действует</StyledTableCell>
                    <StyledTableCell className='right'>Исполнен</StyledTableCell>
                    <StyledTableCell className='right'>Не заключен</StyledTableCell>
                    <StyledTableCell className='right'>Расторгнут</StyledTableCell>
                  </StyledTableRow>
                </StyledTableHead>
                <tbody>
                  {Object.keys(groupedStatuses).map((year, index) => (
                    <React.Fragment key={index}>
                      <StyledTableRow onClick={() => toggleYear(year)}>
                        <StyledTableCell colSpan={1}>
                          {year}
                          <ArrowIcon
                            src={expandedYears[year] ? IconArrowDown : IconArrow}
                            alt='arrow'
                            style={{ float: 'right' }}
                          />
                        </StyledTableCell>
                        <StyledTableCell className='right'>
                          {groupedStatuses[year].total['Действует'] || '-'} KZT
                        </StyledTableCell>
                        <StyledTableCell className='right'>
                          {groupedStatuses[year].total['Исполнен'] || '-'} KZT
                        </StyledTableCell>
                        <StyledTableCell className='right'>
                          {groupedStatuses[year].total['Не заключен'] || '-'} KZT
                        </StyledTableCell>
                        <StyledTableCell className='right'>
                          {groupedStatuses[year].total['Расторгнут'] || '-'} KZT
                        </StyledTableCell>
                      </StyledTableRow>
                      {expandedYears[year] &&
                        Object.keys(groupedStatuses[year].months).map((month) => (
                          <StyledTableRow key={month}>
                            <StyledTableCell className='open' style={{ paddingLeft: '48px' }}>
                              {monthNames[Number(month)]}
                            </StyledTableCell>
                            <StyledTableCell className='right open'>
                              {groupedStatuses[year].months[Number(month)]['Действует'] || '-'} KZT
                            </StyledTableCell>
                            <StyledTableCell className='right open'>
                              {groupedStatuses[year].months[Number(month)]['Исполнен'] || '-'} KZT
                            </StyledTableCell>
                            <StyledTableCell className='right open'>
                              {groupedStatuses[year].months[Number(month)]['Не заключен'] || '-'}{' '}
                              KZT
                            </StyledTableCell>
                            <StyledTableCell className='right open'>
                              {groupedStatuses[year].months[Number(month)]['Расторгнут'] || '-'} KZT
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </StyledTable>
            )}
          </TableContainer>
        )}
      </MainInfoBox>
    );
  };

  const renderProcurementMethodsReport = () => {
    if (!reportData || !reportData.trades || reportData.trades.report.length === 0) {
      return null;
    }

    const groupedTrades: GroupedStatuses = reportData.trades.report.reduce(
      (acc: GroupedStatuses, item: StatusReportItem) => {
        const year = item.year.toString();
        const month = item.month;
        const method = item.name;

        if (!acc[year]) {
          acc[year] = { months: {}, total: {} };
        }

        if (month === null) {
          acc[year].total[method] = item.sum;
        } else {
          if (!acc[year].months[month]) {
            acc[year].months[month] = {};
          }
          acc[year].months[month][method] = item.sum;
        }

        return acc;
      },
      {} as GroupedStatuses,
    );

    // Determine active columns
    const columns = [
      { key: 'Из одного источника', label: 'Источник', hint: 'Из одного источника      ' },
      { key: 'Товарные биржи', label: 'Биржи', hint: 'Товарные биржи     ' },
      { key: 'Аукцион', label: 'Аукцион', hint: 'Аукцион              ' },
      { key: 'Запрос ценовых предложений', label: 'Ценовые', hint: 'Запрос ценовых предложений' },
      { key: 'Электронный магазин', label: 'Магазин', hint: 'Электронный магазин      ' },
      { key: 'Конкурс/Тендер', label: 'Конкурс/Тендер', hint: 'Конкурс/Тендер        ' },
    ];

    const activeColumns = columns.filter(({ key }) => {
      return Object.values(groupedTrades).some(
        (yearData) =>
          yearData.total[key] || Object.values(yearData.months).some((monthData) => monthData[key]),
      );
    });

    const formatValue = (value: string) => {
      const number = parseFloat(value);
      return isNaN(number) ? '- KZT' : `${number.toLocaleString('ru-RU')} KZT`;
    };

    return (
      <MainInfoBox>
        <MainInfoTitle onClick={() => toggleSection('procurementMethods')}>
          <img src={IconDocuments} alt='icon' />
          Способы закупки
          {reportData?.trades?.pro && <img src={IconPro} alt='iconPro' />}
          <ArrowIcon
            src={expandedSections['procurementMethods'] ? IconArrowDown : IconArrow}
            alt='arrow'
          />
        </MainInfoTitle>
        {expandedSections['procurementMethods'] && (
          <TableContainer>
            {!proAccessed && reportData.trades.pro ? (
              <ImgContainer>
                <img src={BlurReport} alt='Pro-only report' />
              </ImgContainer>
            ) : (
              <StyledTable>
                <StyledTableHead>
                  <StyledTableRow>
                    <StyledTableCell>Месяц</StyledTableCell>
                    {activeColumns.map(({ label, hint }) => (
                      <StyledTableCell key={label}>
                        <Hint displayText={label} text={hint} />
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                </StyledTableHead>
                <tbody>
                  {Object.keys(groupedTrades).map((year, index) => (
                    <React.Fragment key={index}>
                      <StyledTableRow onClick={() => toggleYear(year)}>
                        <StyledTableCell colSpan={1}>
                          {year}
                          <ArrowIcon
                            src={expandedYears[year] ? IconArrowDown : IconArrow}
                            alt='arrow'
                            style={{ float: 'right' }}
                          />
                        </StyledTableCell>
                        {activeColumns.map(({ key }) => (
                          <StyledTableCell key={key} className='right'>
                            {formatValue(groupedTrades[year].total[key] || '-')}
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                      {expandedYears[year] &&
                        Object.keys(groupedTrades[year].months).map((month) => (
                          <StyledTableRow key={month}>
                            <StyledTableCell style={{ paddingLeft: '48px' }} className='open'>
                              {monthNames[Number(month)]}
                            </StyledTableCell>
                            {activeColumns.map(({ key }) => (
                              <StyledTableCell key={key} className='right open'>
                                {formatValue(groupedTrades[year].months[Number(month)][key] || '-')}
                              </StyledTableCell>
                            ))}
                          </StyledTableRow>
                        ))}
                    </React.Fragment>
                  ))}
                  <StyledTableRow>
                    <StyledTableCell>Итого</StyledTableCell>
                    {activeColumns.map(({ key }) => (
                      <StyledTableCell key={key} className='right'>
                        {formatValue(
                          Object.values(groupedTrades)
                            .reduce((sum: number, yearData) => {
                              return sum + (parseFloat(yearData.total[key] || '0') || 0);
                            }, 0)
                            .toString(),
                        )}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                </tbody>
              </StyledTable>
            )}
          </TableContainer>
        )}
      </MainInfoBox>
    );
  };

  const renderAIReport = () => {
    if (!reportData || !reportData.summary || reportData.summary.report.length === 0) {
      return null;
    }

    const summaryContent = reportData.summary.report[0].summary;

    return (
      <AIMainInfoBox>
        <AIMainInfoTitle onClick={() => toggleSection('summary')}>
          <img src={AIMagicIcon} alt='icon' />
          Результаты ИИ аналитики по данным ЭГЗ
          {reportData?.summary?.pro && <AIIsPro>PRO</AIIsPro>}
          <ArrowIcon src={expandedSections['summary'] ? IconArrowDown : IconArrow} alt='arrow' />
        </AIMainInfoTitle>
        {expandedSections['summary'] && (
          <>
            {!proAccessed && reportData.summary.pro ? (
              <ImgContainer>
                <img src={AIBlurReport} alt='Pro-only report' />
              </ImgContainer>
            ) : (
              <AITableContainer>
                <StyledSummary
                  dangerouslySetInnerHTML={{ __html: formatSummary(summaryContent) }}
                />
              </AITableContainer>
            )}
          </>
        )}
      </AIMainInfoBox>
    );
  };

  /**
   * Форматирование содержимого summary
   * - Заменяет `###` на заголовки.
   * - Форматирует текст в виде HTML.
   */
  const formatSummary = (summary: string): string => {
    const formatted = summary.replace(/### (.+)/g, '<h3>$1</h3>').replace(/\n\n/g, '<br/><br/>');
    return formatted;
  };

  const isDataUnavailable =
    (!reportData?.dynamics?.report || reportData.dynamics.report.length === 0) &&
    (!reportData?.customers?.report || reportData.customers.report.length === 0) &&
    (!reportData?.statuses?.report || reportData.statuses.report.length === 0);
  !reportData?.trades?.report || reportData.trades.report.length === 0;
  !reportData?.summary?.report || reportData.summary.report.length === 0;

  if (loading) {
    return <LoadingGif />;
  }

  return (
    <Container>
      {isDataUnavailable ? (
        <div>
          <ExpandableSection>
            <MainInfoTitle>
              <img src={IconDocuments} alt='icon' />
              Данные ЭГЗ отсутствуют
            </MainInfoTitle>
          </ExpandableSection>
        </div>
      ) : (
        <>
          {renderAIReport()}
          {renderDynamicsReport()}
          {renderCustomerReport()}
          {renderStatusesReport()}
          {renderProcurementMethodsReport()}
        </>
      )}
    </Container>
  );
};

export default InfoEGZNew;
