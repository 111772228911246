import React, { useEffect, useMemo, useState } from 'react';
import { Container, LinkContract, TitleBox } from './OffersBusinessList.style';
import {
  ScrollableTableContainer,
  StatusCircle,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TableRowHeader,
} from 'components/ApplicationBusinessList/ApplicationBusinessList.style';
import axios from 'axios';
import { TableData, Request, OffersBusinessListProps } from './OffersBusinessList.interface';
import { Pagination } from 'components/Pagination';
import { formatDate } from 'utils/formatDate';
import { BreadCrumbsBox } from 'components/ApplicationInvestor/ApplicationInvestorNew.style';
import Hint from 'components/common/Hint/Hint';
import { default as GroupIconAccept } from 'assets/img/GroupIconAccept.svg';
import { default as GroupIconDecline } from 'assets/img/GroupIconDecline.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorModal } from 'components/common/ErrorModal';
import SkeletonTable from 'components/SkeletonTable/SkeletonTable';
import { ConfirmModal } from 'components/common/ConfirmModal';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;

export const OffersBusinessList: React.FC<OffersBusinessListProps> = ({ requestId }) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const rowsPerPage = 10;

  const [currentPage, setCurrentPage] = useState(0);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const [isAcceptModalOpen, setAcceptModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [selectedOfferId, setSelectedOfferId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [requestNumber, setRequestNumber] = useState('');
  const [requestNId, setRequestId] = useState('');
  const [requestAmount, setRequestedAmount] = useState<number>(0);
  const [requestRate, setRequestRate] = useState<number>(0);
  const [requestPeriod, setRequestPeriod] = useState('');

  useEffect(() => {
    axios
      .get(`${BASE_URL}cabinet/creditor/request/get?id=${id}`, { withCredentials: true })
      .then((response) => {
        setRequestNumber(response.data.num);
        setRequestId(response.data.id);
        setRequestedAmount(response.data.requestedAmount);
        setRequestPeriod(response.data.requestPeriod);
        setRequestRate(response.data.requestRate);
      })
      .catch((error) => {
        console.error('Ошибка загрузки данных:', error);
      });
  }, [id]);

  const fetchData = () => {
    setIsLoading(true);
    axios
      .get<Request[]>(`${BASE_URL}cabinet/creditor/request/offers/${id}`, {
        withCredentials: true,
      })
      .then((response) => {
        const transformedData: TableData[] = response.data
          .sort((a, b) => b.id - a.id)
          .map((item) => ({
            id: item.id,
            offerPeriod: `${item.offerPeriod} дней`,
            offerSum: item.offerSum.toLocaleString('ru-KZ', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
            dtCreate: formatDate(item.dtCreate),
            offerRate: `${item.offerRate.toLocaleString('ru-KZ', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} %`,
            offerPenaltyRate: `${item.offerPenaltyRate.toLocaleString('ru-KZ', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} %`,
            overpaymentSum: item.overpaymentSum.toLocaleString('ru-KZ', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
            organizationName: item.organizationName,
            offerTransfer: `${item.offerTransfer} дней`,
            offerExtra: item.offerExtra,
            organizationType: item.organizationType,
            approvedAmount: item.approvedAmount
              ? item.approvedAmount.toLocaleString('ru-KZ')
              : 'null',
            status: item.statusName,
            contractId: item.contractId,
          }));

        setTableData(transformedData);
      })
      .catch((error) => {
        setError('Ошибка при загрузке данных. Попробуйте позже.');
        setErrorDialogOpen(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const handleAccept = (offerId: number) => {
    setLoading(true);
    const acceptUrl = `${BASE_URL}cabinet/creditor/request/offers/accept/${offerId}`;
    axios
      .get(acceptUrl, { withCredentials: true })
      .then((response) => {
        navigate(`/contracts/${response.data.contract}`);
      })
      .catch((error) => {
        console.error('Ошибка принятия предложения:', error);
        const errorMessage = error.response?.data?.message || 'Ошибка при принятии предложения.';
        setError(errorMessage);
        setErrorDialogOpen(true);
      })
      .finally(() => {
        setLoading(false);
        setAcceptModalOpen(false);
      });
  };

  const handleReject = (offerId: number) => {
    setLoading(true);
    const rejectUrl = `${BASE_URL}cabinet/creditor/request/offers/reject/${offerId}`;
    axios
      .get(rejectUrl, { withCredentials: true })
      .then(() => {
        fetchData();
        setRejectModalOpen(false);
      })
      .catch((error) => {
        console.error('Ошибка отказа от предложения:', error);
        const errorMessage = error.response?.data?.message || 'Ошибка при отказе от предложения.';
        setError(errorMessage);
        setErrorDialogOpen(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const offset = currentPage * rowsPerPage;
  const currentTableData = tableData.slice(offset, offset + rowsPerPage);

  const handlePageChange = ({ selected }: { selected: number }) => {
    setCurrentPage(selected);
  };

  return (
    <Container>
      {/* REUSABLE CONFIRM MODALS */}
      <ConfirmModal
        isOpen={isAcceptModalOpen && selectedOfferId !== null}
        headerText='Вы уверены, что хотите принять предложение?'
        bodyText='Данное действие переведет сделку в статус Принят'
        confirmText='Принять'
        cancelText='Отменить'
        onConfirm={() => selectedOfferId && handleAccept(selectedOfferId)}
        onCancel={() => setAcceptModalOpen(false)}
        loading={loading}
        confirmButtonClass='green'
        cancelButtonClass='red'
      />

      <ConfirmModal
        isOpen={isRejectModalOpen && selectedOfferId !== null}
        headerText='Вы уверены, что хотите отказаться от предложения?'
        bodyText='Данное действие приведет к отклонению предложения.'
        confirmText='Отклонить'
        cancelText='Отменить'
        onConfirm={() => selectedOfferId && handleReject(selectedOfferId)}
        onCancel={() => setRejectModalOpen(false)}
        loading={loading}
        confirmButtonClass='red'
        cancelButtonClass='green'
      />

      {/* ERROR DIALOG */}
      {errorDialogOpen && (
        <ErrorModal
          headerError='Ошибка'
          textError={error}
          buttonClose='Закрыть'
          onClick={() => setErrorDialogOpen(false)}
        />
      )}

      <BreadCrumbsBox>
        <p>
          <a href='/applications'>Заявки</a>
        </p>
        <p>{'>'}</p>
        <p>
          <a href={`/request/${id}`}>Заявка {requestNumber}</a>
        </p>
        <p>{'>'}</p>
        <p className='select'>Предложения инвесторов</p>
      </BreadCrumbsBox>

      <TitleBox>
        <h2>Предложения</h2>
      </TitleBox>

      {isLoading ? (
        <SkeletonTable />
      ) : (
        <>
          <ScrollableTableContainer>
            <Table>
              <thead>
                <TableRowHeader>
                  <TableHeader>
                    <Hint displayText='Моя заявка' text='' />
                  </TableHeader>
                  <TableHeader className='right'>
                    <Hint displayText='Запр. сумма' text='Моя запрашиваемая сумма' />
                  </TableHeader>
                  <TableHeader className='right'>
                    <Hint
                      displayText='Срок. фин.'
                      text='Период, на который предоставляется финансирование'
                    />
                  </TableHeader>
                  <TableHeader className='right'>
                    <Hint displayText='Старт. фин.' text='Начало периода финансирования в днях' />
                  </TableHeader>
                  <TableHeader className='right'>
                    <Hint
                      displayText='Процент'
                      text='Процентная ставка, применяемая к этой сделке'
                    />
                  </TableHeader>
                  <TableHeader className='right'>
                    <Hint displayText='Пеня/день' text='Штраф за просрочку платежей в день' />
                  </TableHeader>
                  <TableHeader className='right'>
                    <Hint displayText='Сум. перепл.' text='Сумма переплаты по вашей заявке' />
                  </TableHeader>
                  <TableHeader>
                    <Hint displayText='Статус' text='Текущий статус предложения' />
                  </TableHeader>
                  <TableHeader>
                    <Hint displayText='Действие' text='Действие' />
                  </TableHeader>
                </TableRowHeader>
              </thead>
              <tbody>
                <TableRow>
                  <TableCell>
                    <a href={`/request/${id}`}>{requestNumber}</a>
                  </TableCell>
                  <TableCell className='right'>
                    {requestAmount.toLocaleString('ru-KZ', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{' '}
                    ₸
                  </TableCell>
                  <TableCell className='right'>{requestPeriod} дней</TableCell>
                  <TableCell className='right'></TableCell>
                  <TableCell className='right'>
                    {requestRate.toLocaleString('ru-KZ', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{' '}
                    %
                  </TableCell>
                  <TableCell className='right'></TableCell>
                  <TableCell className='right'></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </tbody>
            </Table>
          </ScrollableTableContainer>

          <ScrollableTableContainer>
            <Table>
              <thead>
                <TableRowHeader>
                  <TableHeader>
                    <Hint displayText='Инвестор' text='' />
                  </TableHeader>
                  <TableHeader className='right'>
                    <Hint displayText='Предл. сумма' text='Предложенная сумма инвестора' />
                  </TableHeader>
                  <TableHeader className='right'>
                    <Hint
                      displayText='Срок. фин.'
                      text='Период, на который предоставляется финансирование'
                    />
                  </TableHeader>
                  <TableHeader className='right'>
                    <Hint displayText='Старт. фин.' text='Начало периода финансирования в днях' />
                  </TableHeader>
                  <TableHeader className='right'>
                    <Hint
                      displayText='Процент'
                      text='Процентная ставка, применяемая к этой сделке'
                    />
                  </TableHeader>
                  <TableHeader className='right'>
                    <Hint displayText='Пеня/день' text='Штраф за просрочку платежей в день' />
                  </TableHeader>
                  <TableHeader className='right'>
                    <Hint displayText='Сум. перепл.' text='Сумма переплаты по вашей заявке' />
                  </TableHeader>
                  <TableHeader>
                    <Hint displayText='Статус' text='Текущий статус предложения' />
                  </TableHeader>
                  <TableHeader>
                    <Hint displayText='Действие' text='Действие' />
                  </TableHeader>
                </TableRowHeader>
              </thead>
              <tbody>
                {currentTableData.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <Hint text={row.organizationName} truncatedLength={43} />
                    </TableCell>
                    <TableCell className='right'>{row.offerSum} ₸</TableCell>
                    <TableCell className='right'>{row.offerPeriod}</TableCell>
                    <TableCell className='right'>{row.offerTransfer}</TableCell>
                    <TableCell className='right'>{row.offerRate}</TableCell>
                    <TableCell className='right'>{row.offerPenaltyRate}</TableCell>
                    <TableCell className='right'>{row.overpaymentSum} ₸</TableCell>
                    <TableCell>
                      <div>
                        <StatusCircle $status={row.status} />
                        <Hint text={row.status} truncatedLength={8} />
                      </div>
                    </TableCell>
                    <TableCell>
                      {row.status === 'Принят' && row.contractId ? (
                        <LinkContract href={`/contracts/${row.contractId}`}>
                          К договору
                        </LinkContract>
                      ) : row.status === 'Активный' ? (
                        <div style={{ display: 'flex', gap: '10px' }}>
                          <img
                            src={GroupIconAccept}
                            alt='Принять'
                            style={{ cursor: 'pointer', width: '18px', height: '18px' }}
                            onClick={() => {
                              setSelectedOfferId(row.id);
                              setAcceptModalOpen(true);
                            }}
                          />
                          <img
                            src={GroupIconDecline}
                            alt='Отклонить'
                            style={{ cursor: 'pointer', width: '18px', height: '18px' }}
                            onClick={() => {
                              setSelectedOfferId(row.id);
                              setRejectModalOpen(true);
                            }}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </tbody>
            </Table>
          </ScrollableTableContainer>
          <Pagination
            pageCount={Math.ceil(tableData.length / rowsPerPage)}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </Container>
  );
};
