import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  border-radius: 4px;
  width: 100%;
  max-width: 800px;
  padding: 24px 36px;
  border: 1px solid var(--neutral-300, #bcbcbc);
  background: #fff;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  div {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  h2 {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    line-height: 125%; /* 25px */
  }

  p {
    color: var(--neutral-600, #686868);
    font-size: 14px;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  h3 {
    color: var(--neutral-800, #303030);
    font-size: 20px;
    font-weight: 500;
    line-height: 120%; /* 24px */
  }
`;

export const BalanceRow = styled.div`
  display: flex;
  padding: 8px;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--neutral-100, #f4f4f4);
  h3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 0;
    align-self: stretch;

    color: var(--neutral-800, #303030);
    font-size: 16px;
    font-weight: 500;
    line-height: 120%; /* 19.2px */
  }
`;

export const TokenOptions = styled.div`
  display: flex;
  gap: 12px;
`;

export const TokenOptionsBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 12px;
  background: #f4f4f4;
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    border-radius: 4px;
    border: 1px solid #8757f7;

    background: linear-gradient(90deg, rgba(135, 87, 247, 0.05) 0%, rgba(135, 87, 247, 0) 100%);
  }

  &.selected {
    border-radius: 4px;
    border: 1px solid #8757f7;

    background: linear-gradient(90deg, rgba(135, 87, 247, 0.05) 0%, rgba(135, 87, 247, 0) 100%);
  }
  p {
    color: var(--neutral-400, #a0a0a0);
    font-size: 12px;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
  }
  span {
    color: var(--neutral-900, #141414);
    font-size: 16px;
    font-weight: 600;
    line-height: 125%; /* 20px */
    &.selected {
      color: #8757f7;
    }
  }
`;

export const ButtonBox = styled.div`
  display: flex;
  gap: 12px;
  margin-left: auto;
`;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

export const IconHeader = styled.img`
  width: 34px;
  height: 34px;
`;

export const BalanceBox = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  p {
    color: var(--neutral-800, #303030);
    font-size: 16px;
    font-weight: 500;
    line-height: 120%; /* 19.2px */
  }
`;

export const Line = styled.div`
  height: 2px;
  align-self: stretch;
  background: var(--neutral-100, #f4f4f4);
`;

export const SectionBalanceNew = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const PriceOneToken = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  color: var(--neutral-600, #686868);
  font-size: 14px;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
`;

export const BoxPrice = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 4px;
`;
