import React, { FC, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { sendRequestWithSign } from 'components/api/registration.api';
import { ISignBody } from 'components/api/registration.interface';
import SuccessMessageComponent from './SuccessMessageComponent'; // Import the new component
import {
  Container,
  LeftBlock,
  RightBlock,
  Title,
  Text,
  Button,
  BoxInput,
  BoxCheck,
  LogoImg,
  BoxContent,
  FAQContent,
  FAQIcon,
  FAQSection,
  FAQTitle,
  StyledFAQItem,
} from './ModalRegistration.style';
import { InputText } from 'components/common/Input/InputText';
import { InputTextType } from 'components/common/Input/InputText/InputText';
import { validateEmail } from 'utils/validateEmail';
import InputMask from 'react-input-mask';
import { SelectNew } from 'components/common/Input/SelectNew';
import { Checkbox } from 'components/common/Checkbox';
import { default as Logo } from 'assets/img/Logo.svg';
import { default as IconInfo } from 'assets/img/iconI.png';
import { ProgressIndicator } from 'components/ProgressIndicator';
import { ErrorModal } from 'components/common/ErrorModal';

const faqData = [
  {
    title: 'Выбор роли',
    content: (
      <>
        <div>
          <span>Бизнес привлекает инвестиции, инвестор предоставляет финансирование</span>
          <p>
            Если ваша организация в будущем захочет предоставлять финансирование роль всегда можно
            поменять
          </p>
        </div>
      </>
    ),
  },
  {
    title: 'Почему нам нужны эти данные',
    content: (
      <>
        <div>
          <span>
            Эти данные необходимы для обеспечения вашей безопасности и защиты данных на платформе.
          </span>
          <p>
            Они позволяют идентифицировать вас как надежного контрагента, что способствует
            предотвращению случаев мошенничества и несанкционированного доступа к вашей учетной
            записи
          </p>
        </div>
      </>
    ),
  },
];

type ArrowIconProps = {
  rotated: boolean;
};

type FAQItemProps = {
  index: number;
  isOpen: boolean;
  onClick: (index: number) => void;
};
const FAQItem: React.FC<FAQItemProps> = ({ index, isOpen, onClick }) => {
  const { title, content } = faqData[index];

  return (
    <StyledFAQItem>
      <FAQTitle onClick={() => onClick(index)}>
        <div>
          <img src={IconInfo} alt='iconInfo' />
          {title}
        </div>
        <FAQIcon>
          <ArrowIcon rotated={isOpen} />
        </FAQIcon>
      </FAQTitle>
      {isOpen && <FAQContent>{content}</FAQContent>}
    </StyledFAQItem>
  );
};

const ArrowIcon: React.FC<ArrowIconProps> = ({ rotated }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='17'
    height='16'
    viewBox='0 0 17 16'
    fill='none'
    style={{
      transform: rotated ? 'rotate(180deg)' : 'rotate(0deg)',
      transition: 'transform 0.3s ease',
    }}>
    <path
      d='M4.5 6L8.5 10L12.5 6'
      stroke='black'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const ModalRegister: FC<ISignBody> = ({ organization, user, xml, token, onClose, urlToken }) => {
  const [contactDetails, setContactDetails] = useState<Record<string, string>>({
    email: '',
    phone: '',
    address: '',
  });
  const [selectedRole, setSelectedRole] = useState<string>('');
  const [contactDetailsError, setContactDetailsError] = useState<Record<string, string | null>>({
    email: null,
    phone: null,
    address: null,
  });
  const [isAgreed, setIsAgreed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();
  const isRoleSelected = selectedRole !== '';
  const [isOpen, setIsOpen] = useState(false);
  const [faqOpen, setFaqOpen] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState('firstStep');
  const [error, setError] = useState<{ header: string; text: string } | null>(null); // Error state for modal

  const toggleDropdown = () => setIsOpen(!isOpen);

  const validateAddress = (address: string) => {
    return address.length >= 3 ? null : 'Адрес должен содержать не менее 3 символов';
  };

  const handleFAQClick = (index: number) => {
    setFaqOpen(faqOpen === index ? null : index);
  };

  const validatePhone = (phone: string) => {
    const re = /^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/;
    return re.test(phone) ? null : 'Невалидный формат';
  };

  const cleanPhoneNumber = (phoneNumber: string) => {
    return phoneNumber.replace(/\D+/g, '');
  };

  const isButtonDisabled = useMemo(() => {
    const hasEmailError = contactDetailsError.email && contactDetailsError.email !== null;
    const hasPhoneError = contactDetailsError.phone && contactDetailsError.phone !== null;
    const hasAddressError = contactDetailsError.address && contactDetailsError.address !== null;
    return (
      !selectedRole ||
      hasEmailError ||
      !contactDetails.email ||
      hasPhoneError ||
      !contactDetails.phone ||
      hasAddressError ||
      !contactDetails.address ||
      !isAgreed ||
      isSubmitting // Disable button if submitting
    );
  }, [selectedRole, contactDetails, contactDetailsError, isAgreed, isSubmitting]);

  const handleRegistration = async () => {
    setIsSubmitting(true); // Set submitting state to true
    try {
      const cleanedPhone = cleanPhoneNumber(contactDetails.phone);
      await sendRequestWithSign({
        token: xml,
        role: selectedRole,
        email: contactDetails.email,
        phone: cleanedPhone,
        address: contactDetails.address,
        refId: urlToken,
      });
      setCurrentPage('finishStep');
    } catch (error) {
      console.error('Error during registration:', error);
      setError({
        header: 'Ошибка регистрации',
        text: 'Произошла ошибка при регистрации. Пожалуйста, попробуйте еще раз.',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCheckboxChange = () => {
    setIsAgreed(!isAgreed);
  };

  const closeMessage = () => {
    window.location.href = '/login';
  };

  const steps = ['firstStep', 'finishStep'];
  const currentStepIndex = steps.indexOf(currentPage);

  return (
    <Container>
      <LogoImg src={Logo} alt='MoneyLink Logo' />
      <ProgressIndicator steps={steps} currentStep={currentStepIndex} />
      {currentPage === 'finishStep' && <SuccessMessageComponent onClose={closeMessage} />}
      {currentPage === 'firstStep' && (
        <BoxContent>
          <LeftBlock>
            <Title>{t('modalRegistration.infoUser')}</Title>
            <Text>
              <p>{t('modalRegistration.fio')}</p>
              {token.name}
            </Text>
            <Text>
              <p> {t('modalRegistration.iin')}</p>
              {token.iin}
            </Text>
            <Text>
              <p>Подпись:</p>
              {token.validate
                ? t('modalRegistration.signValid')
                : t('modalRegistration.signNotValid')}
            </Text>
            <Title>{t('modalRegistration.infoOrganization')}</Title>
            <Text>
              <p>{t('modalRegistration.name')}</p>
              {organization.name}
            </Text>
            <Text>
              <p>{t('modalRegistration.bin')}</p>
              {organization.iinbin}
            </Text>
            <Text>Выберите роль:</Text>

            <BoxInput>
              <SelectNew
                // title={t('modalRegistration.selectRole')}
                options={[
                  {
                    value: t('modalRegistration.roleBusiness'),
                    label: t('modalRegistration.roleBusiness'),
                  },
                  {
                    value: t('modalRegistration.roleInvestor'),
                    label: t('modalRegistration.roleInvestor'),
                  },
                ]}
                onOptionChange={setSelectedRole}
                selectedValue={selectedRole}
                isOpen={isOpen}
                onToggleOpen={toggleDropdown}
              />
              <InputText
                type={InputTextType.TEXT}
                placeholder={'Введите ваш адрес'}
                value={contactDetails.address}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value;
                  const error = validateAddress(value);
                  setContactDetailsError({ ...contactDetailsError, address: error });
                  setContactDetails({ ...contactDetails, address: value });
                }}
                error={contactDetailsError.address || undefined}
              />
              <InputText
                type={InputTextType.TEXT}
                placeholder={t('modalRegistration.selectEmail')}
                value={contactDetails.email || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value;
                  const error = validateEmail(value);
                  setContactDetailsError({ ...contactDetailsError, email: error });
                  setContactDetails({ ...contactDetails, email: value });
                }}
                error={contactDetailsError.email || undefined}
              />
              <InputMask
                mask='+7 (999) 999-99-99'
                maskChar={null}
                value={contactDetails.phone || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value;
                  const error = validatePhone(value);
                  setContactDetailsError({ ...contactDetailsError, phone: error });
                  setContactDetails({ ...contactDetails, phone: value });
                }}>
                {(inputProps: any) => (
                  <InputText
                    type={InputTextType.TEXT}
                    placeholder={t('modalRegistration.selectPhone')}
                    {...inputProps}
                    error={contactDetailsError.phone || undefined}
                  />
                )}
              </InputMask>
            </BoxInput>
            <Button onClick={handleRegistration} disabled={isButtonDisabled}>
              Зарегистрироваться
            </Button>
            <BoxCheck>
              <Checkbox checked={isAgreed} onChange={handleCheckboxChange} />
              <p>Нажимая, вы соглашаетесь с правилами</p>
              <a href='https://lend.kz/public_agreement' target='_blank' rel='noopener noreferrer'>
                публичной оферты
              </a>
            </BoxCheck>
          </LeftBlock>
          <RightBlock>
            <FAQSection>
              {faqData.map((_, index) => (
                <FAQItem
                  key={index}
                  index={index}
                  isOpen={faqOpen === index}
                  onClick={handleFAQClick}
                />
              ))}
            </FAQSection>
          </RightBlock>
        </BoxContent>
      )}
      {error && (
        <ErrorModal
          headerError={error.header}
          textError={error.text}
          buttonClose='Закрыть'
          onClick={() => setError(null)} // Close modal on click
        />
      )}
    </Container>
  );
};

export default ModalRegister;
