import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NewButton } from 'components/common/Button';
import { ErrorModal } from 'components/common/ErrorModal';
import { VeriLiveText } from './KycNew.style';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

export default function Live({ onCompletion }) {
  const [showRetryButton, setShowRetryButton] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(null);

  const onRetryButtonClick = () => {
    setShowRetryButton(false);
    setIsChecking(true);
    setErrorMessage('');
    initiateVerilive();
  };

  const onSuccessCallback = async (data) => {
    const sessionId = data.session_id;
    await window.verilive.dispose();
    setIsChecking(true);
    try {
      const response = await axios.get(`${BASE_URL}/api/v1/kyc/liveness/${sessionId}`, {
        withCredentials: true,
      });
      setIsChecking(false);
      onCompletion();
      console.log('Liveness check response:', response.data);
    } catch (error) {
      console.error('Error sending session_id:', error);
      setError('Error sending session_id. Please try again.');
      setIsChecking(false);
    }
  };

  const onErrorCallback = (data) => {
    setShowRetryButton(true);
    setIsChecking(false);
    setErrorMessage('Проверка прошла неудачно. Пожалуйста, попробуйте еще раз.');
    console.log(data);

    console.log('onErrorCallback');
    window.verilive.dispose();
  };

  const onFailCallback = (data) => {
    setShowRetryButton(true);
    setIsChecking(false);
    setErrorMessage('Проверка прошла неудачно. Пожалуйста, попробуйте еще раз.');
    console.log(data);

    console.log('onFailCallback');

    window.verilive.dispose();
  };

  const initiateVerilive = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/v1/kyc/token`, {
        withCredentials: true,
      });

      const accessToken = response.data.access_token;
      const personId = response.data.person_id;

      const configParams = window.verilive.getDefaultConfig();
      await window.verilive.init(
        'https://services.verigram.ai:8443/s/verilive/verilive',
        '',
        configParams,
      );
      window.verilive.start(accessToken, personId);
    } catch (error) {
      console.log('Error fetching access token: ', error);
      setError('Error fetching access token. Please try again.');
      setIsChecking(false);
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://s3.eu-central-1.amazonaws.com/verilive-statics.verigram.ai/verilive-v1.16.x.js';
    script.async = true;

    document.body.appendChild(script);

    script.onload = () => {
      window.verilive.successCallback = onSuccessCallback;
      window.verilive.failCallback = onFailCallback;
      window.verilive.errorCallback = onErrorCallback;

      initiateVerilive().catch((error) => {
        console.log(error);
      });
    };

    return async () => {
      document.body.removeChild(script);
      if (window.verilive) {
        console.log('Calling window.verilive.dispose() on component unmount');
        await window.verilive.dispose();
      }
    };
  }, []);

  const closeModal = () => {
    setError(null);
  };

  return (
    <div>
      <div id='id_verilive' style={{ width: '100%', marginBottom: '10px' }}></div>
      {isChecking && <VeriLiveText>Ожидайте, идет отправка данных ...</VeriLiveText>}
      {!isChecking && showRetryButton && (
        <div>
          <VeriLiveText style={{ marginBottom: '10px' }}>{errorMessage}</VeriLiveText>
          <NewButton text='Повторить' onClick={onRetryButtonClick} />
        </div>
      )}
      {error && (
        <ErrorModal
          headerError='Ошибка'
          textError={error}
          buttonClose='Закрыть'
          onClick={closeModal}
        />
      )}
    </div>
  );
}
