import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppContext } from 'contexts/AppContext';
import {
  SidebarContainer,
  SidebarHeader,
  SidebarMenu,
  StyledNavLink,
  Line,
  ExtraTitle,
  LogoMenu,
  LogoCompany,
  IconOrganization,
  IconApplications,
  IconDocuments,
  IconPayments,
  IconExit,
  StyledIconExit,
  IconMySuggestions,
  IconAdmin,
  IconGroup,
  IconLanding,
} from './SideBar.style';
import {
  ORGANIZATION_URL,
  APPLICATIONS_URL,
  CONTRACTS_URL,
  PAYMENTS_URL,
  ADMIN_URL,
  SUGGESTIONS_URL,
  GROUP_URL,
} from 'constants/urls';
import { default as IconMenu } from 'assets/img/IconMenu.svg';
import { default as NewLogo } from 'assets/img/NewLogo.svg';
import { logOut } from 'components/api/logOut.api';
import { useNavigate } from 'react-router-dom';
import { useKycStore } from 'components/store/useKycStore';

export const SideBar = () => {
  const { openModal } = useKycStore();
  const { t } = useTranslation();
  const navigate = useNavigate(); // Добавляем хук для навигации
  const [isOpen, setIsOpen] = useState(true);
  const { userDataLight } = useAppContext();

  const isSpecialUser = userDataLight?.roles?.includes('NOT_VERIFIED');

  const toggleIsOpen = useCallback(() => setIsOpen((prev) => !prev), []);

  if (!userDataLight) return null;

  const handleLogout = async () => {
    try {
      await logOut();
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const handleRestrictedNavigation = (event: React.MouseEvent, url: string) => {
    if (isSpecialUser) {
      event.preventDefault(); // Предотвращаем переход
      openModal(); // Открываем модалку KYC
    } else {
      navigate(url); // Обычная навигация
    }
  };

  const handleMainClick = () => {
    navigate('/');
  };

  return (
    <SidebarContainer $isOpen={isOpen}>
      <SidebarHeader>
        <LogoMenu src={IconMenu} alt='IconMenu' onClick={toggleIsOpen} />
        {isOpen && <LogoCompany src={NewLogo} alt='logo' onClick={handleMainClick} />}
      </SidebarHeader>
      <SidebarMenu $isOpen={isOpen}>
        <StyledNavLink to={APPLICATIONS_URL}>
          <IconApplications />
          {isOpen &&
            (userDataLight?.organizationRole !== 'BUSINESS' ? 'Сделки' : t('sidebar.applications'))}
        </StyledNavLink>
        {userDataLight?.organizationRole !== 'BUSINESS' && (
          <StyledNavLink
            to={SUGGESTIONS_URL}
            onClick={(e) => handleRestrictedNavigation(e, SUGGESTIONS_URL)}>
            <IconMySuggestions />
            {isOpen && t('sidebar.mySuggestions')}
          </StyledNavLink>
        )}
        <StyledNavLink
          to={CONTRACTS_URL}
          onClick={(e) => handleRestrictedNavigation(e, CONTRACTS_URL)}>
          <IconDocuments />
          {isOpen && t('sidebar.documents')}
        </StyledNavLink>
        <StyledNavLink
          to={PAYMENTS_URL}
          onClick={(e) => handleRestrictedNavigation(e, PAYMENTS_URL)}>
          <IconPayments />
          {isOpen && t('sidebar.payments')}
        </StyledNavLink>
        <StyledNavLink to={GROUP_URL} onClick={(e) => handleRestrictedNavigation(e, GROUP_URL)}>
          <IconGroup />
          {isOpen && 'Мои группы'}
        </StyledNavLink>
        <StyledNavLink to={ORGANIZATION_URL}>
          <IconOrganization />
          {isOpen && 'Настройки'}
        </StyledNavLink>
        <Line $isOpen={isOpen} />
        <ExtraTitle $isOpen={isOpen}>{isOpen && 'Дополнительно'}</ExtraTitle>{' '}
        <StyledNavLink to={'https://lend.kz/'}>
          <IconLanding />
          {isOpen && 'О платформе'}
        </StyledNavLink>
        {userDataLight?.roles && userDataLight.roles.includes('ADMIN') && (
          <StyledNavLink to={ADMIN_URL}>
            <IconAdmin />
            {isOpen && t('sidebar.administration')}
          </StyledNavLink>
        )}
        <StyledIconExit to='/login' $isOpen={isOpen} onClick={handleLogout}>
          <IconExit />
          {isOpen && 'Выйти'}
        </StyledIconExit>
      </SidebarMenu>
    </SidebarContainer>
  );
};
