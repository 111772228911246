import React, { useState } from 'react';
import {
  BalanceContainer,
  BalanceHeader,
  BalanceAmount,
  BalanceBlock,
  BalanceImage,
  BalanceText,
} from './BalanceCabinet.style';
import IconBalance from 'assets/img/IconBalance.svg';
import { NewButton } from 'components/common/Button';
import ModalBuyingToken from './ModalBuyingToken';
import { useAppContext } from 'contexts/AppContext';

export const BalanceCabinet = () => {
  const { userDataLight } = useAppContext();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => setModalOpen(true);

  // Безопасная проверка на наличие userDataLight
  const balance = userDataLight?.balance ?? '—'; // Если нет данных, показываем "—"

  return (
    <>
      <BalanceContainer>
        <BalanceHeader>Текущий баланс</BalanceHeader>
        <BalanceBlock>
          <BalanceText>Баланс:</BalanceText>
          <BalanceAmount>{balance}</BalanceAmount>
          <BalanceImage src={IconBalance} alt='IconBalance' />
        </BalanceBlock>
        <NewButton text='Пополнить баланс' onClick={handleOpenModal} />
      </BalanceContainer>
      <ModalBuyingToken isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
};
