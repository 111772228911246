import styled from 'styled-components';

const Box = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background: #fefefe;
  padding: 24px;
  gap: 24px;
`;

const Title = styled.h3`
  color: #0d0d0d;
  font-size: 24px;
  font-weight: 600;
  line-height: 106%;
`;

const CardBox = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 24px 16px;
  background: #fff;
  border: 1px solid var(--neutral-200, #d8d8d8);
  max-width: 272px;
  gap: 16px;
  min-height: 260px;
  cursor: pointer;
`;

const PaymentDate = styled.div`
  display: flex;
  gap: 16px;
  color: #000;
  font-size: 20px;
  font-weight: 500;
  line-height: 125%; /* 25px */
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  color: #000;
  font-family: 'IBM Plex Sans';
  font-size: 12px;
  font-weight: 500;
  line-height: 125%; /* 15px */
`;

const PaymentDay = styled.div`
  background-color: #5784f7;
  color: #ffffff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  margin: 0 auto;
`;

const PaymentSum = styled.p`
  color: var(--Control, #9aa4b0);
  font-family: 'IBM Plex Sans';
  font-size: 24px;
  font-weight: 500;
  line-height: 125%; /* 30px */
`;

export const BoxPayment = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Details = styled.p`
  color: var(--primary-300, #5784f7);
  font-size: 16px;
  font-weight: 400;
  line-height: 125%; /* 20px */
`;

export const getPaymentDayStyles = (status: string) => {
  switch (status) {
    case 'Просрочен':
      return {
        color: '#FEFEFE',
        backgroundColor: '#FA4D56',
      };
    case 'Оплачен':
      return {
        color: '#fff',
        backgroundColor: '#29E584',
      };
    case 'Не оплачен':
      return {
        color: '#686868',
        backgroundColor: '#F4F4F4',
      };
    default:
      return {
        color: '#686868',
        backgroundColor: '#F4F4F4',
      };
  }
};

export const getPaymentSumStyles = (status: string) => {
  switch (status) {
    case 'Просрочен':
      return { color: '#FA4D56' };
    case 'Оплачен':
      return { color: '#2BB774' };
    case 'Не оплачен':
      return { color: '#303030' };
    default:
      return { color: '#303030' }; // Default color
  }
};

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px;
  border: 1px solid var(--primary-400, #0f62fe);
  gap: 16px;
  width: 80%;
  max-width: 500px; // Adjust max-width as needed
  position: relative;
  align-items: center;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0px;
  right: 20px;
  background: transparent;
  border: none;
  font-size: 40px;
  cursor: pointer;
  color: #5784f7;
`;

export const Block = styled.div<{ status: string }>`
  width: 100%;
  display: flex;
  border-radius: 8px;
  border: 1px solid;
  background: #fff;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  font-family: 'IBM Plex Sans';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */
  border-color: ${({ status }) => getBlockStyles(status).borderColor};
  color: ${({ status }) => getBlockStyles(status).color};
`;

const getBlockStyles = (status: string) => {
  switch (status) {
    case 'Просрочен':
      return {
        borderColor: '#FA4D56',
        color: '#FA4D56',
      };
    case 'Оплачен':
      return {
        borderColor: '#29E584',
        color: '#2BB774',
      };
    case 'Не оплачен':
    default:
      return {
        borderColor: '#686868',
        color: '#686868',
      };
  }
};

export const BoxInfo = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--neutral-900, #141414);
  background: #fff;
  flex-direction: column;
`;

export const BankInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 0;
`;

export const BankInfoDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  background: #fff;
  padding: 8px 12px;
`;

export const BankInfoHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
`;

export { Box, Title, CardBox, Card, PaymentDate, PaymentDay, PaymentSum };
