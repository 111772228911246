import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import {
  OrganizationContainer,
  HeaderOrganization,
  OrganizationInfo,
  ContentBox,
  ContactBox,
  Input,
  Block,
  TagsContainer,
} from './OrganizationCabinet.style';
import { BankAccount } from 'components/BankAccount';
import { NewButton } from 'components/common/Button';
import { Organization } from './OrganizationCabinet.interface';
import { TagsWhiteLabel } from 'components/TagWhiteLabels';
import { useAppContext } from 'contexts/AppContext';
import { LoadingGif } from 'components/common/LoadingGif';
import { InputNew } from 'components/common/Input/InputNew/InputNew';

interface WhiteLabel {
  code: string;
  name: string;
}

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

export const OrganizationCabinet = () => {
  const { userDataLight } = useAppContext();
  const [orgData, setOrgData] = useState<Organization | null>(null);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [editable, setEditable] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrgData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/v1/ui/organization`, {
          withCredentials: true,
        });
        setOrgData(response.data);
      } catch (error) {
        console.error('Failed to fetch organization data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrgData();
  }, []);

  useEffect(() => {
    if (orgData) {
      setEmail(orgData.email || '');
      setPhone(orgData.phone ? (formatPhone(orgData.phone) ?? '') : '');
      setAddress(orgData.address || '');
    }
  }, [orgData]);

  const formatPhone = useCallback((phone: string) => {
    const cleaned = phone.replace(/\D/g, '');
    const match = cleaned.match(/^7(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
      const intlCode = '+7';
      return [intlCode, ' (', match[1], ') ', match[2], '-', match[3], '-', match[4]].join('');
    }
    return null;
  }, []);

  const cleanPhone = useCallback((formattedPhone: string) => {
    return formattedPhone.replace(/\D/g, '');
  }, []);

  const handleSaveAllChanges = useCallback(async () => {
    await saveUserData();
    setEditable(false);
  }, [email, phone, address]);

  const saveUserData = useCallback(async () => {
    const cleanedPhone = cleanPhone(phone);
    const payload = {
      email,
      phone: cleanedPhone,
      address,
    };

    try {
      const response = await axios.post(`${BASE_URL}/api/v1/ui/organization/save`, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      console.log('Data saved:', response.data);
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [email, phone, address, cleanPhone]);

  const toggleEditable = useCallback(() => {
    setEditable((prev) => !prev);
    if (editable) {
      handleSaveAllChanges();
    }
  }, [editable, handleSaveAllChanges]);

  const renderTags = useCallback(() => {
    return (
      <TagsContainer>
        {userDataLight?.whiteLabel.map((label: WhiteLabel) => (
          <TagsWhiteLabel
            key={label.code}
            text={label.name}
            className={label.code === 'OMARKET' ? 'smartcash' : label.code.toLowerCase()}
          />
        ))}
      </TagsContainer>
    );
  }, [userDataLight]);

  if (loading) {
    return <LoadingGif />;
  }

  const {
    name = 'Имя по умолчанию',
    ceoName = '',
    iinbin = '',
    organizationRole = '',
  } = orgData || {};

  return (
    <OrganizationContainer>
      <HeaderOrganization>Данные об организации</HeaderOrganization>
      <Block>
        <div>
          <ContentBox>
            <OrganizationInfo>
              <strong>Название:</strong> <p>{name || ''}</p>
            </OrganizationInfo>
            <OrganizationInfo>
              <strong>Первый руководитель:</strong> <p>{ceoName || ''}</p>
            </OrganizationInfo>
            <OrganizationInfo>
              <strong>БИН/ИНН:</strong> <p>{iinbin || ''}</p>
            </OrganizationInfo>
            <OrganizationInfo>
              <strong>Роль:</strong> <p>{organizationRole || ''}</p>
            </OrganizationInfo>
            <OrganizationInfo>
              <strong>Группа</strong>
              {renderTags()}
            </OrganizationInfo>
            <OrganizationInfo className='col'>
              <strong>Email:</strong>
              <ContactBox>
                <InputNew
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder='Email'
                  readOnly={!editable}
                  disabled={!editable}
                />
              </ContactBox>
            </OrganizationInfo>
            <OrganizationInfo className='col'>
              <strong>Мобильный номер:</strong>
              <ContactBox>
                <Input
                  mask='+7 (___) ___-__-__'
                  replacement={{ _: /\d/ }}
                  value={phone}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhone(e.target.value)}
                  placeholder='Phone'
                  $readOnly={!editable}
                  disabled={!editable}
                  $hasContent={!!phone}
                />
              </ContactBox>
            </OrganizationInfo>
            <OrganizationInfo className='col'>
              <strong>Юридический адрес:</strong>
              <ContactBox>
                <InputNew
                  value={address}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAddress(e.target.value)}
                  placeholder='Address'
                  readOnly={!editable}
                  disabled={!editable}
                />
              </ContactBox>
            </OrganizationInfo>
            <NewButton
              text={editable ? 'Сохранить изменения' : 'Редактировать'}
              onClick={toggleEditable}
              className='white'
            />
          </ContentBox>
        </div>
        {/* <div>
          <BankAccount />
        </div> */}
      </Block>
    </OrganizationContainer>
  );
};
