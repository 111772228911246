import { styled, css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { ReactComponent as LogoDashboard } from 'assets/img/LogoDashboard.svg';
import { ReactComponent as LogoOrganization } from 'assets/img/LogoOrganization.svg';
import { ReactComponent as LogoApplications } from 'assets/img/LogoApplications.svg';
import { ReactComponent as LogoDocuments } from 'assets/img/LogoDocuments.svg';
import { ReactComponent as LogoPayments } from 'assets/img/LogoPayments.svg';
import { ReactComponent as LogoSettings } from 'assets/img/LogoSettings.svg';
import { ReactComponent as LogoHelps } from 'assets/img/LogoHelps.svg';
import { ReactComponent as LogoExit } from 'assets/img/LogoExit.svg';
import { ReactComponent as LogoBiling } from 'assets/img/LogoBiling.svg';
import { ReactComponent as LogoInvestorSettings } from 'assets/img/LogoInvestorSettings.svg';
import { ReactComponent as MySuggestions } from 'assets/img/MySuggestions.svg';
import { ReactComponent as LogoAdmin } from 'assets/img/LogoAdmin.svg';
import { ReactComponent as LogoGroup } from 'assets/img/LogoGroup.svg';
import { ReactComponent as LogoLanding } from 'assets/img/LogoLanding.svg';

const transitionStyles = css`
  transition:
    width 0.3s ease-in-out,
    padding-left 0.3s ease-in-out,
    padding-right 0.3s ease-in-out,
    gap 0.3s ease-in-out;
`;

const SidebarContainer = styled.div<{ $isOpen: boolean }>`
  width: ${({ $isOpen }) => ($isOpen ? '230px' : '70px')};
  height: 100%;
  background: #fff;
  padding-left: ${({ $isOpen }) => ($isOpen ? '20px' : 'none')};
  padding-right: ${({ $isOpen }) => ($isOpen ? '20px' : 'none')};
  align-items: ${({ $isOpen }) => ($isOpen ? 'baseline' : 'center')};
  position: relative;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: ${({ $isOpen }) => ($isOpen ? '54px' : '54px')};
  ${transitionStyles}
`;

const SidebarHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const SidebarMenu = styled.ul<{ $isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ $isOpen }) => ($isOpen ? '24px' : '24px')};
  .active {
    color: #5784f7;
    path {
      stroke: #5784f7;
    }
  }
  :hover {
    color: #5784f7;
    path {
      stroke: #5784f7;
    }
  }
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  text-decoration: none;
  color: var(--neutral-600, #686868);
  font-family: 'IBM Plex Sans';
  font-size: 16px;
  gap: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
`;

const LogoMenu = styled.img`
  cursor: pointer;
  height: 32px;
  width: 32px;
`;

const LogoCompany = styled.img`
  /* width: 104px;
  height: 28px; */
  width: 100px;
  height: 32px;
  cursor: pointer;
`;

const IconMenu = (Component: React.ComponentType) => styled(Component)`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const Line = styled.div<{ $isOpen: boolean }>`
  border: 1px solid #e0e8f1;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
`;

const ExtraTitle = styled.h4<{ $isOpen: boolean }>`
  display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
  color: #3f4e67;
  font-size: 20px;
  font-weight: 600;
  /* margin-bottom: 14px; */
  &:hover {
    color: #3f4e67;
  }
`;

const StyledIconExit = styled(StyledNavLink)<{ $isOpen: boolean }>`
  position: ${({ $isOpen }) => ($isOpen ? 'relative' : 'absolute')};
  bottom: ${({ $isOpen }) => ($isOpen ? 'auto' : '20px')};
  left: ${({ $isOpen }) => ($isOpen ? 'auto' : '20px')};
`;

const IconDashboard = IconMenu(LogoDashboard);
const IconOrganization = IconMenu(LogoOrganization);
const IconApplications = IconMenu(LogoApplications);
const IconDocuments = IconMenu(LogoDocuments);
const IconPayments = IconMenu(LogoPayments);
const IconSettings = IconMenu(LogoSettings);
const IconHelps = IconMenu(LogoHelps);
const IconExit = IconMenu(LogoExit);
const IconBiling = IconMenu(LogoBiling);
const IconInvestorSettings = IconMenu(LogoInvestorSettings);
const IconMySuggestions = IconMenu(MySuggestions);
const IconAdmin = IconMenu(LogoAdmin);
const IconGroup = IconMenu(LogoGroup);
const IconLanding = IconMenu(LogoLanding);

export {
  SidebarContainer,
  SidebarHeader,
  SidebarMenu,
  StyledNavLink,
  Line,
  ExtraTitle,
  LogoMenu,
  LogoCompany,
  IconDashboard,
  IconOrganization,
  IconApplications,
  IconDocuments,
  IconPayments,
  IconSettings,
  IconHelps,
  IconExit,
  StyledIconExit,
  IconBiling,
  IconInvestorSettings,
  IconMySuggestions,
  IconAdmin,
  IconGroup,
  IconLanding,
};
