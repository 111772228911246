import styled from 'styled-components';

export const BalanceContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 24px;
  background: var(--neutral-50, #fefefe);
  button {
    max-width: 412px;
  }
`;

export const BalanceHeader = styled.p`
  color: #0d0d0d;
  font-size: 24px;
  font-weight: 600;
  line-height: 106%; /* 25.44px */
`;

export const BalanceBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  max-height: 40px;
  border-radius: 24px;
  background: var(--neutral-100, #f4f4f4);
  border: 1px solid #f4f4f4;
  width: fit-content; // Блок займет ровно столько места, сколько нужно
`;

export const BalanceText = styled.p`
  color: var(--neutral-400, #a0a0a0);
  font-size: 12px;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
`;

export const BalanceAmount = styled.p`
  color: var(--neutral-800, #303030);
  font-size: 16px;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
`;

export const BalanceImage = styled.img`
  width: 16px;
  height: 16px;
`;
