import styled from 'styled-components';

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContainer = styled.div`
  position: relative;
  display: flex;
  padding: 24px;
  max-width: 800px;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  border-radius: 24px;
  border: 1px solid var(--neutral-300, #bcbcbc);
  background: #fff;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
    h4 {
      color: #000;
      font-size: 20px;
      font-weight: 500;
      line-height: 125%; /* 25px */
    }
    p {
      color: var(--neutral-600, #686868);
      font-weight: 400;
      line-height: 120%; /* 19.2px */
    }
  }
`;

export const CloseButton = styled.button`
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  font-size: 24px;
  color: #303030;
  cursor: pointer;

  &:hover {
    color: #666;
  }
`;

export const Title = styled.p`
  color: var(--neutral-800, #303030);
  font-size: 16px;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
`;

export const FeaturesList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  p {
    color: var(--neutral-800, #303030);
    font-size: 16px;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
  }
`;

export const Features = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  div {
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 4px;
    background: var(--neutral-100, #f4f4f4);
    color: var(--neutral-800, #303030);
    font-size: 16px;
    font-weight: 500;
    line-height: 120%; /* 19.2px */
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
