import styled, { css } from 'styled-components';
import colors from 'global_styling/colors';

interface InputProps {
  $hasError?: boolean;
  $hasContent?: boolean;
  $readOnly?: boolean;
  maxWidth?: string; // Accept maxWidth as a prop
}

const Input = styled.input<InputProps>`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || '100%'}; // Apply max-width here, default is 100%
  padding: 8px;
  border-radius: 4px;
  background: var(--neutral-100, #f4f4f4);
  color: var(--neutral-400, #a0a0a0);
  border: 1px solid var(--Control, #f4f4f4);
  font-size: 14px;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  /* Custom styles for specific classes */
  &.max {
    height: 164px;
    vertical-align: top; /* Aligns text to the top */
    text-align: start; /* Aligns text to the left */
  }

  &.maxApplication {
    height: 164px;
    vertical-align: top;
    font-family: 'IBM Plex Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.16px;
    white-space: pre-wrap; /* Preserves whitespace and line breaks */
  }

  /* Remove spin buttons for number inputs in different browsers */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & {
    -moz-appearance: textfield;
  }

  /* Style when the input has content */
  ${({ $hasContent }) =>
    $hasContent &&
    css`
      color: var(--neutral-800, #303030);
      border: 1px solid var(--neutral-800, #f4f4f4);
    `}

  /* Style when the input is read-only */
  ${({ $readOnly }) =>
    $readOnly &&
    css`
      color: var(--neutral-600, #686868);
      border: 1px solid var(--neutral-400, #f4f4f4);
      background: var(--neutral-200, #d8d8d8);
      cursor: not-allowed;
    `}

  /* Style when the input is focused (only if there's no error) */
  &:focus {
    outline: none;
    border: 1px solid var(--Border-border-interactive, #0f62fe);
    background: var(--neutral-100, #f4f4f4);
    border-radius: 4px;
    color: var(--neutral-800, #303030);
  }

  /* Style when there is an error (override the focus styles) */
  ${({ $hasError }) =>
    $hasError &&
    css`
      border: 1px solid ${colors.WARNING} !important;
      &:focus {
        border: 1px solid ${colors.WARNING} !important;
      }
    `}
`;

const ErrorMessage = styled.span`
  color: ${colors.WARNING};
  font-size: 12px;
  font-weight: 400;
`;

const InputBox = styled.div<InputProps>`
  display: flex;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  flex-direction: column;
  gap: 8px;
  padding: 4px 0px;
  align-items: flex-start;

  p {
    color: var(--neutral-800, #303030);
    font-size: 16px;
    font-weight: 500;
    line-height: 120%; /* 19.2px */
  }
`;

export { InputBox, Input, ErrorMessage };
