import React, { useState } from 'react';
import styled from 'styled-components';
import { default as PromptWindowIcon } from 'assets/img/PromptWindowIcon.svg';

interface TooltipProps {
  show: boolean;
  bottom?: boolean;
}

const Tooltip = styled.div<TooltipProps>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  position: absolute;
  ${(props) =>
    props.bottom
      ? `
    top: 125%;
    &::after {
      top: -6px;
      transform: translateX(-50%) rotate(180deg);
      border-color: transparent transparent #f4f9ff transparent;
    }
  `
      : `
    bottom: 125%;
    &::after {
      top: 100%;
      border-color: #f4f9ff transparent transparent transparent;
    }
  `}
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  padding: 4px;
  background-color: #f4f9ff;
  border-radius: 8px;
  z-index: 100;

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    border-width: 6px;
    border-style: solid;
  }

  p {
    color: var(--primary-400, #0f62fe);
    text-align: center;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
  }
`;

const IconContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

interface PromptWindowProps {
  description: string;
  bottom?: boolean;
}

const PromptWindow: React.FC<PromptWindowProps> = ({ description, bottom }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <IconContainer
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}>
      <img src={PromptWindowIcon} alt='prompt' />
      <Tooltip show={showTooltip} bottom={bottom}>
        <p>{description}</p>
      </Tooltip>
    </IconContainer>
  );
};

export default PromptWindow;
