import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '@react-pdf-viewer/core/lib/styles/index.css';
import BlurReport from 'assets/img/BlurReport.webp';

import {
  ExpandableSection,
  MainInfoTitle,
  MainInfoBox,
  ArrowIcon,
} from 'components/ApplicationInvestor/ApplicationInvestorNew.style';

import { ErrorModal } from 'components/common/ErrorModal';
import { LoadingGif } from 'components/common/LoadingGif';

import IconArrow from 'assets/img/IconArrow.svg';
import IconArrowDown from 'assets/img/IconArrowDown.svg';

import miniIconWarning from 'assets/img/miniIconWarning.svg';
import miniIconError from 'assets/img/miniIconError.svg';
import IconDocuments from 'assets/img/IconDocuments.svg';

import {
  Container,
  MainInfoContent,
  MainInfo,
  MainInfoItem,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  TableCellHeader,
  Header,
  FormatValueBox,
} from './PKBInfo.style';
import IconPro from 'assets/img/IconPro.svg';
import { ImgContainer } from 'components/BankStatement/BankStatement.style';

interface PKBInfoProps {
  id: string | number;
}

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;

export const PKBInfo: React.FC<PKBInfoProps> = ({ id }) => {
  // Состояния для данных, загрузки и ошибок
  const [data, setData] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Состояния развёрнутости разных секций
  const [isBasicOpen, setIsBasicOpen] = useState(false);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [isRiskOpen, setIsRiskOpen] = useState(false);
  const [isTaxDedOpen, setIsTaxDedOpen] = useState(false);
  const [isLitigationOpen, setIsLitigationOpen] = useState(false);
  const [isFoundersOpen, setIsFoundersOpen] = useState(false);
  const [isConnectedDiagramOpen, setIsConnectedDiagramOpen] = useState(false);
  const [isLicenseGeneralOpen, setIsLicenseGeneralOpen] = useState(false);
  const [isContractOpen, setIsContractOpen] = useState(false);
  const [isTrustworthyExtOpen, setIsTrustworthyExtOpen] = useState(false);
  const [isRequisitesOpen, setIsRequisitesOpen] = useState(false);
  const [isRiskFactorExtOpen, setIsRiskFactorExtOpen] = useState(false);
  const [isTaxpayerOpen, setIsTaxpayerOpen] = useState(false);
  const [isKzCoTrustworthyPlusOpen, setIsKzCoTrustworthyPlusOpen] = useState(false);
  const [isKzCoContactOpen, setIsKzCoContactOpen] = useState(false);
  const [proAccessed, setProAccessed] = useState<boolean>(false); // <-- добавили это

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${BASE_URL}cabinet/investor/request/creditor/adata/${id}`, {
        withCredentials: true,
      })
      .then((res) => {
        const dataArray = res.data?.data || [];
        const proFlag = !!res.data?.proAccessed; // <-- берём значение
        setProAccessed(proFlag); // <-- сохраняем

        // Если массив пустой, просто ставим data = {} — тогда отобразится "данные не доступны"
        if (dataArray.length === 0) {
          setData({});
          return;
        }

        // Определяем карту соответствий:
        const sectionsMap: Record<string, { dataField: string; proField: string }> = {
          basic: { dataField: 'basic', proField: 'basicPro' },
          status: { dataField: 'status', proField: 'statusPro' },
          riskfactor: { dataField: 'riskFactor', proField: 'riskFactorPro' },
          taxdeductions: { dataField: 'taxDeductions', proField: 'taxDeductionsPro' },
          litigation: { dataField: 'litigation', proField: 'litigationPro' },
          founders: { dataField: 'founders', proField: 'foundersPro' },
          connecteddiagram: { dataField: 'connectedDiagram', proField: 'connectedDiagramPro' },
          licensegeneral: { dataField: 'licenseGeneral', proField: 'licenseGeneralPro' },
          contract: { dataField: 'contract', proField: 'contractPro' },
          trustworthyextended: {
            dataField: 'trustworthyExtended',
            proField: 'trustworthyExtendedPro',
          },
          requisites: { dataField: 'requisites', proField: 'requisitesPro' },
          riskfactorextended: {
            dataField: 'riskFactorExtended',
            proField: 'riskFactorExtendedPro',
          },
          taxpayer: { dataField: 'taxpayer', proField: 'taxpayerPro' },
          kzcotrustworthyplus: {
            dataField: 'kzCoTrustworthyPlus',
            proField: 'kzCoTrustworthyPlusPro',
          },
          kzcocontact: { dataField: 'kzCoContact', proField: 'kzCoContactPro' },
        };

        // Изначально пустые объекты для данных и все флаги pro = false:
        const mergedData: any = {
          basic: {},
          status: {},
          riskFactor: {},
          taxDeductions: {},
          litigation: {},
          founders: {},
          connectedDiagram: {},
          licenseGeneral: {},
          contract: {},
          trustworthyExtended: {},
          requisites: {},
          riskFactorExtended: {},
          taxpayer: {},
          kzCoTrustworthyPlus: {},
          kzCoContact: [],

          basicPro: false,
          statusPro: false,
          riskFactorPro: false,
          taxDeductionsPro: false,
          litigationPro: false,
          foundersPro: false,
          connectedDiagramPro: false,
          licenseGeneralPro: false,
          contractPro: false,
          trustworthyExtendedPro: false,
          requisitesPro: false,
          riskFactorExtendedPro: false,
          taxpayerPro: false,
          kzCoTrustworthyPlusPro: false,
          kzCoContactPro: false,
        };

        // Проходимся по каждому объекту в dataArray
        dataArray.forEach((item: any) => {
          // ...
          Object.entries(sectionsMap).forEach(([key, { dataField, proField }]) => {
            // стало так:
            if (key in item) {
              // Важно! Ставим флаг pro, даже если само поле пришло как null
              mergedData[proField] = item.pro;

              // Записываем «сырые» данные (это может быть null, если недоступно)
              mergedData[dataField] = item[key];
            }
          });
        });

        setData(mergedData);
      })
      .catch((err) => {
        setError('Ошибка при загрузке данных: ' + String(err));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const formatValue = (
    value: boolean | number | null | undefined,
    non?: boolean,
  ): string | JSX.Element => {
    if (value === null || value === undefined) {
      return (
        <FormatValueBox>
          <img src={miniIconWarning} alt='iconWarning' />
          <p>Недоступно</p>
        </FormatValueBox>
      );
    }

    if (typeof value === 'boolean') {
      if (value) {
        return non ? (
          'Да'
        ) : (
          <FormatValueBox>
            <img src={miniIconError} alt='iconError' />
            <p>Да</p>
          </FormatValueBox>
        );
      } else {
        return 'Нет';
      }
    }

    return String(value);
  };

  const formatType = (value: string | number | null | undefined): string | JSX.Element => {
    if (value === null || value === undefined) {
      return (
        <FormatValueBox>
          <img src={miniIconWarning} alt='iconWarning' />
          <p>Недоступно</p>
        </FormatValueBox>
      );
    }

    if (value === 'enterprise') {
      return 'ИП';
    }

    if (value === 'company') {
      return 'Юридическое лицо';
    }

    return String(value);
  };

  const TableRisk = data?.riskFactor?.head?.litigation?.court_cases || [];

  const TableBasic = data?.basic?.okeds || [];

  const TableContract = data?.contract?.details || [];

  const TableLicense = data?.licenseGeneral?.details || [];

  const TableTax = data?.taxDeductions?.details || [];

  const TableLitigation = data?.litigation?.court_cases || [];

  const TableFounders = data?.founders?.founders_dtl || [];

  const TableAffilHead = data?.connectedDiagram?.affiliation_by_head?.companies || [];

  const TableAffilFounder = data?.connectedDiagram?.affiliation_by_founder || [];

  if (loading) return <LoadingGif />;

  if (error) {
    const closeErrorModal = () => setError(null);
    return (
      <ErrorModal
        headerError='Произошла ошибка'
        textError={error}
        buttonClose='Закрыть'
        onClick={closeErrorModal}
      />
    );
  }

  // Если data вообще нет (например, массив пустой)
  if (!data || Object.keys(data).length === 0) {
    return (
      <ExpandableSection>
        <MainInfoTitle>
          <img src={IconDocuments} alt='icon' />
          Данные ADATA отсутствуют
        </MainInfoTitle>
      </ExpandableSection>
    );
  }

  // Ниже идёт тот же рендер, что и в вашем исходном коде,
  // только используем data, которое мы собрали из массива

  return (
    <Container>
      {/* -------------------- ОСНОВНЫЕ СВЕДЕНИЯ (basic) -------------------- */}
      <ExpandableSection>
        <MainInfoTitle onClick={() => setIsBasicOpen(!isBasicOpen)}>
          Основные сведения о компании
          {data.basicPro && <img src={IconPro} alt='iconPro' />}
          <ArrowIcon src={isBasicOpen ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {isBasicOpen &&
          (data.basicPro && !proAccessed ? (
            <ImgContainer>
              <img src={BlurReport} alt='Pro-only report' />
            </ImgContainer>
          ) : (
            <MainInfoBox>
              <MainInfoContent>
                <MainInfoItem>
                  <span>Полное наименование компании на русском языке</span>{' '}
                  {formatValue(data.basic.name_ru)}
                </MainInfoItem>
                <MainInfoItem>
                  <span>Полное наименование компании на казахском языке</span>{' '}
                  {formatValue(data.basic.name_kz)}
                </MainInfoItem>
                <MainInfoItem>
                  <span>Краткое наименование компании на русском языке </span>{' '}
                  {formatValue(data.basic.short_name)}
                </MainInfoItem>
                <MainInfoItem>
                  <span>БИН компании или ИИН индивидуального предпринимателя </span>{' '}
                  {formatValue(data.basic.biin)}
                </MainInfoItem>
                <MainInfoItem>
                  <span>Юридический адрес на русском</span> {formatValue(data.basic.legal_address)}
                </MainInfoItem>
                <MainInfoItem>
                  <span>Юридический адрес на казахском </span>{' '}
                  {formatValue(data.basic.legal_address_kz)}
                </MainInfoItem>
                <MainInfoItem>
                  <span>ID окэд</span> {formatValue(data.basic.oked_id)}
                </MainInfoItem>
                <MainInfoItem>
                  <span>Основной окэд</span> {formatValue(data.basic.oked)}
                </MainInfoItem>
                <MainInfoItem>
                  <span>Номер като</span> {formatValue(data.basic.id_kato)}
                </MainInfoItem>
                <MainInfoItem>
                  <span>Название като </span> {formatValue(data.basic.kato_name)}
                </MainInfoItem>
                <MainInfoItem>
                  <span>Номер КРП</span> {formatValue(data.basic.id_krp)}
                </MainInfoItem>
                <MainInfoItem>
                  <span>ФИО директора</span> {formatValue(data.basic.fullname_director)}
                </MainInfoItem>
                <MainInfoItem>
                  <span>ИИН директора</span> {formatValue(data.basic.director_iin)}
                </MainInfoItem>
                <MainInfoItem>
                  <span>Дата регистрации </span> {formatValue(data.basic.date_registration)}
                </MainInfoItem>
                <MainInfoItem>
                  <span>Форма собственности</span> {formatValue(data.basic.type_of_ownership)}
                </MainInfoItem>
                <MainInfoItem>
                  <span>Правовая форма компании</span> {formatValue(data.basic.legal_form)}
                </MainInfoItem>
                <MainInfoItem>
                  <span>Тип контрагента</span> {formatType(data.basic.counterparty_type)}
                </MainInfoItem>
              </MainInfoContent>
              <MainInfoContent>
                <MainInfo>
                  <span>Проблемы у предприятия</span> {formatValue(data.basic.company_problems)}
                </MainInfo>
                <MainInfo>
                  <span>Проблемы с финансами</span> {formatValue(data.basic.financial_problems)}
                </MainInfo>
                <MainInfo>
                  <span>В реестре недобросовестных участников закупок</span>{' '}
                  {formatValue(data.basic.unreliable_zakup)}
                </MainInfo>
                <MainInfo>
                  <span>Проблемы у руководителя</span> {formatValue(data.basic.head_problems)}
                </MainInfo>
                <MainInfo>
                  <span>Плательщик НДС</span> {formatValue(data.basic.is_nds_payer)}
                </MainInfo>
              </MainInfoContent>
              {TableBasic.length > 0 ? (
                <Table>
                  <TableHeader>
                    <tr>
                      <TableCellHeader>Номер ОКЭД</TableCellHeader>
                      <TableCellHeader>Наименование ОКЭД</TableCellHeader>
                    </tr>
                  </TableHeader>
                  <tbody>
                    {TableBasic.map((o: any, i: number) => (
                      <TableRow key={i} $even={i % 2 === 0}>
                        <TableCell>{o.i}</TableCell>
                        <TableCell>{o.name_ru}</TableCell>
                      </TableRow>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <></>
              )}
            </MainInfoBox>
          ))}
      </ExpandableSection>

      {/* -------------------- СТАТУС (status) -------------------- */}
      <ExpandableSection>
        <MainInfoTitle onClick={() => setIsStatusOpen(!isStatusOpen)}>
          Статус предприятия
          {data.statusPro && <img src={IconPro} alt='iconPro' />}
          <ArrowIcon src={isStatusOpen ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {isStatusOpen &&
          (data.statusPro && !proAccessed ? (
            <ImgContainer>
              <img src={BlurReport} alt='Pro-only report' />
            </ImgContainer>
          ) : (
            <MainInfoBox>
              <MainInfoContent>
                <MainInfo>
                  <span>Действующее предприятие</span>{' '}
                  {formatValue(data.status.company_status, true)}
                </MainInfo>
                <MainInfo>
                  <span>В списке лжепредприятий</span> {formatValue(data.status.pseudo_company)}
                </MainInfo>
                <MainInfo>
                  <span>В списке банкротов</span> {formatValue(data.status.bankcrupt)}
                </MainInfo>
                <MainInfo>
                  <span>Бездействующее предприятие</span> {formatValue(data.status.inactive)}
                </MainInfo>
                <MainInfo>
                  <span>Отсутствует по юрид. адресу</span>{' '}
                  {formatValue(data.status.absent_at_address)}
                </MainInfo>
                <MainInfo>
                  <span>Регистрация недействительна</span>{' '}
                  {formatValue(data.status.registration_invalid)}
                </MainInfo>
                <MainInfo>
                  <span>Реорганизован с нарушением норм НК</span>{' '}
                  {formatValue(data.status.violation_tax)}
                </MainInfo>
                <MainInfo>
                  <span>Сумма задолженности по налогам</span> {formatValue(data.status.tax_debt)}
                </MainInfo>
              </MainInfoContent>
            </MainInfoBox>
          ))}
      </ExpandableSection>

      {/* -------------------- ФАКТОРЫ РИСКА (riskFactor) -------------------- */}
      <ExpandableSection>
        <MainInfoTitle onClick={() => setIsRiskOpen(!isRiskOpen)}>
          Факторы риска
          {data.riskFactorPro && <img src={IconPro} alt='iconPro' />}
          <ArrowIcon src={isRiskOpen ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {isRiskOpen &&
          (data.riskFactorPro && !proAccessed ? (
            <ImgContainer>
              <img src={BlurReport} alt='Pro-only report' />
            </ImgContainer>
          ) : (
            <MainInfoBox>
              <Header>Факторы риска по компании</Header>
              <MainInfoContent>
                <MainInfo>
                  <span>В списке банкротов</span> {formatValue(data.riskFactor.company?.bankrupt)}
                </MainInfo>
                <MainInfo>
                  <span>Степень риска налогоплательщика </span>{' '}
                  {formatValue(data.riskFactor.company?.tax_risk_degree)}
                </MainInfo>
                <MainInfo>
                  <span>Сделки без факт. выполнения работ</span>{' '}
                  {formatValue(data.riskFactor.company?.irresponsible_taxpayer)}
                </MainInfo>
                <MainInfo>
                  <span>На стадии ликвидации</span>{' '}
                  {formatValue(data.riskFactor.company?.liquidating_taxpayer)}
                </MainInfo>
                <MainInfo>
                  <span>Имеется запрет на выезд</span>{' '}
                  {formatValue(data.riskFactor.company?.ban_leaving)}
                </MainInfo>
                <MainInfo>
                  <span>Сумма задолженности запрета на выезд</span>{' '}
                  {formatValue(data.riskFactor.company?.ban_leaving_sum)}
                </MainInfo>
                <MainInfo>
                  <span>Задолженность по исполнительным производствам</span>{' '}
                  {formatValue(data.riskFactor.company?.enforcement_debt)}
                </MainInfo>
                <MainInfo>
                  <span>Сумма задолженности по исполнительным производствам </span>{' '}
                  {formatValue(data.riskFactor.company?.enforcement_debt_sum)}
                </MainInfo>
                <MainInfo>
                  <span>Арест на имущество</span>{' '}
                  {formatValue(data.riskFactor.company?.seized_property)}
                </MainInfo>
                <MainInfo>
                  <span>Арест на банковский счет</span>{' '}
                  {formatValue(data.riskFactor.company?.seized_bank_account)}
                </MainInfo>
                <MainInfo>
                  <span>Запрет на регистрационные действия ЮЛ</span>{' '}
                  {formatValue(data.riskFactor.company?.ban_registration_actions_legal_ent)}
                </MainInfo>
                <MainInfo>
                  <span>Запрет на регистрационные действия ФЛ</span>{' '}
                  {formatValue(data.riskFactor.company?.ban_registration_actions_physical_ent)}
                </MainInfo>
                <MainInfo>
                  <span>Запрет на совершение нотариальных действий</span>{' '}
                  {formatValue(data.riskFactor.company?.ban_notarius_actions)}
                </MainInfo>
                <MainInfo>
                  <span>Запрет на реализацию арестованного имущества</span>{' '}
                  {formatValue(data.riskFactor.company?.ban_seized_property_actions)}
                </MainInfo>
                <MainInfo>
                  <span>Обременение</span> {formatValue(data.riskFactor.company?.encumbrance)}
                </MainInfo>
              </MainInfoContent>

              <Header>Факторы риска по руководителю</Header>

              <MainInfoContent>
                <MainInfo>
                  <span>Связан с финансированием терроризма </span>{' '}
                  {formatValue(data.riskFactor.head?.terrorist)}
                </MainInfo>
                <MainInfo>
                  <span>В списке лиц, причастных к террористической деятельности</span>{' '}
                  {formatValue(data.riskFactor.head?.terrorism_involved)}
                </MainInfo>
                <MainInfo>
                  <span>В базе педофилов </span> {formatValue(data.riskFactor.head?.pedophile)}
                </MainInfo>
                <MainInfo>
                  <span>В базе алиментщиков </span>{' '}
                  {formatValue(data.riskFactor.head?.alimony_payer)}
                </MainInfo>
                <MainInfo>
                  <span>В базе провавших без вести</span>{' '}
                  {formatValue(data.riskFactor.head?.missing)}
                </MainInfo>
                <MainInfo>
                  <span>В базе должников по исполнительным документам</span>{' '}
                  {formatValue(data.riskFactor.head?.debtor_for_executive_documents)}
                </MainInfo>
                <MainInfo>
                  <span>В базе граждан, скрывающихся от следствий</span>{' '}
                  {formatValue(data.riskFactor.head?.citizen_hiding_from_investigation)}
                </MainInfo>
                <MainInfo>
                  <span>Имеется запрет на выезд</span>{' '}
                  {formatValue(data.riskFactor.head?.ban_leaving)}
                </MainInfo>
                <MainInfo>
                  <span>Сумма задолженности запрета на выезд</span>{' '}
                  {formatValue(data.riskFactor.head?.ban_leaving_sum)}
                </MainInfo>
                <MainInfo>
                  <span>Имеются исполнительные производства</span>{' '}
                  {formatValue(data.riskFactor.head?.enforcement_debt)}
                </MainInfo>
                <MainInfo>
                  <span>Сумма задолженности на исполнительные производства</span>{' '}
                  {formatValue(data.riskFactor.head?.enforcement_debt_sum)}
                </MainInfo>
                <MainInfo>
                  <span>Арест на имущество</span>{' '}
                  {formatValue(data.riskFactor.head?.seized_property)}
                </MainInfo>
                <MainInfo>
                  <span>Арест на банковский счет</span>{' '}
                  {formatValue(data.riskFactor.head?.seized_bank_account)}
                </MainInfo>
                <MainInfo>
                  <span>Запрет на регистрационные действия ЮЛ </span>{' '}
                  {formatValue(data.riskFactor.head?.ban_registration_actions_legal_ent)}
                </MainInfo>
                <MainInfo>
                  <span>Запрет на регистрационные действия ФЛ</span>{' '}
                  {formatValue(data.riskFactor.head?.ban_registration_actions_physical_ent)}
                </MainInfo>
                <MainInfo>
                  <span>Запрет на совершение нотариальных действий</span>{' '}
                  {formatValue(data.riskFactor.head?.ban_notarius_actions)}
                </MainInfo>
                <MainInfo>
                  <span>Запрет на реализацию арестованного имущества</span>{' '}
                  {formatValue(data.riskFactor.head?.ban_seized_property_actions)}
                </MainInfo>
                <MainInfo>
                  <span>Сумма задолженности</span> {formatValue(data.riskFactor.head?.tax_debt)}
                </MainInfo>
              </MainInfoContent>
              <Header>Сведения по судебным разбирательствам руководителя</Header>
              <MainInfoContent>
                <MainInfo>
                  <span>Количество гражданских дел</span>{' '}
                  {String(data.riskFactor.head?.litigation?.total_civil_count)}
                </MainInfo>
                <MainInfo>
                  <span>Количество уголовных дел</span>{' '}
                  {String(data.riskFactor.head?.litigation?.total_criminal_count)}
                </MainInfo>
                <MainInfo>
                  <span>Количество административных дел</span>{' '}
                  {String(data.riskFactor.head?.litigation?.total_administrative_count)}
                </MainInfo>
              </MainInfoContent>
              {TableRisk.length > 0 ? (
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableCellHeader>Год</TableCellHeader>
                      <TableCellHeader>Кол-во гражданских дел</TableCellHeader>
                      <TableCellHeader>Кол-во уголовных дел</TableCellHeader>
                      <TableCellHeader>Кол-во административных дел</TableCellHeader>
                    </TableRow>
                  </TableHeader>
                  <tbody>
                    {TableRisk.map((caseItem: any, i: number) => (
                      <TableRow key={i} $even={i % 2 === 0}>
                        <TableCell>{caseItem.year}</TableCell>
                        <TableCell right>{caseItem.civil_count}</TableCell>
                        <TableCell right>{caseItem.criminal_count}</TableCell>
                        <TableCell right>{caseItem.administrative_count}</TableCell>
                      </TableRow>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <></>
              )}
            </MainInfoBox>
          ))}
      </ExpandableSection>

      {/* -------------------- НАЛОГОВЫЕ ОТЧИСЛЕНИЯ (taxDeductions) -------------------- */}
      <ExpandableSection>
        <MainInfoTitle onClick={() => setIsTaxDedOpen(!isTaxDedOpen)}>
          Сведения по налоговым отчислениям
          {data.taxDeductionsPro && <img src={IconPro} alt='iconPro' />}
          <ArrowIcon src={isTaxDedOpen ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {isTaxDedOpen &&
          (data.taxDeductionsPro && !proAccessed ? (
            <ImgContainer>
              <img src={BlurReport} alt='Pro-only report' />
            </ImgContainer>
          ) : (
            <MainInfoBox>
              <MainInfoContent>
                <MainInfo>
                  <span>Общая сумма налоговых отчислений</span>
                  {formatValue(
                    data.taxDeductions.sum.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }),
                  )}{' '}
                  ₸
                </MainInfo>
              </MainInfoContent>
              {TableTax.length > 0 ? (
                <Table>
                  <TableHeader>
                    <tr>
                      <TableCellHeader>Год</TableCellHeader>
                      <TableCellHeader>Сумма налоговых отчислений за период </TableCellHeader>
                    </tr>
                  </TableHeader>
                  <tbody>
                    {TableTax.map((c: any, i: number) => (
                      <TableRow key={i} $even={i % 2 === 0}>
                        <TableCell>{c.year}</TableCell>
                        <TableCell right>
                          {c.amount?.toLocaleString('ru-RU', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{' '}
                          ₸
                        </TableCell>
                      </TableRow>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <></>
              )}
            </MainInfoBox>
          ))}
      </ExpandableSection>

      {/* -------------------- СУДЕБНЫЕ РАЗБИРАТЕЛЬСТВА (litigation) -------------------- */}
      <ExpandableSection>
        <MainInfoTitle onClick={() => setIsLitigationOpen(!isLitigationOpen)}>
          Сведения по судебным разбирательствам
          {data.litigationPro && <img src={IconPro} alt='iconPro' />}
          <ArrowIcon src={isLitigationOpen ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {isLitigationOpen &&
          (data.litigationPro && !proAccessed ? (
            <ImgContainer>
              <img src={BlurReport} alt='Pro-only report' />
            </ImgContainer>
          ) : (
            <MainInfoBox>
              <MainInfoContent>
                <MainInfo>
                  <span>Количество гражданских дел</span>{' '}
                  {formatValue(data.litigation.total_civil_count)}
                </MainInfo>
                <MainInfo>
                  <span>Количество уголовных дел</span>{' '}
                  {formatValue(data.litigation.total_criminal_count)}
                </MainInfo>
                <MainInfo>
                  <span>Количество административных дел</span>{' '}
                  {formatValue(data.litigation.total_administrative_count)}
                </MainInfo>
              </MainInfoContent>
              {TableLitigation.length > 0 ? (
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableCellHeader>Год</TableCellHeader>
                      <TableCellHeader>Кол-во гражданских дел</TableCellHeader>
                      <TableCellHeader>Кол-во уголовных дел</TableCellHeader>
                      <TableCellHeader>Кол-во административных дел</TableCellHeader>
                    </TableRow>
                  </TableHeader>
                  <tbody>
                    {TableLitigation.map((caseItem: any, i: number) => (
                      <TableRow key={i} $even={i % 2 === 0}>
                        <TableCell>{caseItem.year}</TableCell>
                        <TableCell right>{caseItem.civil_count}</TableCell>
                        <TableCell right>{caseItem.criminal_count}</TableCell>
                        <TableCell right>{caseItem.administrative_count}</TableCell>
                      </TableRow>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <></>
              )}
            </MainInfoBox>
          ))}
      </ExpandableSection>

      {/* -------------------- УЧРЕДИТЕЛИ (founders) -------------------- */}
      <ExpandableSection>
        <MainInfoTitle onClick={() => setIsFoundersOpen(!isFoundersOpen)}>
          Сведения по учредителям
          {data.foundersPro && <img src={IconPro} alt='iconPro' />}
          <ArrowIcon src={isFoundersOpen ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {isFoundersOpen &&
          (data.foundersPro && !proAccessed ? (
            <ImgContainer>
              <img src={BlurReport} alt='Pro-only report' />
            </ImgContainer>
          ) : (
            <MainInfoBox>
              <MainInfoContent>
                <MainInfo>
                  <span>Количество учредителей</span>
                  {formatValue(data.founders.founders_cnt)}
                </MainInfo>
              </MainInfoContent>
              {TableFounders.length > 0 ? (
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableCellHeader>Наименование учредителя</TableCellHeader>
                      <TableCellHeader>ИИН/БИН учредителя</TableCellHeader>
                      <TableCellHeader>Дата начало учредительства</TableCellHeader>
                      <TableCellHeader>Является юр. лицом</TableCellHeader>
                    </TableRow>
                  </TableHeader>
                  <tbody>
                    {TableFounders.map((caseItem: any, i: number) => (
                      <TableRow key={i} $even={i % 2 === 0}>
                        <TableCell>{caseItem.name}</TableCell>
                        <TableCell>{caseItem.founder_uin_formatted}</TableCell>
                        <TableCell>{caseItem.start_date}</TableCell>
                        <TableCell>{formatValue(caseItem.is_company, true)}</TableCell>
                      </TableRow>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <></>
              )}
            </MainInfoBox>
          ))}
      </ExpandableSection>

      {/* -------------------- АФФИЛИРОВАННОСТЬ (connectedDiagram) -------------------- */}
      <ExpandableSection>
        <MainInfoTitle onClick={() => setIsConnectedDiagramOpen(!isConnectedDiagramOpen)}>
          Информация по аффилированности
          {data.connectedDiagramPro && <img src={IconPro} alt='iconPro' />}
          <ArrowIcon src={isConnectedDiagramOpen ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {isConnectedDiagramOpen &&
          (data.connectedDiagramPro && !proAccessed ? (
            <ImgContainer>
              <img src={BlurReport} alt='Pro-only report' />
            </ImgContainer>
          ) : (
            <MainInfoBox>
              {/* <div>
                <strong>affiliation_by_company:</strong>{' '}
                {JSON.stringify(data.connectedDiagram.affiliation_by_company)}
              </div> */}

              {/* affiliation_by_head */}
              <Header>Аффилированность по руководителю</Header>

              <MainInfoContent>
                <MainInfo>
                  <span>ФИО руководителя</span>{' '}
                  {formatValue(data.connectedDiagram.affiliation_by_head?.head_name)}
                </MainInfo>
              </MainInfoContent>
              {TableAffilHead.length > 0 ? (
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableCellHeader>ФИО руководителя</TableCellHeader>
                      <TableCellHeader>Тип участия</TableCellHeader>
                      <TableCellHeader>Наименование компании</TableCellHeader>
                      <TableCellHeader>БИН компании</TableCellHeader>
                    </TableRow>
                  </TableHeader>
                  <tbody>
                    {TableAffilHead.map((caseItem: any, i: number) => (
                      <TableRow key={i} $even={i % 2 === 0}>
                        <TableCell>{caseItem.director}</TableCell>
                        <TableCell>{caseItem.type}</TableCell>
                        <TableCell>{caseItem.name}</TableCell>
                        <TableCell>{caseItem.bin_formatted}</TableCell>
                      </TableRow>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <></>
              )}

              {TableAffilFounder.length > 0 ? (
                <>
                  {/* affiliation_by_founder */}
                  <Header>Аффилированность учредителей</Header>

                  {TableAffilFounder.map((f: any, i: number) => (
                    <div key={i} style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                      <MainInfoContent>
                        <MainInfo>
                          <span>ФИО учредителя</span> {formatValue(f.founder_name)}
                        </MainInfo>
                      </MainInfoContent>
                      <MainInfoContent>
                        <MainInfo>
                          <span>БИН/ИИН учредителя</span> {formatValue(f.founder_biin_formatted)}
                        </MainInfo>
                      </MainInfoContent>
                      <Table style={{ marginTop: '24px' }}>
                        <TableHeader>
                          <TableRow>
                            <TableCellHeader>ФИО учрелителя</TableCellHeader>
                            <TableCellHeader>Тип участия</TableCellHeader>
                            <TableCellHeader>Наименование компании</TableCellHeader>
                            <TableCellHeader>БИН компании</TableCellHeader>
                          </TableRow>
                        </TableHeader>
                        <tbody>
                          {f.companies?.map((caseItem: any, i: number) => (
                            <TableRow key={i} $even={i % 2 === 0}>
                              <TableCell>{caseItem.director}</TableCell>
                              <TableCell>{caseItem.type}</TableCell>
                              <TableCell>{caseItem.name}</TableCell>
                              <TableCell>{caseItem.bin_formatted}</TableCell>
                            </TableRow>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  ))}
                </>
              ) : (
                <></>
              )}
            </MainInfoBox>
          ))}
      </ExpandableSection>

      {/* -------------------- ЛИЦЕНЗИИ (licenseGeneral) -------------------- */}
      <ExpandableSection>
        <MainInfoTitle onClick={() => setIsLicenseGeneralOpen(!isLicenseGeneralOpen)}>
          Информация по лицензиям и документам
          {data.licenseGeneralPro && <img src={IconPro} alt='iconPro' />}
          <ArrowIcon src={isLicenseGeneralOpen ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {isLicenseGeneralOpen &&
          (data.licenseGeneralPro && !proAccessed ? (
            <ImgContainer>
              <img src={BlurReport} alt='Pro-only report' />
            </ImgContainer>
          ) : (
            <MainInfoBox>
              <MainInfoContent>
                <MainInfo>
                  <span>Количество лицензий </span>{' '}
                  {formatValue(data.licenseGeneral.total_licenses_count)}
                </MainInfo>
                <MainInfo>
                  <span>Количество документов</span>{' '}
                  {formatValue(data.licenseGeneral.total_other_docs_count)}
                </MainInfo>
              </MainInfoContent>
              {TableLicense.length > 0 ? (
                <Table>
                  <TableHeader>
                    <tr>
                      <TableCellHeader>Год</TableCellHeader>
                      <TableCellHeader>Количество лицензий</TableCellHeader>
                      <TableCellHeader>Количество документов</TableCellHeader>
                    </tr>
                  </TableHeader>
                  <tbody>
                    {TableLicense.map((det: any, i: number) => (
                      <TableRow key={i} $even={i % 2 === 0}>
                        <TableCell>{det.year}</TableCell>
                        <TableCell right>{det.licenses_count}</TableCell>
                        <TableCell right>{det.other_docs_count}</TableCell>
                      </TableRow>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <></>
              )}
            </MainInfoBox>
          ))}
      </ExpandableSection>

      {/* -------------------- КОНТРАКТЫ (contract) -------------------- */}
      <ExpandableSection>
        <MainInfoTitle onClick={() => setIsContractOpen(!isContractOpen)}>
          Информация по контрактам
          {data.contractPro && <img src={IconPro} alt='iconPro' />}
          <ArrowIcon src={isContractOpen ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {isContractOpen &&
          (data.contractPro && !proAccessed ? (
            <ImgContainer>
              <img src={BlurReport} alt='Pro-only report' />
            </ImgContainer>
          ) : (
            <MainInfoBox>
              <MainInfoContent>
                <MainInfo>
                  <span>Количество контрактов</span> {formatValue(data.contract.total_count)}
                </MainInfo>
                <MainInfo>
                  <span>Сумма контрактов</span>{' '}
                  {formatValue(data.contract.total_sum.toLocaleString('ru-RU'))} ₸
                </MainInfo>
              </MainInfoContent>
              {TableContract.length > 0 ? (
                <Table>
                  <TableHeader>
                    <tr>
                      <TableCellHeader>Год</TableCellHeader>
                      <TableCellHeader>Количество контрактов</TableCellHeader>
                      <TableCellHeader>Сумма контрактов</TableCellHeader>
                    </tr>
                  </TableHeader>
                  <tbody>
                    {TableContract.map((c: any, i: number) => (
                      <TableRow key={i} $even={i % 2 === 0}>
                        <TableCell>{c.year}</TableCell>
                        <TableCell right>{c.count}</TableCell>
                        <TableCell right>
                          {c.amount?.toLocaleString('ru-RU', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{' '}
                          ₸
                        </TableCell>
                      </TableRow>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <></>
              )}
            </MainInfoBox>
          ))}
      </ExpandableSection>

      {/* -------------------- РАСШИРЕННЫЕ ПРИЗНАКИ БЛАГОНАДЁЖНОСТИ (trustworthyExtended) -------------------- */}
      <ExpandableSection>
        <MainInfoTitle onClick={() => setIsTrustworthyExtOpen(!isTrustworthyExtOpen)}>
          Расширенные признаки благонадежности
          {data.trustworthyExtendedPro && <img src={IconPro} alt='iconPro' />}
          <ArrowIcon src={isTrustworthyExtOpen ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {isTrustworthyExtOpen &&
          (data.trustworthyExtendedPro && !proAccessed ? (
            <ImgContainer>
              <img src={BlurReport} alt='Pro-only report' />
            </ImgContainer>
          ) : (
            <MainInfoBox>
              <MainInfoContent>
                <MainInfo>
                  <span>Дата начала</span>{' '}
                  {formatValue(data.trustworthyExtended?.liquidating_taxpayer?.start_date)}
                </MainInfo>
                <MainInfo>
                  <span>Количество задолженности</span>{' '}
                  {formatValue(data.trustworthyExtended?.enforcement_debt?.count)}
                </MainInfo>
                <MainInfo>
                  <span>Сумма задолженности</span>{' '}
                  {formatValue(data.trustworthyExtended?.enforcement_debt?.amount)}
                </MainInfo>
                <MainInfo>
                  <span>Количество ограничений</span>{' '}
                  {formatValue(data.trustworthyExtended?.leaving_restriction?.count)}
                </MainInfo>
                <MainInfo>
                  <span>Сумма ограничений</span>{' '}
                  {formatValue(data.trustworthyExtended?.leaving_restriction?.amount)}
                </MainInfo>
                <MainInfo>
                  <span>В списке компаний попавших под налоговую проверку в 1-ом полугодии</span>{' '}
                  {formatValue(data.trustworthyExtended?.first_half_tax_inspection)}
                </MainInfo>
                <MainInfo>
                  <span>В списке компаний попавших под налоговую проверку во 2-ом полугодии</span>{' '}
                  {formatValue(data.trustworthyExtended?.second_half_tax_inspection)}
                </MainInfo>
                <MainInfo>
                  <span>
                    В списке налогоплательщиков, имеющих налоговую задолженность более 150 МРП
                  </span>{' '}
                  {formatValue(data.trustworthyExtended?.tax_arrears_150)}
                </MainInfo>
                <MainInfo>
                  <span>
                    В списке банкротов, в отношении которых решения суда о признании их банкротами
                    вступили в законную силу
                  </span>{' '}
                  {formatValue(data.trustworthyExtended?.force_entered)}
                </MainInfo>
                <MainInfo>
                  <span>
                    В списке банкротов, в отношении которых процедура банкротства завершена
                  </span>{' '}
                  {formatValue(data.trustworthyExtended?.finished)}
                </MainInfo>
                <MainInfo>
                  <span>
                    В списке банкротов, в отношении которых начался реабилитационный период
                  </span>{' '}
                  {formatValue(data.trustworthyExtended?.rehabilitation)}
                </MainInfo>
                <MainInfo>
                  <span>Временное ограничение на выезд из РК</span>{' '}
                  {formatValue(data.trustworthyExtended?.restriction_on_leaving)}
                </MainInfo>
                <MainInfo>
                  <span>Арест на транспорт</span>{' '}
                  {formatValue(data.trustworthyExtended?.transport_arrest)}
                </MainInfo>
                <MainInfo>
                  <span>В списке «Исключен из реестра stat.gov»</span>{' '}
                  {formatValue(data.trustworthyExtended?.inactive_statgov)}
                </MainInfo>
                <MainInfo>
                  <span>
                    В списке «Перечень крупных налогоплательщиков, подлежащих мониторингу»
                  </span>{' '}
                  {formatValue(data.trustworthyExtended?.large_taxpayer)}
                </MainInfo>
                <MainInfo>
                  <span>
                    В списке налогоплательщиков, сделки с которыми совершены без фактического
                    выполнения работ, оказания услуг, отгрузки товаров
                  </span>{' '}
                  {formatValue(data.trustworthyExtended?.workless_taxpayer)}
                </MainInfo>
                <MainInfo>
                  <span>В списке «Отсутствует по юридическому адресу»</span>{' '}
                  {formatValue(data.trustworthyExtended?.wrong_address)}
                </MainInfo>
                <MainInfo>
                  <span>Состоит в реестре по которым произведено ограничение выписки ЭСФ</span>{' '}
                  {formatValue(data.trustworthyExtended?.esf_bounded)}
                </MainInfo>
                <MainInfo>
                  <span>Состоит в реестре по которым произведен отзыв ЭСФ самостоятельно</span>{' '}
                  {formatValue(data.trustworthyExtended?.esf_withdrawn)}
                </MainInfo>
                <MainInfo>
                  <span>Примененные меры воздействия и санкции</span>{' '}
                  {formatValue(data.trustworthyExtended?.sanction_count)}
                </MainInfo>
                <MainInfo>
                  <span>В розыске должников/ответчиков по исполнительным документам</span>{' '}
                  {formatValue(data.trustworthyExtended?.debtor_on_executive_docs)}
                </MainInfo>
                <MainInfo>
                  <span>В розыске преступников</span>{' '}
                  {formatValue(data.trustworthyExtended?.is_wanted_criminal)}
                </MainInfo>
              </MainInfoContent>
            </MainInfoBox>
          ))}
      </ExpandableSection>

      {/* -------------------- РЕКВИЗИТЫ (requisites) -------------------- */}
      {/* <ExpandableSection>
        <MainInfoTitle onClick={() => setIsRequisitesOpen(!isRequisitesOpen)}>
          <strong>Данные о реквизите (requisites)</strong>
          {data.requisitesPro && <img src={IconPro} alt='iconPro' />}
          <ArrowIcon src={isRequisitesOpen ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {isRequisitesOpen && (
          <MainInfoBox>
            <MainInfoContent>
              <div>
                <strong>БИН реквизита (bin):</strong> {data.requisites.bin}
              </div>
              <div>
                <strong>краткое наименование (short_name):</strong> {data.requisites.short_name}
              </div>
              <div>
                <strong>юридический адрес (legal_address):</strong> {data.requisites.legal_address}
              </div>
              <div>
                <strong>ИИК (iik):</strong> {String(data.requisites.iik)}
              </div>
              <div>
                <strong>название банка (bank):</strong> {String(data.requisites.bank)}
              </div>
              <div>
                <strong>код КБЕ (kbe_code):</strong> {String(data.requisites.kbe_code)}
              </div>
              <div>
                <strong>БИК (bik):</strong> {String(data.requisites.bik)}
              </div>
            </MainInfoContent>
          </MainInfoBox>
        )}
      </ExpandableSection> */}

      {/* -------------------- РИСК ФАКТОРЫ РАСШИРЕННЫЕ (riskFactorExtended) -------------------- */}
      <ExpandableSection>
        <MainInfoTitle onClick={() => setIsRiskFactorExtOpen(!isRiskFactorExtOpen)}>
          Риск факторы расширенные
          {data.riskFactorExtendedPro && <img src={IconPro} alt='iconPro' />}
          <ArrowIcon src={isRiskFactorExtOpen ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {isRiskFactorExtOpen &&
          (data.riskFactorExtendedPro && !proAccessed ? (
            <ImgContainer>
              <img src={BlurReport} alt='Pro-only report' />
            </ImgContainer>
          ) : (
            <MainInfoBox>
              <MainInfoContent>
                <MainInfo>
                  <span>
                    В списке агентов, имеющих задолженность по обязательным пенсионным взносам,
                    обязательным профессиональным пенсионным взносом, не погашенную в срок более 6
                    месяцев со дня ее возникновения
                  </span>{' '}
                  {formatValue(data.riskFactorExtended.company?.pension_contribution_debt)}
                </MainInfo>
                <MainInfo>
                  <span>
                    В списке плательщиков, имеющих задолженность по социальным отчислениям, не
                    погашенную в срок более 6 месяцев со дня ее возникновения
                  </span>{' '}
                  {formatValue(data.riskFactorExtended.company?.social_contribution_debt)}
                </MainInfo>
                <MainInfo>
                  <span>В реестре ненадежных поставщиков Самрук Казына</span>{' '}
                  {formatValue(
                    data.riskFactorExtended.company?.zakup?.unreliable_suppliers_register?.samruk,
                  )}
                </MainInfo>{' '}
              </MainInfoContent>
            </MainInfoBox>
          ))}
      </ExpandableSection>

      {/* -------------------- НАЛОГОПЛАТЕЛЬЩИК (taxpayer) -------------------- */}
      <ExpandableSection>
        <MainInfoTitle onClick={() => setIsTaxpayerOpen(!isTaxpayerOpen)}>
          Налогоплательщик
          {data.taxpayerPro && <img src={IconPro} alt='iconPro' />}
          <ArrowIcon src={isTaxpayerOpen ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {isTaxpayerOpen &&
          (data.taxpayerPro && !proAccessed ? (
            <ImgContainer>
              <img src={BlurReport} alt='Pro-only report' />
            </ImgContainer>
          ) : (
            <MainInfoBox>
              <MainInfoContent>
                <MainInfo>
                  <span>Дата снятия с учета ЮЛ/ИП</span> {formatValue(data.taxpayer.unreg_date)}
                </MainInfo>
                <MainInfo>
                  <span>Причина снятия с учета ЮЛ/ИП</span>{' '}
                  {formatValue(data.taxpayer.unreg_reason)}
                </MainInfo>
                <MainInfo>
                  <span>Период приостановки деятельности ЮЛ/ИП</span>{' '}
                  {formatValue(data.taxpayer.suspension_date)}
                </MainInfo>
              </MainInfoContent>
            </MainInfoBox>
          ))}
      </ExpandableSection>

      {/* -------------------- Расширенные признаки благонадежности+ (kzCoTrustworthyPlus) -------------------- */}
      <ExpandableSection>
        <MainInfoTitle onClick={() => setIsKzCoTrustworthyPlusOpen(!isKzCoTrustworthyPlusOpen)}>
          Расширенные признаки благонадежности +
          {data.kzCoTrustworthyPlusPro && <img src={IconPro} alt='iconPro' />}
          <ArrowIcon src={isKzCoTrustworthyPlusOpen ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {isKzCoTrustworthyPlusOpen &&
          (data.kzCoTrustworthyPlusPro && !proAccessed ? (
            <ImgContainer>
              <img src={BlurReport} alt='Pro-only report' />
            </ImgContainer>
          ) : (
            <MainInfoBox>
              <MainInfoContent>
                <MainInfo>
                  <span>
                    В списке «Профилактический контроль и надзор на 1-ое полугодие текущего года»
                  </span>{' '}
                  {formatValue(data.kzCoTrustworthyPlus.first_half_control_supervision)}
                </MainInfo>
                <MainInfo>
                  <span>
                    В списке «Профилактический контроль и надзор на 2-ое полугодие текущего года»
                  </span>{' '}
                  {formatValue(data.kzCoTrustworthyPlus.second_half_control_supervision)}
                </MainInfo>
                <MainInfo>
                  <span>
                    В списке «Комплексные выездные таможенные проверки на 1-ое полугодие текущего
                    года»
                  </span>{' '}
                  {formatValue(data.kzCoTrustworthyPlus.first_half_custom_inspection)}
                </MainInfo>
                <MainInfo>
                  <span>
                    В списке «Комплексные выездные таможенные проверки на 2-ое полугодие текущего
                    года»
                  </span>{' '}
                  {formatValue(data.kzCoTrustworthyPlus.second_half_custom_inspection)}
                </MainInfo>
                <MainInfo>
                  <span>Исполнительная надпись</span>{' '}
                  {formatValue(data.kzCoTrustworthyPlus.executive_inscription)}
                </MainInfo>
                <MainInfo>
                  <span>Объявление о возбуждении дела о реабилитации</span>{' '}
                  {formatValue(data.kzCoTrustworthyPlus.rehabilitation_proceedings)}
                </MainInfo>
                <MainInfo>
                  <span>
                    Объявления о применении реабилитационной процедуры и приема требований временным
                    администратором
                  </span>{' '}
                  {formatValue(data.kzCoTrustworthyPlus.rehabilitation_proceedings_admin_approval)}
                </MainInfo>
                <MainInfo>
                  <span>
                    Объявления о проведении собрания кредиторов в реабилитационной процедуре
                  </span>{' '}
                  {formatValue(data.kzCoTrustworthyPlus.creditor_meeting_announcements_for_rehab)}
                </MainInfo>
                <MainInfo>
                  <span>
                    Объявления о возбуждении дела о банкротстве и порядке заявления требований
                    кредиторами временному управляющему
                  </span>{' '}
                  {formatValue(
                    data.kzCoTrustworthyPlus.bankruptcy_initiation_and_manager_creditor_claims,
                  )}
                </MainInfo>
                <MainInfo>
                  <span>
                    Объявления о признании банкротом и ликвидации с возбуждением процедуры
                    банкротства
                  </span>{' '}
                  {formatValue(
                    data.kzCoTrustworthyPlus.bankruptcy_and_liquidation_initiation_notices,
                  )}
                </MainInfo>
                <MainInfo>
                  <span>Объявления о проведении собрания кредиторов в процедуре банкротства</span>{' '}
                  {formatValue(data.kzCoTrustworthyPlus.creditors_meeting_in_bankruptcy)}
                </MainInfo>
                <MainInfo>
                  <span>В реестре торгов арестованного имущества</span>{' '}
                  {formatValue(data.kzCoTrustworthyPlus.in_seized_property_sales_registry)}
                </MainInfo>
                <MainInfo>
                  <span>
                    Состоит в реестре аккредитованных профессиональных организаций бухгалтеров
                  </span>{' '}
                  {formatValue(data.kzCoTrustworthyPlus.accredited_accountant_organization_member)}
                </MainInfo>
                <MainInfo>
                  <span>
                    Состоит в реестре аккредитованных профессиональных аудиторских организаций
                  </span>{' '}
                  {formatValue(data.kzCoTrustworthyPlus.in_accredited_audit_organizations_register)}
                </MainInfo>
                <MainInfo>
                  <span>
                    Состоит в реестре аккредитованных организаций по профессиональной сертификации
                    бухгалтеров
                  </span>{' '}
                  {formatValue(data.kzCoTrustworthyPlus.certified_accountant_organizations_member)}
                </MainInfo>
                <MainInfo>
                  <span>Состоит в реестре аудиторских организаций </span>{' '}
                  {formatValue(data.kzCoTrustworthyPlus.in_audit_organizations_register)}
                </MainInfo>
                <MainInfo>
                  <span>Участие в судебных делах</span>{' '}
                  {formatValue(data.kzCoTrustworthyPlus.court_case_participation)}
                </MainInfo>
                <MainInfo>
                  <span>Проблема в компаниях с таким руководителем</span>{' '}
                  {formatValue(data.kzCoTrustworthyPlus.same_director_problem_company)}
                </MainInfo>
                <MainInfo>
                  <span>Массовый адрес </span> {formatValue(data.kzCoTrustworthyPlus.mass_address)}
                </MainInfo>
              </MainInfoContent>
            </MainInfoBox>
          ))}
      </ExpandableSection>
      {/* -------------------- КОНТАКТЫ (kzCoContact) -------------------- */}
      {/* <ExpandableSection>
        <MainInfoTitle onClick={() => setIsKzCoContactOpen(!isKzCoContactOpen)}>
          <strong>Контакты (kzCoContact)</strong>
          {data.kzCoContactPro && <img src={IconPro} alt='iconPro' />}
          <ArrowIcon src={isKzCoContactOpen ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {isKzCoContactOpen && (
          <MainInfoBox>
            <MainInfoContent>
              {data.kzCoContact.map((item: any, i: number) => (
                <div key={i} style={{ marginBottom: '10px' }}>
                  <div>
                    <strong>Телефоны (phones):</strong>
                  </div>
                  {item.phones?.map((ph: { contact: string }, idx: number) => (
                    <div key={idx} style={{ marginLeft: '20px' }}>
                      - {ph.contact}
                    </div>
                  ))}
                  <div style={{ marginTop: '5px' }}>
                    <strong>Почты (emails):</strong>
                  </div>
                  {item.emails?.map((em: { contact: string }, idx: number) => (
                    <div key={idx} style={{ marginLeft: '20px' }}>
                      - {em.contact}
                    </div>
                  ))}
                  <div style={{ marginTop: '5px' }}>
                    <strong>Сайты (websites):</strong>
                    {item.websites?.length === 0 ? ' [нет сайтов]' : ''}
                  </div>
                  {item.websites?.map((w: WebsiteItem, idx: number) => (
                    <div key={idx} style={{ marginLeft: '20px' }}>
                      - {w.contact}
                    </div>
                  ))}
                </div>
              ))}
            </MainInfoContent>
          </MainInfoBox>
        )}
      </ExpandableSection> */}
    </Container>
  );
};
