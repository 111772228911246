import React, { useEffect, useState, useCallback } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import {
  CustomSelectWrapper,
  CustomSelect,
  CustomOptions,
  CustomOption,
  ButtonBoxDowland,
  Title,
  CalendarWrapper,
  TableBox,
  CalendarBox,
} from './Payments.style'; // <-- Ваши стили
import {
  Container,
  StyledLink,
  BoxHeader,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TableRowHeader,
  BoxInputDescription,
  BoxInfoInput,
  StyledDatePicker,
  DatePickerWrapper,
  CalendarIcon,
  BoxInput,
  ButtonsBox,
  ActionFilterBox,
  ControlBox,
  FilterBox,
  ScrollableTableContainer,
} from 'components/ApplicationBusinessList/ApplicationBusinessList.style';
import { useAppContext } from 'contexts/AppContext';
import { NewButton } from 'components/common/Button';
import { Checkbox } from 'components/common/Checkbox';
import SelectBox from 'components/common/SelectBox/SelectBox';
import { Pagination } from 'components/Pagination';
import { PaymentData } from './Payments.interface';
import 'react-datepicker/dist/react-datepicker.css';
import { default as CalendarIcons } from 'assets/img/CalendarIcon.svg';
import { InputSearch } from 'components/common/Input/InputSearch';
import { default as IconTune } from 'assets/img/IconTune.svg';
import { InputNew } from 'components/common/Input/InputNew/InputNew';
import { ru } from 'date-fns/locale';
import { formatNumberWithSpaces, removeSpacesFromNumber } from 'utils/numberFormat';
import SkeletonTable from 'components/SkeletonTable/SkeletonTable';
import Hint from 'components/common/Hint/Hint';
import { SwitcherBox, Tab } from 'components/ApplicationInvestor/ApplicationInvestorNew.style';
import { format } from 'date-fns';
import { LoadingGif } from 'components/common/LoadingGif';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/`;

export const Payments = () => {
  const { userDataLight } = useAppContext();

  // ------------------------------------------------------
  //    1. Состояние для переключения вкладок
  // ------------------------------------------------------
  const [activeTab, setActiveTab] = useState<'list' | 'calendar'>('list');

  // ------------------------------------------------------
  //    2. Всё, что связано со "Списком платежей"
  // ------------------------------------------------------
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [payments, setPayments] = useState<PaymentData[]>([]);
  const [selectedPayments, setSelectedPayments] = useState<Set<number>>(new Set());
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);
  const [isLoadingOneC, setIsLoadingOneC] = useState(false);
  const [openSelectId, setOpenSelectId] = useState<number | null>(null);

  const [currentPage, setCurrentPage] = useState(0);
  const [paginationInfo, setPaginationInfo] = useState({
    totalPages: 0,
    totalElements: 0,
    currentPage: 0,
  });

  const [isFilterBoxOpen, setFilterBoxOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Фильтры (как в вашем исходном коде)
  const [filters, setFilters] = useState<{
    dateFrom: string | null;
    dateTo: string | null;
    contractNumber: string;
    paymentType: string[];
    debitMin: string;
    debitMax: string;
    creditMin: string;
    creditMax: string;
    balanceMin: string;
    balanceMax: string;
    status: string[];
    showPlan: boolean | null;
  }>({
    dateFrom: null,
    dateTo: null,
    contractNumber: '',
    paymentType: [],
    debitMin: '',
    debitMax: '',
    creditMin: '',
    creditMax: '',
    balanceMin: '',
    balanceMax: '',
    status: [],
    showPlan: null,
  });

  // Локальные стейты, связанные с фильтрами:
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);
  const [contractNumber, setContractNumber] = useState<string>('');
  const [paymentType, setPaymentType] = useState<string[]>([]);
  const [status, setStatus] = useState<string[]>([]);
  const [debitMin, setDebitMin] = useState('');
  const [debitMax, setDebitMax] = useState('');
  const [creditMin, setCreditMin] = useState('');
  const [creditMax, setCreditMax] = useState('');
  const [balanceMin, setBalanceMin] = useState('');
  const [balanceMax, setBalanceMax] = useState('');
  const [showPlan, setShowPlan] = useState<boolean>(false);

  // Сортировка для вкладки "Список"
  const [sortConfig, setSortConfig] = useState<{ field: string; direction: 'asc' | 'desc' | null }>(
    { field: '', direction: null },
  );

  // Эндпоинт для вкладки "Список" (зависит от роли)
  const endpoint =
    userDataLight.organizationRole === 'INVESTOR'
      ? `api/v1/cabinet/investor/payment/calendar/`
      : `api/v1/cabinet/creditor/payment/calendar/`;

  // Основная загрузка "Списка платежей"
  const fetchPayments = useCallback(
    async (
      page = 0,
      filtersArg: any = {},
      sort: { field: string; direction: 'asc' | 'desc' | null } = sortConfig,
    ) => {
      setIsLoading(true);
      try {
        const params = new URLSearchParams();
        params.append('page', page.toString());

        if (sort.field && sort.direction) {
          params.append('sort', `${sort.field},${sort.direction}`);
        }

        const url = `${BASE_URL}${endpoint}find_all?${params.toString()}`;
        const response = await axios.post(url, filtersArg, { withCredentials: true });

        setPayments(response.data.content || []);
        setPaginationInfo({
          totalPages: response.data.totalPages,
          totalElements: response.data.totalElements,
          currentPage: response.data.number,
        });
      } catch (error) {
        console.error('Ошибка при загрузке данных о платежах:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [sortConfig, endpoint],
  );

  // При загрузке вкладки "Список" (или смене фильтров/страницы/сортировки) вызываем fetchPayments
  useEffect(() => {
    if (activeTab === 'list') {
      fetchPayments(currentPage, filters, sortConfig);
    }
  }, [currentPage, filters, sortConfig, activeTab, fetchPayments]);

  // Сортировка в "Списке"
  const handleSort = useCallback(
    (field: string) => {
      setSortConfig((prevSortConfig) => {
        const newDirection: 'asc' | 'desc' | null =
          prevSortConfig.field === field && prevSortConfig.direction === 'asc' ? 'desc' : 'asc';

        const newSortConfig = { field, direction: newDirection };
        // Перегружаем платежи с нуля, применив новую сортировку
        fetchPayments(0, filters, newSortConfig);

        return newSortConfig;
      });
    },
    [filters, fetchPayments],
  );

  // Применение фильтров
  const applyFiltersAndSorting = () => {
    const currentFilters = {
      dateFrom: dateFrom ? dateFrom.toISOString().split('T')[0] : null,
      dateTo: dateTo ? dateTo.toISOString().split('T')[0] : null,
      contractNumber: contractNumber || '',
      paymentType: paymentType.length ? paymentType : [],
      debitMin,
      debitMax,
      creditMin,
      creditMax,
      balanceMin,
      balanceMax,
      status: status.length ? status : [],
      showPlan,
    };
    setFilters(currentFilters);
    setCurrentPage(0);
  };

  // Сброс фильтров
  const clearFilters = () => {
    setDateFrom(null);
    setDateTo(null);
    setContractNumber('');
    setPaymentType([]);
    setDebitMin('');
    setDebitMax('');
    setCreditMin('');
    setCreditMax('');
    setBalanceMin('');
    setBalanceMax('');
    setStatus([]);
    setShowPlan(false);
    setCurrentPage(0);
    fetchPayments(0, {}, sortConfig);
  };

  // Переключение страниц в "Списке"
  const handlePageChange = ({ selected }: { selected: number }) => {
    setCurrentPage(selected);
    fetchPayments(selected, filters, sortConfig);
  };

  // Чекбокс (выбор платежа) - общий для обеих вкладок
  const handlePaymentSelection = (paymentId: number) => {
    setSelectedPayments((prev) => {
      const updated = new Set(prev);
      if (updated.has(paymentId)) {
        updated.delete(paymentId);
      } else {
        updated.add(paymentId);
      }
      return updated;
    });
  };

  // Генерация PDF счетов (общая)
  const handleGenerateInvoice = async () => {
    const selectedIds = Array.from(selectedPayments);
    if (!selectedIds.length) return;

    const url = `${BASE_URL}${endpoint}payment_ordinary`;
    setIsLoadingPdf(true);
    try {
      const response = await axios.post(url, selectedIds, {
        withCredentials: true,
        responseType: 'blob',
      });
      const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urlBlob;
      link.setAttribute('download', 'invoice.zip');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error generating invoice:', error);
    } finally {
      setIsLoadingPdf(false);
    }
  };

  // Генерация 1С счетов (общая)
  const handleGenerateOneCInvoice = async () => {
    const selectedIds = Array.from(selectedPayments);
    if (!selectedIds.length) return;

    const url = `${BASE_URL}${endpoint}export_onec`;
    setIsLoadingOneC(true);
    try {
      const response = await axios.post(url, selectedIds, {
        withCredentials: true,
        responseType: 'blob',
      });
      const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urlBlob;
      link.setAttribute('download', 'invoice_onec.txt');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error generating 1C invoice:', error);
    } finally {
      setIsLoadingOneC(false);
    }
  };

  // Изменение статуса платежа (общая логика)
  const handleStatusChange = async (paymentCalendarId: number, status: string) => {
    const url = `${BASE_URL}${endpoint}payment_calendar/status/${paymentCalendarId}?paymentStatus=${status}`;
    try {
      await axios.get(url, { withCredentials: true });
      // Обновляем массив `payments` (для вкладки "Список")
      const updatedPayments = payments.map((p) =>
        p.paymentCalendarId === paymentCalendarId ? { ...p, paymentStatus: status } : p,
      );
      setPayments(updatedPayments);

      // Также обновим `dayPayments` (для вкладки "Календарь"), чтобы статус менялся и там
      setDayPayments((prev) =>
        prev.map((p) =>
          p.paymentCalendarId === paymentCalendarId ? { ...p, paymentStatus: status } : p,
        ),
      );

      setOpenSelectId(null);
    } catch (error) {
      console.error('Ошибка при обновлении статуса:', error);
    }
  };

  // Класс для ячеек статуса (PAID / UNPAID / AWAITING_PAYMENT)
  const getStatusClassName = (status: string) => {
    switch (status) {
      case 'PAID':
        return 'paid';
      case 'UNPAID':
        return 'unpaid';
      case 'AWAITING_PAYMENT':
        return 'awaiting';
      default:
        return '';
    }
  };

  // Закрытие раскрывающегося списка при клике вне
  const toggleDropdown = (index: number) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest('.dropdown-container')) {
        setOpenDropdownIndex(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Открыть/закрыть блок с фильтрами во вкладке "Список"
  const toggleFilterBox = () => {
    setFilterBoxOpen((prev) => !prev);
  };

  // Справочники для селектов (тип операции, статус)
  const paymentTypeOptions = [
    { id: 'INTEREST', name: 'Процент' },
    { id: 'BODY', name: 'Тело' },
  ];
  const statusOptions = [
    { id: 'PAID', name: 'Оплачено' },
    { id: 'UNPAID', name: 'Не оплачено' },
    { id: 'AWAITING_PAYMENT', name: 'Ожидание' },
  ];
  const selectedPaymentTypeNamesArray = paymentTypeOptions
    .filter((o) => paymentType.includes(o.id))
    .map((o) => o.name);
  const selectedStatusNamesArray = statusOptions
    .filter((o) => status.includes(o.id))
    .map((o) => o.name);

  // ------------------------------------------------------
  //    3. Логика для "Календаря"
  // ------------------------------------------------------
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [markedDates, setMarkedDates] = useState<string[]>([]); // Даты, где есть платежи
  const [dayPayments, setDayPayments] = useState<PaymentData[]>([]); // Платежи за выбранный день

  // Эндпоинт для списка дат (зависит от роли)
  const endpointCalendarList =
    userDataLight.organizationRole === 'INVESTOR'
      ? `api/v1/cabinet/investor/payment/calendar/list/`
      : `api/v1/cabinet/creditor/payment/calendar/list/`;

  // При переходе на вкладку "Календарь" или смене месяца/года — загружаем отмеченные даты
  useEffect(() => {
    if (activeTab === 'calendar') {
      const month = calendarDate.getMonth() + 1; // (JS 0–11)
      const year = calendarDate.getFullYear();
      fetchMarkedDates(month, year);
    }
  }, [activeTab, calendarDate]);

  // Запрашивает «в какие дни этого месяца есть платежи»
  const fetchMarkedDates = async (month: number, year: number) => {
    try {
      // Преобразуем месяц в строку с ведущим нулём, если требуется
      const formattedMonth = month.toString().padStart(2, '0');
      const url = `${BASE_URL}${endpointCalendarList}${formattedMonth}.${year}`;
      const response = await axios.get(url, { withCredentials: true });
      // Формат ответа: [["2024-11-03",1],["2024-11-23",1]]
      const data = response.data as [string, number][];
      const dates = data.map(([dateStr]) => dateStr);
      setMarkedDates(dates);
    } catch (error) {
      console.error('Ошибка загрузки дат для календаря:', error);
    }
  };

  // При клике по конкретному дню – загружаем платежи за этот день
  const fetchDayPayments = useCallback(
    async (date: Date) => {
      try {
        setIsLoading(true);
        const isoDate = format(date, 'yyyy-MM-dd'); // без часовых смещений

        const dayFilter = {
          dateFrom: isoDate,
          dateTo: isoDate,
        };

        // Для дня всегда page=0
        const params = new URLSearchParams();
        params.append('page', '0');

        const url = `${BASE_URL}${endpoint}find_all?${params.toString()}`;
        const response = await axios.post(url, dayFilter, { withCredentials: true });
        setDayPayments(response.data.content || []);
      } catch (err) {
        console.error('Ошибка при загрузке платежей за день:', err);
      } finally {
        setIsLoading(false);
      }
    },
    [endpoint],
  );

  const handleCalendarChange = (date: Date) => {
    setCalendarDate(date);
    fetchDayPayments(date);
  };

  // Подсветка дат в react-calendar
  const tileClassName = ({ date, view }: { date: Date; view: string }) => {
    if (view === 'month') {
      const y = date.getFullYear();
      const m = String(date.getMonth() + 1).padStart(2, '0');
      const d = String(date.getDate()).padStart(2, '0');
      const fullDate = `${y}-${m}-${d}`;
      if (markedDates.includes(fullDate)) {
        return 'highlight-date';
      }
    }
    return '';
  };

  // ------------------------------------------------------
  //    4. Полный render
  // ------------------------------------------------------
  return (
    <Container>
      <Title>Мои платежи</Title>

      {/* Вкладки переключения */}
      <SwitcherBox>
        <Tab active={activeTab === 'list'} onClick={() => setActiveTab('list')}>
          Список платежей
        </Tab>
        <Tab active={activeTab === 'calendar'} onClick={() => setActiveTab('calendar')}>
          Календарь платежей
        </Tab>
      </SwitcherBox>

      {/* --------------------------------------------- */}
      {/*     Вкладка "Список платежей"                */}
      {/* --------------------------------------------- */}
      {activeTab === 'list' && (
        <>
          <BoxHeader>
            <ControlBox>
              <InputSearch
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder='Поиск платежей'
                name='searchQuery'
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    applyFiltersAndSorting();
                  }
                }}
              />
              <FilterBox onClick={toggleFilterBox} isActive={isFilterBoxOpen}>
                <img src={IconTune} alt='Фильтр' />
              </FilterBox>
            </ControlBox>
          </BoxHeader>

          <ActionFilterBox isOpen={isFilterBoxOpen}>
            <BoxInputDescription>
              <BoxInput>
                <p>Дата</p>
                <BoxInfoInput>
                  <span>От</span>
                  <DatePickerWrapper>
                    <StyledDatePicker
                      selected={dateFrom}
                      dateFormat='dd/MM/yyyy'
                      onChange={(val: Date | null) => setDateFrom(val)}
                      placeholderText='Выберите дату'
                      locale={ru}
                    />
                    <CalendarIcon src={CalendarIcons} alt='Календарь' />
                  </DatePickerWrapper>
                  <span>До</span>
                  <DatePickerWrapper>
                    <StyledDatePicker
                      selected={dateTo}
                      dateFormat='dd/MM/yyyy'
                      onChange={(val: Date | null) => setDateTo(val)}
                      placeholderText='Выберите дату'
                      locale={ru}
                    />
                    <CalendarIcon src={CalendarIcons} alt='Календарь' />
                  </DatePickerWrapper>
                </BoxInfoInput>
              </BoxInput>

              <BoxInput>
                <p>Доп. фильтры</p>
                <BoxInfoInput>
                  <Checkbox checked={showPlan} onChange={() => setShowPlan((prev) => !prev)} />
                  <span>Платежи по договорам на стадии подписания </span>
                </BoxInfoInput>
              </BoxInput>
            </BoxInputDescription>

            <BoxInputDescription>
              <BoxInput>
                <p>Приход</p>
                <BoxInfoInput>
                  <span>От</span>
                  <InputNew
                    value={formatNumberWithSpaces(debitMin)}
                    onChange={(e) => setDebitMin(removeSpacesFromNumber(e.target.value))}
                    placeholder='100 000'
                    maxWidth='100px'
                  />
                  <span>До</span>
                  <InputNew
                    value={formatNumberWithSpaces(debitMax)}
                    onChange={(e) => setDebitMax(removeSpacesFromNumber(e.target.value))}
                    placeholder='900 000'
                    maxWidth='100px'
                  />
                </BoxInfoInput>
              </BoxInput>

              <BoxInput>
                <p>Расход</p>
                <BoxInfoInput>
                  <span>От</span>
                  <InputNew
                    value={formatNumberWithSpaces(creditMin)}
                    onChange={(e) => setCreditMin(removeSpacesFromNumber(e.target.value))}
                    placeholder='100 000'
                    maxWidth='100px'
                  />
                  <span>До</span>
                  <InputNew
                    value={formatNumberWithSpaces(creditMax)}
                    onChange={(e) => setCreditMax(removeSpacesFromNumber(e.target.value))}
                    placeholder='900 000'
                    maxWidth='100px'
                  />
                </BoxInfoInput>
              </BoxInput>

              <BoxInput>
                <p>Сальдо</p>
                <BoxInfoInput>
                  <span>От</span>
                  <InputNew
                    value={formatNumberWithSpaces(balanceMin)}
                    onChange={(e) => setBalanceMin(removeSpacesFromNumber(e.target.value))}
                    placeholder='100 000'
                    maxWidth='100px'
                  />
                  <span>До</span>
                  <InputNew
                    value={formatNumberWithSpaces(balanceMax)}
                    onChange={(e) => setBalanceMax(removeSpacesFromNumber(e.target.value))}
                    placeholder='900 000'
                    maxWidth='100px'
                  />
                </BoxInfoInput>
              </BoxInput>
            </BoxInputDescription>

            <BoxInputDescription>
              <BoxInput>
                <SelectBox
                  label='Тип операции'
                  options={paymentTypeOptions}
                  selectedOptions={selectedPaymentTypeNamesArray}
                  onOptionSelect={(option) =>
                    setPaymentType((prev) =>
                      prev.includes(option.id)
                        ? prev.filter((id) => id !== option.id)
                        : [...prev, option.id],
                    )
                  }
                  isDropdownOpen={openDropdownIndex === 0}
                  toggleDropdown={() => toggleDropdown(0)}
                />
              </BoxInput>

              <BoxInput>
                <SelectBox
                  label='Статус'
                  options={statusOptions}
                  selectedOptions={selectedStatusNamesArray}
                  onOptionSelect={(option) =>
                    setStatus((prev) =>
                      prev.includes(option.id)
                        ? prev.filter((id) => id !== option.id)
                        : [...prev, option.id],
                    )
                  }
                  isDropdownOpen={openDropdownIndex === 1}
                  toggleDropdown={() => toggleDropdown(1)}
                />
              </BoxInput>
            </BoxInputDescription>

            <ButtonsBox>
              <NewButton
                text='Сбросить все фильтры'
                className='white small'
                onClick={clearFilters}
              />
              <NewButton
                text='Применить фильтры'
                className='small'
                onClick={applyFiltersAndSorting}
              />
            </ButtonsBox>
          </ActionFilterBox>

          {/* Кнопки "Сгенерировать счет .pdf" и "Выгрузка 1С" */}
          <ButtonBoxDowland>
            <NewButton
              text={isLoadingPdf ? 'Загрузка...' : 'Сгенерировать счет .pdf'}
              loading={isLoadingPdf}
              onClick={handleGenerateInvoice}
              disabled={selectedPayments.size === 0 || isLoadingOneC}
            />
            <NewButton
              text={isLoadingOneC ? 'Загрузка...' : 'Выгрузка в формате 1С'}
              loading={isLoadingOneC}
              onClick={handleGenerateOneCInvoice}
              disabled={selectedPayments.size === 0 || isLoadingPdf}
            />
          </ButtonBoxDowland>

          {/* Основная таблица со списком платежей */}
          {isLoading ? (
            <SkeletonTable />
          ) : (
            <>
              <ScrollableTableContainer>
                <Table>
                  <thead>
                    <TableRowHeader>
                      <TableHeader width='40px'>
                        {/* Если нужно "Выбрать все" — добавьте логику */}
                        <Checkbox
                          checked={false}
                          onChange={() => {
                            // Если нужно "выбрать все", здесь логика
                          }}
                        />{' '}
                      </TableHeader>
                      <TableHeader
                        width='120px'
                        isSorted={sortConfig.field === 'paymentDate'}
                        sortable={true}
                        direction={sortConfig.field === 'paymentDate' ? sortConfig.direction : null}
                        onClick={() => handleSort('paymentDate')}>
                        <Hint displayText='Дата' text='Дата проведения операции' />
                      </TableHeader>
                      <TableHeader
                        isSorted={sortConfig.field === 'contractNum'}
                        sortable={true}
                        direction={sortConfig.field === 'contractNum' ? sortConfig.direction : null}
                        onClick={() => handleSort('contractNum')}>
                        <Hint displayText='Номер контракта' text='Уникальный номер контракта' />
                      </TableHeader>
                      <TableHeader sortable={false}>
                        <Hint displayText='Контрагент' text='Лицо, с которым заключен договор' />
                      </TableHeader>
                      <TableHeader sortable={false}>
                        <Hint displayText='Тип операции' text='Тип финансовой операции' />
                      </TableHeader>
                      <TableHeader sortable={false} className='right'>
                        <Hint displayText='Приход' text='Сумма поступления средств' />
                      </TableHeader>
                      <TableHeader sortable={false} className='right'>
                        <Hint displayText='Расход' text='Сумма списания средств' />
                      </TableHeader>
                      <TableHeader sortable={false} className='right'>
                        <Hint displayText='Сальдо' text='Текущее сальдо счета после операции' />
                      </TableHeader>
                      <TableHeader sortable={false}>
                        <Hint displayText='Статус' text='Текущий статус платежной операции' />
                      </TableHeader>
                    </TableRowHeader>
                  </thead>
                  <tbody>
                    {payments.map((payment) => (
                      <TableRow key={uuidv4()}>
                        <TableCell>
                          <Checkbox
                            checked={selectedPayments.has(payment.paymentCalendarId)}
                            onChange={() => handlePaymentSelection(payment.paymentCalendarId)}
                          />
                        </TableCell>
                        <TableCell>{payment.paymentDate}</TableCell>
                        <TableCell>
                          <StyledLink to={`/contracts/${payment.contractId}`}>
                            {payment.contractNum}
                          </StyledLink>
                        </TableCell>
                        <TableCell>
                          <Hint
                            text={
                              userDataLight?.organizationRole === 'INVESTOR'
                                ? (payment.businessName ?? '')
                                : (payment.investorName ?? '')
                            }
                            truncatedLength={34}
                          />
                        </TableCell>
                        <TableCell>{payment.paymentTypeName}</TableCell>
                        <TableCell className='right'>
                          {payment.debit.toLocaleString('ru-KZ', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{' '}
                          ₸
                        </TableCell>
                        <TableCell className='right'>
                          {payment.credit.toLocaleString('ru-KZ', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{' '}
                          ₸
                        </TableCell>
                        <TableCell
                          className='right'
                          style={{ color: payment.balance > 0 ? '#5784F7' : '#fa4d56' }}>
                          {payment.balance > 0 ? '+' : ''}
                          {payment.balance.toLocaleString('ru-KZ', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{' '}
                          ₸
                        </TableCell>
                        <TableCell className='paymets'>
                          <CustomSelectWrapper>
                            <CustomSelect
                              className={`${getStatusClassName(payment.paymentStatus)} ${
                                openSelectId === payment.paymentCalendarId ? 'open' : ''
                              }`}
                              onClick={() =>
                                setOpenSelectId(
                                  openSelectId === payment.paymentCalendarId
                                    ? null
                                    : payment.paymentCalendarId,
                                )
                              }>
                              {payment.paymentStatus === 'PAID'
                                ? 'Оплачено'
                                : payment.paymentStatus === 'UNPAID'
                                  ? 'Не оплачено'
                                  : 'Ожидание'}
                              <span>▾</span>
                            </CustomSelect>
                            {openSelectId === payment.paymentCalendarId && (
                              <CustomOptions>
                                <CustomOption
                                  className='paid'
                                  onClick={() =>
                                    handleStatusChange(payment.paymentCalendarId, 'PAID')
                                  }>
                                  Оплачено
                                </CustomOption>
                                <CustomOption
                                  className='awaiting'
                                  onClick={() =>
                                    handleStatusChange(
                                      payment.paymentCalendarId,
                                      'AWAITING_PAYMENT',
                                    )
                                  }>
                                  Ожидание
                                </CustomOption>
                                <CustomOption
                                  className='unpaid'
                                  onClick={() =>
                                    handleStatusChange(payment.paymentCalendarId, 'UNPAID')
                                  }>
                                  Не оплачено
                                </CustomOption>
                              </CustomOptions>
                            )}
                          </CustomSelectWrapper>
                        </TableCell>
                      </TableRow>
                    ))}
                  </tbody>
                </Table>
              </ScrollableTableContainer>

              <Pagination
                pageCount={paginationInfo.totalPages}
                currentPage={paginationInfo.currentPage}
                onPageChange={handlePageChange}
              />
            </>
          )}
        </>
      )}

      {/* --------------------------------------------- */}
      {/*    Вкладка "Календарь платежей"              */}
      {/* --------------------------------------------- */}
      {activeTab === 'calendar' && (
        <CalendarWrapper>
          {/* Левая часть: сам календарь */}
          <CalendarBox>
            <Calendar
              locale='ru'
              value={calendarDate}
              onChange={(date) => {
                if (date instanceof Date) {
                  handleCalendarChange(date);
                }
              }}
              onActiveStartDateChange={({ activeStartDate, view }) => {
                // при листании месяца/года
                if (activeStartDate && view === 'month') {
                  setCalendarDate(activeStartDate);
                }
              }}
              tileClassName={tileClassName}
            />
          </CalendarBox>

          {/* Правая часть: таблица платежей за выбранный день */}
          {isLoading ? (
            <div style={{ width: '100%' }}>
              <LoadingGif />
            </div>
          ) : (
            <TableBox>
              <h2>Платежи за {calendarDate.toLocaleDateString('ru-RU')}</h2>

              {/* Кнопки "Сгенерировать счет .pdf" и "Выгрузка в формате 1С" */}
              <ButtonBoxDowland>
                <NewButton
                  text={isLoadingPdf ? 'Загрузка...' : 'Сгенерировать счет .pdf'}
                  loading={isLoadingPdf}
                  onClick={handleGenerateInvoice}
                  disabled={selectedPayments.size === 0 || isLoadingOneC}
                />
                <NewButton
                  text={isLoadingOneC ? 'Загрузка...' : 'Выгрузка в формате 1С'}
                  loading={isLoadingOneC}
                  onClick={handleGenerateOneCInvoice}
                  disabled={selectedPayments.size === 0 || isLoadingPdf}
                />
              </ButtonBoxDowland>
              <ScrollableTableContainer>
                <Table>
                  <thead>
                    <TableRowHeader>
                      <TableHeader width='40px'>
                        <Checkbox
                          checked={false}
                          onChange={() => {
                            // Если нужно "выбрать все", здесь логика
                          }}
                        />
                      </TableHeader>
                      <TableHeader>
                        <Hint displayText='Контракт' text='Уникальный номер контракта' />
                      </TableHeader>
                      <TableHeader>
                        <Hint displayText='Контрагент' text='Лицо, с которым заключен договор' />
                      </TableHeader>
                      <TableHeader width='60px'>
                        <Hint displayText='Тип' text='Тип финансовой операции' />
                      </TableHeader>
                      <TableHeader className='right'>
                        <Hint displayText='Приход' text='Сумма поступления средств' />
                      </TableHeader>
                      <TableHeader className='right'>
                        <Hint displayText='Расход' text='Сумма списания средств' />
                      </TableHeader>
                      <TableHeader className='right'>
                        <Hint displayText='Сальдо' text='Текущее сальдо счета после операции' />
                      </TableHeader>
                      <TableHeader>
                        <Hint displayText='Статус' text='Текущий статус платежной операции' />
                      </TableHeader>
                    </TableRowHeader>
                  </thead>
                  <tbody>
                    {dayPayments.map((payment) => (
                      <TableRow key={payment.paymentCalendarId}>
                        <TableCell>
                          <Checkbox
                            checked={selectedPayments.has(payment.paymentCalendarId)}
                            onChange={() => handlePaymentSelection(payment.paymentCalendarId)}
                          />
                        </TableCell>
                        <TableCell>
                          <StyledLink to={`/contracts/${payment.contractId}`}>
                            {payment.contractNum}
                          </StyledLink>
                        </TableCell>
                        <TableCell>
                          <Hint
                            text={
                              userDataLight?.organizationRole === 'INVESTOR'
                                ? (payment.businessName ?? '')
                                : (payment.investorName ?? '')
                            }
                            truncatedLength={18}
                          />
                        </TableCell>
                        <TableCell>{payment.paymentTypeName}</TableCell>
                        <TableCell className='right'>
                          {payment.debit.toLocaleString('ru-KZ', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{' '}
                          ₸
                        </TableCell>
                        <TableCell className='right'>
                          {payment.credit.toLocaleString('ru-KZ', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{' '}
                          ₸
                        </TableCell>
                        <TableCell
                          className='right'
                          style={{ color: payment.balance > 0 ? '#5784F7' : '#fa4d56' }}>
                          {payment.balance > 0 ? '+' : ''}
                          {payment.balance.toLocaleString('ru-KZ', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{' '}
                          ₸
                        </TableCell>
                        <TableCell>
                          <CustomSelectWrapper>
                            <CustomSelect
                              className={`${getStatusClassName(payment.paymentStatus)} ${
                                openSelectId === payment.paymentCalendarId ? 'open' : ''
                              }`}
                              onClick={() =>
                                setOpenSelectId(
                                  openSelectId === payment.paymentCalendarId
                                    ? null
                                    : payment.paymentCalendarId,
                                )
                              }>
                              {payment.paymentStatus === 'PAID'
                                ? 'Оплачено'
                                : payment.paymentStatus === 'UNPAID'
                                  ? 'Не оплачено'
                                  : 'Ожидание'}
                              <span>▾</span>
                            </CustomSelect>
                            {openSelectId === payment.paymentCalendarId && (
                              <CustomOptions>
                                <CustomOption
                                  className='paid'
                                  onClick={() =>
                                    handleStatusChange(payment.paymentCalendarId, 'PAID')
                                  }>
                                  Оплачено
                                </CustomOption>
                                <CustomOption
                                  className='awaiting'
                                  onClick={() =>
                                    handleStatusChange(
                                      payment.paymentCalendarId,
                                      'AWAITING_PAYMENT',
                                    )
                                  }>
                                  Ожидание
                                </CustomOption>
                                <CustomOption
                                  className='unpaid'
                                  onClick={() =>
                                    handleStatusChange(payment.paymentCalendarId, 'UNPAID')
                                  }>
                                  Не оплачено
                                </CustomOption>
                              </CustomOptions>
                            )}
                          </CustomSelectWrapper>
                        </TableCell>
                      </TableRow>
                    ))}
                    {!dayPayments.length && (
                      <TableRow>
                        <TableCell colSpan={9}>Нет платежей за выбранный день</TableCell>
                      </TableRow>
                    )}
                  </tbody>
                </Table>
              </ScrollableTableContainer>
            </TableBox>
          )}
        </CalendarWrapper>
      )}
    </Container>
  );
};
