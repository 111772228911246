import styled, { css } from 'styled-components';
import colors from 'global_styling/colors';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { ReactComponent as SpisokIcon } from 'assets/img/SpisokIcon.svg';
import { ReactComponent as TableIcon } from 'assets/img/TableIcon.svg';

type StatusCircleProps = {
  $status:
    | 'На рассмотрении у инвесторов'
    | 'Откланена'
    | 'На рассмотрение'
    | 'Просрочена'
    | 'Подписан'
    | 'На подписании'
    | 'Отменен заёмщиком'
    | 'Активный'
    | string;
};

interface TableHeaderProps {
  isSorted?: boolean;
  direction?: 'asc' | 'desc' | null;
  sortable?: boolean;
  width?: string;
}

const Container = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  background: #f4f4f4;
`;

const BoxHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 16px;
  gap: 40px;
  justify-content: space-between;
  align-items: center;
`;

const ControlBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const FilterBox = styled.div<{ isActive: boolean }>`
  display: flex;
  width: 46px;
  height: 44px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ isActive }) => (isActive ? '#BCBCBC' : 'var(--primary-300, #5784F7)')};
  border-radius: 8px;
  margin-left: 2px;
  cursor: pointer;
  &:hover {
    background: ${({ isActive }) => (isActive ? '#BCBCBC' : 'var(--primary-400, #0f62fe)')};
  }
`;

export const ActionFilterBox = styled.div<{ isOpen: boolean }>`
  height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  overflow: hidden;
  transition:
    height 0.4s ease-in-out,
    opacity 0.4s ease-in-out;
  background: #fff;
  padding: ${({ isOpen }) => (isOpen ? '36px' : '0 36px')};
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px 0px rgba(7, 17, 43, 0.07);
`;

const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  max-height: 46px;
`;

const Title = styled.h1`
  color: #292929;
  font-size: 28px;
  font-weight: 600;
  line-height: 106%;
  font-family: 'IBM Plex Sans', sans-serif;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0px 22.78125px 48.825px 0px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  margin-bottom: 30px;
  background: var(--Neutral-2, #fafafa);
`;

export const TableHeader = styled.th<TableHeaderProps>`
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  padding: 12px;
  padding-right: 16px;
  text-align: left;
  background: var(--Neutral-2, #fafafa);
  cursor: ${({ sortable }) => (sortable ? 'pointer' : 'default')};
  position: relative;
  white-space: nowrap; /* Prevent text wrapping */
  text-overflow: ellipsis; /* Add ellipsis for overflowing text */

  width: ${({ width }) => width || 'auto'};
  min-width: ${({ width }) => width || 'auto'};

  &:hover {
    background-color: ${({ sortable }) => (sortable ? '#f2f2f2' : 'transparent')};
  }

  /* Reserve space for the arrow */
  &::after {
    content: '';
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    background-size: cover;

    /* Display the correct icon depending on sort direction */
    background-image: ${({ direction }) =>
      direction === 'asc'
        ? 'url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2716%27 height=%2716%27 viewBox=%270 0 16 16%27 fill=%27none%27%3E%3Cpath d=%27M8 5L12 11H4L8 5Z%27 fill=%27%23A0A0A0%27/%3E%3C/svg%3E")'
        : direction === 'desc'
          ? 'url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2716%27 height=%2716%27 viewBox=%270 0 16 16%27 fill=%27none%27%3E%3Cpath d=%27M8 11L12 5H4L8 11Z%27 fill=%27%23A0A0A0%27/%3E%3C/svg%3E")'
          : ''}; /* Use no icon if no sort direction */
    display: ${({ isSorted, sortable }) => (isSorted && sortable ? 'block' : 'none')};
  }

  /* Show the neutral sort icon if sorting is available but not active */
  &::before {
    content: '';
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    background-size: cover;
    background-image: url('data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2716%27 height=%2716%27 viewBox=%270 0 16 16%27 fill=%27none%27%3E%3Crect x=%275%27 y=%277%27 width=%276%27 height=%272%27 fill=%27%23D8D8D8%27/%3E%3C/svg%3E');
    display: ${({ isSorted, sortable }) => (!isSorted && sortable ? 'block' : 'none')};
  }
`;
const TableRowHeader = styled.tr`
  border: none;
  .fixStatus {
    min-width: 140px;
  }
  .right {
    text-align: right;
  }
`;

const TableRow = styled.tr<{ $isSelected?: boolean }>`
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  background: ${(props) => (props.$isSelected ? '#EDFEFF' : 'transparent')};

  cursor: pointer;

  &:hover {
    background-color: #f2f2f2;
  }

  .right {
    text-align: right;
  }

  .paymets {
    overflow: visible;
  }
`;

const TableCell = styled.td`
  padding: 12px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  a {
    color: var(--Primary, #5784f7);
    text-decoration: none;
    font-weight: 500;
  }
  div {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  button {
    width: 100px;
  }

  .fixStatus {
    min-width: 120px;
  }

  white-space: nowrap;
`;

export const ScrollableTableContainer = styled.div`
  width: 100%;
  overflow-x: scroll;
  overflow: scroll;

  /* Заставляем таблицу быть «больше» экрана,
+     чтобы на узких экранах появилась горизонтальная прокрутка */
  table {
    min-width: 800px;
    overflow-x: scroll;
    overflow: scroll;
    /* Можно 1000px, 1400px — подберите комфортную ширину */
  }
`;

const statusesAccept = ['Принято предложение инвестора', 'Подписан', 'Принят', 'Договор подписан'];
const statusesReject = [
  'Откланена',
  'Отменен заёмщиком',
  'Отменён инвестором',
  'Договор отклонен заемщиком',
  'Договор отклонен инвестором',
];
const statusesWait = [
  'На рассмотрение',
  'На рассмотрении у инвесторов',
  'Активный',
  'На подписании',
];

const StatusCircle = styled.span<StatusCircleProps>`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;

  background-color: ${({ $status }) => {
    if (statusesAccept.includes($status)) {
      return '#89E141';
    }
    if (statusesReject.includes($status)) {
      return '#FF3838';
    }
    if (statusesWait.includes($status)) {
      return '#FFD54E';
    }
    switch ($status) {
      case 'Просрочена':
        return ' #FF9C41';
      default:
        return 'gray';
    }
  }};
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ActionBox = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  gap: 10px;
  min-width: 200px;
  background: var(--White, #fff);
  top: 90%;
  right: 50px;
  z-index: 999;
  border-radius: 8px;
  background: var(--White, #fff);
  box-shadow: 0px 8px 16px 0px rgba(30, 34, 72, 0.16);
  li {
    color: var(--black-500-ink, #1e2248);
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    padding: 8px 16px;
    width: 100%;
    &:hover {
      background: var(--Primary-Highlight, #def5f6);
    }
  }
`;

const StyledLink = styled(Link)`
  color: #5784f7;
  text-decoration: none;
`;

interface InputProps {
  $hasError?: boolean;
  $hasContent?: boolean;
  $readOnly?: boolean;
}

export const Input = styled.input<InputProps>`
  width: 100%;
  padding: 0px 10px;
  border-radius: 0px 2px 2px 0px;
  border: none;
  border-bottom: 1px solid var(--neutral-300, #bcbcbc);
  color: var(--Control, #9aa4b0);
  font-size: 14px;
  line-height: 125%;
  font-weight: 400;
  align-items: center;
  justify-content: center;
  display: flex;
  &.max {
    height: 164px;
    vertical-align: top; /* Добавлено для выравнивания текста сверху */
    text-align: start; /* Выравнивание текста слева */
  }

  &.maxApplication {
    height: 164px;
    vertical-align: top;
    font-family: 'IBM Plex Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.16px;
    white-space: pre-wrap; /* Ensures that whitespace and line breaks are preserved */
  }

  /* Chrome, Safari, Edge, Opera Убираем стрелочки больше и меньше */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  & {
    -moz-appearance: textfield;
  }

  ${({ $hasContent }) =>
    $hasContent &&
    css`
      color: var(--neutral-800, #303030);
      border-bottom: 1px solid var(--neutral-300, #bcbcbc);
    `}

  ${({ $readOnly }) =>
    $readOnly &&
    css`
      color: var(--neutral-600, #686868);
      border-bottom: 1px solid var(--neutral-300, #bcbcbc);
    `}

  &:focus {
    outline: none;
    border-bottom: 1px solid var(--neutral-300, #5784f7);
    color: #292d32;
  }

  ${({ $hasError }) =>
    $hasError &&
    css`
      border: 1px solid ${colors.WARNING};
    `}
`;

export const BoxInputHeader = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 348px;
  gap: 8px;
`;

export const BoxInputDescription = styled.div`
  display: flex;
  gap: 24px;
  align-self: stretch;
`;

export const BoxInfoInput = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  span {
    color: var(--neutral-600, #686868);
    font-size: 14px;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
  }
`;

export const DatePickerWrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: 10; // добавьте это свойство
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  padding: 8px;
  border-radius: 0px 2px 2px 0px;
  border: none;
  background: var(--neutral-100, #f4f4f4);
  color: var(--neutral-400, #a0a0a0);
  border: 1px solid var(--Control, #f4f4f4);
  font-size: 14px;
  line-height: 129%;
  font-weight: 400;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 4px;

  &::placeholder {
    color: var(--Control, #9aa4b0);
  }

  &:focus {
    outline: none;
    border: 1px solid var(--primary-300, #5784f7);
    color: #292d32;
  }

  ${({ $hasContent }) =>
    $hasContent &&
    css`
      color: var(--neutral-800, #303030);
      border: 1px solid var(--neutral-800, #f4f4f4);
    `}

  ${({ $hasError }) =>
    $hasError &&
    css`
      border-bottom: 1px solid ${colors.WARNING};
    `}
`;

export const CalendarIcon = styled.img`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
`;

export const BoxDropDown = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`;
export const ButtonsBox = styled.div`
  display: flex;
  gap: 10px;
  margin-left: auto;
`;

export const BoxInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  p {
    color: var(--neutral-800, #303030);
    font-size: 16px;
    font-weight: 500;
    line-height: 120%; /* 19.2px */
  }
`;

export const WhiteLabelBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const ViewToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 16px 0 0 0;
  background-color: #fff;
  max-width: 180px;
  border-radius: 4px;
  padding: 4px;
`;

// Стили для «Табличного» и «Карточного» тумблера
export const ToggleIconButton = styled.button<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  gap: 4px;
  transition: background-color 0.2s ease;
  /* Cостояние по умолчанию */
  background-color: #fff;
  color: #5784f7;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px; /* 125% */

  /* Меняем стили, если кнопка «активна» (выбранная) */
  ${({ active }) =>
    active &&
    css`
      background-color: #5784f7; /* пример «активного» цвета фона */
      color: #fff;
    `}

  /* Состояние hover (при наведении мыши) */
  &:hover {
    background: rgba(87, 132, 247, 0.15);
    color: #0f62fe;

    &:hover path {
      stroke: #377dff;
    }

    ${({ active }) =>
      active &&
      css`
        background: rgba(87, 132, 247, 0.15);
      `}
  }
`;

export const IconBase = (Component: React.ComponentType<{ className?: string }>) => styled(
  Component,
)<{
  active?: boolean;
}>`
  width: 20px;
  height: 20px;
  cursor: pointer;

  path {
    stroke: ${(props) => (props.active ? '#fff' : '#377DFF')};
    transition: stroke 0.3s ease;
  }

  &:hover path {
    stroke: #377dff;
  }
`;

export const ToggleNotTable = IconBase(SpisokIcon);
export const ToggleTable = IconBase(TableIcon);

// Сетка для карточек (card view)
export const CardListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Всегда 4 колонки */
  grid-gap: 16px;
  margin-bottom: 16px;
  margin-top: 16px;
`;

// Каждая карточка
export const CardItem = styled.div`
  position: relative;
  border-radius: 6px;
  background: #fff;
  padding: 16px;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 12px;
  &:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
`;

export const MainInfoItem = styled.div<{ isEditing?: boolean }>`
  display: flex;
  flex-direction: column;
  color: var(--neutral-800, #303030);
  font-size: 20px;
  font-weight: 600;
  border-radius: 4px;
  width: 100%;

  span {
    color: #292d32;
    font-size: 12px;
    font-weight: 600;
  }
  h4 {
    color: #5784f7;
    font-size: 24px;
    font-weight: 600;
  }
`;

export const TitleCardList = styled.p`
  color: #808185;

  font-size: 10px;
  font-weight: 400;
`;

export const BoxCardList = styled.div`
  display: flex;
  gap: 12px;
`;

export const CardListStatus = styled.p<StatusCircleProps>`
  font-size: 12px;
  font-weight: 600;
  color: ${({ $status }) => {
    if (statusesAccept.includes($status)) {
      return '#89E141';
    }
    if (statusesReject.includes($status)) {
      return '#FF3838';
    }
    if (statusesWait.includes($status)) {
      return '#F1C21B';
    }
    switch ($status) {
      case 'Просрочена':
        return ' #FF9C41';
      default:
        return 'gray';
    }
  }};
`;

export const WhiteLabelList = styled.div`
  display: flex;
  gap: 10px;
`;

export {
  Container,
  Title,
  Table,
  TableRowHeader,
  TableRow,
  TableCell,
  BoxHeader,
  ControlBox,
  ButtonBox,
  StatusCircle,
  IconWrapper,
  ActionBox,
  StyledLink,
};
