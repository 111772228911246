import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './screens/app/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter
    future={{
      v7_startTransition: true,
      v7_relativeSplatPath: true,
    }}>
    <App />
  </BrowserRouter>,
);

reportWebVitals();
