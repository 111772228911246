import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
`;

const TitleBox = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
  }
`;

const ContentBox = styled.div`
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  border-radius: 4px;
  background: #f6f8fa;
`;

export const ErrorBox = styled.div`
  color: red;
`;

export const MainInfoContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr; /* Две равные колонки */
  gap: 12px; /* Расстояние между колонками */
  grid-auto-flow: dense; /* Заполнение пустых мест */
`;

export const MainInfo = styled.div`
  display: flex;
  padding: 8px 12px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  flex: 1 0 0;
  border-radius: 4px;
  background: #f4f4f4;
  color: var(--neutral-900, #141414);
  font-size: 16px;
  font-weight: 600;
  line-height: 125%; /* 20px */

  &:nth-child(odd):last-child {
    grid-column: span 2; /* Растянуть на две колонки, если элемент один в строке */
  }

  span {
    color: var(--neutral-800, #303030);
    font-size: 12px;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
  }
`;

export const MainInfoItem = styled.div<{ isEditing?: boolean }>`
  display: flex;
  flex-direction: column;
  color: var(--neutral-800, #303030);
  font-family: 'IBM Plex Sans';
  font-size: 20px;
  font-weight: 600;
  line-height: 125%; /* 25px */
  border-radius: 4px;
  padding: 12px;
  width: 100%;
  border-left: 4px solid var(--Support-support-info-inverse, #4589ff);
  background: #f4f4f4;
  &:nth-child(odd):last-child {
    grid-column: span 2; /* Растянуть на две колонки, если элемент один в строке */
  }
  span {
    color: var(--neutral-800, #303030);
    font-size: 12px;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

interface TableCellProps {
  right?: boolean;
}

export const TableHeader = styled.thead`
  background: #f4f4f4;
`;

export const TableRow = styled.tr<{ $even?: boolean }>`
  background: ${(props) => (props.$even ? '#FEFEFE' : '#F4F4F4')};
`;

export const TableCell = styled.td<TableCellProps>`
  padding: 12px 12px;
  text-align: ${({ right }) => (right ? 'right' : 'left')};
  color: #0f1419;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px; /* 125% */
`;

export const TableCellHeader = styled.td<TableCellProps>`
  padding: 12px 12px;
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  text-align: ${({ right }) => (right ? 'right' : 'left')};
  font-size: 14px;
  font-weight: 500;
  line-height: 14px; /* 107.143% */
  padding: 12px 12px;
`;

export const Header = styled.td`
  color: var(--neutral-900, #141414);
  font-size: 16px;
  font-weight: 600;
  line-height: 125%; /* 20px */
`;

export const FormatValueBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  p {
    color: var(--neutral-900, #141414);
    font-size: 16px;
    font-weight: 600;
    line-height: 125%; /* 20px */
  }
  img {
    width: 24px;
    height: 24px;
  }
`;

export { TitleBox, ContentBox };
