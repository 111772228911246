import styled from 'styled-components';

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
`;

export const BlackListPlatformBox = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--neutral-50, #fefefe);
  button {
    margin-right: auto; /* прижать кнопку влево */
  }
`;

export const HeaderBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  p {
    color: var(--character-title-85, rgba(0, 0, 0, 0.85));
    font-size: 14px;
    font-weight: 500;
    line-height: 160%; /* 22.4px */
  }
`;

export const IconHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  h4 {
    color: #0d0d0d;

    font-size: 24px;
    font-weight: 600;
    line-height: 106%; /* 25.44px */
  }
`;
export const HideOrgBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 260px;
  margin-right: auto;
  p {
    color: var(--neutral-800, #303030);
    font-size: 16px;
    font-weight: 500;
    line-height: 120%; /* 19.2px */
  }
`;
