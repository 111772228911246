import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Modal,
  ModalContent,
  CloseButton,
  Title,
  HeaderBox,
  BoxInput,
  MoreInfoBox,
  ButtonBox,
  BoxSubmitted,
  BoxCheck,
  InputContainer,
  AddAccountItem,
  DropdownContainer,
  DropdownHeader,
  DropdownListContainer,
  DropdownListItem,
  ExpectedIncome,
  ExpectedIncomeBox,
  BlockInput,
} from './ApproveModal.style';
import { NewButton } from 'components/common/Button';
import { getMyAccounts } from 'components/api/bankAccount.api';
import { useAppContext } from 'contexts/AppContext';
import { ErrorModal } from 'components/common/ErrorModal';
import ExpectedIncomeIcon from 'assets/img/ExpectedIncomeIcon.svg';
import IconClose from 'assets/img/IconClose.svg';
import { RadioButton } from 'components/common/RadioButton';
import { InputNew } from 'components/common/Input/InputNew/InputNew';
import { DropdownArrow } from 'components/NewCreateApplication/TwoStep.style';
import { KBE } from 'components/NewCreateApplication/NewCreateApplication.interface';
import AproveGIF from 'assets/img/AproveGIF.gif';

interface CreateApplicationProps {
  closeModal: () => void;
  amount?: string | number;
  period?: string | number;
  rate?: string | number;
}

interface Account {
  iban: string;
  id: number;
}

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/`;

export const ApproveModal: React.FC<CreateApplicationProps> = ({
  closeModal,
  amount,
  period,
  rate,
}) => {
  const { id } = useParams();
  const initialLoanAmount = amount ? amount.toString() : '';
  const initialFinancingTerm = period ? period.toString() : '';
  const initialRemunerationRate = rate ? rate.toString() : '';
  const { userDataLight } = useAppContext();

  // Функция для форматирования числа пробелами между тысячами
  const formatNumber = (num: string | number): string => {
    // Приводим к строке, затем ставим пробелы
    return (
      num
        .toString()
        // Убедимся, что отделяем целую часть от дробной (если она есть)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    );
  };

  // Состояния по сумме, срокам, ставкам и т.д.
  const [loanAmount, setLoanAmount] = useState(initialLoanAmount);
  const [financingTerm, setFinancingTerm] = useState(initialFinancingTerm);
  const [startFinancing, setStartFinancing] = useState('');
  const [remunerationRate, setRemunerationRate] = useState(initialRemunerationRate);
  const [fine, setFine] = useState('');
  const [additionalConditions, setAdditionalConditions] = useState('test');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
  const [formattedLoanAmount, setFormattedLoanAmount] = useState(formatNumber(initialLoanAmount));
  const [isLoading, setIsLoading] = useState(false);
  const [isIndividual, setIsIndividual] = useState(false);
  const [isTypeContract, setIsTypeContract] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [isAddingNewAccount, setIsAddingNewAccount] = useState(false);
  const [isKbeDropdownOpen, setIsKbeDropdownOpen] = useState(false);
  const [selectedKbe, setSelectedKbe] = useState<KBE | null>(null);
  const [kbeOptions, setKbeOptions] = useState<KBE[]>([]);
  const [accountNumber, setAccountNumber] = useState('');
  const [isAccountDropdownOpen, setIsAccountDropdownOpen] = useState(false);
  const kbeDropdownRef = useRef<HTMLDivElement>(null);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);
  const accountDropdownRef = useRef<HTMLDivElement>(null);
  const [selectAccountNum, setSelectAccountNum] = useState('');

  // Новое состояние для расчётного «Ожидаемого дохода»
  const [expectedIncome, setExpectedIncome] = useState('0.00');

  const isUserRole = userDataLight?.roles.includes('MANAGER');

  const offerData = {
    request: id,
    offerSum: loanAmount,
    offerPeriod: financingTerm,
    offerTransfer: startFinancing,
    offerRate: remunerationRate,
    offerPenaltyRate: fine,
    offerExtra: additionalConditions,
    account: selectedAccount ? selectedAccount.id : null,
    isInvestorIndividual: isIndividual,
    isGeneralBusinessAgreement: isTypeContract,
  };

  const sendOffer = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}v1/cabinet/investor/request/offer`, offerData, {
        withCredentials: true,
      });
      if (response.status === 200) {
        setIsSubmitted(true);
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        setErrorMessage(error.response.data.message);
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Запрос списка KBE, если пользователь добавляет новый счёт
  useEffect(() => {
    if (isAddingNewAccount) {
      axios
        .get(`${BASE_URL}v1/dictionary/get?name=kbe`, {
          withCredentials: true,
        })
        .then((response) => setKbeOptions(response.data))
        .catch((error) => {
          console.error('Error fetching KBE options:', error);
          setErrorMessage('Error fetching KBE options');
        });
    }
  }, [isAddingNewAccount]);

  // Управляем кнопкой «Добавить» счёт
  useEffect(() => {
    setIsAddButtonDisabled(!(selectedKbe && accountNumber.length === 20));
  }, [selectedKbe, accountNumber]);

  // При загрузке получаем список счетов
  useEffect(() => {
    getMyAccounts()
      .then((response) => {
        setAccounts(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  // Расчёт «Ожидаемого дохода» при каждом изменении необходимых полей
  useEffect(() => {
    const numericLoanAmount = parseFloat(loanAmount.replace(/\s/g, '')); // убираем пробелы из суммы
    const numericFinancingTerm = parseFloat(financingTerm);
    const numericRate = parseFloat(remunerationRate);

    if (
      isNaN(numericLoanAmount) ||
      isNaN(numericFinancingTerm) ||
      isNaN(numericRate) ||
      numericLoanAmount <= 0 ||
      numericFinancingTerm <= 0 ||
      numericRate <= 0
    ) {
      setExpectedIncome('0.00');
      return;
    }

    // Формула:
    // (financingTerm (дней) / 30) * (remunerationRate / 100) * loanAmount
    const calculatedIncome = (numericFinancingTerm / 30) * (numericRate / 100) * numericLoanAmount;

    // Округлим до двух знаков и отформатируем
    const formattedIncome = formatNumber(calculatedIncome.toFixed(2));
    setExpectedIncome(formattedIncome);
  }, [loanAmount, financingTerm, remunerationRate]);

  const handleCheckboxChange = (isIndividualCheckbox: boolean) => {
    setIsIndividual(isIndividualCheckbox);
  };

  const handleCheckboxContract = (isIndividualCheckboxContract: boolean) => {
    setIsTypeContract(isIndividualCheckboxContract);
  };

  // Поле «Сумма моего предложения»
  const handleLoanAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numericValue = e.target.value.replace(/\D/g, ''); // оставляем только цифры
    setLoanAmount(numericValue);
    setFormattedLoanAmount(formatNumber(numericValue));
  };

  // Срок финансирования
  const handleFinancingTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFinancingTerm(e.target.value);
  };

  // Начало финансирования
  const handleStartFinancingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartFinancing(e.target.value);
  };

  // Ставка вознаграждения
  const handleRemunerationRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regex = /^(\d+)?(\.\d{0,2})?$/;

    if (regex.test(value) || value === '') {
      setRemunerationRate(value);
    }
  };

  // Пеня
  const handleFineChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regex = /^(\d+)?(\.\d{0,2})?$/;

    if (regex.test(value) || value === '') {
      setFine(value);
    }
  };

  // Выбор счёта из выпадающего списка
  const handleAccountSelect = (account: Account) => {
    setSelectedAccount(account);
    setIsAccountDropdownOpen(false);
    setSelectAccountNum(account.id.toString());
  };

  // Открытие/закрытие списка со счетами
  const toggleAccountDropdown = () => {
    setIsAccountDropdownOpen(!isAccountDropdownOpen);
    if (!isAccountDropdownOpen) {
      setIsKbeDropdownOpen(false);
    }
  };

  // Обработчик добавления нового счёта
  const handleAddNewAccount = () => {
    setIsAddingNewAccount(true);
    setIsAccountDropdownOpen(false);
  };

  // Открытие/закрытие списка с KBE
  const toggleKbeDropdown = () => {
    setIsKbeDropdownOpen(!isKbeDropdownOpen);
    if (!isKbeDropdownOpen) {
      setIsAccountDropdownOpen(false);
    }
  };

  // Выбор KBE
  const handleKbeSelect = (kbe: KBE) => {
    setSelectedKbe(kbe);
    setIsKbeDropdownOpen(false);
  };

  // Добавление нового счёта (реальный вызов API)
  const addAccount = async (kbe: number | null, iban: string) => {
    try {
      const response = await axios.post(
        `${BASE_URL}v1/ui/accounts/add`,
        { kbe, iban },
        { withCredentials: true },
      );
      return response.data;
    } catch (error) {
      console.error('Error adding account:', error);
      setErrorMessage('Error adding account');
      return null;
    }
  };

  // Кнопка «Добавить» счёт
  const handleAddAccount = async () => {
    if (selectedKbe && accountNumber) {
      const newAccount = await addAccount(selectedKbe.id, accountNumber);
      if (newAccount) {
        setAccounts((prevAccounts) => [...prevAccounts, newAccount]);
        setSelectedAccount(newAccount);
        setSelectAccountNum(newAccount.id.toString());
        setIsAddingNewAccount(false);
      }
    }
  };

  // Проверка на заполненность формы
  const isFormValid = () => {
    return (
      loanAmount && financingTerm && startFinancing && remunerationRate && fine && selectedAccount
    );
  };

  return (
    <>
      {errorMessage && (
        <ErrorModal
          headerError='Ошибка'
          textError={errorMessage}
          buttonClose='Закрыть'
          onClick={() => setErrorMessage(null)}
        />
      )}
      {!errorMessage && (
        <Modal>
          <ModalContent>
            <HeaderBox>
              {isSubmitted ? <Title /> : <Title>Предложение</Title>}
              <CloseButton src={IconClose} alt='iconClose' onClick={closeModal} />
            </HeaderBox>

            {isSubmitted ? (
              <BoxSubmitted>
                <img src={AproveGIF} alt='IconDone' />

                <p>Ваше уникальное предложение отправлено!</p>
                <div>
                  <NewButton
                    text='Закрыть'
                    onClick={() => {
                      closeModal();
                      window.location.reload();
                    }}
                  />
                </div>
              </BoxSubmitted>
            ) : (
              <>
                <InputContainer>
                  <InputNew
                    title='Сумма моего предложения, тенге'
                    placeholder=''
                    type='text'
                    value={formatNumber(loanAmount)}
                    onChange={handleLoanAmountChange}
                  />
                  <BoxInput>
                    <InputNew
                      title='Срок финансирования (в днях)'
                      placeholder=''
                      type='number'
                      value={financingTerm}
                      onChange={handleFinancingTermChange}
                    />
                    <InputNew
                      title='Начало финансирования (в днях)'
                      placeholder=''
                      type='number'
                      value={startFinancing}
                      onChange={handleStartFinancingChange}
                    />
                  </BoxInput>
                  <BoxInput>
                    <InputNew
                      title='Ставка вознаграждения в месяц (%)'
                      placeholder=''
                      type='text'
                      value={remunerationRate}
                      onChange={handleRemunerationRateChange}
                    />
                    <InputNew
                      title='Пеня за день (%)'
                      placeholder=''
                      type='text'
                      value={fine}
                      onChange={handleFineChange}
                    />
                  </BoxInput>
                </InputContainer>

                {/* Блок с расчётом «Ожидаемого дохода» */}
                <ExpectedIncome>
                  <ExpectedIncomeBox>
                    <img src={ExpectedIncomeIcon} alt='ExpectedIncomeIcon' />
                    Предварительный расчёт дохода
                  </ExpectedIncomeBox>
                  {/* Выводим рассчитанный доход и добавляем символ тенге в конце */}
                  {`${expectedIncome} ₸`}
                </ExpectedIncome>

                <DropdownContainer ref={accountDropdownRef}>
                  <label>Выбрать счет</label>
                  <DropdownHeader
                    onClick={toggleAccountDropdown}
                    isOpen={isAccountDropdownOpen}
                    $hasContent={!!selectedAccount}
                    $isDisabled={accounts.length === 0}>
                    {selectedAccount ? selectedAccount.iban : 'Выбрать счет'}
                    <DropdownArrow isOpen={isAccountDropdownOpen} />
                  </DropdownHeader>
                  {isAccountDropdownOpen && (
                    <DropdownListContainer>
                      {isUserRole && (
                        <AddAccountItem onClick={handleAddNewAccount}>
                          Добавить новый счет
                        </AddAccountItem>
                      )}
                      {accounts.map((account) => (
                        <DropdownListItem
                          key={account.id}
                          onClick={() => handleAccountSelect(account)}>
                          {account.iban}
                        </DropdownListItem>
                      ))}
                    </DropdownListContainer>
                  )}
                </DropdownContainer>

                {/* Добавление нового счёта */}
                {isAddingNewAccount && (
                  <BlockInput>
                    <InputNew
                      title='Номер счета'
                      placeholder='Введите номер счета (20 чисел)'
                      value={accountNumber}
                      onChange={(e) => setAccountNumber(e.target.value)}
                    />
                    <DropdownContainer ref={kbeDropdownRef}>
                      <label>Выбрать KBE</label>
                      <DropdownHeader
                        onClick={toggleKbeDropdown}
                        isOpen={isKbeDropdownOpen}
                        $hasContent={!!selectedKbe}
                        $isDisabled={kbeOptions.length === 0}>
                        {selectedKbe ? selectedKbe.name : 'Выбрать KBE'}
                        <DropdownArrow isOpen={isKbeDropdownOpen} />
                      </DropdownHeader>
                      {isKbeDropdownOpen && (
                        <DropdownListContainer>
                          {kbeOptions.map((kbe) => (
                            <DropdownListItem key={kbe.id} onClick={() => handleKbeSelect(kbe)}>
                              {kbe.name}
                            </DropdownListItem>
                          ))}
                        </DropdownListContainer>
                      )}
                    </DropdownContainer>
                    <NewButton
                      text='Добавить'
                      onClick={handleAddAccount}
                      disabled={isAddButtonDisabled}
                      className='mini'
                    />
                  </BlockInput>
                )}

                <BoxInput>
                  {userDataLight.organizationType !== 'LLC' && (
                    <MoreInfoBox>
                      <p>Тип инвестора</p>
                      <BoxCheck checked={isIndividual}>
                        <label onClick={() => handleCheckboxChange(true)}>
                          <RadioButton
                            checked={isIndividual}
                            onChange={() => handleCheckboxChange(true)}
                          />
                          <span>Физическое лицо</span>
                        </label>
                      </BoxCheck>
                      <BoxCheck checked={!isIndividual}>
                        <label onClick={() => handleCheckboxChange(false)}>
                          <RadioButton
                            checked={!isIndividual}
                            onChange={() => handleCheckboxChange(false)}
                          />
                          <span>Индивидуальный предприниматель (ИП)</span>
                        </label>
                      </BoxCheck>
                    </MoreInfoBox>
                  )}

                  <MoreInfoBox>
                    <p>Тип договора</p>
                    <BoxCheck checked={!isTypeContract}>
                      <label onClick={() => handleCheckboxContract(false)}>
                        <RadioButton
                          checked={!isTypeContract}
                          onChange={() => handleCheckboxContract(false)}
                        />
                        <span>Договор займа</span>
                      </label>
                    </BoxCheck>
                    <BoxCheck checked={isTypeContract}>
                      <label onClick={() => handleCheckboxContract(true)}>
                        <RadioButton
                          checked={isTypeContract}
                          onChange={() => handleCheckboxContract(true)}
                        />
                        <span>Договор о совместной деятельности</span>
                      </label>
                    </BoxCheck>
                  </MoreInfoBox>
                </BoxInput>
                <ButtonBox>
                  <NewButton text='Отмена' className='white' onClick={closeModal} />
                  <NewButton
                    text={isLoading ? 'Отправка...' : 'Отправить'}
                    onClick={sendOffer}
                    disabled={!isFormValid() || isLoading}
                  />
                </ButtonBox>
              </>
            )}
          </ModalContent>
        </Modal>
      )}
    </>
  );
};
