import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TableRowHeader,
} from 'components/ApplicationBusinessList/ApplicationBusinessList.style';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'; // Optional for additional styling

const SkeletonTable = () => {
  return (
    <Table>
      <thead>
        <TableRowHeader>
          <TableHeader>
            <Skeleton />
          </TableHeader>
          <TableHeader>
            <Skeleton />
          </TableHeader>
          <TableHeader>
            <Skeleton />
          </TableHeader>
          <TableHeader>
            <Skeleton />
          </TableHeader>
          <TableHeader>
            <Skeleton />
          </TableHeader>
          <TableHeader>
            <Skeleton />
          </TableHeader>
          <TableHeader>
            <Skeleton />
          </TableHeader>
          <TableHeader>
            <Skeleton />
          </TableHeader>
          <TableCell>
            <Skeleton />
          </TableCell>
        </TableRowHeader>
      </thead>
      <tbody>
        {Array.from({ length: 10 }).map((_, index) => (
          <TableRow key={index}>
            <TableCell>
              <Skeleton />
            </TableCell>
            <TableCell>
              <Skeleton />
            </TableCell>
            <TableCell>
              <Skeleton />
            </TableCell>
            <TableCell>
              <Skeleton />
            </TableCell>
            <TableCell>
              <Skeleton />
            </TableCell>
            <TableCell>
              <Skeleton />
            </TableCell>
            <TableCell>
              <Skeleton />
            </TableCell>
            <TableCell>
              <Skeleton />
            </TableCell>
            <TableCell>
              <Skeleton />
            </TableCell>
          </TableRow>
        ))}
      </tbody>
    </Table>
  );
};

export default SkeletonTable;
