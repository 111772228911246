import styled from 'styled-components';

export const gradients = [
  'linear-gradient(180deg, #EE9696 0%, #F88 100%)',
  'linear-gradient(180deg, #96EE96 0%, #8F8 100%)',
  'linear-gradient(180deg, #96C4EE 0%, #98F 100%)',
  'linear-gradient(180deg, #EE96EE 0%, #F8F 100%)',
  'linear-gradient(180deg, #96EEEE 0%, #8FF 100%)',
];

export const getRandomGradient = () => {
  return gradients[Math.floor(Math.random() * gradients.length)];
};

export const CircleAvatar = styled.div<{ gradient: string }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: ${({ gradient }) => gradient};
  display: inline-block;
  margin-right: 8px;
`;

export const StatusText = styled.span<{ color: string }>`
  color: ${({ color }) => {
    switch (color) {
      case 'blue':
        return '#007bff';
      default:
        return '#000';
    }
  }};
  font-weight: 500;
`;

export const StatusCircle = styled.span<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  display: inline-block;
  margin-right: 16px;
`;

export const OwnerText = styled.p`
  color: var(--neutral-400, #a0a0a0);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
`;
