import React, { useState, useEffect } from 'react';
import {
  CustomButton,
  Container,
  LeftBlock,
  RightBlock,
  LogoImg,
  LogoBox,
  WelcomeText,
  SubText,
  ContentLeft,
  FAQSection,
  StyledFAQItem,
  FAQTitle,
  FAQContent,
  FAQIcon,
  StyledLink,
  LoadingOverlay,
  LoadingGif,
  ButtonsBox,
  IconBox,
} from './EnterSite.style';
import { default as NewLogo } from 'assets/img/NewLogo.svg';
import { default as EnterSiteIcon } from 'assets/img/EnterSiteIcon.svg';
import { default as IphoneGif } from 'assets/img/LoadingEnter1.gif';
import { default as KeyECP } from 'assets/img/KeyECP.webp';
import { useTranslation } from 'react-i18next';
import { sendSign } from 'components/api/authorization.api';
import { ErrorModal } from 'components/common/ErrorModal';
import { ISignBody } from 'components/api/registration.interface';
import { getCertFromSign } from 'components/api/registration.api';
import ModalRegistration from './ModalRegistration';
import axios from 'axios';
import { signAuthXML } from 'components/TestStand/ncaLayerWebSocket';
import { NewButton } from 'components/common/Button';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/`;

type EnterButtonProps = {
  children: React.ReactNode;
  onClick?: () => void;
};

type ArrowIconProps = {
  rotated: boolean;
};

type FAQItemProps = {
  index: number;
  isOpen: boolean;
  onClick: (index: number) => void;
};

const faqData = [
  {
    title: 'Где скачать NCAlayer?',
    content: (
      <>
        <div>
          <div>
            <StyledLink
              href='https://pki.gov.kz/en/ncalayer-2/'
              target='_blank'
              rel='noopener noreferrer'>
              https://pki.gov.kz/en/ncalayer-2/
            </StyledLink>
          </div>
        </div>
      </>
    ),
  },
  {
    title: 'Как использовать ЭЦП?',
    content: (
      <>
        <div>
          <p>Файл эцп на носителе выглядит примерно так:</p>
          <div>
            {' '}
            <img src={KeyECP} alt='exampleKey' />
          </div>
          <p>
            Он необходим для регистрации и подписи документов. Например подписи договоров на
            платформе
          </p>
        </div>
      </>
    ),
  },
  {
    title: 'Почему необходимо ЭЦП для регистрации ?',
    content: (
      <>
        <div>
          <p>
            ЭЦП обеспечивает прозрачность и доверие между участниками платформы, позволяя проводить
            проверку и аутентификацию пользователей. Также он гарантирует юридическую значимость
            сделок, делая их такими же законными, как и договоры на бумаге
          </p>
        </div>
      </>
    ),
  },
  {
    title: 'Как мне получить ЭЦП если у меня его нет?',
    content: (
      <>
        <div>
          <p>Получение ЭЦП удаленно</p>
          <div>
            <StyledLink
              href='https://www.gov.kz/services/4362?lang=ru'
              target='_blank'
              rel='noopener noreferrer'>
              https://www.gov.kz/services/4362?lang=ru
            </StyledLink>
          </div>
        </div>
      </>
    ),
  },
];

const KeyIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <g clipPath='url(#clip0_1747_24719)'>
      <path
        d='M4.33325 18.2295C4.33325 18.6107 4.4847 18.9764 4.75429 19.246C5.02387 19.5155 5.3895 19.667 5.77075 19.667C6.152 19.667 6.51763 19.5155 6.78722 19.246C7.0568 18.9764 7.20825 18.6107 7.20825 18.2295C7.20825 17.8482 7.0568 17.4826 6.78722 17.213C6.51763 16.9434 6.152 16.792 5.77075 16.792C5.3895 16.792 5.02387 16.9434 4.75429 17.213C4.4847 17.4826 4.33325 17.8482 4.33325 18.2295Z'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.1459 0.979492L10.7715 10.3549C9.3497 9.61914 7.71164 9.41615 6.15324 9.78254C4.59484 10.1489 3.21884 11.0606 2.2738 12.3528C1.32877 13.6449 0.876995 15.2325 1.0001 16.8287C1.1232 18.4248 1.81306 19.9243 2.94506 21.0563C4.07706 22.1883 5.57657 22.8782 7.17272 23.0013C8.76888 23.1244 10.3564 22.6726 11.6486 21.7276C12.9408 20.7825 13.8524 19.4065 14.2188 17.8481C14.5852 16.2897 14.3822 14.6517 13.6465 13.2299L16.7918 10.0837H18.2293V8.64616L18.7084 8.16699H20.1459V6.72949L20.6251 6.25033H22.0626V4.81283L23.0209 3.85449V0.979492H20.1459Z'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1747_24719'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export const ArrowIcon: React.FC<ArrowIconProps> = ({ rotated }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='25'
    viewBox='0 0 25 25'
    fill='none'
    style={{
      transform: rotated ? 'rotate(180deg)' : 'rotate(0deg)',
      transition: 'transform 0.3s ease',
    }}>
    <rect x='0.5' y='0.529785' width='24' height='24' rx='12' fill='#F0F3FF' />
    <path
      d='M8.5 10.5298L12.5 14.5298L16.5 10.5298'
      stroke='#5784F7'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const FAQItem: React.FC<FAQItemProps> = ({ index, isOpen, onClick }) => {
  const { title, content } = faqData[index];

  return (
    <StyledFAQItem>
      <FAQTitle onClick={() => onClick(index)}>
        <div>
          <IconBox>
            <img src={EnterSiteIcon} alt='iconInfo' />
          </IconBox>
          {title}
        </div>
        <FAQIcon>
          <ArrowIcon rotated={isOpen} />
        </FAQIcon>
      </FAQTitle>
      {isOpen && <FAQContent>{content}</FAQContent>}
    </StyledFAQItem>
  );
};

const EnterButton = ({ children, onClick }: EnterButtonProps) => (
  <CustomButton onClick={onClick}>
    <KeyIcon />
    {children}
  </CustomButton>
);

export const EnterSite = () => {
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorDialogText, setErrorDialogText] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState<ISignBody | null>(null);
  const [signXml, setSignXml] = useState<string>('');
  const [urlToken, setUrlToken] = useState<string | null>(null);
  const [loading, setLoading] = useState(false); // New loading state

  const { t } = useTranslation();

  const [faqOpen, setFaqOpen] = useState<number | null>(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const refToken = urlParams.get('ref');
    setUrlToken(refToken);
  }, []);

  const handleFAQClick = (index: number) => {
    setFaqOpen(faqOpen === index ? null : index);
  };

  const handleAuthX509 = async () => {
    try {
      await signAuthXML('<root></root>', async (signedData: any) => {
        setLoading(true); // Show loading indicator
        try {
          const { data } = await sendSign(signedData);
          await axios.get(`${BASE_URL}api/v1/ui`, { withCredentials: true });

          // Перенаправление на оригинальный URL после успешной аутентификации
          const urlParams = new URLSearchParams(window.location.search);
          const redirectTo = urlParams.get('redirectTo') || '/applications';
          window.location.assign(decodeURIComponent(redirectTo));
        } catch (error: any) {
          setLoading(false); // Hide loading indicator on error
          if (error.response) {
            if (error.response.status === 406) {
              setErrorDialogText(
                'Вы обновили сертификат. Пожалуйста, пройдите регистрацию повторно',
              );
            } else if (error.response.status === 401) {
              console.error(error);
              setErrorDialogText(
                'Проблема с аутентификацией. Пожалуйста, проверьте введенные данные и повторите попытку.',
              );
            } else if (error.response.status === 404) {
              setErrorDialogText('Сертификат не найден. Пожалуйста, зарегистрируйтесь в системе.');
            } else {
              setErrorDialogText('Произошла ошибка на сервере.');
            }
          } else {
            console.error('Unexpected error:', error);
            setErrorDialogText('Произошла неизвестная ошибка.');
          }
          setErrorDialogOpen(true);
        }
      });
    } catch (signError) {
      setLoading(false); // Hide loading indicator on error
      console.error('Ошибка при подписании XML:', signError);
      setErrorDialogText('Не удалось подписать XML. Пожалуйста, повторите попытку.');
      setErrorDialogOpen(true);
    }
  };

  const handleSignXML = async () => {
    try {
      await signAuthXML('<root></root>', async (signedData: any) => {
        try {
          const response = await getCertFromSign(signedData);
          setSignXml(signedData);
          setData(response.data);
          setShowModal(true);
        } catch (error: any) {
          if (error.response) {
            if (error.response.status === 400) {
              setErrorDialogText('Вы уже зарегистрированы на платформе');
            } else if (error.response.status === 403) {
              setErrorDialogText(t('loginAndRegister.errorConnect'));
            } else {
              setErrorDialogText(t('loginAndRegister.errorConnect'));
            }
          } else {
            setErrorDialogText('Неизвестная ошибка');
          }
          setErrorDialogOpen(true);
        }
      });
    } catch (signError) {
      console.error('Ошибка при регистрации XML:', signError);
      setErrorDialogText('Не удалось зарегистрировать XML. Пожалуйста, повторите попытку.');
      setErrorDialogOpen(true);
    }
  };

  return (
    <Container>
      <LeftBlock>
        <ContentLeft>
          <LogoBox>
            <LogoImg src={NewLogo} alt='MoneyLink Logo' />
          </LogoBox>
          <WelcomeText>Добро пожаловать на Lend.kz</WelcomeText>
          <SubText>Выгодное инвестирование с доходностью выше, чем по вкладам</SubText>
          <ButtonsBox>
            <NewButton text='Создать аккаунт с ЭЦП' className='white' onClick={handleSignXML} />
            <EnterButton onClick={handleAuthX509}>Войти по ЭЦП</EnterButton>
          </ButtonsBox>
        </ContentLeft>
      </LeftBlock>
      <RightBlock>
        <FAQSection>
          {faqData.map((_, index) => (
            <FAQItem
              key={index}
              index={index}
              isOpen={faqOpen === index}
              onClick={handleFAQClick}
            />
          ))}
        </FAQSection>
      </RightBlock>

      {errorDialogOpen && (
        <ErrorModal
          headerError={t('loginAndRegister.errorTitle')}
          textError={errorDialogText}
          buttonClose={t('loginAndRegister.buttonClose')}
          onClick={() => setErrorDialogOpen(false)}
        />
      )}

      {loading && (
        <LoadingOverlay>
          <LoadingGif src={IphoneGif} alt='Loading...' />
        </LoadingOverlay>
      )}

      {data && showModal && (
        <ModalRegistration
          urlToken={urlToken}
          token={data.token}
          organization={data.organization}
          onClose={() => setShowModal(false)}
          user={data.user}
          xml={signXml}
        />
      )}
    </Container>
  );
};
