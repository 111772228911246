import styled from 'styled-components';

export const CostMoneyBox = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-right: auto; /* Альтернативный вариант */
  margin-bottom: 16px;
  width: 100%;
`;

export const CostMoneyItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px 16px;
  max-width: 200px;
  gap: 4px;
  border-radius: 8px;
  background: var(--neutral-50, #fefefe);
`;

export const CostMoneyText = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--neutral-400, #a0a0a0);
  font-size: 12px;
  font-weight: 500;
  line-height: 14px; /* 125% */
`;

export const CostMoneyLine = styled.div`
  background: var(--neutral-100, #f4f4f4);
  border: 1px solid #f4f4f4;
`;

export const CostMoneyCount = styled.p`
  color: #5784f7;

  font-size: 24px;
  font-weight: 700;
  line-height: 100%; /* 32px */
`;
