import { RadioButtonContainer } from './RadioButton.style';
import RadioButtonOn from 'assets/img/RadioButtonOn.svg';
import RadioButtonOff from 'assets/img/RadioButtonOff.svg';

type CustomRadioButtonProps = {
  checked: boolean;
  onChange: () => void;
};

export const RadioButton: React.FC<CustomRadioButtonProps> = ({ checked, onChange }) => {
  return (
    <RadioButtonContainer onClick={onChange}>
      <img src={checked ? RadioButtonOn : RadioButtonOff} alt='Radio button' />
    </RadioButtonContainer>
  );
};
