import { css, styled } from 'styled-components';

const UserContainer = styled.div`
  display: flex;
  padding: 16px;
  gap: 24px;
  width: 100%;
  background: var(--neutral-50, #fefefe);
  justify-content: space-between;
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  max-width: 460px;
  .col {
    flex-direction: column;
    align-items: baseline;
    gap: 0;
  }
  button {
    width: 100%;
  }
`;

const UserInfo = styled.div`
  font-size: 14px;
  align-items: center;
  display: flex;
  gap: 10px;
  width: 100%;
  max-width: 460px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  color: #0d0d0d;
  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #5f5f5f;
  }
`;

const ContactBox = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  max-width: 460px;
  button {
    height: 36px;
    width: 36px;
  }
`;

export const ContainerAvatar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
`;

export const BoxAvatar = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  justify-content: space-between;
  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`;

export const TitleAvatar = styled.p`
  color: #0d0d0d;
  font-family: 'IBM Plex Sans';
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
`;

export const DescriptionAvatar = styled.p`
  color: var(--neutral-400, #a0a0a0);
  font-family: 'IBM Plex Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.16px;
`;

export const BoxAvatarImg = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 10px;
`;

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

export const Circle = styled.div<{ avatarFileName?: string }>`
  min-width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #e3e3e3;

  ${({ avatarFileName }) =>
    avatarFileName &&
    css`
      background-image: url(${BASE_URL}/images/avatar/${avatarFileName});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    `}
`;

export { UserContainer, UserInfo, ContentBox, ContactBox };
