import React, { useEffect, useState } from 'react';
import { KBE, MyAccount } from './types';
import * as api from 'components/api/bankAccount.api';
import { ContactBox } from 'components/OrganizationCabinet/OrganizationCabinet.style';
import {
  BankContainer,
  ContentBox,
  HeaderBank,
  ModalBackground,
  ModalContent,
  OrganizationInfo,
} from './BankAccount.style';
import { returnBill } from 'components/api/bankAccount.api';
import { NewButton } from 'components/common/Button';
import { InputNew } from 'components/common/Input/InputNew/InputNew';
import {
  DropdownContainer,
  DropdownHeader,
  DropdownListContainer,
  DropdownListItem,
} from 'components/RequestDetailsNew/RequestDetailsNew.style';
import IconArrow from 'assets/img/IconArrow.svg';
import IconArrowDown from 'assets/img/IconArrowDown.svg';
import IconDelete from 'assets/img/IconDelete.svg';
import { ArrowIcon } from 'components/ApplicationInvestor/ApplicationInvestorNew.style';
import { ConfirmModal } from 'components/common/ConfirmModal';
import { ErrorModal } from 'components/common/ErrorModal';
import { ModalActions } from 'components/OffersBusinessList/OffersBusinessList.style';

export const BankAccount = () => {
  const [myAccounts, setMyAccounts] = useState<MyAccount[]>([]);
  const [kbes, setKBEs] = useState<KBE[]>([]);
  const [selectedKbe, setSelectedKbe] = useState<KBE | null>(null);
  const [accountNumber, setAccountNumber] = useState<string>('');
  const [showDeletedModal, setShowDeletedModal] = useState(false);
  const [isKBeDropdownOpen, setIsKBeDropdownOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState<number | null>(null);
  const [error, setError] = useState<{ header: string; text: string } | null>(null);

  useEffect(() => {
    api
      .getKBEs()
      .then((response) => setKBEs(response.data))
      .catch(() =>
        setError({ header: 'Ошибка загрузки', text: 'Не удалось загрузить список КБЕ' }),
      );

    api
      .getMyAccounts()
      .then((response) => setMyAccounts(response.data))
      .catch(() => setError({ header: 'Ошибка загрузки', text: 'Не удалось загрузить счета' }));
  }, []);

  const deleteAccount = async (id: number) => {
    try {
      await api.deleteAccount(id);
      setMyAccounts((prev) => prev.filter((acc) => acc.id !== id));
      setConfirmDelete(null);
    } catch {
      setError({ header: 'Ошибка удаления', text: 'Не удалось удалить счет' });
    }
  };

  const addAccount = async () => {
    if (!selectedKbe) return;
    try {
      const response = await api.addAccount(selectedKbe.id, accountNumber);
      setMyAccounts((prev) => [...prev, response.data]);
    } catch (error: any) {
      if (error.response?.data?.detail === 'IBAN_ALREADY_DELETED') {
        setShowDeletedModal(true);
      } else {
        setError({ header: 'Ошибка добавления', text: 'Не удалось добавить счет' });
      }
    }
  };

  const handleReturnBill = async () => {
    try {
      await returnBill(accountNumber);
      const updatedAccounts = await api.getMyAccounts();
      setMyAccounts(updatedAccounts.data);
      setShowDeletedModal(false);
    } catch {
      setError({ header: 'Ошибка восстановления', text: 'Не удалось вернуть счет' });
    }
  };

  const toggleKBeDropdown = () => {
    setIsKBeDropdownOpen((prev) => !prev);
  };

  const handleKBeSelect = (kbe: KBE) => {
    setSelectedKbe(kbe);
    setIsKBeDropdownOpen(false);
  };

  return (
    <BankContainer>
      <HeaderBank>Банковские счета</HeaderBank>
      <ContentBox>
        <h3>Мои счета:</h3>
        {myAccounts.map((account) => (
          <li key={account.id} style={{ listStyle: 'none' }}>
            {account.iban}
            <img
              src={IconDelete}
              alt='Удалить'
              onClick={() => setConfirmDelete(account.id)}
              style={{ marginLeft: 8, cursor: 'pointer' }}
            />
          </li>
        ))}

        <h3>Добавить счет:</h3>
        <OrganizationInfo>
          <DropdownContainer>
            <label>Выбрать КБЕ:</label>
            <DropdownHeader
              onClick={toggleKBeDropdown}
              isOpen={isKBeDropdownOpen}
              $hasContent={!!selectedKbe}>
              {selectedKbe ? selectedKbe.name : 'Выбрать КБЕ'}
              <ArrowIcon src={isKBeDropdownOpen ? IconArrowDown : IconArrow} alt='arrow' />
            </DropdownHeader>
            {isKBeDropdownOpen && (
              <DropdownListContainer>
                {kbes.map((kbe) => (
                  <DropdownListItem key={kbe.id} onClick={() => handleKBeSelect(kbe)}>
                    {kbe.name}
                  </DropdownListItem>
                ))}
              </DropdownListContainer>
            )}
          </DropdownContainer>
        </OrganizationInfo>

        <OrganizationInfo>
          <label>Добавить счет:</label>
          <ContactBox>
            <InputNew
              placeholder='20 значный IBAN'
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
            />
          </ContactBox>
        </OrganizationInfo>

        <NewButton
          className='white'
          text='Добавить счет'
          onClick={addAccount}
          disabled={!selectedKbe || accountNumber.length !== 20}
        />
      </ContentBox>

      {confirmDelete !== null && (
        <ConfirmModal
          isOpen={true}
          headerText='Подтверждение'
          bodyText='Вы уверены, что хотите удалить этот счет?'
          confirmText='Удалить'
          cancelText='Отмена'
          onConfirm={() => deleteAccount(confirmDelete)}
          onCancel={() => setConfirmDelete(null)}
        />
      )}

      {error && (
        <ErrorModal
          headerError={error.header}
          textError={error.text}
          buttonClose='Закрыть'
          onClick={() => setError(null)}
        />
      )}
      {showDeletedModal && (
        <ModalBackground>
          <ModalContent>
            <h2>Вы действительно хотите вернуть ранее удаленный счет?</h2>
            <ModalActions>
              <NewButton text='Да' onClick={handleReturnBill} />
              <NewButton text='Нет' onClick={() => setShowDeletedModal(false)} />
            </ModalActions>
          </ModalContent>
        </ModalBackground>
      )}
    </BankContainer>
  );
};
