import styled from 'styled-components';

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

export const Modal = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 460px;
  gap: 20px;
  p {
    font-size: 16px;
  }
`;

export const ModalHeader = styled.h2`
  font-size: 18px;
  color: var(--Black-Main, #292d32);
  font-weight: 600;
  line-height: 20px;
`;

export const ModalActions = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 10px;
`;
