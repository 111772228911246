import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Box, HeaderBox, BlackListPlatformBox, IconHeader, HideOrgBlock } from './BlackList.style';
import BlackListPlatformIcon from 'assets/img/BlackListPlatformIcon.svg';
import IgnoreOrgIcon from 'assets/img/IgnoreOrgIcon.svg';
import { InputSearchNew } from 'components/common/Input/InputSearch';
import { NewButton } from 'components/common/Button';
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TableRowHeader,
} from 'components/ApplicationBusinessList/ApplicationBusinessList.style';
import { InputNew } from 'components/common/Input/InputNew/InputNew';
import { Pagination } from 'components/Pagination';
import { formatDateTime } from 'utils/formatDate';
import SuccessModal from 'components/common/SuccessModal/SuccessModal';
import { ConfirmModal } from 'components/common/ConfirmModal';
import { ErrorModal } from 'components/common/ErrorModal';

// -----------------------------
// Тип для конфигурации сортировки
// -----------------------------
type SortConfig = {
  field: string;
  direction: 'asc' | 'desc';
};

// Изначальные настройки сортировки
const initialSortConfig: SortConfig = {
  field: 'name',
  direction: 'asc',
};

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;

export const BlackList = () => {
  // -----------------------------
  // 1. Состояния и логика для ЧЕРНОГО СПИСКА ПЛАТФОРМЫ
  // -----------------------------
  const [searchValue, setSearchValue] = useState(''); // поле поиска
  const [blackList, setBlackList] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [sortConfig, setSortConfig] = useState<SortConfig>(initialSortConfig);

  // Для упрощения (и единообразия) возьмём размер страницы фиксированным
  const pageSize = 5;

  // Функция для сортировки (черный список)
  const handleSort = (field: string) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig.field === field && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ field, direction });
    setCurrentPage(0); // При смене сортировки сбрасываем на первую страницу
  };

  // Запрос данных (черный список) с API
  useEffect(() => {
    const fetchBlackList = async () => {
      try {
        const response = await axios.get(`${BASE_URL}ui/blacklist/platform/list`, {
          withCredentials: true,
          params: {
            searchText: searchValue,
            page: currentPage,
            size: pageSize,
            sort: `${sortConfig.field},${sortConfig.direction}`,
          },
        });
        const data = response.data;
        setBlackList(data?.content || []);
        setTotalPages(data?.totalPages || 0);
      } catch (error) {
        setError({
          header: 'Ошибка загрузки',
          message: 'Не удалось загрузить Черный список платформы',
        });
      }
    };
    fetchBlackList();
  }, [searchValue, currentPage, sortConfig]);

  const handlePageChange = (data: { selected: number }) => {
    setCurrentPage(data.selected);
  };

  // -----------------------------
  // 2. Состояния и логика для ИГНОРИРУЕМЫХ ОРГАНИЗАЦИЙ
  // -----------------------------
  const [searchIgnoreValue, setSearchIgnoreValue] = useState('');
  const [hideOrg, setHideOrg] = useState('');
  const [ignoreList, setIgnoreList] = useState<any[]>([]);
  const [ignoreTotalPages, setIgnoreTotalPages] = useState(0);
  const [ignoreCurrentPage, setIgnoreCurrentPage] = useState(0);
  const [ignoreSortConfig, setIgnoreSortConfig] = useState<SortConfig>(initialSortConfig);
  const [loading, setLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedOrgId, setSelectedOrgId] = useState<number | null>(null);
  const [error, setError] = useState<{ header: string; message: string } | null>(null);

  // Функция для сортировки (игнорируемые организации)
  const handleSortIgnore = (field: string) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (ignoreSortConfig.field === field && ignoreSortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setIgnoreSortConfig({ field, direction });
    setIgnoreCurrentPage(0); // сброс на первую страницу
  };

  // Запрос данных (игнорируемые организации) с API
  const fetchIgnoreList = useCallback(async () => {
    try {
      const response = await axios.get(`${BASE_URL}ui/blacklist/organization/list`, {
        withCredentials: true,
        params: {
          searchText: searchIgnoreValue,
          page: ignoreCurrentPage,
          size: pageSize,
          sort: `${ignoreSortConfig.field},${ignoreSortConfig.direction}`,
        },
      });
      setIgnoreList(response.data?.content || []);
      setIgnoreTotalPages(response.data?.totalPages || 0);
    } catch (error) {
      setError({
        header: 'Ошибка загрузки',
        message: 'Не удалось загрузить Игнорируемые организации.',
      });
    }
  }, [searchIgnoreValue, ignoreCurrentPage, ignoreSortConfig]);

  useEffect(() => {
    fetchIgnoreList();
  }, [fetchIgnoreList]);

  const handleAddToIgnoreList = async () => {
    if (!hideOrg || hideOrg.length !== 12 || !/^\d{12}$/.test(hideOrg)) {
      return;
    }
    setLoading(true);

    try {
      await axios.put(`${BASE_URL}ui/blacklist/organization`, null, {
        withCredentials: true,
        params: { biin: hideOrg },
      });

      setShowSuccessModal(true);
      setHideOrg('');
      setIgnoreCurrentPage(0);
      fetchIgnoreList();
    } catch (error) {
      setError({ header: 'Ошибка добавления', message: 'Не удалось скрыть организацию.' });
    } finally {
      setLoading(false);
    }
  };

  const handleIgnorePageChange = (data: { selected: number }) => {
    setIgnoreCurrentPage(data.selected);
  };

  // Открытие модального окна перед удалением
  const handleDeleteClick = (organizationId: number) => {
    setSelectedOrgId(organizationId);
    setConfirmDelete(true);
  };

  const handleConfirmDelete = async () => {
    if (!selectedOrgId) return;
    setLoading(true);

    try {
      await axios.delete(`${BASE_URL}ui/blacklist/organization`, {
        withCredentials: true,
        params: { orgId: selectedOrgId },
      });

      setConfirmDelete(false);
      setSelectedOrgId(null);
      fetchIgnoreList();
    } catch (error) {
      setError({ header: 'Ошибка удаления', message: 'Не удалось удалить организацию.' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      {/* ------------------------------------- */}
      {/* Блок 1: Черный список платформы       */}
      {/* ------------------------------------- */}
      <BlackListPlatformBox>
        <HeaderBox>
          <IconHeader>
            <img src={BlackListPlatformIcon} alt='iconBlackList' />
            <h4>Черный список платформы</h4>
          </IconHeader>
          <p>
            В этом списке находятся заблокированные пользователи, которые нарушили условия сервиса
            или вызвали претензии со стороны инвесторов. Мы следим за прозрачностью и безопасностью
            сделок, поэтому эти участники больше не могут использовать платформу.
          </p>
        </HeaderBox>

        {/* Поле поиска (черный список) */}
        <InputSearchNew
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
            setCurrentPage(0);
          }}
          placeholder='Имя/Название организации'
          name='searchQuery'
        />

        {/* Таблица - Черный список платформы */}
        <Table>
          <thead>
            <TableRowHeader>
              <TableHeader
                isSorted={sortConfig.field === 'name'}
                sortable={true}
                direction={sortConfig.field === 'name' ? sortConfig.direction : null}
                onClick={() => handleSort('name')}>
                Имя/Название организации
              </TableHeader>

              <TableHeader
                isSorted={sortConfig.field === 'iin'}
                sortable={true}
                direction={sortConfig.field === 'iin' ? sortConfig.direction : null}
                onClick={() => handleSort('iin')}>
                ИИН/БИН
              </TableHeader>

              <TableHeader
                isSorted={sortConfig.field === 'reason'}
                sortable={true}
                direction={sortConfig.field === 'reason' ? sortConfig.direction : null}
                onClick={() => handleSort('reason')}>
                Причина внесения
              </TableHeader>

              <TableHeader
                isSorted={sortConfig.field === 'date'}
                sortable={true}
                direction={sortConfig.field === 'date' ? sortConfig.direction : null}
                onClick={() => handleSort('date')}>
                Дата внесения
              </TableHeader>
            </TableRowHeader>
          </thead>
          <tbody>
            {blackList.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.biin}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{formatDateTime(row.dtCreate)}</TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>

        {/* Кнопка (пока disabled) */}
        <NewButton
          disabled
          className='white'
          text='Подать заявку на добавление пользователя в черный список'
        />

        {/* Пагинация для Черного списка */}
        <Pagination
          pageCount={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </BlackListPlatformBox>

      {/* ------------------------------------- */}
      {/* Блок 2: Игнорируемые организации      */}
      {/* ------------------------------------- */}
      <BlackListPlatformBox>
        <HeaderBox>
          <IconHeader>
            <img src={IgnoreOrgIcon} alt='iconBlackList' />
            <h4>Игнорируемые организации</h4>
          </IconHeader>
          <p>
            Игнорируемые организации — это ваш личный список скрытых заемщиков и компаний, заявки
            которых вы не хотите видеть. Их заявки больше не будут отображаться в вашем списке,
            позволяя вам сосредоточиться на приоритетных сделках.
          </p>
        </HeaderBox>

        {/* Поле поиска (игнорируемые организации) */}
        <InputSearchNew
          value={searchIgnoreValue}
          onChange={(e) => {
            setSearchIgnoreValue(e.target.value);
            setIgnoreCurrentPage(0);
          }}
          placeholder='Имя/Название организации'
          name='searchQuery'
        />

        <HideOrgBlock>
          <p>Скрыть организацию</p>
          <InputNew
            placeholder='Введите ИИН/БИН'
            value={hideOrg}
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, '').slice(0, 12);
              setHideOrg(value);
            }}
          />
          <NewButton
            text={loading ? 'Загрузка...' : 'Скрыть организацию'}
            disabled={loading || hideOrg.length !== 12}
            onClick={handleAddToIgnoreList}
            className='white'
          />
        </HideOrgBlock>

        {/* Таблица - Игнорируемые организации */}
        <Table>
          <thead>
            <TableRowHeader>
              <TableHeader
                isSorted={ignoreSortConfig.field === 'name'}
                sortable={true}
                direction={ignoreSortConfig.field === 'name' ? ignoreSortConfig.direction : null}
                onClick={() => handleSortIgnore('name')}>
                Имя/Название организации
              </TableHeader>
              <TableHeader
                isSorted={ignoreSortConfig.field === 'date'}
                sortable={true}
                direction={ignoreSortConfig.field === 'date' ? ignoreSortConfig.direction : null}
                onClick={() => handleSortIgnore('date')}>
                Дата внесения
              </TableHeader>
              <TableHeader>Действие</TableHeader>
            </TableRowHeader>
          </thead>
          <tbody>
            {ignoreList.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{formatDateTime(row.createdAt)}</TableCell>
                <TableCell>
                  <a onClick={() => handleDeleteClick(row.blockedOrganizationId)}>
                    Удалить из скрытых
                  </a>
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>

        {/* Пагинация для Игнорируемых организаций */}
        <Pagination
          pageCount={ignoreTotalPages}
          currentPage={ignoreCurrentPage}
          onPageChange={handleIgnorePageChange}
        />
      </BlackListPlatformBox>
      {showSuccessModal && (
        <SuccessModal
          onClose={() => setShowSuccessModal(false)}
          title='Организация скрыта'
          message='Вы успешно скрыли организацию.'
          buttonText='Закрыть'
        />
      )}
      {confirmDelete && (
        <ConfirmModal
          isOpen={confirmDelete}
          headerText='Подтверждение удаления'
          bodyText='Вы уверены, что хотите удалить организацию из списка скрытых?'
          confirmText='Отмена'
          cancelText='Удалить'
          loading={loading}
          onCancel={handleConfirmDelete}
          onConfirm={() => setConfirmDelete(false)}
        />
      )}
      {error && (
        <ErrorModal
          headerError={error.header}
          textError={error.message}
          buttonClose='Закрыть'
          onClick={() => setError(null)}
        />
      )}
    </Box>
  );
};
