import './AdminScreen.css';
import { SideBar } from 'components/SideBar';
import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { InvestorSettings } from 'components/InvestorSettings';
import { AppProvider, useAppContext } from 'contexts/AppContext';
import ApplicationScreen from 'screens/ApplicationScreen/ApplicationScreen';
import { DocumentsList } from 'components/DocumentsList';
import SuggestionsInvestorList from 'components/SuggestionsInvestorList/SuggestionsInvestorList';
import { ContractBusiness } from 'components/ContractBusiness';
import { NotFound404 } from 'components/NotFoundPage';
import KycNew from 'components/KYC/KycNew';
import { Payments } from 'components/Payments';
import { CabinetAccount } from 'components/CabinetAccount';
import { NewCreateApplication } from 'components/NewCreateApplication';
import { Groups } from 'components/Groups';
import { GroupDetail } from 'components/Groups/GroupDetail';
import RequestScreen from 'screens/RequestScreen/RequestScreen';
import { AdminSettings } from 'components/AdminSettings/AdminSettings';
import { Header } from 'components/Header';
import { KycModalWrapper } from 'components/KYC/KycModal/KycModalWrapper';

type ProtectedRouteProps = {
  condition: boolean;
  children: any;
};

const AdminScreen = () => {
  return (
    <AppProvider>
      <AdminScreenContent />
    </AppProvider>
  );
};

const AdminScreenContent = () => {
  const { userDataLight } = useAppContext();
  const navigate = useNavigate();

  const isSpecialUser = userDataLight?.roles?.includes('NOT_VERIFIED');

  const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ condition, children }) => {
    return condition ? children : <NotFound404 />;
  };

  useEffect(() => {
    if (userDataLight && !userDataLight.offerSigned) {
      navigate('/account/agreement');
    }
  }, [userDataLight, navigate]);

  return (
    <div className='App'>
      <SideBar />
      <div className='header'>
        <Header />
        <div className='app1'>
          <KycModalWrapper />
          <Routes>
            <Route path='/' element={<Navigate to='applications' replace />} />
            <Route path='investor-settings' element={<InvestorSettings />} />
            <Route path='applications' element={<ApplicationScreen />} />
            <Route
              path='kyc'
              element={
                <ProtectedRoute condition={isSpecialUser}>
                  <KycNew />
                </ProtectedRoute>
              }
            />
            <Route path='/account' element={<Navigate to='/account/user' replace />} />
            <Route path='/account/:tab' element={<CabinetAccount />} />

            <Route path='/request/:id/:tab?/:subTab?' element={<RequestScreen />} />

            {userDataLight?.roles ? (
              userDataLight.roles.includes('ADMIN') ? (
                <Route path='/admin' element={<AdminSettings />} />
              ) : (
                <Route path='/admin' element={<NotFound404 />} />
              )
            ) : null}

            <Route path='/payments' element={<Payments />} />
            <Route path='/contracts' element={<DocumentsList />} />
            <Route path='/contracts/:id' element={<ContractBusiness />} />

            {/* Пример: только для бизнеса */}
            <Route
              path='/create-application'
              element={
                <ProtectedRoute condition={userDataLight?.organizationRole === 'BUSINESS'}>
                  <NewCreateApplication />
                </ProtectedRoute>
              }
            />

            <Route
              path='/suggestions'
              element={
                <ProtectedRoute condition={userDataLight?.organizationRole === 'INVESTOR'}>
                  <SuggestionsInvestorList />
                </ProtectedRoute>
              }
            />

            <Route path='/groups' element={<Groups />} />
            <Route path='/group/:groupId' element={<GroupDetail />} />

            {/* Если ни один путь не подошёл */}
            <Route path='*' element={<NotFound404 />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default AdminScreen;
