import styled from 'styled-components';

export const WarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  display: flex;
  padding: 50px;
  height: 100vh;
  align-items: center;
  gap: 50px;
  background: #181b3e;
`;

export const WarningTitle = styled.h2`
  color: #fff;

  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const WarningText = styled.p`
  color: #fff;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
