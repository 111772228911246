import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  HeaderMenu,
  HeaderNav,
  UserInitialsCircle,
  StyledNavLink,
  IconWithBadge,
  NotificationBadge,
  BalanceBlock,
  BalanceText,
  BalanceAmount,
  BalanceImage,
  KYCFailBox,
  KYCFailContainer,
} from './Header.style';
import { ORGANIZATION_URL } from 'constants/urls';
import { useAppContext } from 'contexts/AppContext';
import { NotificationsDropdown } from './NotificationsDropdown';
import IconBalance from 'assets/img/IconBalance.svg';
import KYCFailHeader from 'assets/img/KYCFailHeader.svg';
import KYCAlert from 'assets/img/KYCAlert.svg';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

export const Header = () => {
  const navigate = useNavigate();
  const { userDataLight } = useAppContext();
  const isSpecialUser = userDataLight?.roles?.includes('NOT_VERIFIED');

  const [notificationsCount, setNotificationsCount] = useState(userDataLight?.notification ?? 0);
  const balance = userDataLight?.balance ?? '—';
  const fio = userDataLight?.fio || '';

  const initials = fio
    ? fio.split(' ').reduce((acc: string, word: string, index: number) => {
        if (index < 2) acc += word.charAt(0);
        return acc;
      }, '')
    : '';

  const avatarUrl = userDataLight?.avatar
    ? `${BASE_URL}/images/avatar/${userDataLight.avatar}`
    : '';

  return (
    <HeaderMenu>
      <HeaderNav isSpecialUser={isSpecialUser}>
        {isSpecialUser ? (
          <KYCFailBox onClick={() => navigate('/kyc')}>
            <KYCFailContainer>
              <img src={KYCFailHeader} alt='alertKYC' />
              <div>
                <img src={KYCAlert} alt='alertKYC' />
                <p>
                  Вы используете платформу в режиме просмотра. <u>Пройти верификацию</u>
                </p>
              </div>
              <img src={KYCFailHeader} alt='alertKYC' />
            </KYCFailContainer>
          </KYCFailBox>
        ) : null}

        <BalanceBlock onClick={() => navigate('/account/balance')} style={{ cursor: 'pointer' }}>
          <BalanceText>Баланс:</BalanceText>
          <BalanceAmount>{balance}</BalanceAmount>
          <BalanceImage src={IconBalance} alt='IconBalance' />
        </BalanceBlock>

        <IconWithBadge onClick={() => setNotificationsCount(0)}>
          <NotificationsDropdown />
          {notificationsCount > 0 && <NotificationBadge>{notificationsCount}</NotificationBadge>}
        </IconWithBadge>

        <StyledNavLink to={ORGANIZATION_URL}>
          {avatarUrl ? (
            <img
              src={avatarUrl}
              alt='User Avatar'
              style={{ borderRadius: '50%', width: '50px', height: '50px' }}
            />
          ) : (
            <UserInitialsCircle>{initials}</UserInitialsCircle>
          )}
        </StyledNavLink>
      </HeaderNav>
    </HeaderMenu>
  );
};
