import styled from 'styled-components';
import { Button } from 'components/common/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;
  div {
    width: 100%;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;

  /* p {
    text-align: center;
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  } */
`;

const ServiceButton = styled.button<{ $isActive: boolean }>`
  border: none;
  width: 100%;
  text-align: left;
  background-color: ${({ $isActive }) => ($isActive ? '#F0EBE8' : '#fff')};
  font-size: 16px;
  padding: 10px 40px;
`;

const BoxInput = styled.div`
  text-align: left;
  padding-top: 10px;
`;

const BoxInputTitle = styled.span`
  font-size: 16px;
  width: 100%;
`;

const BoxButton = styled(Button)`
  margin-top: 20px;
`;

const TextDiv = styled.div`
  padding: 5px;
  font-size: 15px;
`;

const TextDivBold = styled.div`
  font-style: oblique;
  font-size: 15px;
`;

const Item = styled.div`
  margin-bottom: 10px;
`;

interface HeaderProps {
  isOpen?: boolean;
}

const Header = styled.div<HeaderProps>`
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  background: #f6f8fa;
  p {
    color: #0d0d0d;

    font-size: 24px;
    font-weight: 600;
    line-height: 125%;
  }
`;

const ArrowButton = styled.button<{ isOpen: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
  ${({ isOpen }) => isOpen && 'transform: rotate(180deg);'}
`;

interface ContentProps {
  isOpen: boolean;
}

const Content = styled.div<ContentProps>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
`;

export const ExpandableSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MainInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px;
  border-radius: 0px 0px 8px 8px;
  background: var(--neutral-50, #fefefe);
`;

export const ArrowIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-left: auto;
`;

export const MainInfoTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 8px 8px 0px 0px;
  color: var(--neutral-800, #303030);
  font-size: 18px;
  font-weight: 600;
  line-height: 125%; /* 23.193px */
  cursor: pointer;
  background: var(--neutral-50, #fefefe);
  padding: 24px;
  width: 100%;
`;

export const BoxSimple = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
  padding: 8px 12px;
  border: 1px solid var(--neutral-800, #303030);
  border-radius: 8px;
  color: var(--neutral-800, #303030);
  font-size: 18px;
  font-weight: 600;
  line-height: 125%;
  margin-top: 8px;
  align-items: center;
  span {
    max-width: 80%;
  }
`;

export {
  Box,
  ServiceButton,
  BoxInput,
  BoxInputTitle,
  BoxButton,
  TextDiv,
  TextDivBold,
  Item,
  Header,
  Content,
  ArrowButton,
};
