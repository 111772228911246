import React, { useState, useEffect, useRef } from 'react';
import {
  Dropdown,
  NotificationItem,
  NotificationHeader,
  NotificationClose,
  NotificationBox,
  NotificationTime,
  LoadingIndicator,
  IconDot,
  NotificationInfo,
  NotificationLinkItem,
} from './NotificationsDropdown.style';
import { IconNotifications } from './Header.style';
import axios from 'axios';
import IphoneGif from 'assets/img/IphoneGif.gif';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useAppContext } from 'contexts/AppContext';
import { Notification } from './NotificationsDropdown.interface';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;

export const NotificationsDropdown = () => {
  const { userDataLight } = useAppContext();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isOpen, setIsOpen] = useState(false); // Controls the open/close animation
  const [isVisible, setIsVisible] = useState(false); // Controls whether the dropdown is in the DOM
  const [isLoading, setIsLoading] = useState(true);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Fetch notifications from the API
  const fetchNotifications = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}ui/user/notification`, {
        withCredentials: true,
      });
      if (Array.isArray(response.data)) {
        const sortedNotifications = response.data.sort(
          (a, b) => new Date(b.dtCreate).getTime() - new Date(a.dtCreate).getTime(),
        );

        setNotifications(sortedNotifications);
      } else {
        console.error('Unexpected data format:', response.data);
        setNotifications([]);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
      setNotifications([]);
    }
    setIsLoading(false);
  };

  // Open the dropdown with fetching notifications
  const handleOpen = () => {
    setIsVisible(true); // Make the dropdown visible
    setIsOpen(true); // Start the open animation
    fetchNotifications(); // Fetch notifications when opening
  };

  // Close the dropdown with animation
  const handleClose = () => {
    setIsOpen(false); // Start the close animation
    // Wait for the animation to complete before hiding the dropdown
    setTimeout(() => setIsVisible(false), 500); // Match this to the animation duration (0.5s)
  };

  // Close the dropdown when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        handleClose(); // Close the dropdown if clicked outside
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      <IconNotifications onClick={isOpen ? handleClose : handleOpen} />
      {isVisible && (
        <Dropdown ref={dropdownRef} isOpen={isOpen}>
          <NotificationHeader>
            Уведомления
            <NotificationClose onClick={handleClose} />
          </NotificationHeader>
          {isLoading ? (
            <LoadingIndicator src={IphoneGif} alt='Loading...' />
          ) : notifications.length > 0 ? (
            notifications.map((notification) =>
              notification.link ? (
                <NotificationLinkItem
                  key={notification.dtCreate}
                  href={notification.link}
                  target='_blank'
                  style={{
                    background: notification.read ? 'inherit' : 'var(--Highlight-Primary, #EDFEFF)',
                  }}>
                  <NotificationBox>
                    <NotificationTime>
                      {format(new Date(notification.dtCreate), 'EEEE, HH:mm ⠀d MMMM, yyyy', {
                        locale: ru,
                      })}
                    </NotificationTime>
                    <NotificationInfo>
                      <p>{notification.template}</p>
                      <IconDot iconType={notification.iconType as 'WARNING' | 'INFO' | 'SYSTEM'} />
                    </NotificationInfo>
                  </NotificationBox>
                </NotificationLinkItem>
              ) : (
                <NotificationItem
                  key={notification.dtCreate}
                  style={{
                    background: notification.read ? 'inherit' : 'var(--Highlight-Primary, #EDFEFF)',
                  }}>
                  <NotificationBox>
                    <NotificationTime>
                      {format(new Date(notification.dtCreate), 'EEEE, HH:mm ⠀d MMMM, yyyy', {
                        locale: ru,
                      })}
                    </NotificationTime>
                    <NotificationInfo>
                      <p>{notification.template}</p>
                      <IconDot iconType={notification.iconType as 'WARNING' | 'INFO' | 'SYSTEM'} />
                    </NotificationInfo>
                  </NotificationBox>
                </NotificationItem>
              ),
            )
          ) : (
            <div>No notifications to display</div>
          )}
        </Dropdown>
      )}
    </>
  );
};
