import React, { useState } from 'react';
import { ApplicationDocumentsListProps } from './ApplicationDocumentsList.interface';
import IconDocuments from 'assets/img/IconDocuments.svg';
import IconArrow from 'assets/img/IconArrow.svg';
import IconArrowDown from 'assets/img/IconArrowDown.svg';
import IconDownloadFiles from 'assets/img/IconDownloadFiles.svg'; // исправлено
import { Container, MainInfoBox, FileItem, FileLink } from './ApplicationDocumentsList.style';
import {
  ExpandableSection,
  MainInfoTitle,
  ArrowIcon,
  MainInfoItemText,
} from 'components/ApplicationInvestor/ApplicationInvestorNew.style';
import { DocLinkBox } from 'components/RequestDetailsNew/RequestDetailsNew.style';
import { WarningModal } from 'components/common/WarningModal';
import { useFileDownload } from 'utils/hooks';

export const ApplicationDocumentsList: React.FC<ApplicationDocumentsListProps> = ({
  files = [], // добавлено значение по умолчанию
  docLink,
}) => {
  const [isMainInfoExpanded, setIsMainInfoExpanded] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [externalLink, setExternalLink] = useState<string | null>(null);
  const { downloadFile } = useFileDownload();

  const handleDocLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault(); // предотвращаем переход по ссылке
    setExternalLink(docLink);
    setIsModalOpen(true);
  };

  const handleModalConfirm = () => {
    if (externalLink) {
      window.open(externalLink, '_blank');
    }
    setIsModalOpen(false);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setExternalLink(null);
  };

  return (
    <Container>
      {files?.length === 0 ? ( // безопасный доступ к длине массива
        <ExpandableSection>
          <MainInfoTitle>
            <img src={IconDocuments} alt='icon' />
            Документы отсутствуют
          </MainInfoTitle>
        </ExpandableSection>
      ) : (
        <ExpandableSection>
          <MainInfoTitle onClick={() => setIsMainInfoExpanded(!isMainInfoExpanded)}>
            <img src={IconDocuments} alt='icon' />
            Документы
            <ArrowIcon src={isMainInfoExpanded ? IconArrowDown : IconArrow} alt='arrow' />
          </MainInfoTitle>
          {isMainInfoExpanded && (
            <MainInfoBox>
              {docLink && (
                <DocLinkBox>
                  <MainInfoItemText>Ссылка на гугл папку</MainInfoItemText>
                  <a href='#' onClick={handleDocLinkClick} style={{ cursor: 'pointer' }}>
                    {docLink}
                  </a>
                </DocLinkBox>
              )}

              {files.map((file) => (
                <FileItem key={file.file}>
                  <FileLink onClick={() => downloadFile(file.file)}>
                    <div>
                      <img src={IconDocuments} alt='download icon' />
                      {file.title}
                    </div>
                    <p>скачать</p>
                  </FileLink>
                </FileItem>
              ))}
            </MainInfoBox>
          )}
        </ExpandableSection>
      )}

      {isModalOpen && (
        <WarningModal
          headerWarning='Предупреждение'
          textWarning='Вы переходите на внешнюю ссылку'
          buttonClose='Согласен'
          onClick={handleModalConfirm}
          onClose={handleModalClose}
        />
      )}
    </Container>
  );
};
