export const faqData = [
  {
    title: 'Почему мне нужно проходить верификацию?',
    content: (
      <>
        <div>
          <p>
            В соответствии с законодательством Республики Казахстан, мы обязаны идентифицировать
            всех пользователей платформы. Прохождение верификации гарантирует защиту ваших средств,
            безопасность сделок и предотвращение финансовых преступлений. Это стандартная процедура,
            принятая на всех финансовых платформах, работающих в регулируемой среде.
          </p>
        </div>
      </>
    ),
  },
  {
    title: 'Что будет, если я не пройду верификацию?',
    content: (
      <>
        <div>
          <p>
            Вы сможете просматривать платформу, но не сможете участвовать в сделках и делать
            предложения.
          </p>
        </div>
      </>
    ),
  },
];

export const faqDataPDF = [
  {
    title: 'Почему требуется подпись через ЭЦП?',
    content: (
      <>
        <div>
          <p>
            Электронно-цифровая подпись (ЭЦП) обеспечивает юридическую значимость вашего согласия с
            условиями оферты. Это требование законодательства Республики Казахстан, которое
            гарантирует подлинность и защиту ваших данных.
          </p>
        </div>
      </>
    ),
  },
  {
    title: 'Можно ли продолжить пользоваться платформой без подписания оферты?',
    content: (
      <>
        <div>
          <p>
            Нет, подписание оферты – обязательное условие для полного доступа к функционалу
            платформы. Без этого вы не сможете совершать сделки или взаимодействовать с другими
            пользователями.
          </p>
        </div>
      </>
    ),
  },
];

export const faqDataLive = [
  {
    title: 'Почему мне нужно проходить верификацию?',
    content: (
      <>
        <div>
          <p>
            Верификация необходима для обеспечения безопасности платформы и подтверждения вашей
            личности. Это защищает инвесторов и заемщиков, а также предотвращает мошенничество
          </p>
        </div>
      </>
    ),
  },
  {
    title: 'Что будет, если я не пройду верификацию?',
    content: (
      <>
        <div>
          <p>
            Вы сможете просматривать платформу, но не сможете участвовать в сделках и делать
            предложения.
          </p>
        </div>
      </>
    ),
  },
];

export const faqDataSms = [
  {
    title: 'Как найти код в приложении Egov',
    content: (
      <>
        <div>
          <span>Откройте приложение Egov;</span>
          <p>
            <br />· Перейдите в раздел «Цифровые документы»;
            <br />· Найдите удостоверения личности - нажмите «Открыть»;
            <br />· Нажмите «Открыть доступ»;
            <br />· 6-значный код будет расположен сразу под QR-кодом.
          </p>
        </div>
      </>
    ),
  },
  {
    title: 'Как найти код в приложении Kaspi.kz / Halyk',
    content: (
      <>
        <div>
          <span>Откройте приложение банка</span>
          <p>
            · Откройте приложение банка;
            <br />· Перейдите в раздел «Госуслуги»;
            <br />· Перейдите в раздел «Цифровые документы»;
            <br />· Нажмите «Предъявить документ»;
            <br />· Ваш 6-значный код будет расположен сразу под QR-кодом.
          </p>
        </div>
      </>
    ),
  },
];
