import styled from 'styled-components';
import { Tooltip } from 'react-tooltip';

export const StyledTooltip = styled(Tooltip)`
  background: var(--primary-50, #dfe8fd) !important;
  color: var(--primary-400, #0f62fe) !important;
  text-align: center !important;
  border-radius: 4px !important;
  padding: 0px 4px !important;
  font-size: 10px !important;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  /* @media (max-width: 1824px) {
    max-width: 1200px;
  } */
`;

export default Container;
