import { NewButton } from 'components/common/Button';
import React from 'react';
import {
  Backdrop,
  ButtonRow,
  CloseButton,
  Features,
  FeaturesList,
  HeaderContainer,
  ModalContainer,
  Title,
} from './KycModal.style';
import ShieldIcon from 'assets/img/ShieldIcon.svg';
import IconPlus from 'assets/img/IconPlus.svg';
import BannerKyc from 'assets/img/BannerKyc.webp';
import { useNavigate } from 'react-router-dom';

interface KycModalProps {
  onClose: () => void;
}

export const KycModal: React.FC<KycModalProps> = ({ onClose }) => {
  const navigate = useNavigate();

  const handleKycClick = () => {
    navigate('/kyc');
    onClose(); // Закрываем модальное окно после перехода
  };
  return (
    <Backdrop>
      <ModalContainer>
        <HeaderContainer>
          <img src={ShieldIcon} alt='shieldIcon' />
          <div>
            <h4>Требуется верификация</h4>
            <p>
              Чтобы сделать предложение бизнесу, вам необходимо пройти верификацию личности (KYC).
              Это быстро, безопасно и необходимо для соблюдения требований безопасности платформы.
            </p>
          </div>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </HeaderContainer>
        <img src={BannerKyc} alt='bannerKyc' />
        <Title>
          Вы используете платформу в режиме просмотра. <br /> Вы можете изучать заявки и
          пользоваться аналитикой, но для подачи предложений бизнесам и полноценного использования
          всех функций платформы необходимо пройти <strong>верификацию личности (KYC)</strong>.
        </Title>
        <FeaturesList>
          <Features>
            <img src={IconPlus} alt='iconPlus' />
            <div>Подавать предложения бизнесам</div>
          </Features>
          <Features>
            <img src={IconPlus} alt='iconPlus' />
            <div>Участвовать в сделках</div>
          </Features>
          <Features>
            <img src={IconPlus} alt='iconPlus' />
            <div>Получить доступ к аналитике</div>
          </Features>
          <p>и многое другое.</p>
        </FeaturesList>
        <ButtonRow>
          <NewButton className='white' text='Назад' onClick={onClose} />
          <NewButton text='Пройти верификацию' onClick={handleKycClick} />
        </ButtonRow>
      </ModalContainer>
    </Backdrop>
  );
};
