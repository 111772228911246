import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
`;

const FirstBlock = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background: #fff;
`;

const BlockInput = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  button {
    margin-top: 30px;
  }
`;

const Header = styled.h2`
  color: var(--neutral-700, var(--neutral-700, #4c4c4c));
  font-family: 'IBM Plex Sans';
  font-size: 24px;
  font-weight: 500;
`;

const CalendarBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  padding: 4px 0px;

  label {
    color: var(--neutral-800, #303030);
    font-family: 'IBM Plex Sans';
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.32px;
  }
`;

const Calendar = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledDatePickerWrapper = styled.div<{ $hasContent: boolean }>`
  width: 100%;

  .react-datepicker__navigation {
    top: 10px;
    width: 24px;
    height: 24px;
    margin: 0 10px;
  }
  .react-datepicker__navigation--previous {
    z-index: 1;
  }

  .react-datepicker__navigation--next {
    z-index: 1;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-container {
    width: 100%;

    input {
      width: 100%;
      padding: 12px 16px;
      border-radius: 6px;
      background: var(--White, #fff);
      border: 1px solid var(--Control, #9aa4b0);
      color: var(--Control, #9aa4b0);
      font-size: 14px;
      line-height: 125%;
      font-weight: 400;

      &:focus {
        outline: none;
        border-color: #5784f7;
        color: #292d32;
      }

      ${({ $hasContent }) =>
        $hasContent &&
        css`
          color: var(--neutral-800, #303030);
          border: 1px solid var(--neutral-800, #303030);
        `}
    }
  }
`;

const DatePickerWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledInputWithIcon = styled.input<{ $hasContent: boolean }>`
  width: 100%;
  padding: 12px 40px 12px 16px; /* Adjust padding to make room for the icon */
  border-radius: 6px;
  background: var(--White, #fff);
  border: 1px solid var(--Control, #9aa4b0);
  color: var(--Control, #9aa4b0);
  font-size: 14px;
  line-height: 125%;
  font-weight: 400;

  &:focus {
    outline: none;
    border-color: #5784f7;
    color: #292d32;
  }

  ${({ $hasContent }) =>
    $hasContent &&
    css`
      color: var(--neutral-800, #303030);
      border: 1px solid var(--neutral-800, #303030);
    `}
`;

const StyledInput = styled.input<{ $hasContent: boolean }>`
  width: 100%;
  padding: 12px 16px;
  border-radius: 6px;
  background: var(--White, #fff);
  border: 1px solid var(--Control, #9aa4b0);
  color: var(--Control, #9aa4b0);
  font-size: 14px;
  line-height: 125%;
  font-weight: 400;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline: none;
    border-color: #5784f7;
    color: #292d32;
  }

  ${({ $hasContent }) =>
    $hasContent &&
    css`
      color: var(--neutral-800, #303030);
      border: 1px solid var(--neutral-800, #303030);
    `}
`;

const CalendarIconWrapper = styled.img`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  pointer-events: none; /* This ensures the icon doesn't block input clicks */
`;

const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
  label {
    color: var(--neutral-800, var(--neutral-800, #303030));
    font-family: 'IBM Plex Sans';
    font-size: 12px;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.32px;
  }
`;

const DropdownHeader = styled.div<{
  isOpen?: boolean;
  $hasContent?: boolean;
  $isDisabled?: boolean;
}>`
  margin-top: 5px;
  padding: 12px 16px;
  border: 1px solid var(--Control, #9aa4b0);
  border-radius: 6px;
  background: var(--White, #fff);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--Control, #9aa4b0);
  font-size: 14px;
  line-height: 125%;
  font-weight: 400;

  ${({ $hasContent }) =>
    $hasContent &&
    css`
      color: var(--neutral-800, #303030);
      border: 1px solid var(--neutral-800, #303030);
    `}

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `}
`;

const DropdownListContainer = styled.div`
  position: absolute;
  width: 100%;
  border: 1px solid var(--Control, #9aa4b0);
  border-radius: 6px;
  background: var(--White, #fff);
  z-index: 1000;
  margin-top: 4px;
  overflow-x: scroll;
  max-height: 170px;
`;

const DropdownListItem = styled.div<{ $isSelected?: any }>`
  padding: 12px;
  cursor: pointer;
  border-bottom: 1px solid var(--neutral-300, #bcbcbc);
  font-size: 14px;
  line-height: 125%;
  font-weight: 400;
  &:hover {
    background: var(--Light, #f5f5f5);
  }
  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background: var(--Light, #f5f5f5);
    `}
`;

const DropdownArrow = styled.div<{ isOpen: boolean }>`
  display: inline-block;
  margin-left: 8px;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: ${({ isOpen }) => (isOpen ? 'none' : '4px solid #A0A0A0')};
  border-bottom: ${({ isOpen }) => (isOpen ? '4px solid #A0A0A0' : 'none')};
`;

const AddAccountItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  background: #4f8df9;
  color: var(--neutral-50, #fefefe);
  font-family: 'IBM Plex Sans';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.16px;

  &:hover {
    background: #3e7ee6;
  }

  &::after {
    content: '+';
    font-size: 24px;
  }
`;

const BoxTag = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    color: var(--neutral-800, #303030);
    font-family: 'IBM Plex Sans';
    font-size: 12px;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.32px;
  }
`;

const Tag = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.32px;
  border-radius: 24px;
  cursor: pointer;
  padding: 8px 16px;
  transition:
    background-color 0.3s,
    color 0.3s;

  /* Базовый градиент для невырбранного (неактивного) состояния */
  background: linear-gradient(90deg, #637bfe 0%, #4db5ff 100%);
  color: #fff;
  border: 1px solid #57c2f7;

  /* Резервируем место под галочку псевдоэлементом, чтобы текст не сдвигался */
  &::before {
    content: ''; /* пустой контент */
    display: inline-block;
    width: 12px; /* ширина иконки */
    height: 9px; /* высота иконки */
    margin-right: 8px; /* отступ между иконкой и текстом */
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  /* Hover state */
  &:hover {
    opacity: 0.9; /* Пример */
  }

  /* Активное состояние (класс .activeGroup) */
  &.activeGroup {
    position: relative;
    background: linear-gradient(270deg, rgba(87, 194, 247, 0.25) 0%, rgba(87, 132, 247, 0.25) 100%);
    color: #5784f7;
    border: 1px solid #57c2f7;
    border-radius: 24px;
    background-origin: border-box;

    &::before {
      /* Показываем иконку галочки */
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='9' viewBox='0 0 12 9' fill='none'><path d='M1.33398 5.92578L3.66732 8.25911L10.6673 0.925781' stroke='%235784F7' stroke-linecap='round' stroke-linejoin='round'/></svg>");
    }
  }

  /* Дополнительные классы для «брендовых» тем */
  &.smartcash {
    background: var(--smartcash-bg-color, #fef600);
    color: var(--smartcash-text-color, #021033);
  }

  &.unimoney {
    background: var(--unimoney-bg-color, #01c0b0);
    color: var(--unimoney-text-color, #dfe8fd);
  }
`;

const Tags = styled.div`
  display: flex;
  gap: 8px;
`;

const SecondBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 16px;
  border-radius: 8px;
  background: #fff;
  width: 100%;
  label {
    color: var(--neutral-800, var(--neutral-800, #303030));
    font-family: 'IBM Plex Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.16px;
  }
  p {
    color: var(--neutral-500, var(--neutral-500, #848484));
    font-family: 'IBM Plex Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.16px;
  }
  div {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }
`;

const FileUploadArea = styled.div`
  border: 2px dashed #9aa4b0;
  width: 100%;
  padding: 10px;
  display: flex;
  height: 96px;
  color: #5784f7;
  cursor: pointer;
  position: relative;
`;

const FileUploadInput = styled.input`
  display: none;
`;

const UploadedFile = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  color: #f4f4f4;
  background-color: #262626;
  max-height: 32px;
  font-size: 12px;
`;

const FileDeleteButton = styled.button`
  background: none;
  border: none;
  color: #f4f4f4;
  cursor: pointer;
  font-size: 14px;
  margin-left: 8px;
`;

const LoadingIndicator = styled.div`
  margin-top: 10px;
  color: #5784f7;
  font-size: 14px;
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const FactoryBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-left: auto;
`;

export const BlockDocument = styled.div`
  display: flex;
  width: 100%;
`;

export const RequiredStar = styled.span`
  color: var(--functional-negative, var(--neutral-800, #fa4d56));
  font-family: 'IBM Plex Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.16px;
`;

export const FeeBLock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 16px;
  border-radius: 8px;
  background: #fff;
  width: 100%;
`;

export const FeeBoxList = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  justify-content: space-between;
`;

export const FeeBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  p {
    color: var(--neutral-800, #303030);
    font-size: 12px;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
  }
`;

export {
  Container,
  FirstBlock,
  BlockInput,
  Header,
  CalendarBox,
  Calendar,
  StyledDatePickerWrapper,
  DatePickerWrapper,
  StyledInputWithIcon,
  CalendarIconWrapper,
  DropdownContainer,
  DropdownHeader,
  DropdownListContainer,
  DropdownListItem,
  DropdownArrow,
  AddAccountItem,
  BoxTag,
  Tag,
  Tags,
  SecondBlock,
  FileUploadArea,
  FileUploadInput,
  UploadedFile,
  FileDeleteButton,
  LoadingIndicator,
  ButtonBox,
  StyledInput,
};
