import { Route, Routes, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import AdminScreen from 'screens/AdminScreen/AdminScreen';
import EnterSiteScreen from 'screens/EnterSiteScreen/EnterSiteScreen';
import Logo from 'assets/img/Logo.svg';
import { WarningContainer, WarningText, WarningTitle } from './App.style';

const BITRIX_SCRIPT_SRC = 'https://cdn-ru.bitrix24.kz/b32505112/crm/site_button/loader_1_x8o5wt.js';

const App = () => {
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 1025);
    }

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Explicitly declare the variable type so TypeScript can track it
    let scriptElement: HTMLScriptElement | null = null;

    if (!location.pathname.includes('login')) {
      // Add the script if on a non-login page
      scriptElement = document.createElement('script');
      scriptElement.src = `${BITRIX_SCRIPT_SRC}?${(Date.now() / 60000) | 0}`;
      scriptElement.async = true;
      document.body.appendChild(scriptElement);
    } else {
      // Remove the script if we are on login
      const oldScript = document.querySelector(`script[src^="${BITRIX_SCRIPT_SRC}"]`);
      if (oldScript) {
        oldScript.remove();
      }

      // Remove any chat widget DOM that Bitrix injected
      const chatWidget = document.querySelector('.bx-livechat-wrapper');
      if (chatWidget) {
        chatWidget.remove();
      }
    }

    // Cleanup on unmount or next re-render:
    return () => {
      if (scriptElement) {
        scriptElement.remove();
      }
    };
  }, [location.pathname]);

  if (isMobile) {
    return (
      <WarningContainer>
        <img src={Logo} alt='LogoCompany' />
        <WarningTitle>
          Для регистрации или входа на платформу необходим NCA Layer и ЭЦП
        </WarningTitle>
        <WarningText>
          На данный момент регистрация и вход на платформу требуют использования NCA Layer и
          электронной цифровой подписи (ЭЦП). Эти функции доступны только на ПК. Чтобы продолжить
          работу, пожалуйста, перейдите на стационарный компьютер или ноутбук. Если у вас уже есть
          учетная запись, войдите на платформу через ПК для полного доступа к функционалу.
        </WarningText>
      </WarningContainer>
    );
  }

  return (
    <div>
      <Routes>
        <Route path='/*' element={<AdminScreen />} />
        <Route path='login' element={<EnterSiteScreen />} />
      </Routes>
    </div>
  );
};

export default App;
