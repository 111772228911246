import React, { useEffect, useState } from 'react';
import axios from 'axios';
import IconBalance from 'assets/img/IconBalance.svg';
import { NewButton } from 'components/common/Button';
import {
  BalanceBox,
  BalanceRow,
  BoxPrice,
  ButtonBox,
  Icon,
  IconHeader,
  Line,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  PriceOneToken,
  Section,
  SectionBalanceNew,
  TokenOptions,
  TokenOptionsBox,
} from './ModalBuyingToken.style';
import Acquire from 'components/Acquire/Acquire';
import { useAppContext } from 'contexts/AppContext';
import { ErrorModal } from 'components/common/ErrorModal';

interface ModalBuyingTokenProps {
  isOpen: boolean;
  onClose: () => void;
}

interface Tariff {
  id: number;
  tokenCount: number;
  amount: number;
}

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/`;

const ModalBuyingToken: React.FC<ModalBuyingTokenProps> = ({ isOpen, onClose }) => {
  const { userDataLight } = useAppContext();

  const [selectedTokens, setSelectedTokens] = useState<number | null>(null);
  const [paymentData, setPaymentData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tariffs, setTariffs] = useState<Tariff[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (!isOpen) return;

    const fetchTariffs = async () => {
      try {
        const res = await axios.get<Tariff[]>(`${BASE_URL}api/v1/public/payment/tariffs`);
        setTariffs(res.data.sort((a, b) => a.tokenCount - b.tokenCount));
      } catch (err) {
        console.error('Ошибка при загрузке тарифов:', err);
        setErrorMessage('Не удалось загрузить тарифы. Попробуйте позже.');
      }
    };

    fetchTariffs();
  }, [isOpen]);

  const handleTokenSelection = (amount: number) => {
    setSelectedTokens(amount);
  };

  if (!isOpen) return null;

  const balance = userDataLight.balance;
  const selectedTariff = tariffs.find((t) => t.tokenCount === selectedTokens);
  const totalPrice = selectedTariff ? selectedTariff.amount : 0;
  const newBalance = selectedTokens ? balance + selectedTokens : balance;
  const pricePerToken = selectedTariff
    ? (selectedTariff.amount / selectedTariff.tokenCount).toFixed(2)
    : '0';

  const handlePay = async () => {
    if (!selectedTokens) return;

    try {
      setIsLoading(true);

      const res = await axios.get(`${BASE_URL}api/v1/ui/payment/pay?count=${selectedTokens}`, {
        withCredentials: true,
      });

      setPaymentData(res.data);
    } catch (err) {
      console.error('Ошибка при запросе на оплату:', err);
      setErrorMessage('Ошибка при оплате. Попробуйте снова.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {errorMessage && (
        <ErrorModal
          headerError='Ошибка'
          textError={errorMessage}
          buttonClose='Закрыть'
          onClick={() => setErrorMessage(null)}
        />
      )}

      <ModalOverlay>
        <ModalContent>
          <ModalHeader>
            <IconHeader src={IconBalance} alt='Balance Icon' />
            <div>
              <h2>Покупка токенов</h2>
              <p>
                Приобретайте токены для доступа к дополнительным услугам и расширенным возможностям
                платформы.
              </p>
            </div>
          </ModalHeader>

          <BalanceRow>
            <h3>Текущий баланс:</h3>
            <BalanceBox>
              <span>{balance}</span>
              <Icon src={IconBalance} alt='Balance Icon' />
            </BalanceBox>
          </BalanceRow>
          <Line />

          <Section>
            <h3>Выберите количество токенов</h3>
            <TokenOptions>
              {tariffs.map((tariff) => (
                <TokenOptionsBox
                  key={tariff.id}
                  className={selectedTokens === tariff.tokenCount ? 'selected' : ''}
                  onClick={() => handleTokenSelection(tariff.tokenCount)}>
                  <BoxPrice>
                    <span className={selectedTokens === tariff.tokenCount ? 'selected' : ''}>
                      {tariff.tokenCount}
                    </span>
                    <Icon src={IconBalance} alt='Balance Icon' />
                  </BoxPrice>
                  <p>{tariff.amount.toLocaleString()} ₸</p>
                </TokenOptionsBox>
              ))}
            </TokenOptions>
            <PriceOneToken>
              <Icon src={IconBalance} alt='Balance Icon' />
              Стоимость 1 токена – {pricePerToken} ₸
            </PriceOneToken>
          </Section>

          <SectionBalanceNew>
            <BalanceRow>
              <h3>Ваш новый баланс после покупки:</h3>
              <BalanceBox>
                <span>{newBalance.toFixed(2)}</span>
                <Icon src={IconBalance} alt='Balance Icon' />
              </BalanceBox>
            </BalanceRow>

            <BalanceRow>
              <h3>Сумма к оплате:</h3>
              <BalanceBox>
                <span>{totalPrice.toLocaleString()} ₸</span>
              </BalanceBox>
            </BalanceRow>
          </SectionBalanceNew>

          <ButtonBox>
            <NewButton text='Отмена' onClick={onClose} />
            <NewButton
              text={isLoading ? 'Загрузка...' : 'Перейти к оплате'}
              onClick={handlePay}
              disabled={!selectedTokens || isLoading}
              loading={isLoading}
            />
          </ButtonBox>

          {!isLoading && paymentData && (
            <>
              <Acquire paymentData={paymentData} />
            </>
          )}
        </ModalContent>
      </ModalOverlay>
    </>
  );
};

export default ModalBuyingToken;
