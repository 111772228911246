import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Container,
  FirstBlock,
  BlockInput,
  Header,
  CalendarBox,
  Calendar,
  StyledDatePickerWrapper,
  DatePickerWrapper,
  StyledInputWithIcon,
  StyledInput,
  CalendarIconWrapper,
  DropdownContainer,
  DropdownHeader,
  DropdownListContainer,
  DropdownListItem,
  DropdownArrow,
  AddAccountItem,
  BoxTag,
  Tag,
  Tags,
  SecondBlock,
  FileUploadArea,
  UploadedFile,
  FileDeleteButton,
  FileUploadInput,
  LoadingIndicator,
  ButtonBox,
  FactoryBox,
  BlockDocument,
  RequiredStar,
  FeeBoxList,
  FeeBLock,
  FeeBox,
} from './TwoStep.style';
import { InputText } from 'components/common/Input/InputText';
import { default as CalendarIcons } from 'assets/img/CalendarIcon.svg';
import { default as ArrowRight } from 'assets/img/ArrowRight.svg';
import { default as ArrowLeft } from 'assets/img/ArrowLeft.svg';
import { ru } from 'date-fns/locale';
import { useAppContext } from 'contexts/AppContext';
import { NewButton } from 'components/common/Button';
import {
  Account,
  CollateralOption,
  Contract,
  KBE,
  TwoStepProps,
  WhiteLabelOption,
} from './NewCreateApplication.interface';
import { ErrorModal } from 'components/common/ErrorModal';
import { Checkbox } from 'components/common/Checkbox';
import PromptWindow from 'components/PromptWindow/PromptWindow';

const formatNumberWithSpaces = (number: string | number) => {
  return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const removeSpaces = (number: string) => {
  return number.replace(/\s+/g, '');
};

const regexPercent = /^[0-9]*\.?[0-9]{0,2}$/;

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;

const TwoStep: React.FC<TwoStepProps> = ({
  setCurrentStep,
  selectedProductCode,
  sum,
  setSum,
  days,
  setDays,
  minSum,
  setMinSum,
  percent,
  setPercent,
  setSelectAccountNum,
  selectedGroups,
  setSelectedGroups,
  uploadedFilesOther,
  setUploadedFilesOther,
  selectedContract,
  setSelectedContract,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  selectedCollateral,
  setSelectedCollateral,
  setDocLink,
  docLink,
  uploadedFilesBank,
  setUploadedFilesBank,
}) => {
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
  const [isAccountDropdownOpen, setIsAccountDropdownOpen] = useState(false);
  const [isKbeDropdownOpen, setIsKbeDropdownOpen] = useState(false);
  const [isAddingNewAccount, setIsAddingNewAccount] = useState(false);
  const [accountNumber, setAccountNumber] = useState('');
  const [kbeOptions, setKbeOptions] = useState<KBE[]>([]);
  const [selectedKbe, setSelectedKbe] = useState<KBE | null>(null);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);
  const [isLoadingOtherFiles, setIsLoadingOtherFiles] = useState(false);
  const [isLoadingBankFiles, setIsLoadingBankFiles] = useState(false);
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [isContractDropdownOpen, setIsContractDropdownOpen] = useState(false);
  const [sumError, setSumError] = useState<string | null>(null);
  const [minSumError, setMinSumError] = useState<string | null>(null);
  const { userDataLight } = useAppContext();
  const accountDropdownRef = useRef<HTMLDivElement>(null);
  const kbeDropdownRef = useRef<HTMLDivElement>(null);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isCollateralChecked, setIsCollateralChecked] = useState(false);
  const [collateralOptions, setCollateralOptions] = useState<CollateralOption[]>([]);
  const [isCollateralDropdownOpen, setIsCollateralDropdownOpen] = useState(false);
  const collateralDropdownRef = useRef(null);
  const [errorLink, setErrorLink] = useState<string | null>(null);

  const [availableGroups, setAvailableGroups] = useState<any[]>([]);

  const isUserRole = userDataLight?.roles.includes('MANAGER');

  // Запрос списка групп
  useEffect(() => {
    axios
      .get(`${BASE_URL}ui/group/list`, { withCredentials: true })
      .then((response) => {
        setAvailableGroups(response.data);
      })
      .catch((err) => {
        console.error('Error fetching groups:', err);
      });
  }, []);

  // Запрос списка счетов
  useEffect(() => {
    axios
      .get(`${BASE_URL}ui/accounts`, { withCredentials: true })
      .then((response) => setAccounts(response.data))
      .catch((error) => {
        console.error('Error fetching accounts:', error);
        setError('Error fetching accounts');
      });
  }, []);

  // Запрос списка залогов, если есть чекбокс «Имеется залог»
  useEffect(() => {
    if (isCollateralChecked) {
      axios
        .get(`${BASE_URL}dictionary/get?name=collateral`, {
          withCredentials: true,
        })
        .then((response) => setCollateralOptions(response.data))
        .catch((error) => {
          console.error('Error fetching collateral options:', error);
          setError('Error fetching collateral options');
        });
    } else {
      setSelectedCollateral(null);
    }
  }, [isCollateralChecked]);

  // Логика автокалькуляции даты окончания, исходя из количества дней
  useEffect(() => {
    if (days && !isNaN(Number(days))) {
      const newEndDate = new Date(startDate);
      newEndDate.setDate(newEndDate.getDate() + Number(days));
      setEndDate(newEndDate);
    } else {
      setEndDate(null);
    }
  }, [startDate, days]);

  // Логика пересчёта кол-ва дней, если вручную выбрана endDate
  useEffect(() => {
    if (endDate) {
      const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      setDays(diffDays.toString());
    }
  }, [endDate, startDate]);

  // Запрос списка KBE, если пользователь добавляет новый счёт
  useEffect(() => {
    if (isAddingNewAccount) {
      axios
        .get(`${BASE_URL}dictionary/get?name=kbe`, {
          withCredentials: true,
        })
        .then((response) => setKbeOptions(response.data))
        .catch((error) => {
          console.error('Error fetching KBE options:', error);
          setError('Error fetching KBE options');
        });
    }
  }, [isAddingNewAccount]);

  // Слушатель клика вне выпадающих списков
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        accountDropdownRef.current &&
        !accountDropdownRef.current.contains(event.target as Node)
      ) {
        setIsAccountDropdownOpen(false);
      }
      if (kbeDropdownRef.current && !kbeDropdownRef.current.contains(event.target as Node)) {
        setIsKbeDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Запрос контрактов, если выбрана категория TENDER
  useEffect(() => {
    if (selectedProductCode === 'TENDER') {
      axios
        .get(`${BASE_URL}ui/contract/list/egz`, {
          withCredentials: true,
        })
        .then((response) => setContracts(response.data))
        .catch((error) => {
          console.error('Error fetching contracts:', error);
          setError('Error fetching contracts');
        });
    }
  }, [selectedProductCode]);

  // Логика валидации: если выбран контракт (TENDER), проверяем сумму и мин. сумму
  useEffect(() => {
    if (selectedContract) {
      validateSum(sum);
      validateMinSum(minSum);
    }
  }, [selectedContract]);

  // Управляем кнопкой «Добавить» счёт
  useEffect(() => {
    setIsAddButtonDisabled(!(selectedKbe && accountNumber.length === 20));
  }, [selectedKbe, accountNumber]);

  // Установка selectedGroup, если в userDataLight есть ровно одна группа ALL
  useEffect(() => {
    if (userDataLight.whiteLabel.length === 1) {
      setSelectedGroups([{ code: userDataLight.whiteLabel[0].code }]);
    }
  }, [userDataLight.whiteLabel]);

  useEffect(() => {
    validateSum(sum); // Проверяем, не превысила ли сумма сумму контракта (для TENDER)
    validateMinSum(minSum); // Проверяем, не превышает ли minSum основную сумму
  }, [sum, minSum, selectedContract]);

  // Валидация суммы
  const validateSum = (sumValue: string) => {
    const value = Number(removeSpaces(sumValue));
    if (selectedProductCode === 'TENDER' && selectedContract) {
      if (value > selectedContract.contractSum) {
        setSumError('Сумма займа не может превышать сумму контракта');
      } else {
        setSumError(null);
      }
    } else {
      setSumError(null);
    }
  };

  // Валидация минимальной суммы
  const validateMinSum = (minSumValue: string) => {
    const minValue = Number(removeSpaces(minSumValue));
    const sumValue = Number(removeSpaces(sum));
    if (selectedProductCode === 'TENDER' && selectedContract) {
      if (minValue > sumValue || minValue > selectedContract.contractSum) {
        setMinSumError(
          'Минимальный порог для входа инвестора не может превышать сумму займа и сумму контракта',
        );
      } else {
        setMinSumError(null);
      }
    } else {
      if (minValue > sumValue) {
        setMinSumError('Минимальный порог для входа инвестора не должен превышать сумму займа');
      } else {
        setMinSumError(null);
      }
    }
  };

  // Обработчики изменения суммы/мин суммы/процентов/дней
  const handleSumChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = removeSpaces(e.target.value);
    if (!isNaN(Number(value))) {
      setSum(value);
    }
  };

  const handleMinSumChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = removeSpaces(e.target.value);
    if (!isNaN(Number(value))) {
      setMinSum(value);
    }
  };

  const handlePercentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (regexPercent.test(value)) {
      setPercent(value);
    }
  };
  const handleDaysChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDays(e.target.value);
  };

  // Обработчик выбора счёта
  const handleAccountSelect = (account: Account) => {
    setSelectedAccount(account);
    setIsAccountDropdownOpen(false);
    setSelectAccountNum(account.id.toString());
  };
  const toggleAccountDropdown = () => {
    setIsAccountDropdownOpen(!isAccountDropdownOpen);
    if (!isAccountDropdownOpen) {
      setIsKbeDropdownOpen(false);
    }
  };

  // Обработчик выбора контракта
  const handleContractSelect = (contract: Contract) => {
    setSelectedContract(contract);
    setIsContractDropdownOpen(false);
    validateSum(sum);
    validateMinSum(minSum);
  };
  const toggleContractDropdown = () => {
    setIsContractDropdownOpen(!isContractDropdownOpen);
  };

  // Обработчик добавления нового счёта
  const handleAddNewAccount = () => {
    setIsAddingNewAccount(true);
    setIsAccountDropdownOpen(false);
  };
  const toggleKbeDropdown = () => {
    setIsKbeDropdownOpen(!isKbeDropdownOpen);
    if (!isKbeDropdownOpen) {
      setIsAccountDropdownOpen(false);
    }
  };

  // Реальный вызов API для добавления счёта
  const addAccount = async (kbe: number | null, iban: string) => {
    try {
      const response = await axios.post(
        `${BASE_URL}ui/accounts/add`,
        {
          kbe,
          iban,
        },
        { withCredentials: true },
      );
      return response.data;
    } catch (error) {
      console.error('Error adding account:', error);
      setError('Error adding account');
      return null;
    }
  };

  // Кнопка «Добавить» счёт
  const handleAddAccount = async () => {
    if (selectedKbe && accountNumber) {
      const newAccount = await addAccount(selectedKbe.id, accountNumber);
      if (newAccount) {
        setAccounts((prevAccounts) => [...prevAccounts, newAccount]);
        setSelectedAccount(newAccount);
        setSelectAccountNum(newAccount.id.toString());
        setIsAddingNewAccount(false);
      }
    }
  };

  // Чекбокс «Имеется залог»
  const handleCollateralCheckboxChange = () => {
    setIsCollateralChecked(!isCollateralChecked);
    if (!isCollateralChecked) {
      setSelectedCollateral(null);
    }
  };
  // Выбор вида залога
  const handleCollateralSelect = (collateral: any) => {
    setSelectedCollateral(collateral);
    setIsCollateralDropdownOpen(false);
  };
  const toggleCollateralDropdown = () => {
    if (collateralOptions.length > 0) {
      setIsCollateralDropdownOpen(!isCollateralDropdownOpen);
    }
  };

  // Обработчик ссылки Google Drive
  const validateGoogleDriveLink = (link: string) => {
    const googleDrivePattern = /google\.com\/drive\/folders\//;
    return googleDrivePattern.test(link);
  };
  const handleGoogleDriveLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const link = e.target.value.trim();
    if (!link || validateGoogleDriveLink(link)) {
      setDocLink(link);
      setErrorLink(null);
    } else {
      setErrorLink('Неправильный формат ссылки.');
    }
  };

  // Обработчики дропа файлов
  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    files.forEach((file) => handleFileUpload(file));
  };
  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };
  const handleFileUpload = async (file: File) => {
    try {
      setIsLoadingOtherFiles(true);
      const formData = new FormData();
      formData.append('file', file);
      const response = await axios.post(`${BASE_URL}ui/file`, formData, {
        withCredentials: true,
      });
      const { uid } = response.data;
      setUploadedFilesOther((prevFiles) => [...prevFiles, { name: file.name, uid }]);
    } catch (error) {
      console.error('Ошибка при добавление файла', error);
      setError('Ошибка при добавление файла');
    } finally {
      setIsLoadingOtherFiles(false);
    }
  };
  // Банковские выписки
  const onDropBankFiles = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    files.forEach((file) => handleBankFileUpload(file));
  };
  const handleBankFileUpload = async (file: File) => {
    try {
      setIsLoadingBankFiles(true);
      const formData = new FormData();
      formData.append('file', file);
      const response = await axios.post(`${BASE_URL}ui/file`, formData, {
        withCredentials: true,
      });
      const { uid } = response.data;
      setUploadedFilesBank((prevFiles) => [
        ...prevFiles,
        { name: file.name, uid, code: 'BANK_STATEMENT' },
      ]);
    } catch (error) {
      console.error('Ошибка при загрузке банковской выписки', error);
      setError('Ошибка при загрузке файла');
    } finally {
      setIsLoadingBankFiles(false);
    }
  };

  const handleKbeSelect = (kbe: KBE) => {
    setSelectedKbe(kbe);
    setIsKbeDropdownOpen(false);
  };

  const handleFileDelete = (uid: string) => {
    setUploadedFilesOther((prevFiles) => prevFiles.filter((file) => file.uid !== uid));
  };
  const handleBankFileDelete = (uid: string) => {
    setUploadedFilesBank((prevFiles) => prevFiles.filter((file) => file.uid !== uid));
  };

  const commonGroups = availableGroups.filter((g) => g.group === 'ALL');
  const privateGroups = availableGroups.filter((g) => g.group !== 'ALL');

  const totalCommon = commonGroups.reduce((acc, g) => acc + (g.total || 0), 0);
  const totalPrivate = privateGroups.reduce((acc, g) => acc + (g.total || 0), 0);

  // Логика выбора группы (можно только одну категорию)
  // Стало:
  const handleGroupSelect = (optionCode: string, isCommon: boolean) => {
    let newSelectedGroups = [...selectedGroups];

    if (isCommon) {
      // Если выбираем «общую» (ALL), то убираем все «частные»
      newSelectedGroups = newSelectedGroups.filter((sel) => {
        const grp = availableGroups.find((g) => g.group === sel.code);
        return grp?.group === 'ALL';
      });
    } else {
      // Если выбираем «частную», то убираем все «общие» (ALL)
      newSelectedGroups = newSelectedGroups.filter((sel) => {
        const grp = availableGroups.find((g) => g.group === sel.code);
        return grp?.group !== 'ALL';
      });
    }

    // Далее — стандартная логика: либо убираем, либо добавляем выбранную группу
    const index = newSelectedGroups.findIndex((group) => group.code === optionCode);
    if (index !== -1) {
      newSelectedGroups.splice(index, 1);
    } else {
      newSelectedGroups.push({ code: optionCode });
    }

    setSelectedGroups(newSelectedGroups);
  };

  // Блок комиссии показываем всегда
  const showGroupCommissionBlock = true;

  // Проверяем, выбраны ли только группы с подпиской
  const selectedAnySubscription = selectedGroups.some((selGroup) => {
    const grp = availableGroups.find((g) => g.group === selGroup.code);
    return grp?.isSubscription === true;
  });

  // Определяем комиссию платформы (если только подписка - 0)
  const platformFee = userDataLight?.fee || 0;
  let platformFeeToShow = platformFee;
  if (selectedAnySubscription) {
    platformFeeToShow = 0;
  }

  // Определяем максимальную комиссию среди групп без подписки
  const nonSubscriptionGroups = selectedGroups
    .map((selGroup) => availableGroups.find((g) => g.group === selGroup.code))
    .filter((g) => g && g.isSubscription === false);

  const groupFeeMax =
    nonSubscriptionGroups.length > 0
      ? Math.max(...nonSubscriptionGroups.map((g) => g.fee || 0))
      : 0;

  const sumNumber = Number(removeSpaces(sum)) || 0;
  const totalFeePercent = platformFeeToShow + groupFeeMax;
  const publishedSum = sumNumber + sumNumber * (totalFeePercent / 100);

  // Проверяем, заполнены ли все поля для перехода далее
  useEffect(() => {
    const isComplete =
      sum &&
      minSum &&
      percent &&
      days &&
      selectedAccount &&
      (!selectedProductCode || selectedProductCode !== 'TENDER' || selectedContract) &&
      (userDataLight.whiteLabel.length === 1 || selectedGroups.length > 0) &&
      !sumError &&
      !minSumError &&
      (!isCollateralChecked || (isCollateralChecked && selectedCollateral)) &&
      (selectedProductCode !== 'LOAN' || uploadedFilesBank.length > 0);

    setIsFormComplete(isComplete);
  }, [
    sum,
    minSum,
    percent,
    days,
    selectedAccount,
    selectedProductCode,
    selectedContract,
    selectedGroups,
    userDataLight.whiteLabel,
    sumError,
    minSumError,
    isCollateralChecked,
    selectedCollateral,
    uploadedFilesBank,
  ]);

  const closeModal = () => {
    setError(null);
  };

  const hasContent = days.trim() !== '';

  return (
    <Container>
      <FirstBlock>
        <Header>Основная информация</Header>
        <BlockInput>
          <InputText
            title='Какой объем средств вам нужен?'
            placeholder='20 000 000'
            value={formatNumberWithSpaces(sum)}
            onChange={handleSumChange}
            type='text'
            error={sumError}
          />
          <InputText
            title='Минимальный порог для входа инвестора'
            placeholder='0'
            value={formatNumberWithSpaces(minSum)}
            onChange={handleMinSumChange}
            type='text'
            error={minSumError}
          />
        </BlockInput>
        <BlockInput>
          <InputText
            title='Процентная ставка в месяц'
            placeholder='20 %'
            value={percent}
            onChange={handlePercentChange}
            type='text'
          />
          <CalendarBox>
            <label>На какой срок вам нужны средства (дней)</label>
            <Calendar>
              <DatePickerWrapper>
                <StyledDatePickerWrapper $hasContent={hasContent}>
                  <DatePicker
                    selected={endDate}
                    onChange={(date: Date) => setEndDate(date)}
                    dateFormat='dd.MM.yyyy'
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    locale={ru}
                    customInput={<StyledInputWithIcon $hasContent={hasContent} />}
                  />
                </StyledDatePickerWrapper>
                <CalendarIconWrapper src={CalendarIcons} alt='icon-calendar' />
              </DatePickerWrapper>
              <StyledInput
                type='number'
                value={days}
                onChange={handleDaysChange}
                placeholder='Кол-во дней'
                $hasContent={hasContent}
              />
            </Calendar>
          </CalendarBox>
        </BlockInput>

        {/* Если productCode = LOAN, показываем блок залога */}
        {selectedProductCode === 'LOAN' && (
          <BlockInput>
            <FactoryBox>
              <Checkbox checked={isCollateralChecked} onChange={handleCollateralCheckboxChange} />
              <label>Имеется залог</label>
            </FactoryBox>
            {isCollateralChecked && (
              <DropdownContainer ref={collateralDropdownRef}>
                <label>Вид залога</label>
                <DropdownHeader
                  onClick={toggleCollateralDropdown}
                  isOpen={isCollateralDropdownOpen}
                  $hasContent={!!selectedCollateral}
                  $isDisabled={collateralOptions.length === 0}>
                  {selectedCollateral ? selectedCollateral.name : 'Выбрать вид залога'}
                  <DropdownArrow isOpen={isCollateralDropdownOpen} />
                </DropdownHeader>
                {isCollateralDropdownOpen && (
                  <DropdownListContainer>
                    {collateralOptions.map((collateral) => (
                      <DropdownListItem
                        key={collateral.id}
                        onClick={() => handleCollateralSelect(collateral)}>
                        {collateral.name}
                      </DropdownListItem>
                    ))}
                  </DropdownListContainer>
                )}
              </DropdownContainer>
            )}
          </BlockInput>
        )}

        {/* Список счетов + выбор */}
        <BlockInput>
          <DropdownContainer ref={accountDropdownRef}>
            <label>Выбрать счет</label>
            <DropdownHeader
              onClick={toggleAccountDropdown}
              isOpen={isAccountDropdownOpen}
              $hasContent={!!selectedAccount}
              $isDisabled={accounts.length === 0}>
              {selectedAccount ? selectedAccount.iban : 'Выбрать счет'}
              <DropdownArrow isOpen={isAccountDropdownOpen} />
            </DropdownHeader>
            {isAccountDropdownOpen && (
              <DropdownListContainer>
                {accounts.map((account) => (
                  <DropdownListItem key={account.id} onClick={() => handleAccountSelect(account)}>
                    {account.iban}
                  </DropdownListItem>
                ))}
                {isUserRole && (
                  <AddAccountItem onClick={handleAddNewAccount}>Добавить новый счет</AddAccountItem>
                )}
              </DropdownListContainer>
            )}
          </DropdownContainer>
          {selectedProductCode === 'TENDER' && (
            <DropdownContainer>
              <label>Выбрать контракт</label>
              <DropdownHeader
                onClick={toggleContractDropdown}
                isOpen={isContractDropdownOpen}
                $hasContent={!!selectedContract}
                $isDisabled={contracts.length === 0}>
                {selectedContract
                  ? `${selectedContract.contractNumberSys} ( сумма договора ${formatNumberWithSpaces(
                      selectedContract.contractSum.toString(),
                    )} тенге )`
                  : 'Выбрать контракт'}
                <DropdownArrow isOpen={isContractDropdownOpen} />
              </DropdownHeader>
              {isContractDropdownOpen && (
                <DropdownListContainer>
                  {contracts.map((contract) => (
                    <DropdownListItem
                      key={contract.id}
                      onClick={() => handleContractSelect(contract)}>
                      {`${contract.contractNumberSys} ( сумма договора ${formatNumberWithSpaces(
                        contract.contractSum.toString(),
                      )} тенге )`}
                    </DropdownListItem>
                  ))}
                </DropdownListContainer>
              )}
            </DropdownContainer>
          )}
        </BlockInput>

        {/* Добавление нового счёта */}
        {isAddingNewAccount && (
          <BlockInput>
            <InputText
              title='Номер счета'
              placeholder='Введите номер счета (20 чисел)'
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
            />
            <DropdownContainer ref={kbeDropdownRef}>
              <label>Выбрать KBE</label>
              <DropdownHeader
                onClick={toggleKbeDropdown}
                isOpen={isKbeDropdownOpen}
                $hasContent={!!selectedKbe}
                $isDisabled={kbeOptions.length === 0}>
                {selectedKbe ? selectedKbe.name : 'Выбрать KBE'}
                <DropdownArrow isOpen={isKbeDropdownOpen} />
              </DropdownHeader>
              {isKbeDropdownOpen && (
                <DropdownListContainer>
                  {kbeOptions.map((kbe) => (
                    <DropdownListItem key={kbe.id} onClick={() => handleKbeSelect(kbe)}>
                      {kbe.name}
                    </DropdownListItem>
                  ))}
                </DropdownListContainer>
              )}
            </DropdownContainer>
            <NewButton text='Добавить' onClick={handleAddAccount} disabled={isAddButtonDisabled} />
          </BlockInput>
        )}

        {/* Разделение групп по подписке, + вывод total */}
        <BoxTag>
          <p style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            Общие группы ({formatNumberWithSpaces(totalCommon)} инвесторов )
            <PromptWindow description='Группы с комиссией взымают дополнительную комиссию, предоставляя вам доступ к пулу инвесторов, которые не увидят вашу заявку на финансирование в других группах.' />
          </p>
          <Tags>
            {commonGroups.map((option) => {
              const isSelected = selectedGroups.some((group) => group.code === option.group);
              return (
                <Tag
                  key={option.group}
                  className={isSelected ? 'activeGroup' : 'inactiveGroup'}
                  onClick={() => handleGroupSelect(option.group, true)}>
                  {option.name}
                </Tag>
              );
            })}
          </Tags>
        </BoxTag>

        <BoxTag>
          <p style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            Частные группы ({formatNumberWithSpaces(totalPrivate)} инвесторов )
            <PromptWindow description='Группы с комиссией взымают дополнительную комиссию, предоставляя вам доступ к пулу инвесторов, которые не увидят вашу заявку на финансирование в других группах.' />
          </p>
          <Tags>
            {privateGroups.map((option) => {
              const isSelected = selectedGroups.some((group) => group.code === option.group);
              return (
                <Tag
                  key={option.group}
                  className={isSelected ? 'activeGroup' : 'inactiveGroup'}
                  onClick={() => handleGroupSelect(option.group, false)}>
                  {option.name}
                </Tag>
              );
            })}
          </Tags>
        </BoxTag>
      </FirstBlock>

      {showGroupCommissionBlock && (
        <FeeBLock>
          <Header>Комиссия платформы</Header>
          <FeeBoxList>
            <FeeBox>
              <InputText
                title='Комиссия платформы'
                value={`${platformFeeToShow}%`}
                disabled={true}
                readOnly={true}
              />
              <p>Процент комиссии, который взимается платформой за предоставление услуг.</p>
            </FeeBox>
            <FeeBox>
              <InputText
                title='Комиссия группы'
                value={`${groupFeeMax}%`}
                disabled={true}
                readOnly={true}
              />
              <p>Процент комиссии, взимаемой группой (брокером), если таковая существует.</p>
            </FeeBox>
            <FeeBox>
              <InputText
                title='Публикуемая сумма'
                value={`${formatNumberWithSpaces(publishedSum.toFixed(0))} ₸`}
                disabled={true}
                readOnly={true}
              />
              <p>Итоговая сумма заявки, которая будет видна инвесторам на платформе.</p>
            </FeeBox>
          </FeeBoxList>
        </FeeBLock>
      )}

      <SecondBlock>
        <InputText
          title='Ссылка на гугл папку'
          placeholder='google.com/drive/folders/'
          value={docLink}
          onChange={handleGoogleDriveLinkChange}
          type='text'
          error={errorLink}
        />
        {selectedProductCode === 'LOAN' && (
          <>
            <label>
              Банковские выписки <RequiredStar>*</RequiredStar>
            </label>
            <p>Пожалуйста приложите документы банковской выписки</p>
            <p>
              Принимаем выписки только юридических лиц Bcc, Jusan, Kaspipay. Поддерживаемые типы
              файлов: (.pdf).
            </p>
            <BlockDocument>
              <FileUploadArea
                onDrop={onDropBankFiles}
                onDragOver={onDragOver}
                onClick={() => document.getElementById('bankFileInput')?.click()}>
                Перетащите файлы сюда или загрузите
                <FileUploadInput
                  id='bankFileInput'
                  type='file'
                  accept='.pdf'
                  onChange={(e) => {
                    if (e.target.files) handleBankFileUpload(e.target.files[0]);
                  }}
                />
              </FileUploadArea>
              {isLoadingBankFiles && <LoadingIndicator>Загрузка...</LoadingIndicator>}
              <div>
                {uploadedFilesBank.map((file) => (
                  <UploadedFile key={file.uid}>
                    {file.name}
                    <FileDeleteButton onClick={() => handleBankFileDelete(file.uid)}>
                      x
                    </FileDeleteButton>
                  </UploadedFile>
                ))}
              </div>
            </BlockDocument>
          </>
        )}
        <label>Прочие документы по проекту</label>
        <p>Пожалуйста приложите нужные документы ( финансовая модель, баланс )</p>
        <p>
          Максимальный размер файла 5 МБ. Поддерживаемые типы файлов:
          (.doc,.docx,.pdf,.ppt,.pptx,.xls,.xlsx)
        </p>
        <BlockDocument>
          <FileUploadArea
            onDrop={onDrop}
            onDragOver={onDragOver}
            onClick={() => document.getElementById('fileInput')?.click()}>
            Перетащите файлы сюда или загрузите
            <FileUploadInput
              id='fileInput'
              type='file'
              accept='.doc,.docx,.pdf,.ppt,.pptx,.xls,.xlsx'
              onChange={(e) => {
                if (e.target.files) handleFileUpload(e.target.files[0]);
              }}
            />
          </FileUploadArea>
          {isLoadingOtherFiles && <LoadingIndicator>Загрузка...</LoadingIndicator>}
          <div>
            {uploadedFilesOther.map((file) => (
              <UploadedFile key={file.uid}>
                {file.name}
                <FileDeleteButton onClick={() => handleFileDelete(file.uid)}>x</FileDeleteButton>
              </UploadedFile>
            ))}
          </div>
        </BlockDocument>
        <ButtonBox>
          <NewButton
            text=''
            icon={ArrowLeft}
            onClick={() => setCurrentStep((prevStep) => prevStep - 1)}
          />
          <NewButton
            text='Далее'
            icon={ArrowRight}
            onClick={() => setCurrentStep((prevStep) => prevStep + 1)}
            disabled={!isFormComplete}
          />
        </ButtonBox>
      </SecondBlock>
      {error && (
        <ErrorModal
          headerError='Ошибка'
          textError={error}
          buttonClose='Закрыть'
          onClick={closeModal}
        />
      )}
    </Container>
  );
};

export default TwoStep;
