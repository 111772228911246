import { useParams } from 'react-router-dom';
import { useAppContext } from 'contexts/AppContext';
import { ApplicationInvestorNew } from 'components/ApplicationInvestor';
import { RequestDetailsNew } from 'components/RequestDetailsNew';
import { OffersBusinessList } from 'components/OffersBusinessList';
import { NotFound404 } from 'components/NotFoundPage';

const RequestScreen = () => {
  const { userDataLight } = useAppContext();
  const { id, tab } = useParams(); // id, tab, subTab, и т.д.

  if (userDataLight?.organizationRole === 'BUSINESS') {
    if (tab === 'offers') {
      return <OffersBusinessList />;
    }
    // Иначе показываем детали по умолчанию
    return <RequestDetailsNew />;
  }

  if (userDataLight?.organizationRole === 'INVESTOR') {
    // Для инвестора — своя логика
    return <ApplicationInvestorNew />;
  }

  // Если роль другая — 404
  return <NotFound404 />;
};

export default RequestScreen;
