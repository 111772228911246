import styled, { css } from 'styled-components';
import colors from 'global_styling/colors';

interface InputProps {
  $hasError?: boolean;
}

const Input = styled.input<InputProps>`
  width: 100%;
  padding: 12px 16px;
  background: var(--White, #fff);
  border: 1px solid var(--Control, #9aa4b0);
  color: var(--Control, #9aa4b0);
  font-size: 14px;
  line-height: 125%;
  font-weight: 400;
  padding-left: 40px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px 0px rgba(7, 17, 43, 0.07);
  &:focus {
    outline: none; /* Убираем стандартный фокус */
    border: 1px solid #5784f7;
    color: #292d32;
  }

  &:not(:placeholder-shown) {
    border: 1px solid #5784f7;
    color: #292d32;
  }

  ${({ $hasError: hasError }) =>
    hasError
      ? css`
          border: 1px solid ${colors.WARNING};
        `
      : ''}
`;

const ErrorMessage = styled.span`
  color: ${colors.WARNING};
  font-size: 12px;
  font-weight: 400;
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  padding: 4px 0px;
  position: relative;
  display: inline-block;

  .search-icon {
    position: absolute;
    left: 10px; /* Расположение по горизонтали */
    top: 50%;
    transform: translateY(-50%); /* Центрирование по вертикали */
    pointer-events: none; /* Чтобы иконка не мешала вводу текста */
  }
`;

export { InputBox, Input, ErrorMessage };
