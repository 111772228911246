import styled from 'styled-components';

const TitleBox = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 40px;
  h2 {
    color: #292929;
    font-size: 28px;
    font-weight: 600;
    line-height: 106%;
    font-family: 'IBM Plex Sans', sans-serif;
    max-width: 478px;
    a {
      color: var(--Primary, #5784f7);
    }
  }
`;

export const Container = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  background: #f4f4f4;
`;

export const LinkContract = styled.a`
  text-decoration: none;
  color: var(--primary-300, #5784f7);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px; /* 150% */
`;

export const DeclineSuggestion = styled.p`
  text-decoration: none;
  color: var(--primary-300, #fa4d56);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px; /* 150% */
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

export const Modal = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 460px;
  gap: 20px;
  p {
    font-size: 16px;
  }
`;

export const ModalHeader = styled.h2`
  font-size: 18px;
  color: var(--Black-Main, #292d32);
  font-weight: 600;
  line-height: 20px;
`;

export const ModalActions = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 10px;
`;

export { TitleBox };
