import React, { useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Container, Header, Tab, TabsContainer } from './CabinetAccount.style';
import { UserCabinet } from 'components/UserCabinet';
import { OrganizationCabinet } from 'components/OrganizationCabinet';
import UserTelegram from 'components/UserCabinet/UserTelegram/UserTelegram';
import { OfertsVersioning } from 'components/OfertsVersioning';
import { BalanceCabinet } from 'components/BalanceCabinet';
import {
  SwitcherBoxMini,
  TabMini,
} from 'components/ApplicationInvestor/ApplicationInvestorNew.style';
import { BankAccount } from 'components/BankAccount';
import { BlackList } from 'components/BlackList';

export const CabinetAccount = () => {
  const { tab } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  // Значение "верхнего" таба
  const activeTab = tab || 'user';

  // Если 'tab' не входит в допустимые, делаем редирект на /account/user
  useEffect(() => {
    if (
      !['user', 'organization', 'balance', 'agreement', 'notifications', 'blacklist'].includes(
        activeTab,
      )
    ) {
      navigate('/account/user', { replace: true });
    }
  }, [activeTab, navigate]);

  const searchParams = new URLSearchParams(location.search);
  const subTab = searchParams.get('sub') || 'personal';

  // Прописываем отдельную функцию для переключения под‑вкладок:
  const handleSubTabClick = (value: string) => {
    // При клике формируем url /account/user?sub=<value>
    navigate(`/account/user?sub=${value}`);
  };

  // Вариант рендеринга "подменю" для учетной записи
  const renderUserSubMenu = () => {
    return (
      <SwitcherBoxMini>
        <TabMini active={subTab === 'personal'} onClick={() => handleSubTabClick('personal')}>
          Личный аккаунт
        </TabMini>
        <TabMini
          active={subTab === 'organization-data'}
          onClick={() => handleSubTabClick('organization-data')}>
          Данные организации
        </TabMini>
        <TabMini
          active={subTab === 'bank-accounts'}
          onClick={() => handleSubTabClick('bank-accounts')}>
          Банковские счета
        </TabMini>
      </SwitcherBoxMini>
    );
  };

  // Рендерим сам контент второго уровня
  const renderUserContent = () => {
    switch (subTab) {
      case 'personal':
        return <UserCabinet />;
      case 'organization-data':
        return <OrganizationCabinet />;
      case 'bank-accounts':
        return <BankAccount />;
      default:
        // Если subTab не валиден, можно редирект на что-то дефолтное
        return <UserCabinet />;
    }
  };

  return (
    <Container>
      <Header>Настройки</Header>

      {/* Вкладки верхнего уровня */}
      <TabsContainer>
        <Tab active={activeTab === 'user'} onClick={() => navigate('/account/user')}>
          Учетная запись
        </Tab>
        <Tab active={activeTab === 'balance'} onClick={() => navigate('/account/balance')}>
          Мой баланс
        </Tab>
        <Tab active={activeTab === 'agreement'} onClick={() => navigate('/account/agreement')}>
          Соглашения и оферта
        </Tab>
        <Tab
          active={activeTab === 'notifications'}
          onClick={() => navigate('/account/notifications')}>
          Уведомления
        </Tab>
        <Tab active={activeTab === 'blacklist'} onClick={() => navigate('/account/blacklist')}>
          Черный список
        </Tab>
      </TabsContainer>

      {/* Второе меню показываем только если выбрана вкладка 'user' */}
      {activeTab === 'user' && renderUserSubMenu()}

      {/* Рендерим контент в зависимости от вкладки */}
      {activeTab === 'user' && renderUserContent()}
      {activeTab === 'balance' && <BalanceCabinet />}
      {activeTab === 'agreement' && <OfertsVersioning />}
      {activeTab === 'notifications' && <UserTelegram />}
      {activeTab === 'blacklist' && <BlackList />}
    </Container>
  );
};
