import React, { FC } from 'react';
import { NewButton } from 'components/common/Button';
import { Backdrop, Modal, ModalActions, ModalHeader } from './ConfirmModal.style';
import { ConfirmModalProps } from './ConfirmModal.interface';

export const ConfirmModal: FC<ConfirmModalProps> = ({
  isOpen,
  headerText,
  bodyText,
  confirmText,
  cancelText,
  loading = false,
  onConfirm,
  onCancel,
  confirmButtonClass = 'green',
  cancelButtonClass = 'red',
}) => {
  if (!isOpen) return null;

  return (
    <Backdrop>
      <Modal>
        <ModalHeader>{headerText}</ModalHeader>
        <p>{bodyText}</p>
        <ModalActions>
          <NewButton
            onClick={onConfirm}
            text={confirmText}
            className={confirmButtonClass}
            disabled={loading}
          />
          <NewButton
            onClick={onCancel}
            text={cancelText}
            className={cancelButtonClass}
            disabled={loading}
          />
        </ModalActions>
      </Modal>
    </Backdrop>
  );
};
