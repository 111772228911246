import React, { useState, useEffect } from 'react';
import { AcquireProps, PaymentData } from './Acquire.interface';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;
const ACQUIRE_BASE = `${process.env.REACT_APP_ACQUIRE_BCC}`;

const Acquire: React.FC<AcquireProps> = ({ paymentData }) => {
  const [popup, setPopup] = useState<Window | null>(null);
  const [intervalId, setIntervalId] = useState<number | null>(null);
  const [clientIp, setClientIp] = useState<string>('0.0.0.0'); // по умолчанию

  // 1. При монтировании получаем IP через публичный сервис
  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then((res) => res.json())
      .then((data) => {
        if (data?.ip) {
          setClientIp(data.ip);
        }
      })
      .catch(() => {
        setClientIp('0.0.0.0');
      });
  }, []);

  const openPopup = () => {
    const width = 700;
    const height = 700;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;

    if (!popup || popup.closed) {
      const newWin = window.open(
        '',
        'BCC_3DS_POPUP',
        `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes,status=no`,
      );
      setPopup(newWin);

      const id = window.setInterval(() => {
        if (newWin?.closed) {
          window.clearInterval(id);
          window.location.reload();
        }
      }, 500);
      setIntervalId(id);

      return newWin;
    }
    return popup;
  };

  const createFormHtml = (data: PaymentData) => {
    // Формируем объект для M_INFO
    const mInfoObject = {
      browserScreenHeight: String(window.screen.height),
      browserScreenWidth: String(window.screen.width),
      mobilePhone: {
        cc: '7',
        subscriber: '7475558888',
      },
    };

    // Конвертация в Base64
    const mInfoBase64 = btoa(JSON.stringify(mInfoObject));

    return `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8" />
        <title>3DS Redirect</title>
      </head>
      <body onload="document.getElementById('paymentForm').submit()">
        <p>Пожалуйста, подождите. Идёт перенаправление в банк...</p>
        <form id="paymentForm" action="${ACQUIRE_BASE}" method="POST">
          <input type="hidden" name="AMOUNT" value="${data.amount.toFixed(2)}" />
          <input type="hidden" name="CURRENCY" value="${data.currency}" />
          <input type="hidden" name="ORDER" value="${data.order}" />
          <input type="hidden" name="MERCH_RN_ID" value="${data.merchantRnId}" />
          <input type="hidden" name="DESC" value="${data.description}" />
          <input type="hidden" name="MERCHANT" value="${data.merchantId}" />
          <input type="hidden" name="MERCH_NAME" value="${data.merchantName}" />
          <input type="hidden" name="TERMINAL" value="${data.terminal}" />
          <input type="hidden" name="TIMESTAMP" value="${data.timestamp}" />
          <input type="hidden" name="MERCH_GMT" value="${data.gmt}" />
          <input type="hidden" name="TRTYPE" value="${data.trType}" />
          <input type="hidden" name="BACKREF" value="${BASE_URL}" />
          <input type="hidden" name="LANG" value="ru" />
          <input type="hidden" name="NONCE" value="${data.nonce}" />
          <input type="hidden" name="P_SIGN" value="${data.p_sign}" />
          <input type="hidden" name="MK_TOKEN" value="MERCH" />
          <input type="hidden" name="NOTIFY_URL" value="${BASE_URL}:443/api/v1/public/payment/callback" />
          <input type="hidden" name="CLIENT_IP" value="${clientIp}" />
          <input type="hidden" name="M_INFO" value="${mInfoBase64}" />
        </form>
      </body>
      </html>
    `;
  };

  const showFormInPopup = (data: PaymentData) => {
    const w = openPopup();
    if (w) {
      const html = createFormHtml(data);
      w.document.open();
      w.document.write(html);
      w.document.close();
    }
  };

  useEffect(() => {
    if (paymentData) {
      showFormInPopup(paymentData);
    }
  }, [paymentData]);

  useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  return null;
};

export default Acquire;
