import styled from 'styled-components';

export const RadioButtonContainer = styled.div`
  cursor: pointer;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
