import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 30px;
  width: 100%;
  background: #f4f4f4;
  gap: 24px;
`;

export const BreadCrumbsBox = styled.div`
  display: flex;
  gap: 16px;
  text-align: center;
  font-family: 'IBM Plex Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  p {
    text-decoration: none;
    color: var(--primary-400, #0f62fe);
    a {
      text-decoration: none;
      color: #5f5f5f;
    }
  }
  span {
    cursor: pointer;
    color: #5f5f5f;
  }
`;

export const Banner = styled.div`
  background: linear-gradient(269deg, #96c4ee 20.23%, #98f 84.47%);
  width: calc(100% + 48px); /* Adjusting for padding in Container */
  height: 140px;
  margin-left: -24px; /* Negative margin to offset MainBox padding */
  margin-right: -24px; /* Negative margin to offset MainBox padding */
`;

export const MainBox = styled.div`
  padding: 0 24px 24px 24px; /* Remove top padding to allow Banner to stretch */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: #fff;
  width: 100%;
  gap: 24px;
  height: 100%;
`;

export const BoxInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
`;

export const BoxInfoLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;

export const BoxInfoDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

export const BoxInfoRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 12px;
  color: var(--Control, #9aa4b0);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
`;

export const Logo = styled.div`
  width: 128px;
  height: 128px;
  border-radius: 50%;
  background: linear-gradient(269deg, #96c4ee 20.23%, #d9d9d9 84.47%);
  display: inline-block;
  margin-top: -100px;
`;

export const Title = styled.h1`
  color: #292d32;
  font-family: 'IBM Plex Sans';
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`;

export const SiteLink = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #8c8c8c;
  font-family: 'IBM Plex Sans';
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;

  a {
    color: #292d32;
    text-decoration: none;
  }

  span {
    color: #c4c4c4;
    font-weight: 500;
  }
`;

export const Participants = styled.p`
  color: var(--primary-300, var(--primary-300, #5784f7));
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

export const TagRole = styled.div`
  display: flex;
  padding: 10px 42px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--primary-300, var(--primary-300, #5784f7));
  font-size: 14px;
  font-weight: 600;
  background: var(--primary-50, #dfe8fd);
`;

export const ContainerDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  label {
    color: var(--Control, #9aa4b0);
    font-feature-settings:
      'liga' off,
      'clig' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
  }
`;

export const Description = styled.div`
  display: flex;
  padding: 12px 16px;

  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 6px;
  background: #f4f4f4;

  color: #292d32;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

export const DocumentAndBrokerBlock = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  label {
    color: var(--Control, #9aa4b0);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
  }
`;
export const DocumentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
`;

export const BrokerBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  align-self: stretch;
`;
export const Fee = styled.div`
  display: flex;
  padding: 12px 16px;

  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 6px;
  background: var(--primary-300, #5784f7);
  color: #fff;

  font-size: 20px;
  font-weight: 700;
  line-height: 100%; /* 20px */
`;

export const InviteBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-end;
`;

export const ButtonBox = styled.div`
  display: flex;
  gap: 16px;
`;

export const ControlGroups = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  gap: 24px;
  border-radius: 8px;
  background: #fff;
  padding: 12px;

  button {
  }
`;

export const SettingGroups = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  padding: 12px;
  background: var(--Transparent-Transparent, #fff);
`;

export const SettingGroupsMainBox = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

export const SettingGroupsMainBoxLeft = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  label {
    color: var(--Control, #9aa4b0);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px; /* 125% */
  }
  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    font-family: 'IBM Plex Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.16px;
    max-width: 300px;
    label {
      color: var(--Text-text-secondary, #525252);
    }
    p {
      color: var(--Text-text-placeholder, #a8a8a8);
    }
    span {
      color: var(--link-link-01, var(--Default-Blue-40, #78a9ff));
      padding: 4px 14px;
      cursor: pointer;
      &:hover {
        color: var(--link-link-01, var(--Default-Blue-40, #0f62fe));
      }
    }
  }
`;

export const Circle = styled.div`
  width: 128px;
  height: 128px;
  border-radius: 500px;
  background-color: #d9d9d9;
`;

export const SettingGroupsMainBoxRight = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

export const SettingDocumentsBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;
export const SettingDocumentsMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  label {
    color: var(--Text-text-secondary, #525252);
    padding: 8px 0px;
    font-family: 'IBM Plex Sans';
    font-size: 12px;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.32px;
  }
`;

export const SettingDocumentBox = styled.div`
  display: flex;
  width: 100%;
`;

export const SettingDocument = styled.div`
  display: flex;
  padding: 10px 16px;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;
  align-items: center;
  color: var(--Text-text-primary, #161616);
  font-family: 'IBM Plex Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.16px;
`;

export const SettingButtonChange = styled.button`
  display: flex;
  padding: 10px 16px;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  color: var(--Link-link-primary, #0f62fe);
  font-family: 'IBM Plex Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.16px;
  border: none;
  background-color: #fff;
  cursor: pointer;
`;

export const AddNewDocumentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  max-width: 300px;
  p {
    color: var(--text-text-02, #c6c6c6);
    font-family: 'IBM Plex Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.16px;
  }
`;

export const SettingButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  align-self: stretch;
`;
