import React from 'react';
import { ButtonStyle } from './NewButton.style';

interface ButtonProps {
  text: string;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  icon?: string;
  iconPosition?: 'left' | 'right'; // Новое свойство
}

export const NewButton = ({
  text,
  className = '',
  onClick,
  disabled,
  loading,
  icon,
  iconPosition = 'right', // По умолчанию иконка справа
}: ButtonProps) => {
  return (
    <ButtonStyle
      className={`${className} ${loading ? 'loading' : ''}`}
      onClick={onClick}
      disabled={disabled}>
      {icon && iconPosition === 'left' && <img src={icon} alt='icon' />} {/* Иконка слева */}
      {text}
      {icon && iconPosition === 'right' && <img src={icon} alt='icon' />} {/* Иконка справа */}
    </ButtonStyle>
  );
};
