import styled, { css } from 'styled-components';

interface BoxCheckProps {
  checked: boolean;
}

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  overflow-y: auto;
`;

const ModalContent = styled.div`
  position: relative;
  display: flex;
  padding: 24px 36px;
  gap: 24px;

  flex-direction: column;
  width: 100%;
  max-width: 866px;
  max-height: 96vh; // 90% от высоты видимой части экрана
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 4px;
  border: 1px solid var(--neutral-300, #bcbcbc);
  background: #fff;
`;

const CloseButton = styled.img`
  cursor: pointer;
  width: 16px;
  height: 16px;
`;

const Title = styled.h2`
  color: #000;
  font-size: 20px;
  font-weight: 500;
  line-height: 125%; /* 25px */
`;
const HeaderBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const BoxInput = styled.div`
  display: flex;
  gap: 24px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const MoreInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;

  label {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  p {
    color: var(--neutral-800, #303030);
    font-size: 20px;
    font-weight: 500;
    line-height: 120%; /* 24px */
  }
  span {
    color: var(--neutral-600, #686868);
    font-size: 14px;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
  }
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 14px;
  gap: 12px;
  margin-bottom: 10px;
`;

const BoxSubmitted = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  p {
    color: #292929;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    line-height: 36px;
    max-width: 484px;
  }
  div {
    max-width: 200px;
    margin-top: 24px;
  }
`;

export const BoxCheck = styled.div<BoxCheckProps>`
  display: flex;
  align-items: center;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
      margin-left: 8px;
      transition: color 0.3s;
      color: ${(props) =>
        props.checked ? 'var(--primary-300, #5784F7)' : 'var(--primary, #9aa4b0)'};
    }

    &:hover p {
      color: var(--primary-300, #5784f7);
    }

    input:checked + p {
      color: var(--primary-300, #5784f7);
    }
  }
`;

export const ExpectedIncome = styled.div`
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--neutral-100, #f4f4f4);
  color: #5784f7;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
`;

export const ExpectedIncomeBox = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  color: var(--neutral-800, #303030);
  font-size: 16px;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
`;

export const AddAccountItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  background: #4f8df9;
  color: var(--neutral-50, #fefefe);
  font-size: 14px;
  font-weight: 400;
  line-height: 125%;

  &:hover {
    background: #3e7ee6;
  }

  &::after {
    content: '+';
    font-size: 20px;
  }
`;

export const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
  gap: 8px;
  padding: 4px 0px;
  label {
    color: var(--neutral-800, #303030);
    font-size: 16px;
    font-weight: 500;
    line-height: 120%; /* 19.2px */
  }
`;

export const DropdownHeader = styled.div<{
  isOpen?: boolean;
  $hasContent?: boolean;
  $isDisabled?: boolean;
}>`
  margin-top: 5px;
  padding: 8px;
  border: 1px solid var(--Control, #f4f4f4);
  border-radius: 4px;
  background: var(--neutral-100, #f4f4f4);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--neutral-400, #a0a0a0);
  font-size: 14px;
  line-height: 120%; /* 16.8px */

  ${({ $hasContent }) =>
    $hasContent &&
    css`
      color: var(--neutral-800, #303030);
      border: 1px solid var(--neutral-800, #f4f4f4);
    `}

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `}
`;

export const DropdownListContainer = styled.div`
  position: absolute;
  width: 100%;
  border-radius: 6px;
  background: var(--White, #f4f4f4);
  z-index: 1000;
  margin-top: 4px;
  overflow-x: scroll;
  max-height: 170px;
`;

export const DropdownListItem = styled.div<{ $isSelected?: boolean }>`
  padding: 8px;
  cursor: pointer;
  font-size: 14px;
  line-height: 125%;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--neutral-400, #a0a0a0);

  &:hover {
    background: var(--Light, #f5f5f5);
    color: var(--neutral-800, #303030);
  }

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background: var(--Light, #f5f5f5);
      color: var(--neutral-800, #303030);
    `}
`;

export const BlockInput = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  align-items: center;
  button {
    margin-top: 30px;
  }
`;

export {
  Modal,
  ModalContent,
  CloseButton,
  Title,
  HeaderBox,
  BoxInput,
  MoreInfoBox,
  ButtonBox,
  BoxSubmitted,
};
