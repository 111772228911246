import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import IconBalance from 'assets/img/IconBalance.svg';

import {
  Container,
  BreadCrumbsBox,
  HeaderBox,
  TagRisk,
  TagTime,
  Timer,
  SwitcherBox,
  Tab,
  ContainerContent,
  LeftBlock,
  RightBlock,
  InfoBoxRight,
  InfoBoxRightCard,
  ButtonBox,
  ContainerMain,
  ExpandableSection,
  MainInfoBox,
  MainInfoItem,
  MainInfoTitle,
  MainInfoContent,
  ArrowIcon,
  ProgressBarContainer,
  ProgressBarFiller,
  SwitcherBoxMini,
  TabMini,
  WhiteLabelBox,
  ModalContainer,
  ModalHeader,
  ModalTitle,
  ModalText,
  ModalButtonBox,
  RatingModalBackdrop,
  RatingModalContainer,
  FieldsBox,
  Textarea,
  ScoreText,
  OverallBox,
  RatingItem,
  RatingsRow,
  RatingLabel,
  DealMetaBox,
  DealInfoBox,
  DealMeta,
  DealTitle,
  StarWrapper,
  StarsRow,
  FeedbackBox,
  FeedbackBoxHeader,
  FeedbackBoxStar,
  RatingLabelInvest,
  RatingItemInvest,
  BuyProBox,
  ConfirmBuy,
  HeaderBoxPRO,
  BannerProBox,
} from './ApplicationInvestorNew.style';

import IconRedTimer from 'assets/img/IconRedTimer.svg';
import CircleWhite from 'assets/img/CircleWhite.svg';
import IconHelpHouse from 'assets/img/IconHelpHouse.svg';
import IconArrow from 'assets/img/IconArrow.svg';
import IconArrowDown from 'assets/img/IconArrowDown.svg';
import IconDecor from 'assets/img/IconDecor.svg';

import { NewButton } from 'components/common/Button';
import { SentOffersList } from './SentOffersList';
import { TagsWhiteLabel } from 'components/TagWhiteLabels';
import InfoCreditStory from 'components/InfoCreditStory/InfoCreditStoryNew';
import { InfoBusinessNew } from 'components/InfoBusiness/InfoBusinessNew';
import { ApplicationDocumentsList } from 'components/ApplicationInvestor/ApplicationDocumentsList';
import { PKBInfo } from 'components/PKBInfo';
import {
  DesignBox,
  InfoDescriptionProject,
  MainDecorContent,
  MainDecorDiv,
  MainDecorInfoBox,
  MainDecorLogo,
  MainDecorTitle,
} from 'components/RequestDetailsNew/RequestDetailsNew.style';
import { ApproveModal } from './ApproveModal';
import InfoEGZNew from 'components/InfoEGZNew/InfoEGZNew';
import { BankStatement } from 'components/BankStatement';
import { LoadingGif } from 'components/common/LoadingGif';

// Иконки звёзд
import StarIcon from 'assets/img/StarIcon.svg';
import StarIconMini from 'assets/img/StarIconMini.svg';
import IconPro from 'assets/img/IconPro.svg';
import BannerPro from 'assets/img/BannerPro1.webp';
import { ErrorModal } from 'components/common/ErrorModal';
import { getReviewWord } from 'utils/feedbackCount';
import { formatDateTime } from 'utils/formatDate';
import { RequestData, ReviewFeedback } from './ApplicationInvestor.interface';
import { useAppContext } from 'contexts/AppContext';
import { ConfirmModal } from 'components/common/ConfirmModal';
import ModalBuyingToken from 'components/BalanceCabinet/ModalBuyingToken';
import SuccessModal from 'components/common/SuccessModal/SuccessModal';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;

export const ApplicationInvestorNew: React.FC = () => {
  const { id, tab, subTab } = useParams();
  const navigate = useNavigate();
  const { userDataLight } = useAppContext();

  const [requestData, setRequestData] = useState<RequestData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [remainingTime, setRemainingTime] = useState('00:00:00');
  const [activeTab, setActiveTab] = useState(tab || 'mainInfo');
  const [activeSubMenu, setActiveSubMenu] = useState(subTab || '');
  const [isMainInfoExpanded, setIsMainInfoExpanded] = useState(true);
  const [isMainInfoDecor, setIsMainInfoDecor] = useState(true);
  const [logoSrc, setLogoSrc] = useState<string | null>(null); // Логотип

  // Модалка "Сделать предложение"
  const [isModalOpen, setModalOpen] = useState(false);

  // ----- Отзывы -----
  const [reviews, setReviews] = useState<ReviewFeedback | null>(null);
  const [isReviewsLoading, setIsReviewsLoading] = useState(false);
  const [reviewsError, setReviewsError] = useState('');
  const [isFeedbackForbidden, setIsFeedbackForbidden] = useState(false);

  // ----- Модалка "Форма обратной связи" (рейтинги, комментарии) -----
  const [isRatingModalOpen, setIsRatingModalOpen] = useState(false);

  // ----- Рейтинги (целые значения 1..5) -----
  const [finStabilityRank, setFinStabilityRank] = useState(0);
  const [reputationRank, setReputationRank] = useState(0);
  const [realisticRank, setRealisticRank] = useState(0);
  const [conditionRank, setConditionRank] = useState(0);
  const [competentionRank, setCompetentionRank] = useState(0);
  const [resourcesRank, setResourcesRank] = useState(0);

  // ----- Поля формы обратной связи -----
  const [feedback, setFeedback] = useState('');
  const [suggestions, setSuggestions] = useState('');

  const [isSendingFeedback, setIsSendingFeedback] = useState(false);

  // Ошибка при отправке отзыва
  const [requestError, setRequestError] = useState('');
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  // ----- HoverRatings: отдельный hover для каждой категории -----
  const [hoverRatings, setHoverRatings] = useState({
    finStability: 0,
    reputation: 0,
    realistic: 0,
    condition: 0,
    competention: 0,
    resources: 0,
  });

  const [isMainInfoFeedback, setIsMainInfoFeedback] = useState(true);

  const [isProConfirmOpen, setIsProConfirmOpen] = useState(false); // Управляет открытием ConfirmModal
  const [isModalBuyingTokenOpen, setModalBuyingTokenOpen] = useState(false);
  const [isProLoading, setIsProLoading] = useState(false); // Состояние загрузки
  const [proTariff, setProTariff] = useState<{ id: number; tokenCount: number } | null>(null);
  const [buyProError, setBuyProError] = useState(''); // Ошибка при покупке
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  // ===== Новая функция при нажатии на кнопку "Получить доступ к PRO" =====
  const handleBuyProClick = async () => {
    try {
      setIsProLoading(true);
      setBuyProError('');

      // 1) Получаем тариф "PRO_REPORT"
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/ui/payment/tariffs/PRO_REPORT`,
        { withCredentials: true },
      );
      // По условию, приходит массив, берем первый элемент
      const [data] = res.data;
      if (!data) {
        throw new Error('Тариф PRO не найден');
      }

      // Сохраняем в стейт (id и tokenCount)
      setProTariff({ id: data.id, tokenCount: data.tokenCount });

      // Открываем модальное окно подтверждения
      setIsProConfirmOpen(true);
    } catch (err: any) {
      // Обработка ошибки
      const message = err?.response?.data?.message || 'Не удалось получить тариф PRO';
      setBuyProError(message);
      setIsErrorModalOpen(true); // или свой стейт под ошибку, если у вас общий ErrorModal
    } finally {
      setIsProLoading(false);
    }
  };

  const handleConfirmBuyPro = async () => {
    if (!proTariff || !requestData) return;

    // Проверяем баланс
    if (userDataLight?.balance < proTariff.tokenCount) {
      setIsProConfirmOpen(false);
      setModalBuyingTokenOpen(true);
      return;
    }

    try {
      setIsProLoading(true);
      setBuyProError('');

      await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/ui/payment/buy?tariffId=${proTariff.id}&id=${requestData.id}`,
        { withCredentials: true },
      );

      // 1) Закрываем окно подтверждения
      setIsProConfirmOpen(false);

      // 2) Обновляем данные
      await fetchRequestDetails();

      // 3) Показываем SuccessModal
      setIsSuccessModalOpen(true);
    } catch (err: any) {
      const detail = err?.response?.data?.detail; // <-- берем detail из ошибки
      const message = detail
        ? detail
        : err?.response?.data?.message || 'Ошибка при покупке PRO-доступа';

      setBuyProError(message);
      setIsErrorModalOpen(true);
    } finally {
      setIsProLoading(false);
    }
  };

  // ===== Обработка "Отменить" в ConfirmModal =====
  const handleCancelBuyPro = () => {
    setIsProConfirmOpen(false);
  };

  // ===========================
  //   1) ЗАГРУЗКА ОТЗЫВОВ (GET)
  // ===========================
  const fetchReviews = async (requestId: string) => {
    try {
      setIsReviewsLoading(true);
      setReviewsError('');
      setIsFeedbackForbidden(false);

      const response = await axios.get(
        `${BASE_URL}cabinet/investor/request/get/${requestId}/feedback`,
        { withCredentials: true },
      );
      setReviews(response.data);
    } catch (err: any) {
      if (axios.isAxiosError(err) && err.response?.status === 400) {
        setIsFeedbackForbidden(true);
      } else if (err instanceof Error) {
        setReviewsError('Ошибка при загрузке отзывов: ' + err.message);
      } else {
        setReviewsError('Ошибка при загрузке отзывов: ' + String(err));
      }
    } finally {
      setIsReviewsLoading(false);
    }
  };

  // ===========================
  //   2) ОТПРАВКА ОТЗЫВА (POST)
  // ===========================
  const handleSendFeedback = async () => {
    if (!id) return;

    // Проверяем, что все поля рейтинга > 0 и поля feedback/suggestions не пусты:
    const isAllFieldsFilled =
      finStabilityRank > 0 &&
      reputationRank > 0 &&
      realisticRank > 0 &&
      conditionRank > 0 &&
      competentionRank > 0 &&
      resourcesRank > 0 &&
      feedback.trim().length > 0 &&
      suggestions.trim().length > 0;

    if (!isAllFieldsFilled) {
      // Если что-то не заполнено — просто выходим (или можно показать тост)
      return;
    }

    // Формируем payload для запроса
    const payload = {
      requestId: parseInt(id, 10),
      finStabilityRank,
      reputationRank,
      realisticRank,
      conditionRank,
      competentionRank,
      resourcesRank,
      feedback,
      suggestions,
    };

    try {
      setIsSendingFeedback(true); // Блокируем кнопку (включаем состояние "в процессе")
      setRequestError(''); // Сбрасываем предыдущую ошибку

      await axios.post(`${BASE_URL}cabinet/investor/request/feedback`, payload, {
        withCredentials: true,
      });

      // Успешно — закрываем модалку и обновляем список
      setIsRatingModalOpen(false);
      fetchReviews(id);
    } catch (error: any) {
      // При ошибке сохраняем текст и показываем ErrorModal
      const errMsg = error?.response?.data?.message || 'Произошла ошибка при отправке отзыва';
      setRequestError(errMsg);
      setIsErrorModalOpen(true);
    } finally {
      // Снимаем блокировку
      setIsSendingFeedback(false);
    }
  };

  // Когда открываем вкладку "reviews", делаем fetchReviews
  useEffect(() => {
    if (activeSubMenu === 'reviews' && id) {
      fetchReviews(id);
    }
  }, [activeSubMenu, id]);

  // ===========================
  //   СИСТЕМА ТАБОВ
  // ===========================
  useEffect(() => {
    if (tab) setActiveTab(tab);
    if (subTab) {
      setActiveSubMenu(subTab);
    } else if (tab === 'mainInfo') {
      setActiveSubMenu('');
    } else if (tab === 'analytics') {
      setActiveSubMenu('dataEgz');
    }
  }, [tab, subTab]);

  const handleTabChange = (newTab: string) => {
    setActiveTab(newTab);
    if (newTab === 'analytics') {
      setActiveSubMenu('dataEgz');
    } else {
      setActiveSubMenu('');
    }
    navigate(`/request/${id}/${newTab}`);
  };

  const handleSubTabChange = (newSubTab: string) => {
    setActiveSubMenu(newSubTab);
    navigate(`/request/${id}/${activeTab}/${newSubTab}`);
  };

  // ===========================
  //   ЗАГРУЗКА ДАННЫХ СДЕЛКИ
  // ===========================
  const fetchRequestDetails = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}cabinet/investor/request/get/${id}`, {
        withCredentials: true,
      });
      const data = response.data;
      setRequestData(data);

      if (data.logoFileId) {
        const logoBlob = await fetchLogo(data.logoFileId);
        if (logoBlob) {
          setLogoSrc(URL.createObjectURL(logoBlob));
        }
      }
    } catch (err) {
      if (err instanceof Error) {
        setError('Ошибка загрузки данных: ' + err.message);
      } else {
        setError('Ошибка загрузки данных: ' + String(err));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLogo = async (logoFileId: string) => {
    try {
      const response = await axios.get(`${BASE_URL}/ui/file/${logoFileId}`, {
        responseType: 'blob',
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.error('Ошибка получения логотипа:', error);
      setError('Ошибка получения логотипа');
    }
  };

  // ===========================
  //   ПРОЧИЕ ДЕЙСТВИЯ
  // ===========================
  // 1) Отклонение сделки
  const handleDecline = async () => {
    try {
      await axios.get(`${BASE_URL}cabinet/investor/request/decline/${id}`, {
        withCredentials: true,
      });
      navigate('/applications');
    } catch (err) {
      if (err instanceof Error) {
        setError('Ошибка при отклонении: ' + err.message);
      } else {
        setError('Ошибка при отклонении: ' + String(err));
      }
    }
  };

  // 2) Модалка "Сделать предложение"
  const closeModal = () => {
    setModalOpen(!isModalOpen);
  };

  // 3) Расчёт прогресса
  const calculatePercentage = (signedSum: any, remainingSum: any) => {
    if (!signedSum || !remainingSum || remainingSum === 0) return 0;
    return (signedSum / remainingSum) * 100;
  };

  // 4) Таймер
  const calculateTimeLeft = useCallback(() => {
    if (!requestData?.requestDtEnd) {
      return '00:00:00';
    }
    const endTime = new Date(requestData.requestDtEnd).getTime();
    const now = new Date().getTime();
    const difference = endTime - now;

    if (difference > 0) {
      const hours = Math.floor(difference / (1000 * 60 * 60))
        .toString()
        .padStart(2, '0');
      const minutes = Math.floor((difference / (1000 * 60)) % 60)
        .toString()
        .padStart(2, '0');
      const seconds = Math.floor((difference / 1000) % 60)
        .toString()
        .padStart(2, '0');
      return `${hours}:${minutes}:${seconds}`;
    }

    return '00:00:00';
  }, [requestData]);

  useEffect(() => {
    fetchRequestDetails();
  }, [id]);

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(timer);
  }, [calculateTimeLeft]);

  // ===========================
  //   УТИЛИТЫ ОТОБРАЖЕНИЯ
  // ===========================
  // Лейблы (OMARKET, BARS, и т.д.)
  const getWhiteLabelTags = () => {
    if (requestData?.whiteLabels?.length) {
      return requestData.whiteLabels.map((whiteLabel) => {
        switch (whiteLabel.code) {
          case 'OMARKET':
            return (
              <TagsWhiteLabel key={whiteLabel.code} text={whiteLabel.name} className='smartcash' />
            );
          case 'ALL':
            return <TagsWhiteLabel key={whiteLabel.code} text={whiteLabel.name} className='all' />;
          case 'MALAFEYEV_GROUP':
            return (
              <TagsWhiteLabel key={whiteLabel.code} text={whiteLabel.name} className='malafeyev' />
            );
          case 'BARS':
            return <TagsWhiteLabel key={whiteLabel.code} text={whiteLabel.name} className='bars' />;
          default:
            return <TagsWhiteLabel key={whiteLabel.code} text={whiteLabel.name} className='all' />;
        }
      });
    }
    return <TagsWhiteLabel text='All' />;
  };

  // Под-меню (borrowerData, creditHistory, reviews)
  const renderSecondaryMenu = () => {
    if (activeTab === 'mainInfo') {
      return (
        <SwitcherBoxMini>
          <TabMini active={activeSubMenu === ''} onClick={() => handleSubTabChange('')}>
            Основная информация
          </TabMini>
          <TabMini
            active={activeSubMenu === 'borrowerData'}
            onClick={() => handleSubTabChange('borrowerData')}>
            Данные о заемщике
          </TabMini>
          <TabMini
            active={activeSubMenu === 'creditHistory'}
            onClick={() => handleSubTabChange('creditHistory')}>
            Кредитная история
          </TabMini>
        </SwitcherBoxMini>
      );
    }

    if (activeTab === 'analytics') {
      return (
        <SwitcherBoxMini>
          <TabMini
            active={activeSubMenu === 'dataEgz'}
            onClick={() => handleSubTabChange('dataEgz')}>
            Данные ЭГЗ
          </TabMini>
          <TabMini
            active={activeSubMenu === 'analyticsBusiness'}
            onClick={() => handleSubTabChange('analyticsBusiness')}>
            История займов на платформе
          </TabMini>
          <TabMini
            active={activeSubMenu === 'analyticsBank'}
            onClick={() => handleSubTabChange('analyticsBank')}>
            Банковская выписка
          </TabMini>
          <TabMini
            active={activeSubMenu === 'reviews'}
            onClick={() => handleSubTabChange('reviews')}>
            Обратная связь
          </TabMini>
        </SwitcherBoxMini>
      );
    }

    return null;
  };

  // Подсчёт общей оценки (6 критериев по 5 звёзд = макс 30)
  const totalScore =
    finStabilityRank +
    reputationRank +
    realisticRank +
    conditionRank +
    competentionRank +
    resourcesRank;
  const maxScore = 6 * 5;

  // Функция отрисовки 5 звёзд (целые значения, 1..5)
  // Используем hoverRatings[categoryKey] для "подсветки".

  // ▼▼▼ Добавленный код ▼▼▼
  /**
   * Рисуем «звёзды» (в том числе половинку), исходя из десятичной оценки (например, 3.7).
   * Если rating >= i     -> закрашиваем звезду целиком
   * Если rating >= i - 0.5 и < i -> половинка
   * Иначе -> пуcтая звезда
   */
  // Подсчёт общей оценки (6 критериев по 5 звёзд = макс 30)

  // Функция отрисовки 5 звёзд (целые значения, 1..5)
  // Используем hoverRatings[categoryKey] для "подсветки".
  const renderStars = (
    currentValue: number,
    onChange: (val: number) => void,
    categoryKey: keyof typeof hoverRatings,
  ) => {
    const stars = [];

    for (let i = 1; i <= 5; i++) {
      // Определяем, активна ли данная звезда (учитываем hover)
      const isActive = i <= (hoverRatings[categoryKey] || currentValue);

      stars.push(
        <StarWrapper
          key={i}
          active={isActive}
          onClick={() => onChange(i)}
          onMouseEnter={() => setHoverRatings((prev) => ({ ...prev, [categoryKey]: i }))}
          onMouseLeave={() => setHoverRatings((prev) => ({ ...prev, [categoryKey]: 0 }))}>
          {isActive ? (
            // fill
            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'>
              <path
                d='M11.5474 4.96431C11.7273 4.58091 12.2727 4.5809 12.4526 4.96431L14.3588 9.02535C14.4296 9.17629 14.571 9.28199 14.7359 9.30717L19.0381 9.96449C19.4404 10.026 19.6047 10.5166 19.3205 10.8079L16.1762 14.0305C16.0654 14.1441 16.0151 14.3036 16.0406 14.4602L16.7785 18.9838C16.8457 19.3957 16.4084 19.7038 16.0431 19.5019L12.2419 17.4006C12.0914 17.3174 11.9086 17.3174 11.7581 17.4006L7.95687 19.5019C7.59158 19.7038 7.1543 19.3957 7.22149 18.9838L7.95938 14.4602C7.98492 14.3036 7.9346 14.1441 7.82378 14.0305L4.67954 10.8079C4.39533 10.5166 4.5596 10.026 4.9619 9.96449L9.26412 9.30717C9.42895 9.28199 9.57038 9.1763 9.64123 9.02535L11.5474 4.96431Z'
                fill='#0F62FE'
              />
            </svg>
          ) : (
            // stroke
            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'>
              <path
                d='M12 5.17676L13.9062 9.2378C14.0478 9.53968 14.3307 9.75107 14.6604 9.80143L18.9626 10.4588L15.8183 13.6814C15.5967 13.9085 15.496 14.2274 15.5471 14.5407L16.285 19.0643L12.4838 16.963C12.1827 16.7966 11.8173 16.7966 11.5162 16.963L7.71497 19.0643L8.45285 14.5407C8.50395 14.2274 8.40331 13.9085 8.18165 13.6814L5.03742 10.4588L9.33964 9.80143C9.6693 9.75107 9.95215 9.53969 10.0938 9.2378L12 5.17676Z'
                stroke='#0F62FE'
              />
            </svg>
          )}
        </StarWrapper>,
      );
    }
    return <StarsRow>{stars}</StarsRow>;
  };

  const renderPartialStars = (rating: number) => {
    const stars: JSX.Element[] = [];
    for (let i = 1; i <= 5; i++) {
      const isFull = rating >= i;
      const isHalf = !isFull && rating >= i - 0.5;

      if (isFull) {
        // Полная звезда
        stars.push(
          <StarWrapper key={i} active={true}>
            {/* Та же самая "закрашенная" звезда, что и у вас в fill */}
            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'>
              <path
                d='M11.5474 4.96431C11.7273 4.58091 12.2727 4.5809 12.4526 4.96431L14.3588 9.02535C14.4296 9.17629 14.571 9.28199 14.7359 9.30717L19.0381 9.96449C19.4404 10.026 19.6047 10.5166 19.3205 10.8079L16.1762 14.0305C16.0654 14.1441 16.0151 14.3036 16.0406 14.4602L16.7785 18.9838C16.8457 19.3957 16.4084 19.7038 16.0431 19.5019L12.2419 17.4006C12.0914 17.3174 11.9086 17.3174 11.7581 17.4006L7.95687 19.5019C7.59158 19.7038 7.1543 19.3957 7.22149 18.9838L7.95938 14.4602C7.98492 14.3036 7.9346 14.1441 7.82378 14.0305L4.67954 10.8079C4.39533 10.5166 4.5596 10.026 4.9619 9.96449L9.26412 9.30717C9.42895 9.28199 9.57038 9.1763 9.64123 9.02535L11.5474 4.96431Z'
                fill='#0F62FE'
              />
            </svg>
          </StarWrapper>,
        );
      } else if (isHalf) {
        // Половинка
        stars.push(
          <StarWrapper key={i} active={true}>
            {/* SVG половинной звезды, которую вы прислали */}
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'>
              <g filter='url(#filter0_i_5305_26261)'>
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M11.5476 4.96431C11.7276 4.58091 12.2729 4.58091 12.4528 4.96431L14.359 9.02536C14.4298 9.1763 14.5713 9.28199 14.7361 9.30717L19.0383 9.96449C19.4406 10.026 19.6049 10.5166 19.3207 10.8079L16.1765 14.0305C16.0656 14.1441 16.0153 14.3036 16.0409 14.4602L16.7787 18.9838C16.8459 19.3957 16.4086 19.7038 16.0434 19.5019L12.2421 17.4006C12.0916 17.3174 11.9089 17.3174 11.7583 17.4006L7.95709 19.5019C7.59181 19.7038 7.15453 19.3957 7.22172 18.9838L7.9596 14.4602C7.98515 14.3036 7.93483 14.1441 7.824 14.0305L4.67977 10.8079C4.39556 10.5166 4.55983 10.026 4.96213 9.96449L9.26435 9.30717C9.42918 9.28199 9.57061 9.1763 9.64145 9.02536L11.5476 4.96431ZM12.0002 6.35351L13.4538 9.45025C13.6663 9.90308 14.0906 10.2201 14.5851 10.2957L17.9254 10.8061L15.4607 13.3322C15.1282 13.6729 14.9773 14.1513 15.0539 14.6212L15.626 18.1285L12.7259 16.5254L12.0002 16.5L12.0002 6.35351Z'
                  fill='#0F62FE'
                />
              </g>
              <defs>
                <filter
                  id='filter0_i_5305_26261'
                  x='4.53711'
                  y='4.67676'
                  width='14.9258'
                  height='14.8887'
                  filterUnits='userSpaceOnUse'
                  colorInterpolationFilters='sRGB'>
                  <feFlood floodOpacity='0' result='BackgroundImageFix' />
                  <feBlend
                    mode='normal'
                    in='SourceGraphic'
                    in2='BackgroundImageFix'
                    result='shape'
                  />
                  <feColorMatrix
                    in='SourceAlpha'
                    type='matrix'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                    result='hardAlpha'
                  />
                  <feOffset dx='4' />
                  <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                  <feColorMatrix
                    type='matrix'
                    values='0 0 0 0 0.0588235 0 0 0 0 0.384314 0 0 0 0 0.996078 0 0 0 1 0'
                  />
                  <feBlend mode='normal' in2='shape' result='effect1_innerShadow_5305_26261' />
                </filter>
              </defs>
            </svg>
          </StarWrapper>,
        );
      } else {
        // Пустая звезда (stroke)
        stars.push(
          <StarWrapper key={i} active={false}>
            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'>
              <path
                d='M12 5.17676L13.9062 9.2378C14.0478 9.53968 14.3307 9.75107 14.6604 9.80143L18.9626 10.4588L15.8183 13.6814C15.5967 13.9085 15.496 14.2274 15.5471 14.5407L16.285 19.0643L12.4838 16.963C12.1827 16.7966 11.8173 16.7966 11.5162 16.963L7.71497 19.0643L8.45285 14.5407C8.50395 14.2274 8.40331 13.9085 8.18165 13.6814L5.03742 10.4588L9.33964 9.80143C9.6693 9.75107 9.95215 9.53969 10.0938 9.2378L12 5.17676Z'
                stroke='#0F62FE'
              />
            </svg>
          </StarWrapper>,
        );
      }
    }
    return <StarsRow>{stars}</StarsRow>;
  };
  // ▲▲▲ Добавленный код ▲▲▲

  const isAllFieldsFilled =
    finStabilityRank > 0 &&
    reputationRank > 0 &&
    realisticRank > 0 &&
    conditionRank > 0 &&
    competentionRank > 0 &&
    resourcesRank > 0 &&
    feedback.trim().length > 0 &&
    suggestions.trim().length > 0;

  // ===========================
  //   РЕНДЕР
  // ===========================
  if (isLoading) {
    return <LoadingGif />;
  }

  if (!requestData) {
    return <div>Данные не найдены.</div>;
  }

  return (
    <Container>
      {/* Хлебные крошки */}
      <BreadCrumbsBox>
        <p>
          <a href='/applications'>Сделки</a>
        </p>
        <span>{'>'}</span>
        <p>Сделка {requestData.num}</p>
      </BreadCrumbsBox>

      {/* Шапка */}
      <HeaderBox>
        <div className='leftContent'>
          <h2>Сделка {requestData.num}</h2>
          <WhiteLabelBox>{getWhiteLabelTags()}</WhiteLabelBox>
          <TagRisk>
            <img src={CircleWhite} alt='circle' />
            {requestData.statusName}
          </TagRisk>
          <TagTime>
            <img src={IconRedTimer} alt='timer' />
            <Timer>{remainingTime}</Timer>
          </TagTime>
        </div>
        <a href='/suggestions'>
          <NewButton text='К предложениям' />
        </a>
      </HeaderBox>

      {/* Вкладки */}
      <SwitcherBox>
        <Tab active={activeTab === 'mainInfo'} onClick={() => handleTabChange('mainInfo')}>
          Основная информация
        </Tab>
        <Tab active={activeTab === 'analytics'} onClick={() => handleTabChange('analytics')}>
          Аналитика
        </Tab>
        <Tab active={activeTab === 'documents'} onClick={() => handleTabChange('documents')}>
          Документы
        </Tab>
      </SwitcherBox>
      {renderSecondaryMenu()}

      {/* Основной контент */}
      <ContainerContent>
        <LeftBlock>
          {/* Пример — вкладка "Основная информация" */}
          {(!activeSubMenu || activeSubMenu === '') && activeTab === 'mainInfo' && (
            <ContainerMain>
              <ExpandableSection>
                <MainInfoTitle onClick={() => setIsMainInfoExpanded(!isMainInfoExpanded)}>
                  <img src={IconHelpHouse} alt='icon' />
                  Общая информация
                  <ArrowIcon src={isMainInfoExpanded ? IconArrowDown : IconArrow} alt='arrow' />
                </MainInfoTitle>
                {isMainInfoExpanded && (
                  <MainInfoBox>
                    <MainInfoContent>
                      <MainInfoItem>
                        <span>Заёмщик</span> {requestData.businessName}
                      </MainInfoItem>
                      {requestData?.publicationDt && (
                        <MainInfoItem>
                          <span>Дата публикации</span> {formatDateTime(requestData.publicationDt)}
                        </MainInfoItem>
                      )}
                      <MainInfoItem>
                        <span>Продукт</span> {requestData.productName}
                      </MainInfoItem>
                      <MainInfoItem>
                        <span>ИИН/БИН Заёмщика</span> {requestData.organization.iinbin}
                      </MainInfoItem>
                      <MainInfoItem>
                        <span>Длительность финансирования</span> {requestData.requestPeriod} дней
                      </MainInfoItem>
                      <MainInfoItem>
                        <span>Запрошенная сумма</span>{' '}
                        {requestData.requestedAmount.toLocaleString()} ₸
                      </MainInfoItem>
                      <MainInfoItem>
                        <span>№ Счета</span> {requestData.accountInfo.iban}
                      </MainInfoItem>
                      <MainInfoItem>
                        <span>Минимальный порог входа</span>{' '}
                        {requestData?.requestedMinAmount?.toLocaleString() || 'N/A'} ₸
                      </MainInfoItem>
                      <MainInfoItem>
                        <span>Процентная ставка</span> {requestData.requestRate} %
                      </MainInfoItem>
                      {/* Пример, если product = 'LOAN' */}
                      {requestData?.product === 'LOAN' && (
                        <>
                          <MainInfoItem>
                            <span>Вид залога</span>{' '}
                            {requestData?.collateral?.name?.text_ru
                              ? requestData.collateral.name.text_ru
                              : 'Нет залога'}
                          </MainInfoItem>
                        </>
                      )}
                      {/* Пример, если product = 'TENDER' */}
                      {requestData?.product === 'TENDER' && (
                        <>
                          <MainInfoItem>
                            <span>Сумма контракта</span>{' '}
                            {requestData.contracts[0]?.sumWithNds?.toLocaleString() || 'N/A'} ₸
                          </MainInfoItem>
                          <MainInfoItem>
                            <span>№ Контракта</span> {requestData.contracts[0]?.num || 'N/A'}
                          </MainInfoItem>
                          <MainInfoItem>
                            <span>Название контракта</span>{' '}
                            {requestData.contracts[0]?.customerName || 'N/A'}
                          </MainInfoItem>
                        </>
                      )}
                    </MainInfoContent>
                  </MainInfoBox>
                )}
              </ExpandableSection>

              <ExpandableSection>
                <MainInfoTitle onClick={() => setIsMainInfoDecor(!isMainInfoDecor)}>
                  <img src={IconDecor} alt='icon' />
                  Оформление
                  <ArrowIcon src={isMainInfoDecor ? IconArrowDown : IconArrow} alt='arrow' />
                </MainInfoTitle>
                {isMainInfoDecor && (
                  <MainInfoBox>
                    <MainDecorContent>
                      <MainDecorDiv>
                        {logoSrc && <MainDecorLogo src={logoSrc} alt='logo' />}
                        <DesignBox>
                          <MainDecorTitle>{requestData?.loanPurposeProject}</MainDecorTitle>
                          <InfoDescriptionProject>
                            {requestData?.loanPurposeProjectDesc}
                          </InfoDescriptionProject>
                        </DesignBox>
                      </MainDecorDiv>

                      <MainDecorInfoBox>
                        <MainInfoContent>
                          {requestData?.funding_goals?.name?.text_ru ? (
                            <MainInfoItem>
                              <span>Цель займа:</span>
                              {requestData.funding_goals.name.text_ru}
                            </MainInfoItem>
                          ) : (
                            <MainInfoItem>
                              <span>Цель займа:</span>
                              Исполнение тендера
                            </MainInfoItem>
                          )}
                          <MainInfoItem>
                            <span>Индустрия:</span>
                            {requestData?.industry?.name?.text_ru}
                          </MainInfoItem>
                        </MainInfoContent>
                      </MainDecorInfoBox>
                    </MainDecorContent>
                  </MainInfoBox>
                )}
              </ExpandableSection>
            </ContainerMain>
          )}

          {/* Остальные под-вкладки mainInfo */}
          {activeSubMenu === 'creditHistory' && <InfoCreditStory id={requestData.id} />}
          {activeSubMenu === 'analyticsBusiness' && (
            <InfoBusinessNew num={requestData.num} id={requestData.id} />
          )}

          {/* Документы */}
          {activeTab === 'documents' && (
            <ApplicationDocumentsList files={requestData.files} docLink={requestData?.docLink} />
          )}

          {/* Аналитика */}
          {activeSubMenu === 'dataEgz' && <InfoEGZNew id={requestData.id} num={requestData.num} />}
          {activeSubMenu === 'borrowerData' && <PKBInfo id={requestData.id} />}
          {activeSubMenu === 'analyticsBank' && <BankStatement id={requestData.id} />}

          {/* ======== Вкладка «Отзывы» (Обратная связь) ======== */}
          {activeSubMenu === 'reviews' && (
            <>
              {isFeedbackForbidden && (
                <ModalContainer>
                  <img src={StarIcon} alt='iconStar' />
                  <div>
                    <h4>Оставьте обратную связь</h4>
                    <p>
                      Заполните форму обратной связи чтобы просмотреть отзывы других инвесторов об
                      этом проекте
                    </p>
                  </div>

                  <NewButton
                    text='Оставить обратную связь'
                    onClick={() => {
                      setIsRatingModalOpen(true);
                    }}
                  />
                </ModalContainer>
              )}

              {reviewsError && !isFeedbackForbidden && (
                <p style={{ color: 'red' }}>{reviewsError}</p>
              )}

              {isReviewsLoading && <LoadingGif />}

              {!isReviewsLoading && !isFeedbackForbidden && !reviewsError && reviews && (
                <ExpandableSection>
                  <MainInfoTitle onClick={() => setIsMainInfoFeedback(!isMainInfoFeedback)}>
                    <img src={StarIconMini} alt='icon' />
                    Средняя оценка проекта
                    <p>
                      ({reviews.count} {getReviewWord(reviews.count)})
                    </p>
                    <ArrowIcon src={isMainInfoFeedback ? IconArrowDown : IconArrow} alt='arrow' />
                  </MainInfoTitle>

                  {isMainInfoFeedback && (
                    <MainInfoBox>
                      <FeedbackBox>
                        <FeedbackBoxHeader>
                          <p>Средняя оценка проекта</p>
                          {(() => {
                            const sum =
                              reviews.finStabilityRank +
                              reviews.reputationRank +
                              reviews.realisticRank +
                              reviews.conditionRank +
                              reviews.competentionRank +
                              reviews.resourcesRank;
                            const averageRating = sum / 6;
                            return (
                              <FeedbackBoxStar>
                                <img src={StarIconMini} alt='icon' />

                                {averageRating.toFixed(2)}
                              </FeedbackBoxStar>
                            );
                          })()}
                        </FeedbackBoxHeader>

                        <RatingsRow>
                          <RatingItemInvest>
                            <RatingLabelInvest>
                              Финансовая устойчивость
                              <p>Насколько стабилен финансовый статус заемщика.</p>
                            </RatingLabelInvest>
                            {/* Используем функцию с половинкой */}
                            {renderPartialStars(reviews.finStabilityRank)}
                          </RatingItemInvest>

                          <RatingItemInvest>
                            <RatingLabelInvest>
                              Условия сделки
                              <p>Выгодность и прозрачность предложенных условий.</p>
                            </RatingLabelInvest>
                            {renderPartialStars(reviews.conditionRank)}
                          </RatingItemInvest>
                        </RatingsRow>
                        <RatingsRow>
                          <RatingItemInvest>
                            <RatingLabelInvest>
                              Репутация заемщика
                              <p>Надежность и история заемщика на платформе.</p>
                            </RatingLabelInvest>
                            {renderPartialStars(reviews.reputationRank)}
                          </RatingItemInvest>
                          <RatingItemInvest>
                            <RatingLabelInvest>
                              Компетенция заемщика
                              <p>Опыт и квалификация заемщика в бизнесе.</p>
                            </RatingLabelInvest>
                            {renderPartialStars(reviews.competentionRank)}
                          </RatingItemInvest>
                        </RatingsRow>
                        <RatingsRow>
                          <RatingItemInvest>
                            <RatingLabelInvest>
                              Реалистичность целей
                              <p>Насколько заявленные цели выглядят выполнимыми.</p>
                            </RatingLabelInvest>
                            {renderPartialStars(reviews.realisticRank)}
                          </RatingItemInvest>
                          <RatingItemInvest>
                            <RatingLabelInvest>
                              Достаточность ресурсов
                              <p>Обеспеченность заемщика необходимыми ресурсами.</p>
                            </RatingLabelInvest>
                            {renderPartialStars(reviews.resourcesRank)}
                          </RatingItemInvest>
                        </RatingsRow>
                      </FeedbackBox>
                    </MainInfoBox>
                  )}
                </ExpandableSection>
              )}
            </>
          )}
        </LeftBlock>

        {/* Правая колонка */}
        <RightBlock>
          <InfoBoxRight>
            <InfoBoxRightCard>
              <div>
                <p>Подписано договоров на сумму:</p>
                <span>{requestData?.signedContractSum?.toLocaleString() || 'N/A'} ₸</span>
              </div>
              <div>
                <p>Принято предложений на сумму:</p>
                <span>{requestData?.approvedAmount?.toLocaleString() || 'N/A'} ₸</span>
              </div>
            </InfoBoxRightCard>
            <InfoBoxRightCard>
              <div>
                <p>Осталось собрать по сделке:</p>
                <span>{requestData?.remainingSum?.toLocaleString() || 'N/A'} ₸</span>
              </div>
            </InfoBoxRightCard>
            <ProgressBarContainer>
              <ProgressBarFiller
                style={{
                  width: `${calculatePercentage(
                    requestData?.signedContractSum,
                    requestData?.remainingSum,
                  )}%`,
                }}
              />
            </ProgressBarContainer>
          </InfoBoxRight>

          {requestData?.status !== '11' && (
            <ButtonBox>
              {requestData?.declinable !== false && (
                <NewButton text='Отклонить' className='red' onClick={handleDecline} />
              )}
              <NewButton text='Сделать предложение' onClick={() => setModalOpen(true)} />
            </ButtonBox>
          )}
          <BuyProBox>
            <HeaderBoxPRO onClick={() => window.open('https://lend.kz/tariff', '_blank')}>
              <h3>Расширьте свои возможности с PRO!</h3>
              <img src={IconPro} alt='iconPro' />
            </HeaderBoxPRO>
            <BannerProBox
              onClick={() => window.open('https://lend.kz/tariff', '_blank')}
              src={BannerPro}
              alt='bannerPro'
            />
            <NewButton
              text='Получить доступ к PRO'
              className='white'
              onClick={handleBuyProClick}
              disabled={isProLoading}
              loading={isProLoading}
            />{' '}
            {/* <span onClick={() => window.open('https://lend.kz/tariff', '_blank')}>
              Узнать больше о PRO
            </span> */}
          </BuyProBox>
          <SentOffersList ids={id} num={requestData.num} />
        </RightBlock>
      </ContainerContent>

      {/* Модалка "Сделать предложение" */}
      {isModalOpen && (
        <ApproveModal
          closeModal={closeModal}
          amount={requestData?.requestedAmount || 0}
          period={requestData?.requestPeriod || 0}
          rate={requestData?.requestRate || 0}
        />
      )}

      <ConfirmModal
        isOpen={isProConfirmOpen}
        headerText='Покупка PRO доступа'
        bodyText={
          proTariff ? (
            <ConfirmBuy style={{ alignItems: 'center' }}>
              Подтвердите вашу покупку PRO-доступа к заявке за {proTariff.tokenCount}
              <img src={IconBalance} alt='iconBalance' />
            </ConfirmBuy>
          ) : (
            'Загрузка...'
          )
        }
        confirmText='Подтвердить'
        cancelText='Отмена'
        onConfirm={handleConfirmBuyPro}
        onCancel={handleCancelBuyPro}
        loading={isProLoading}
        confirmButtonClass='green'
        cancelButtonClass='white'
      />

      <ModalBuyingToken
        isOpen={isModalBuyingTokenOpen}
        onClose={() => setModalBuyingTokenOpen(false)}
      />

      {isSuccessModalOpen && (
        <SuccessModal
          title='Успешная покупка'
          onClose={() => setIsSuccessModalOpen(false)}
          message='Поздравляем! Вы успешно приобрели доступ к PRO.'
          buttonText='Закрыть'
        />
      )}

      {/* =========== Модалка "Форма обратной связи" =========== */}
      {isRatingModalOpen && (
        <RatingModalBackdrop>
          <RatingModalContainer>
            <ModalHeader style={{ marginBottom: '12px' }}>
              <img src={StarIconMini} alt='starIcon' />
              <div>
                <ModalTitle>Форма обратной связи</ModalTitle>
                <ModalText>
                  Поделитесь своим мнением о проекте. Ваш отзыв поможет заемщику улучшить заявку и
                  повысить шансы на успешное финансирование. Ваша обратная связь полностью анонимна.
                </ModalText>
              </div>
            </ModalHeader>

            {/* Инфо о сделке */}
            <DealInfoBox>
              <DealTitle>Сделка {requestData?.num}</DealTitle>
              <DealMeta>
                <DealMetaBox>
                  <p>Процентная ставка </p>
                  {requestData?.requestRate || 0}%
                </DealMetaBox>
                <DealMetaBox>
                  <p>Сумма договора</p>
                  {requestData?.requestedAmount?.toLocaleString() || 0} ₸
                </DealMetaBox>
                <DealMetaBox>
                  <p>Срок</p>
                  {requestData?.requestPeriod} дней
                </DealMetaBox>
              </DealMeta>
            </DealInfoBox>

            {/* Рейтинги (6 критериев) */}
            <RatingsRow>
              <RatingItem>
                <RatingLabel>
                  Финансовая устойчивость
                  <p>Насколько стабилен финансовый статус заемщика.</p>
                </RatingLabel>
                {renderStars(finStabilityRank, setFinStabilityRank, 'finStability')}
              </RatingItem>

              <RatingItem>
                <RatingLabel>
                  Условия сделки
                  <p>Выгодность и прозрачность предложенных условий.</p>
                </RatingLabel>
                {renderStars(conditionRank, setConditionRank, 'condition')}
              </RatingItem>
            </RatingsRow>
            <RatingsRow>
              <RatingItem>
                <RatingLabel>
                  Репутация заемщика
                  <p>Надежность и история заемщика на платформе.</p>
                </RatingLabel>
                {renderStars(reputationRank, setReputationRank, 'reputation')}
              </RatingItem>
              <RatingItem>
                <RatingLabel>
                  Компетенция заемщика
                  <p>Опыт и квалификация заемщика в бизнесе.</p>
                </RatingLabel>
                {renderStars(competentionRank, setCompetentionRank, 'competention')}
              </RatingItem>
            </RatingsRow>
            <RatingsRow>
              <RatingItem>
                <RatingLabel>
                  Реалистичность целей
                  <p>Насколько заявленные цели выглядят выполнимыми.</p>
                </RatingLabel>
                {renderStars(realisticRank, setRealisticRank, 'realistic')}
              </RatingItem>
              <RatingItem>
                <RatingLabel>
                  Достаточность ресурсов
                  <p>Обеспеченность заемщика необходимыми ресурсами.</p>
                </RatingLabel>
                {renderStars(resourcesRank, setResourcesRank, 'resources')}
              </RatingItem>
            </RatingsRow>

            <OverallBox>
              <div>
                <img src={StarIconMini} alt='starIcon' />
                <strong>Общая оценка</strong>
              </div>
              <ScoreText>
                {totalScore}/{maxScore}
              </ScoreText>
            </OverallBox>

            {/* Текстовые поля */}
            <FieldsBox>
              <label>Ваш комментарий</label>
              <Textarea
                placeholder='Напишите ваш комментарий'
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
              />
            </FieldsBox>
            <FieldsBox>
              <label>Чем я могу быть полезен?</label>
              <Textarea
                placeholder='Напишите ваши предложения'
                value={suggestions}
                onChange={(e) => setSuggestions(e.target.value)}
              />
            </FieldsBox>

            <ModalButtonBox>
              <NewButton
                text='Отмена'
                className='white'
                onClick={() => setIsRatingModalOpen(false)}
                disabled={isSendingFeedback}
              />
              <NewButton
                text='Отправить отзыв'
                onClick={handleSendFeedback}
                disabled={!isAllFieldsFilled || isSendingFeedback}
              />
            </ModalButtonBox>
          </RatingModalContainer>
        </RatingModalBackdrop>
      )}
      {isErrorModalOpen && buyProError && (
        <ErrorModal
          headerError='Ошибка'
          textError={buyProError}
          buttonClose='Закрыть'
          onClick={() => {
            setIsErrorModalOpen(false);
            setBuyProError('');
          }}
        />
      )}
    </Container>
  );
};
