import styled from 'styled-components';
import EnterBanner from 'assets/img/EnterBanner.webp';

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  overflow-x: scroll;
  background: #f4f4f4;
  padding: 24px;
  min-height: 90vh;
`;

const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 50%;
  align-items: flex-start;
  gap: 48px;
  background: #fff;
  justify-content: center;
  button {
    width: 100%;
  }
`;

export const LeftBlockPDF = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 50%;
  gap: 24px;
  background: #fff;
  justify-content: center;
  button {
    width: 100%;
  }
`;

const RightBlock = styled.div`
  flex: 1;
  background-image: url(${EnterBanner});
  background-size: cover;
  background-position: center;
  position: relative;
  align-items: center;
  display: flex;
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  h3 {
    color: var(--neutral-800, #303030);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 56px */
  }
  p {
    color: var(--neutral-400, #a0a0a0);
    font-size: 16px;
    font-weight: 400;
    line-height: 140%; /* 28px */
  }
  span {
    color: var(--Black-Main, #292d32);

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 17.5px */
  }
  div {
    display: flex;
    gap: 8px;
  }
`;

const ModalContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  width: 100%;
  button {
    width: 100%;
  }
`;

const StatusBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  p {
    color: var(--neutral-400, #a0a0a0);
    font-size: 16px;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
  }
  button {
    width: 100%;
  }
`;
const StatusCard = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background: var(--neutral-100, #f4f4f4);
  color: var(--Black-Main, #292d32);
  font-size: 16px;
  font-weight: 600;
  line-height: 106%; /* 16.96px */
  width: 100%;
  img {
    width: 16px;
    height: 16px;
  }
`;

const RefreshStatus = styled.p`
  color: var(--Primary, #5784f7);
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const Pdf = styled.div`
  width: 100%;
  height: 410px;
  @media (max-height: 790px) {
    height: 410px;
  }
`;

export const FAQSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  padding: 22px;
  border-radius: 8px;
  width: 100%;
  .opacity {
    opacity: 0.5;
  }
`;

export const StyledFAQItem = styled.div`
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
`;

export const FAQTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  cursor: pointer;
  color: #474747;
  font-size: 16px;
  font-weight: 600;
  div {
    display: flex;
    gap: 16px;
    align-items: center;
    img {
      width: 18px;
      height: 18px;
    }
  }
`;

export const FAQIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FAQContent = styled.div`
  padding: 0px 22px 22px 22px;
  background-color: #fff;
  display: flex;
  gap: 20px;
  flex-direction: column;
  p {
    color: #474747;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
  }
`;

export const IconBox = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: #f0f3ff;
`;

export const StepBlock = styled.div`
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: #fff;
`;

export const StepBlockHeader = styled.p`
  color: #474747;
  font-size: 16px;
  font-weight: 600;
`;
export const NoCheck = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  border: 2px solid #5784f7;
`;

export const ButtonsBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
`;

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  p {
    color: var(--neutral-800, #303030);
    font-size: 16px;
    font-weight: 500;
  }
`;

export const VeriLiveText = styled.p`
  color: var(--neutral-400, #a0a0a0);
  font-size: 16px;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
`;

export {
  Container,
  TextBox,
  ModalContainer,
  StatusBox,
  StatusCard,
  RefreshStatus,
  LeftBlock,
  RightBlock,
  Pdf,
};
