import {
  Title,
  BoxHeader,
  ControlBox,
  Container,
  Table,
  TableRowHeader,
  TableHeader,
  TableRow,
  TableCell,
  StatusCircle,
  ActionFilterBox,
  BoxInputDescription,
  BoxInput,
  BoxInfoInput,
  ButtonsBox,
  FilterBox,
  DatePickerWrapper,
  StyledDatePicker,
  CalendarIcon,
  ScrollableTableContainer,
} from 'components/ApplicationBusinessList/ApplicationBusinessList.style';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InputSearch } from 'components/common/Input/InputSearch';
import { Pagination } from 'components/Pagination';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { SuggestionsInterface } from './SuggestionsInvestorList.interface';
import { InputNew } from 'components/common/Input/InputNew/InputNew';
import { default as CalendarIcons } from 'assets/img/CalendarIcon.svg';
import { NewButton } from 'components/common/Button';
import { default as IconTune } from 'assets/img/IconTune.svg';
import { ru } from 'date-fns/locale';
import { formatNumberWithSpaces, removeSpacesFromNumber } from 'utils/numberFormat';
import SkeletonTable from 'components/SkeletonTable/SkeletonTable';
import Hint from 'components/common/Hint/Hint';
import {
  DeclineSuggestion,
  LinkContract,
} from 'components/OffersBusinessList/OffersBusinessList.style';
import { ErrorModal } from 'components/common/ErrorModal';
import { ConfirmModal } from 'components/common/ConfirmModal';

interface PageChangeEventArgs {
  selected: number;
}

const datePickerPopperProps = {
  strategy: 'fixed',
  modifiers: [
    {
      name: 'preventOverflow',
      options: {
        boundary: 'viewport',
      },
    },
    {
      name: 'offset',
      options: {
        offset: [0, 8], // настройте отступы
      },
    },
  ],
};

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/`;

const SuggestionsInvestorList = () => {
  const [searchValue, setSearchValue] = useState('');
  const [tableData, setTableData] = useState<SuggestionsInterface[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [paginationInfo, setPaginationInfo] = useState({
    totalPages: 0,
    totalElements: 0,
    currentPage: 0,
  });
  const [isLoading, setIsLoading] = useState(true);

  const [isFilterBoxOpen, setFilterBoxOpen] = useState(false);

  // Filter state
  const [requestNum, setRequestNum] = useState<string>('');
  const [minSum, setMinSum] = useState('');
  const [maxSum, setMaxSum] = useState('');
  const [minOfferTransfer, setMinOfferTransfer] = useState<number | null>(null);
  const [maxOfferTransfer, setMaxOfferTransfer] = useState<number | null>(null);
  const [minOfferPenaltyRate, setMinOfferPenaltyRate] = useState('');
  const [maxOfferPenaltyRate, setMaxOfferPenaltyRate] = useState('');
  const [minOfferRate, setMinOfferRate] = useState('');
  const [maxOfferRate, setMaxOfferRate] = useState('');
  const [minOfferPeriod, setMinOfferPeriod] = useState('');
  const [maxOfferPeriod, setMaxOfferPeriod] = useState('');
  const [dateCreateStart, setDateCreateStart] = useState<Date | null>(null);
  const [dateCreateEnd, setDateCreateEnd] = useState<Date | null>(null);
  const [statuses, setStatuses] = useState<string[]>([]);
  const [requestDtEndStart, setRequestDtEndStart] = useState<Date | null>(null);
  const [requestDtEndEnd, setRequestDtEndEnd] = useState<Date | null>(null);
  const [offerExtra, setOfferExtra] = useState<boolean | null>(null);
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Общий loading
  const [error, setError] = useState('');
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [selectedOfferId, setSelectedOfferId] = useState<number | null>(null); // Хранит выбранное предложение

  const [sortConfig, setSortConfig] = useState<{ field: string; direction: 'asc' | 'desc' | null }>(
    {
      field: '',
      direction: null,
    },
  );

  const transformedData = (data: any[]): SuggestionsInterface[] => {
    return data.map((item) => ({
      id: item.id,
      request: item.request,
      requestNum: item.requestNum,
      requestDtEnd: item.requestDtEnd,
      offerSum: item.offerSum.toLocaleString('ru-KZ', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      offerTransfer: item.offerTransfer,
      offerPeriod: item.offerPeriod,
      offerRate: item.offerRate.toLocaleString('ru-KZ', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      offerPenaltyRate: item.offerPenaltyRate.toLocaleString('ru-KZ', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      extraInfo: item.extraInfo,
      statusName: item.statusName || 'Не указан',
      overpaymentSum: item.overpaymentSum.toLocaleString('ru-KZ', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      offerExtra: item.offerExtra || '',
      status: item.status || null,
      requestInfo: item.requestInfo || null,
      contractId: item.contractId || null,
    }));
  };

  const getFilters = useMemo(
    () => ({
      requestNum: requestNum || undefined,
      minSum: minSum || undefined,
      maxSum: maxSum || undefined,
      minOfferTransfer: minOfferTransfer || undefined,
      maxOfferTransfer: maxOfferTransfer || undefined,
      minOfferPenaltyRate: minOfferPenaltyRate || undefined,
      maxOfferPenaltyRate: maxOfferPenaltyRate || undefined,
      minOfferRate: minOfferRate || undefined,
      maxOfferRate: maxOfferRate || undefined,
      minOfferPeriod: minOfferPeriod || undefined,
      maxOfferPeriod: maxOfferPeriod || undefined,
      dateCreateStart: dateCreateStart?.toISOString().split('T')[0] || undefined,
      dateCreateEnd: dateCreateEnd?.toISOString().split('T')[0] || undefined,
      statuses: statuses.length > 0 ? statuses : undefined,
      requestDtEndStart: requestDtEndStart?.toISOString().split('T')[0] || undefined,
      requestDtEndEnd: requestDtEndEnd?.toISOString().split('T')[0] || undefined,
      offerExtra: offerExtra !== null ? offerExtra : undefined,
    }),
    [
      requestNum,
      minSum,
      maxSum,
      minOfferTransfer,
      maxOfferTransfer,
      minOfferPenaltyRate,
      maxOfferPenaltyRate,
      minOfferRate,
      maxOfferRate,
      minOfferPeriod,
      maxOfferPeriod,
      dateCreateStart,
      dateCreateEnd,
      statuses,
      requestDtEndStart,
      requestDtEndEnd,
      offerExtra,
    ],
  );

  const fetchData = useCallback(
    async (page = 0) => {
      setIsLoading(true);
      try {
        const params = new URLSearchParams();
        params.append('page', page.toString());

        if (sortConfig.field && sortConfig.direction) {
          params.append('sort', `${sortConfig.field},${sortConfig.direction}`);
        }

        const response = await axios.post(
          `${BASE_URL}api/v1/cabinet/investor/request/offer/search?${params.toString()}`,
          getFilters,
          { withCredentials: true },
        );

        setTableData(transformedData(response.data.content));
        setPaginationInfo({
          totalPages: response.data.totalPages,
          totalElements: response.data.totalElements,
          currentPage: response.data.number,
        });
      } catch (error) {
        console.error('Ошибка загрузки данных:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [getFilters, sortConfig],
  );

  useEffect(() => {
    fetchData(currentPage);
  }, [fetchData, currentPage]);

  const handlePageChange = ({ selected }: PageChangeEventArgs) => {
    setCurrentPage(selected);
    fetchData(selected);
  };

  const handleSort = (field: string) => {
    setSortConfig(({ field: prevField, direction }) => ({
      field,
      direction: prevField === field ? (direction === 'asc' ? 'desc' : 'asc') : 'asc',
    }));
  };

  const applyFiltersAndSorting = () => {
    setCurrentPage(0);
    fetchData(0);
  };

  const clearFilters = () => {
    setRequestNum('');
    setMinSum('');
    setMaxSum('');
    setMinOfferTransfer(null);
    setMaxOfferTransfer(null);
    setMinOfferPenaltyRate('');
    setMaxOfferPenaltyRate('');
    setMinOfferRate('');
    setMaxOfferRate('');
    setMinOfferPeriod('');
    setMaxOfferPeriod('');
    setDateCreateStart(null);
    setDateCreateEnd(null);
    setStatuses([]);
    setRequestDtEndStart(null);
    setRequestDtEndEnd(null);
    setOfferExtra(null);

    fetchData(0);
  };

  const filteredData = useMemo(() => {
    if (searchValue) {
      return tableData.filter((row) => row.requestNum.toString().includes(searchValue));
    }
    return tableData;
  }, [searchValue, tableData]);

  const toggleFilterBox = () => {
    setFilterBoxOpen((prev) => !prev);
  };

  const handlePercentageInput = (value: string, setter: (value: string) => void) => {
    // Разрешаем только числа с точностью до двух десятичных знаков
    if (/^\d*\.?\d{0,2}$/.test(value)) {
      setter(value);
    }
  };

  const handleReject = (id: number) => {
    setLoading(true);
    const rejectUrl = `${BASE_URL}api/v1/cabinet/investor/request/offer/reject/${id}`;
    axios
      .get(rejectUrl, { withCredentials: true })
      .then(() => {
        setCurrentPage(0); // Сбросить текущую страницу на первую
        fetchData(0);
        setRejectModalOpen(false);
      })
      .catch((error) => {
        console.error('Ошибка отзыва предложения:', error);
        const errorMessage = error.response?.data?.message || 'Ошибка при отзыве предложения.';
        setRejectModalOpen(false);
        setError(errorMessage);
        setErrorDialogOpen(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container>
      <ConfirmModal
        isOpen={isRejectModalOpen && selectedOfferId !== null}
        headerText='Вы уверены, что хотите отозвать свое предложение?'
        bodyText='Данное действие приведет к отзыву предложения.'
        confirmText='Отозвать'
        cancelText='Отменить'
        onConfirm={() => selectedOfferId && handleReject(selectedOfferId)}
        onCancel={() => setRejectModalOpen(false)}
        loading={loading}
        confirmButtonClass='red'
        cancelButtonClass='green'
      />
      {errorDialogOpen && (
        <ErrorModal
          headerError='Ошибка'
          textError={error}
          buttonClose='Закрыть'
          onClick={() => setErrorDialogOpen(false)} // Закрыть модальное окно
        />
      )}
      <Title>Мои предложения</Title>
      <BoxHeader>
        <ControlBox>
          <InputSearch
            value={requestNum}
            onChange={(e) => setRequestNum(e.target.value)}
            placeholder='Введите номер сделки'
            name='searchQuery'
          />
          <FilterBox onClick={toggleFilterBox} isActive={isFilterBoxOpen}>
            <img src={IconTune} alt='Фильтр' />
          </FilterBox>
        </ControlBox>
      </BoxHeader>
      <ActionFilterBox isOpen={isFilterBoxOpen}>
        <BoxInputDescription>
          <BoxInput>
            <p>Сумма займа</p>
            <BoxInfoInput>
              <span>От</span>
              <InputNew
                placeholder='100 000'
                value={formatNumberWithSpaces(minSum)}
                onChange={(e) => {
                  const rawValue = removeSpacesFromNumber(e.target.value);
                  setMinSum(rawValue);
                }}
                maxWidth='100px'
              />
              <span>До</span>
              <InputNew
                placeholder='999 999'
                value={formatNumberWithSpaces(maxSum)}
                onChange={(e) => {
                  const rawValue = removeSpacesFromNumber(e.target.value);
                  setMaxSum(rawValue);
                }}
                maxWidth='100px'
              />
            </BoxInfoInput>
          </BoxInput>
          <BoxInput>
            <p>Начало финансирования</p>
            <BoxInfoInput>
              <span>От</span>
              <InputNew
                placeholder='0 дней'
                value={minOfferTransfer !== null ? minOfferTransfer.toString() : ''}
                onChange={(e) =>
                  setMinOfferTransfer(e.target.value ? parseFloat(e.target.value) : null)
                }
                maxWidth='100px'
                type='number'
              />
              <span>До</span>
              <InputNew
                placeholder='20 дней'
                value={maxOfferTransfer !== null ? maxOfferTransfer.toString() : ''}
                onChange={(e) =>
                  setMaxOfferTransfer(e.target.value ? parseFloat(e.target.value) : null)
                }
                maxWidth='100px'
                type='number'
              />
            </BoxInfoInput>
          </BoxInput>
          <BoxInput>
            <p>Пеня за день %</p>
            <BoxInfoInput>
              <span>От</span>
              <InputNew
                placeholder='0 %'
                value={minOfferPenaltyRate}
                onChange={(e) => handlePercentageInput(e.target.value, setMinOfferPenaltyRate)}
                maxWidth='100px'
                type='text'
              />
              <span>До</span>
              <InputNew
                placeholder='2 %'
                value={maxOfferPenaltyRate}
                onChange={(e) => handlePercentageInput(e.target.value, setMaxOfferPenaltyRate)}
                maxWidth='100px'
                type='text'
              />
            </BoxInfoInput>
          </BoxInput>
        </BoxInputDescription>
        <BoxInputDescription>
          <BoxInput>
            <p>Месячная ставка % </p>
            <BoxInfoInput>
              <span>От</span>
              <InputNew
                placeholder='1 %'
                value={minOfferRate}
                onChange={(e) => handlePercentageInput(e.target.value, setMinOfferRate)}
                maxWidth='100px'
                type='text'
              />
              <span>До</span>
              <InputNew
                placeholder='5 %'
                value={maxOfferRate}
                onChange={(e) => handlePercentageInput(e.target.value, setMaxOfferRate)}
                maxWidth='100px'
                type='text'
              />
            </BoxInfoInput>
          </BoxInput>
          <BoxInput>
            <p>Срок финансирования</p>
            <BoxInfoInput>
              <span>От</span>
              <InputNew
                placeholder='1 дня'
                value={minOfferPeriod}
                onChange={(e) => setMinOfferPeriod(e.target.value)}
                maxWidth='100px'
                type='text'
              />
              <span>До</span>
              <InputNew
                placeholder='25 дней'
                value={maxOfferPeriod}
                onChange={(e) => setMaxOfferPeriod(e.target.value)}
                maxWidth='100px'
                type='text'
              />
            </BoxInfoInput>
          </BoxInput>
        </BoxInputDescription>
        <BoxInputDescription>
          <BoxInput>
            <p>Дата предложения</p>
            <BoxInfoInput>
              <span>С</span>
              <DatePickerWrapper>
                <StyledDatePicker
                  selected={dateCreateStart}
                  dateFormat='dd/MM/yyyy'
                  onChange={(date: Date | null) => setDateCreateStart(date)}
                  placeholderText='Выберите дату'
                  locale={ru}
                  popperProps={datePickerPopperProps}
                />
                <CalendarIcon src={CalendarIcons} alt='Календарь' />
              </DatePickerWrapper>
              <span>По</span>
              <DatePickerWrapper>
                <StyledDatePicker
                  dateFormat='dd/MM/yyyy'
                  selected={dateCreateEnd}
                  onChange={(date: Date | null) => setDateCreateEnd(date)}
                  placeholderText='Выберите дату'
                  locale={ru}
                  popperProps={datePickerPopperProps}
                />
                <CalendarIcon src={CalendarIcons} alt='Календарь' />
              </DatePickerWrapper>
            </BoxInfoInput>
          </BoxInput>
          <BoxInput>
            <p>Срок завершения</p>
            <BoxInfoInput>
              <span>С</span>
              <DatePickerWrapper>
                <StyledDatePicker
                  selected={requestDtEndStart}
                  dateFormat='dd/MM/yyyy'
                  onChange={(date: Date | null) => setRequestDtEndStart(date)}
                  placeholderText='Выберите дату'
                  locale={ru}
                  popperProps={datePickerPopperProps}
                />
                <CalendarIcon src={CalendarIcons} alt='Календарь' />
              </DatePickerWrapper>
              <span>По</span>
              <DatePickerWrapper>
                <StyledDatePicker
                  dateFormat='dd/MM/yyyy'
                  selected={requestDtEndEnd}
                  onChange={(date: Date | null) => setRequestDtEndEnd(date)}
                  placeholderText='Выберите дату'
                  locale={ru}
                  popperProps={datePickerPopperProps}
                />
                <CalendarIcon src={CalendarIcons} alt='Календарь' />
              </DatePickerWrapper>
            </BoxInfoInput>
          </BoxInput>
        </BoxInputDescription>
        <ButtonsBox>
          <NewButton text='Сбросить все фильтры' className='white small' onClick={clearFilters} />
          <NewButton text='Применить фильтры' className='small' onClick={applyFiltersAndSorting} />
        </ButtonsBox>
      </ActionFilterBox>
      {isLoading ? (
        <SkeletonTable />
      ) : (
        <>
          <ScrollableTableContainer>
            <Table>
              <thead>
                <TableRowHeader>
                  <TableHeader
                    isSorted={sortConfig.field === 'requestNum'}
                    sortable={true}
                    direction={sortConfig.field === 'requestNum' ? sortConfig.direction : null}
                    onClick={() => handleSort('requestNum')}>
                    <Hint displayText='№ Сделки' text=' Идентификатор сделки' />
                  </TableHeader>
                  <TableHeader
                    isSorted={sortConfig.field === 'offerSum'}
                    sortable={true}
                    direction={sortConfig.field === 'offerSum' ? sortConfig.direction : null}
                    onClick={() => handleSort('offerSum')}
                    className='right'>
                    <Hint displayText='Предл. сумма' text='Моя предложенная сумма' />
                  </TableHeader>
                  <TableHeader
                    isSorted={sortConfig.field === 'offerPeriod'}
                    sortable={true}
                    direction={sortConfig.field === 'offerPeriod' ? sortConfig.direction : null}
                    onClick={() => handleSort('offerPeriod')}
                    className='right'>
                    <Hint
                      displayText='Срок. фин.'
                      text='Период, на который предоставляется финансирование'
                    />
                  </TableHeader>
                  <TableHeader
                    isSorted={sortConfig.field === 'offerTransfer'}
                    sortable={true}
                    direction={sortConfig.field === 'offerTransfer' ? sortConfig.direction : null}
                    onClick={() => handleSort('offerTransfer')}
                    className='right'>
                    <Hint displayText='Старт. фин.' text='Начало периода финансирования в днях' />
                  </TableHeader>
                  <TableHeader
                    isSorted={sortConfig.field === 'offerRate'}
                    sortable={true}
                    direction={sortConfig.field === 'offerRate' ? sortConfig.direction : null}
                    onClick={() => handleSort('offerRate')}
                    className='right'>
                    <Hint displayText=' Мес. ставка' text='Процентная ставка за месяц' />
                  </TableHeader>
                  <TableHeader
                    isSorted={sortConfig.field === 'offerPenaltyRate'}
                    sortable={true}
                    direction={
                      sortConfig.field === 'offerPenaltyRate' ? sortConfig.direction : null
                    }
                    onClick={() => handleSort('offerPenaltyRate')}
                    className='right'>
                    <Hint displayText='Пеня/день' text='Штраф за просрочку платежей в день' />
                  </TableHeader>
                  <TableHeader className='right'>
                    <Hint displayText='Доход' text='Сумма дохода от финансирования сделки' />
                  </TableHeader>
                  <TableHeader sortable={false}>
                    <Hint
                      displayText='Статус предложения'
                      text='Статус вашего предложения по сделке'
                    />
                  </TableHeader>
                  <TableHeader width='100px'>
                    <Hint displayText='Действие' text='Действие' />
                  </TableHeader>{' '}
                </TableRowHeader>
              </thead>
              <tbody>
                {filteredData.map((row) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell>
                        <Link
                          style={{ color: '#5784F7', textDecoration: 'none' }}
                          to={`/request/${row.request}`}>
                          {row.requestNum}
                        </Link>
                      </TableCell>
                      <TableCell className='right'>{row.offerSum} ₸</TableCell>
                      <TableCell className='right'>{row.offerPeriod} д</TableCell>
                      <TableCell className='right'>{row.offerTransfer} д</TableCell>
                      <TableCell className='right'>{row.offerRate} %</TableCell>
                      <TableCell className='right'>{row.offerPenaltyRate} %</TableCell>
                      <TableCell className='right'>{row.overpaymentSum} ₸</TableCell>
                      <TableCell>
                        <div>
                          <StatusCircle $status={row.statusName || ''} />
                          <Hint text={String(row.statusName)} truncatedLength={11} />
                        </div>
                      </TableCell>
                      <TableCell>
                        {row.statusName === 'Принят' && row.contractId ? (
                          <LinkContract href={`/contracts/${row.contractId}`}>
                            К договору
                          </LinkContract>
                        ) : row.statusName === 'Активный' ? (
                          <div>
                            <DeclineSuggestion
                              onClick={() => {
                                setSelectedOfferId(row.id);
                                setRejectModalOpen(true);
                              }}>
                              Отозвать{' '}
                            </DeclineSuggestion>
                          </div>
                        ) : (
                          ''
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </tbody>
            </Table>
          </ScrollableTableContainer>
          <Pagination
            pageCount={Math.max(paginationInfo.totalPages, 1)}
            currentPage={Math.min(currentPage, paginationInfo.totalPages - 1)}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </Container>
  );
};

export default SuggestionsInvestorList;
