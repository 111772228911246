import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  CostMoneyBox,
  CostMoneyCount,
  CostMoneyItem,
  CostMoneyLine,
  CostMoneyText,
} from './CoastMoney.style';
import PromptWindow from 'components/PromptWindow/PromptWindow';
import { ErrorModal } from 'components/common/ErrorModal';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

export const CoastMoney = () => {
  const [rates, setRates] = useState<{ demand: number; supply: number; avg: number } | null>(null);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/v1/ui/report/costmoney`, { withCredentials: true })
      .then((response) => {
        const data = response.data.reduce((acc: any, item: any) => {
          acc[item.name] = parseFloat(item.value.toFixed(1));
          return acc;
        }, {});
        setRates(data);
      })
      .catch((error) => {
        console.error('Ошибка загрузки ставок:', error);
        setHasError(true);
      });
  }, []);

  const closeErrorModal = () => {
    setHasError(false);
  };

  return (
    <>
      {hasError && (
        <ErrorModal
          headerError='Ошибка загрузки данных'
          textError='Не удалось получить информацию о ставках. Пожалуйста, попробуйте позже.'
          buttonClose='Закрыть'
          onClick={closeErrorModal}
        />
      )}

      {rates && (
        <CostMoneyBox>
          <CostMoneyItem>
            <CostMoneyText>
              Ставка по спросу
              <PromptWindow
                bottom
                description='Средняя ставка, которую запрашивают заёмщики. Это отражает, под какой процент бизнес готов привлекать средства.'
              />
            </CostMoneyText>
            <CostMoneyLine />
            <CostMoneyCount>{rates.demand}%</CostMoneyCount>
          </CostMoneyItem>
          <CostMoneyItem>
            <CostMoneyText>
              Ставка по предложению
              <PromptWindow
                bottom
                description='Средняя ставка, по которой инвесторы готовы вложить деньги в бизнес. Чем выше спрос со стороны инвесторов — тем выше может быть эта ставка.'
              />
            </CostMoneyText>
            <CostMoneyLine />
            <CostMoneyCount>{rates.supply}%</CostMoneyCount>
          </CostMoneyItem>
          <CostMoneyItem>
            <CostMoneyText>
              Равновесная ставка
              <PromptWindow
                bottom
                description='Условный «баланс» между спросом и предложением. Отражает точку, где интересы инвесторов и заёмщиков совпадают. Используется как ориентир для сделок.'
              />
            </CostMoneyText>
            <CostMoneyLine />
            <CostMoneyCount>{rates.avg}%</CostMoneyCount>
          </CostMoneyItem>
        </CostMoneyBox>
      )}
    </>
  );
};
