import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Container, MainInfoContent, PdfViewerContainer } from './InfoCreditStory.style';
import { saveAs } from 'file-saver';

import {
  ExpandableSection,
  MainInfoTitle,
  MainInfoBox,
  ArrowIcon,
} from 'components/ApplicationInvestor/ApplicationInvestorNew.style';
import {
  FileItem,
  FileLink,
} from 'components/ApplicationInvestor/ApplicationDocumentsList/ApplicationDocumentsList.style';

import IconWorkHistory from 'assets/img/IconWorkHistory.svg';
import IconArrow from 'assets/img/IconArrow.svg';
import IconArrowDown from 'assets/img/IconArrowDown.svg';
import { ErrorModal } from 'components/common/ErrorModal';
import { pdfjs } from 'react-pdf';

import IconDocuments from 'assets/img/IconDocuments.svg';
import { LoadingGif } from 'components/common/LoadingGif';
import IconPro from 'assets/img/IconPro.svg';

export interface InfoCreditProps {
  id: string | number;
}

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;

const InfoCreditStory: React.FC<InfoCreditProps> = ({ id }) => {
  const [pdfContent, setPdfContent] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isMainInfoExpanded, setIsMainInfoExpanded] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isAdditionalReportExpanded, setIsAdditionalReportExpanded] = useState(false);

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}cabinet/investor/request/creditor/credit_history/${id}/pdf`,
          {
            responseType: 'arraybuffer',
            withCredentials: true,
          },
        );
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blob);
        setPdfContent(fileURL);
      } catch (err) {
        setError('Ошибка при загрузке PDF.');
      } finally {
        setLoading(false);
      }
    };

    fetchPdf();
  }, [id]);

  const handleDownloadPDF = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}cabinet/investor/request/creditor/credit_history/${id}/pdf`,
        {
          responseType: 'blob',
          withCredentials: true,
        },
      );
      const blob = new Blob([response.data], { type: 'application/pdf' });
      saveAs(blob, `Credit_History_${id}.pdf`);
    } catch (err) {
      setError('Ошибка при скачивании, отчет отсутствует.');
    }
  };

  const closeErrorModal = () => {
    setError(null);
  };

  if (loading) {
    return <LoadingGif />;
  }

  if (error || !pdfContent) {
    return (
      <Container>
        <ExpandableSection>
          <MainInfoTitle>
            <img src={IconWorkHistory} alt='icon' />
            Кредитная история отсутствует
          </MainInfoTitle>
        </ExpandableSection>
      </Container>
    );
  }

  return (
    <>
      {error && (
        <ErrorModal
          headerError='Произошла ошибка'
          textError={error}
          buttonClose='Закрыть'
          onClick={closeErrorModal}
        />
      )}
      <Container>
        <ExpandableSection>
          <MainInfoTitle onClick={() => setIsAdditionalReportExpanded(!isAdditionalReportExpanded)}>
            <img src={IconWorkHistory} alt='icon' />
            Скачать расширенный кредитный отчет
            <img src={IconPro} alt='iconPro' />
            <ArrowIcon src={isAdditionalReportExpanded ? IconArrowDown : IconArrow} alt='arrow' />
          </MainInfoTitle>
          {isAdditionalReportExpanded && (
            <MainInfoBox>
              <MainInfoContent>
                <FileItem>
                  <FileLink onClick={() => handleDownloadPDF()}>
                    <div>
                      <img src={IconDocuments} alt='download icon' />
                      Кредитный отчет
                    </div>
                    <p>скачать</p>
                  </FileLink>
                </FileItem>
              </MainInfoContent>
            </MainInfoBox>
          )}
        </ExpandableSection>

        <ExpandableSection>
          <MainInfoTitle onClick={() => setIsMainInfoExpanded(!isMainInfoExpanded)}>
            <img src={IconWorkHistory} alt='icon' />
            Кредитная история
            <img src={IconPro} alt='iconPro' />
            <ArrowIcon src={isMainInfoExpanded ? IconArrowDown : IconArrow} alt='arrow' />
          </MainInfoTitle>
          {isMainInfoExpanded && (
            <MainInfoBox>
              <MainInfoContent>
                <PdfViewerContainer>
                  <Worker
                    workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
                    <Viewer fileUrl={pdfContent} />
                  </Worker>
                </PdfViewerContainer>
              </MainInfoContent>
            </MainInfoBox>
          )}
        </ExpandableSection>
      </Container>
    </>
  );
};

export default InfoCreditStory;
