import React, { useEffect, useState, useCallback, useRef } from 'react';
import axios from 'axios';
import { pdfjs } from 'react-pdf';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import {
  Container,
  TextBox,
  ModalContainer,
  StatusBox,
  StatusCard,
  RefreshStatus,
  LeftBlock,
  RightBlock,
  Pdf,
  StyledFAQItem,
  FAQTitle,
  FAQIcon,
  FAQContent,
  IconBox,
  FAQSection,
  StepBlock,
  StepBlockHeader,
  NoCheck,
  ButtonsBox,
  LeftBlockPDF,
  InputBox,
} from './KycNew.style';
import IconUpdate from 'assets/img/IconUpdate.svg';
import IconCheck from 'assets/img/IconCheck.svg';
import IconCheckMiss from 'assets/img/IconCheckMiss.svg';
import IconCheckDone from 'assets/img/IconCheckDone.svg';
import { default as EnterSiteIcon } from 'assets/img/EnterSiteIcon.svg';

import { default as IconVerifMini } from 'assets/img/IconVerifMini.svg';
import { NewButton } from 'components/common/Button';
import Llive from './LIive';
import { offerSign } from '../TestStand/signtest/offerSign';
import { useAppContext } from 'contexts/AppContext';
import { ErrorModal } from 'components/common/ErrorModal';
import { LoadingGif } from 'components/common/LoadingGif';
import SuccessModal from 'components/common/SuccessModal/SuccessModal';
import { FAQItemProps, KycStatusType } from './KycNew.interface';
import { ArrowIcon } from 'components/EnterSite/EnterSite';
import { faqData, faqDataLive, faqDataPDF, faqDataSms } from './FAQData';
import { InputNew } from 'components/common/Input/InputNew/InputNew';
import { useNavigate } from 'react-router-dom';
import { logOut } from 'components/api/logOut.api';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

const FAQItem: React.FC<FAQItemProps> = ({ index, isOpen, onClick, data }) => {
  const { title, content } = data[index];

  return (
    <StyledFAQItem>
      <FAQTitle onClick={() => onClick(index)}>
        <div>
          <IconBox>
            <img src={EnterSiteIcon} alt='iconInfo' />
          </IconBox>
          {title}
        </div>
        <FAQIcon>
          <ArrowIcon rotated={isOpen} />
        </FAQIcon>
      </FAQTitle>
      {isOpen && <FAQContent>{content}</FAQContent>}
    </StyledFAQItem>
  );
};

const KycNew = () => {
  const { userDataLight } = useAppContext();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState<string>('firstStep');
  const [code, setCode] = useState('');
  const [kycStatus, setKycStatus] = useState<KycStatusType>({
    liveness: null,
    document: null,
    faceMatching: null,
    validityDocument: null,
    compareToEDS: null,
    offerSigned: null,
  });
  const [error, setError] = useState<string | null>(null);
  const [faqOpen, setFaqOpen] = useState<number | null>(null);
  const [documentContent, setDocumentContent] = useState('');
  const [docId, setDocId] = useState('');
  const [fileHash, setFileHash] = useState('');
  const [isSigning, setIsSigning] = useState(false); // <-- for showing loader if signing takes time
  const [isLoading, setIsLoading] = useState(false); // 1) Флаг "загрузка"
  const [isKycSuccessModalOpen, setIsKycSuccessModalOpen] = useState(false);

  const base64ToBlob = useCallback((base64: string, type = 'application/pdf') => {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return new Blob([bytes], { type });
  }, []);

  // -----------------------------------------
  // 5) Проверка, все ли статусы true
  // -----------------------------------------
  const allStatusesTrue = useCallback(
    () => Object.values(kycStatus).every((status) => status === true),
    [kycStatus],
  );

  const navigateToApplication = () => {
    navigate('/applications');
  };

  // This effect checks if all KYC statuses are true to redirect the user
  useEffect(() => {
    if (allStatusesTrue()) {
      setIsKycSuccessModalOpen(true);
      setCurrentPage('status');
    }
  }, [kycStatus, allStatusesTrue]);

  // -----------------------------------------
  // 6) fetchKycStatus обёрнут в useCallback
  // чтобы не пересоздавать функцию на каждый ререндер
  // -----------------------------------------
  const fetchKycStatus = useCallback(async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/v1/kyc/status`, {
        withCredentials: true,
      });
      const data = response.data;

      setKycStatus(data);

      // Если только оферта подписана, а всё остальное ещё null — сразу переходим к верификации лица
      if (
        data.offerSigned === true &&
        data.liveness === null &&
        data.document === null &&
        data.faceMatching === null &&
        data.validityDocument === null &&
        data.compareToEDS === null
      ) {
        setCurrentPage('veriLive');
      }
      if (
        data.offerSigned === true &&
        data.liveness === true &&
        data.document === null &&
        data.faceMatching === null &&
        data.validityDocument === null &&
        data.compareToEDS === null
      ) {
        setCurrentPage('nextStepIdentifier');
      }
    } catch (err) {
      console.error('Failed to fetch KYC status:', err);
      setError('Failed to fetch KYC status. Please try again.');
    }
  }, []);

  // Reset KYC status
  const resetKycStatus = useCallback(async () => {
    try {
      await axios.get(`${BASE_URL}/api/v1/kyc/reset`, {
        withCredentials: true,
      });
      window.location.reload();
    } catch (err) {
      console.error('Ошибка при сбросе статуса KYC:', err);
      setError('Ошибка при сбросе статуса KYC. Please try again.');
    }
  }, []);

  // Fetch the PDF offer document
  const fetchDocument = useCallback(async () => {
    try {
      const response = await axios.get(`${BASE_URL}/public/api/v1/ui/doc/public/offer`, {
        withCredentials: true,
        responseType: 'json',
      });
      const { docId, hashOffer, offer } = response.data;

      setDocId(docId);
      setFileHash(hashOffer);

      // Используем нашу вспомогательную функцию
      const blob = base64ToBlob(offer);
      const fileURL = URL.createObjectURL(blob);
      setDocumentContent(fileURL);
    } catch (err) {
      console.error('Failed to fetch document:', err);
      setError('Failed to fetch document. Please try again.');
    }
  }, [base64ToBlob]);

  // -----------------------------------------
  // Поллинг статусов (если пользователь на странице 'status')
  // -----------------------------------------

  const pollingTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (currentPage !== 'status') return;

    let isActive = true;

    const fetchAndSchedule = async () => {
      if (!isActive) return;

      try {
        await fetchKycStatus();
      } catch (error) {
        console.error('Ошибка при поллинге статуса KYC:', error);
      } finally {
        if (isActive && !allStatusesTrue()) {
          pollingTimeoutRef.current = setTimeout(fetchAndSchedule, 5000);
        }
      }
    };

    // Очищаем возможный предыдущий таймер
    if (pollingTimeoutRef.current) {
      clearTimeout(pollingTimeoutRef.current);
    }

    fetchAndSchedule();

    return () => {
      isActive = false;
      if (pollingTimeoutRef.current) {
        clearTimeout(pollingTimeoutRef.current);
      }
    };
  }, [currentPage, fetchKycStatus, allStatusesTrue]);

  // -----------------------------------------
  // Если статус у пользователя KYC_IN_PROCESS или KYC_FAIL
  // то переходим на страницу 'status' (автоматически)
  // -----------------------------------------
  useEffect(() => {
    if (userDataLight?.status === 'KYC_IN_PROCESS' || userDataLight?.status === 'KYC_FAIL') {
      setCurrentPage('status');
    }
  }, [userDataLight]);

  // -----------------------------------------
  // При изменении userDataLight или currentPage подтягиваем статусы и документ
  // -----------------------------------------
  useEffect(() => {
    fetchKycStatus();
    if (currentPage === 'ofertaSign') {
      fetchDocument();
    }
  }, [userDataLight, currentPage, fetchDocument, fetchKycStatus]);

  // -----------------------------------------
  // Sign the PDF
  // -----------------------------------------
  const handleSignClick = useCallback(async () => {
    // Хотя fileHash, docId, userDataLight могут меняться —
    // дополнительно указываем их в deps массива useCallback, если хотим
    const documentType = 'PUBLIC_OFFER_REGISTRATION';
    const endpoint = `${BASE_URL}/api/v1/ui/user/sign/public/offer`;

    try {
      setIsSigning(true);
      const response = await offerSign(
        fileHash,
        endpoint,
        docId,
        userDataLight?.iin, // На всякий случай userDataLight?.iin
        userDataLight?.bin, // И тут тоже
        documentType,
      );
      if (response.status === 200) {
        handleNextVerilive();
      }
    } catch (err) {
      console.error('Ошибка при подписании:', err);
      setError('Ошибка при подписании. Please try again.');
    } finally {
      setIsSigning(false);
    }
  }, [fileHash, docId, userDataLight, setError]);

  // -----------------------------------------
  // Переходы по экранам
  // -----------------------------------------
  const handleFirstStep = useCallback(() => {
    setCurrentPage('ofertaSign');
  }, []);

  const handleNextVerilive = useCallback(() => {
    setCurrentPage('veriLive');
  }, []);

  // -----------------------------------------
  // Обработка ввода 6-значного кода
  // -----------------------------------------
  const handleNextStatus = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${BASE_URL}/api/v1/kyc/id/${code}`, {
        withCredentials: true,
      });
      // Предположим, если ответ 200, значит ок
      if (response.status === 200) {
        setCurrentPage('status');
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        setError('Невалидный код');
      } else {
        console.error('Failed to send code:', error);
        setError('Failed to send code. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  }, [code, setCurrentPage]);

  // -----------------------------------------
  // Колбек после прохождения живости (Llive)
  // -----------------------------------------
  const onCompletion = useCallback(() => {
    setCurrentPage('nextStepIdentifier');
  }, []);

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    const input = e.target.value;
    if (/^\d{0,6}$/.test(input)) {
      setCode(input);
    }
  };

  // FAQ toggles
  const handleFAQClick = (index: number) => {
    setFaqOpen(faqOpen === index ? null : index);
  };

  const closeModal = () => {
    setError(null);
  };

  const handleLogout = async () => {
    try {
      await logOut();
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <Container>
      {isKycSuccessModalOpen && (
        <SuccessModal
          onClose={async () => {
            setIsKycSuccessModalOpen(false);
            await handleLogout();
            window.location.href = '/login';
          }}
          title='Вы успешно прошли KYC!'
          message='Для обеспечения безопасности и обновления вашего статуса необходимо повторно авторизоваться. Пожалуйста, войдите в систему, чтобы продолжить работу.'
          buttonText='Продолжить'
        />
      )}

      {currentPage === 'ofertaSign' && (
        <>
          <LeftBlockPDF>
            <TextBox>
              <h3>Прохождение верификации</h3>
              <p>
                Для вашей безопасности и защиты платформы мы просим пройти верификацию. Это
                необходимо для подтверждения вашей личности и обеспечения доверия между участниками.
                Процесс займёт всего пару минут.
              </p>
            </TextBox>
            {documentContent ? (
              <Pdf>
                <Worker
                  workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
                  <Viewer fileUrl={documentContent} renderLoader={() => <LoadingGif />} />
                </Worker>
              </Pdf>
            ) : (
              <LoadingGif />
            )}
            <ButtonsBox>
              <NewButton text='Подписать с ЭЦП' onClick={handleSignClick} disabled={isSigning} />
              <NewButton
                className='white'
                text='Вернуться на платформу'
                onClick={navigateToApplication}
              />
            </ButtonsBox>
          </LeftBlockPDF>
          <RightBlock>
            <FAQSection>
              <StepBlock>
                <StepBlockHeader>1. Начало</StepBlockHeader>
                <img src={IconCheckDone} alt='iconDone' />
              </StepBlock>
              <StepBlock>
                <StepBlockHeader>2. Подписание оферты</StepBlockHeader>
                <NoCheck></NoCheck>
              </StepBlock>

              {faqDataPDF.map((_, index) => (
                <FAQItem
                  key={index}
                  index={index}
                  isOpen={faqOpen === index}
                  onClick={handleFAQClick}
                  data={faqDataPDF}
                />
              ))}
              <StepBlock className='opacity'>
                <StepBlockHeader>3. Верификация личности</StepBlockHeader>
                <NoCheck></NoCheck>
              </StepBlock>
              <StepBlock className='opacity'>
                <StepBlockHeader>4. Подтверждение документа</StepBlockHeader>
                <NoCheck></NoCheck>
              </StepBlock>
            </FAQSection>
          </RightBlock>
        </>
      )}

      {currentPage === 'nextStepIdentifier' && (
        <>
          <LeftBlock>
            <TextBox>
              <h3>Подтвердите ваш документ</h3>
              <p>
                Введите 6-значный код из приложения eGov, Kaspi.kz или Halyk. Этот код подтверждает
                подлинность вашего документа и обновляется каждые 60 секунд, поэтому он безопасен и
                его невозможно использовать повторно.
              </p>
            </TextBox>
            <InputBox>
              <p>Введите 6-значный код</p>
              <InputNew
                placeholder='6-значный код'
                type='text'
                value={code}
                onChange={handleCodeChange}
                error={error}
              />
            </InputBox>
            <ButtonsBox>
              <NewButton
                text={isLoading ? 'Загрузка...' : 'Подтвердить документ'}
                onClick={handleNextStatus}
                disabled={code.length !== 6 || isLoading}
              />
              <NewButton
                className='white'
                text='Вернуться на платформу'
                onClick={handleNextVerilive}
              />
            </ButtonsBox>
          </LeftBlock>
          <RightBlock>
            <FAQSection>
              <StepBlock>
                <StepBlockHeader>1. Начало</StepBlockHeader>
                <img src={IconCheckDone} alt='iconDone' />
              </StepBlock>
              <StepBlock>
                <StepBlockHeader>2. Подписание оферты</StepBlockHeader>
                <img src={IconCheckDone} alt='iconDone' />
              </StepBlock>
              <StepBlock>
                <StepBlockHeader>3. Верификация личности</StepBlockHeader>
                <img src={IconCheckDone} alt='iconDone' />
              </StepBlock>
              <StepBlock>
                <StepBlockHeader>4. Подтверждение документа</StepBlockHeader>
                <NoCheck></NoCheck>
              </StepBlock>
              {faqDataSms.map((_, index) => (
                <FAQItem
                  key={index}
                  index={index}
                  isOpen={faqOpen === index}
                  onClick={handleFAQClick}
                  data={faqDataSms}
                />
              ))}
            </FAQSection>
          </RightBlock>
        </>
      )}

      {currentPage === 'veriLive' && (
        <>
          <LeftBlock>
            <TextBox>
              <h3>Верификация личности</h3>
              <p>
                Запустите окно верификации. Следуйте инструкциям, отображаемым на экране. Убедитесь,
                что у браузера есть доступ к веб-камере вашего устройства.
              </p>
            </TextBox>
            <ModalContainer>
              <Llive onCompletion={onCompletion} />
            </ModalContainer>
            <NewButton
              className='white'
              text='Вернуться на платформу'
              onClick={navigateToApplication}
            />
          </LeftBlock>
          <RightBlock>
            <FAQSection>
              <StepBlock>
                <StepBlockHeader>1. Начало</StepBlockHeader>
                <img src={IconCheckDone} alt='iconDone' />
              </StepBlock>
              <StepBlock>
                <StepBlockHeader>2. Подписание оферты</StepBlockHeader>
                <img src={IconCheckDone} alt='iconDone' />
              </StepBlock>
              <StepBlock>
                <StepBlockHeader>3. Верификация личности</StepBlockHeader>
                <NoCheck></NoCheck>
              </StepBlock>
              {faqDataLive.map((_, index) => (
                <FAQItem
                  key={index}
                  index={index}
                  isOpen={faqOpen === index}
                  onClick={handleFAQClick}
                  data={faqDataLive}
                />
              ))}

              <StepBlock className='opacity'>
                <StepBlockHeader>4. Подтверждение документа</StepBlockHeader>
                <NoCheck></NoCheck>
              </StepBlock>
            </FAQSection>
          </RightBlock>
        </>
      )}

      {currentPage === 'status' && (
        <>
          <LeftBlock>
            <StatusBox>
              <StatusCard>
                Верификация живости
                <img
                  src={
                    kycStatus.liveness === true
                      ? IconCheckDone
                      : kycStatus.liveness === false
                        ? IconCheckMiss
                        : IconCheck
                  }
                  alt='icon'
                />
              </StatusCard>
              <StatusCard>
                Проверка удостоверения личности
                <img
                  src={
                    kycStatus.document === true
                      ? IconCheckDone
                      : kycStatus.document === false
                        ? IconCheckMiss
                        : IconCheck
                  }
                  alt='icon'
                />
              </StatusCard>
              <StatusCard>
                Сравнение фото с документом
                <img
                  src={
                    kycStatus.faceMatching === true
                      ? IconCheckDone
                      : kycStatus.faceMatching === false
                        ? IconCheckMiss
                        : IconCheck
                  }
                  alt='icon'
                />
              </StatusCard>
              <StatusCard>
                Проверка валидности документа
                <img
                  src={
                    kycStatus.validityDocument === true
                      ? IconCheckDone
                      : kycStatus.validityDocument === false
                        ? IconCheckMiss
                        : IconCheck
                  }
                  alt='icon'
                />
              </StatusCard>
              <StatusCard>
                Сравнение документов с ЭЦП
                <img
                  src={
                    kycStatus.compareToEDS === true
                      ? IconCheckDone
                      : kycStatus.compareToEDS === false
                        ? IconCheckMiss
                        : IconCheck
                  }
                  alt='icon'
                />
              </StatusCard>
              <StatusCard>
                Подписание Оферты
                <img
                  src={
                    kycStatus.offerSigned === true
                      ? IconCheckDone
                      : kycStatus.offerSigned === false
                        ? IconCheckMiss
                        : IconCheck
                  }
                  alt='icon'
                />
              </StatusCard>
              <p>
                Процедура проверки обычно занимает не более 24 часов. Уведомление о прохождении
                верификации придет Вам на электронную почту
              </p>
            </StatusBox>
            <ButtonsBox>
              {Object.values(kycStatus).some((status) => status === false) ? (
                <RefreshStatus onClick={resetKycStatus}>Пройти повторную верификацию</RefreshStatus>
              ) : (
                <NewButton text='Обновить статусы' icon={IconUpdate} onClick={fetchKycStatus} />
              )}
              <NewButton
                className='white'
                text='Вернуться на платформу'
                onClick={handleNextVerilive}
              />
            </ButtonsBox>
          </LeftBlock>
          <RightBlock>
            <FAQSection>
              <StepBlock>
                <StepBlockHeader>1. Начало</StepBlockHeader>
                <img src={IconCheckDone} alt='iconDone' />
              </StepBlock>
              <StepBlock>
                <StepBlockHeader>2. Подписание оферты</StepBlockHeader>
                <img src={IconCheckDone} alt='iconDone' />
              </StepBlock>
              <StepBlock>
                <StepBlockHeader>3. Верификация личности</StepBlockHeader>
                <img src={IconCheckDone} alt='iconDone' />
              </StepBlock>
              <StepBlock>
                <StepBlockHeader>4. Подтверждение документа</StepBlockHeader>
                <img src={IconCheckDone} alt='iconDone' />
              </StepBlock>
            </FAQSection>
          </RightBlock>
        </>
      )}

      {currentPage === 'firstStep' && (
        <>
          <LeftBlock>
            <TextBox>
              <h3>Прохождение верификации</h3>
              <p>
                Для вашей безопасности и защиты платформы мы просим пройти верификацию. Это
                необходимо для подтверждения вашей личности и обеспечения доверия между участниками.
                Процесс займёт всего пару минут.
              </p>
            </TextBox>
            <ButtonsBox>
              <NewButton
                icon={IconVerifMini}
                text='Начать верификацию'
                onClick={handleFirstStep}
                iconPosition='left'
              />
              <NewButton
                className='white'
                text='Вернуться на платформу'
                onClick={() => navigateToApplication()}
              />
            </ButtonsBox>
          </LeftBlock>
          <RightBlock>
            <FAQSection>
              <StepBlock>
                <StepBlockHeader>1. Начало</StepBlockHeader>
                <NoCheck></NoCheck>
              </StepBlock>

              {faqData.map((_, index) => (
                <FAQItem
                  key={index}
                  index={index}
                  isOpen={faqOpen === index}
                  onClick={handleFAQClick}
                  data={faqData}
                />
              ))}
              <StepBlock className='opacity'>
                <StepBlockHeader>2. Подписание оферты</StepBlockHeader>
                <NoCheck></NoCheck>
              </StepBlock>
              <StepBlock className='opacity'>
                <StepBlockHeader>3. Верификация личности</StepBlockHeader>
                <NoCheck></NoCheck>
              </StepBlock>
              <StepBlock className='opacity'>
                <StepBlockHeader>4. Подтверждение документа</StepBlockHeader>
                <NoCheck></NoCheck>
              </StepBlock>
            </FAQSection>
          </RightBlock>
        </>
      )}

      {error && (
        <ErrorModal
          headerError='Ошибка'
          textError={error}
          buttonClose='Закрыть'
          onClick={closeModal}
        />
      )}
    </Container>
  );
};

export default KycNew;
