import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
`;

export const MainInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px;
  border-radius: 0px 0px 8px 8px;
  background: var(--neutral-50, #fefefe);
`;

export const FileItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;

  gap: 8px;
  width: 100%;
  justify-content: space-between;
  border-radius: 6px;
  opacity: 0.8;
  background: #f4f4f4;
`;

export const FileLink = styled.a`
  align-items: center;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  color: var(--Text-text-primary, #161616);
  font-feature-settings:
    'liga' off,
    'clig' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  text-decoration-line: underline;
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
  }
`;

export const DeleteIcon = styled.div`
  cursor: pointer;

  img {
    width: 16px;
    height: 16px;
  }
`;

export const EditIcon = styled.div`
  cursor: pointer;

  img {
    width: 16px;
    height: 16px;
  }
`;

export const UploadBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 16px;
  label {
    color: var(--neutral-800, var(--neutral-800, #303030));
    font-family: 'IBM Plex Sans';
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.16px;
  }
  p {
    color: var(--neutral-500, var(--neutral-500, #848484));
    font-family: 'IBM Plex Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.16px;
  }
`;

export const FileUploadArea = styled.div`
  border: 2px dashed #9aa4b0;
  padding: 10px;
  display: flex;
  height: 96px;
  color: #5784f7;
  cursor: pointer;
  position: relative;
`;

export const FileUploadInput = styled.input`
  display: none;
`;

export const WarningMessage = styled.span`
  color: red;
  font-size: 14px;
`;

export const DocumentsBox = styled.div`
  display: flex;
  padding: 8px 12px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  align-self: stretch;
  border-radius: 6px;
  opacity: 0.8;
  background: #f4f4f4;
`;

export const ButtonBox = styled.div`
  display: flex;
  gap: 8px;
`;
